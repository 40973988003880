import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
// import { useLocation } from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';
import 'rsuite/lib/styles/index.less'; // or 'rsuite/dist/styles/rsuite-default.css'
import LayoutMain from './layouts/Main';
import GeneralLayout from './layouts/Module/General/Main';
import './neoverv/scss/styles.scss';
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import 'react-toastify/dist/ReactToastify.css';
import ResetPassword from './views/pages/Module/General/forgotpassword/ResetPassword';
import Spinner from 'react-bootstrap/Spinner';
import ErrorMsg from '../src/views/elements/module/general/ErrorMsg/ErrorMsg';

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(reducers,composeEnhancers(applyMiddleware(thunk)));

// Routes import
import SignUp from './routes/SignUp';

// Themes import
import { theme, components, defaultProps } from "./theme";

// Mantine libs
import { Global, MantineProvider } from "@mantine/core";
import { NotificationsProvider } from '@mantine/notifications';

import UtilityBillingTemplate from "./views/pages/Module/UtilityBilling/UtilityBillingTemplate";
import ErrorBoundary from './helpers/ErrorBoundaries';

export default function App() {
  // const location = useLocation();

  // React.useEffect(() => {
  //   console.log('Location changed');
  // }, [location]);

  const [currentVersion, setCurrentVersion] = useState(null);

  useEffect(() => {
    // Fetch current version from the server
    const fetchVersion = async () => {
      try {
        const response = await fetch('/version.json', {
          headers: {
            'Cache-Control': 'no-cache', // Ensures cache is bypassed
            'Pragma': 'no-cache', // Compatibility with older HTTP 1.0 caches
            'Expires': '0', // Immediately expire cache
          },
        });
        const data = await response.json();
        return data.version;
      } catch (error) {
        console.error('Failed to fetch version:', error);
        return null;
      }
    };

    // Check for updates every 60 seconds
    const checkForUpdates = async () => {
      const latestVersion = await fetchVersion();
      if (!currentVersion) {
        setCurrentVersion(latestVersion);
        return;
      }

      if (currentVersion !== latestVersion) {
        console.log('New version detected, reloading the app...');
        window.location.reload(true); // Force reload from server
      }
    };

    if(currentVersion){
      const intervalId = setInterval(checkForUpdates, 60000);
      return () => clearInterval(intervalId); // Cleanup on unmount
    }else{
      checkForUpdates();
    }
  }, [currentVersion]);

  return (
    <MantineProvider emotionOptions={{ key: 'hello-housing' }} withNormalizeCSS withGlobalStyles theme={theme} styles={components} defaultProps={defaultProps}>
      <NotificationsProvider position="top-center" zIndex={9999} autoClose={5000}>
      <ErrorBoundary>
        <Global styles={(theme) => ({
          html: {
            fontSize: 14,
          },
          body: {
            ...theme.fn.fontStyles(),
            fontFamily: 'Inter, sans-serif'
          },
          ".swiper-pagination-bullet-active": {
            backgroundColor: theme.colors.brandFancyPurple
          }
        })} />
        
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ErrorMsg errorMsg={''} />
            {/* <div className="hh_loader" id="hello-housing-loader" style={{ display: 'block' }}>
              <div className="hh_loader_bg"></div>
              <Spinner animation="border" className="hh_loader_spinner" variant="primary" />
            </div> */}
            <BrowserRouter>
          
              <Switch>
                <Route path="/login" component={GeneralLayout} />
                <Route path="/signup" component={SignUp} />
                <Route path="/forgotpassword" component={GeneralLayout} />
                <Route path="/resetpassword" component={GeneralLayout} />
                <Route path="/" component={(props) => <LayoutMain {...props} />} />
              </Switch>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </ErrorBoundary>
      </NotificationsProvider>
    </MantineProvider>
  );
}
