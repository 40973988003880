import React, { Component } from 'react';
import CustomModal from './index';
import { ColorButton, MultiselectDropdown, ReceiptDetailModal, SelectDropdown } from '../../../../index';
import { connect } from 'react-redux';
import Help from '../../../../../assets/images/module/general/Help.svg';
import TrashIcon from '../../../../../assets/images/module/general/TrashIcon.svg';
import downArrow from '../../../../../assets/images/module/general/SelectArrowDown.svg';
import uparrow from '../../../../../assets/images/module/general/SelectArrowUp.svg';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Autoload from '../../../../Autoload';
import GlobalServices from '../../../../helpers/services/GlobalServices';
import moment from 'moment';
import { toast } from 'react-toastify';
import APIClient from '../../../../config/APIClient';
import { config } from '../../../../config/Setting';
import AlertModal from './AlertModal';
import { formatNumber, formatNumberByLocale } from '../../../../../helpers/stringsManipulations';
import { showNotification } from '@mantine/notifications';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import UpRightArrow from '../../../../../assets/images/module/general/UpRightArrow.svg';
import { NumericFormat, numericFormatter } from 'react-number-format';
import { Checkbox, Tooltip as TooltipMaterialUI } from '@material-ui/core';
import {
  changeCurrentProperty,
  setRunUpdateHeatingCostSplit
} from '../../../../../redux/action/General/generalActions';
import accounting from 'accounting';
import { Group, Loader } from '@mantine/core';
import AddHeatingCostModal from './AddHeatingCostModal';
import dayjs from 'dayjs';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const {
  checkProperty,
  emptyFieldValidation,
  redirection,
  invalidSize,
  loader,
  ConverterLocaleNumber,
  ConverterCurrencyDe,
} = Autoload.MainHelper;

class HeatingCostModal extends Component {
  initialState = {
    errMsg: false,
    totalCost: {
      value: '',
      msg: '',
      err: false,
    },
    amount: {
      value: '',
      msg: '',
      err: false,
    },
    unitAmount: {
      value: '',
      msg: '',
      err: false,
    },
    dropdownStateOne: 'true',
    dropdownStateTwo: 'true',
    costPositions: [],
    cpOptions: [],
    receiptsDropDown: [],
    selectedReciptsArr: [],
    oldSelectedReceipt: [],
    receiptDeleted: [],
    receiptAdded: [],
    energyOptions: [],
    selectedEnergy: {
      fuelTypeName: '',
      conversionkWh: '',
      conversionCO2EmissionskgBykWh: null,
    },
    selectedEnergyErr: false,
    selectedAllocation: {
      allocationKeyName: '',
      conversionkWh: '',
    },
    selectedAllocationErr: false,
    receipts: [],
    allocationOptions: [],
    checkboxone: false,
    openAlert: false,
    openDataFehlen: false,
    openRedirectAlert: false,
    totalCostInFocus: false,
    energyConsumptionInFocus: false,
    waterHeatConsumptionInFocus: false,
    totalCO2Emissions: null,
    isCO2TaxActivated: true,
    addHeatingCostModalOpen: false,
    billingPeriod: null,
    isCO2TaxMainlyUsedForBusiness: false,
    isCO2TaxPreservationObject: false,
    heatedFloorSpace: null,
    heatedFloorSpaceInFocus: false,
    co2TaxReturnLandlordShareSystemId: null,
    heatingCostsLoader: false,
    heatingCostHover: null,
    propertyData: null,
    addHeatingCostModalCostPositionId: null
  };

  form=null;

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }
  componentDidMount() {
    const { cpId, billingPeriodId, propertySelected } = this.props;
    console.log('cpId: ', cpId);
    this.costTypeId = cpId.costTypeId;
    this.costPositionId = cpId.id;
    this.billingPeriodId = billingPeriodId;
    loader(true);
    this.setState({
      totalCost: {
      ...this.state.totalCost,
      value: cpId.amount,
      },
      heatedFloorSpace: propertySelected?.heatedFloorSpace
    },()=> {
      this.getProperty();
      this.getCpOptions();
      this.getTotalCost();
      this.getEnergyOptions();
      this.getAllocationOptions();
      this.getAllByBillingPeriodCostType(this.billingPeriodId, this.costTypeId);
      this.getSelectedReceipts();
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps && this.props.editReceiptOpen === false) {
      this.getAllByBillingPeriodCostType(this.billingPeriodId, this.costTypeId);
    }
  }

  getProperty() {
    const propertyID = this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'properties/' + propertyID,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_property_data',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handle_get_property_data(response){
    if(response.status && response.statuscode === 200){
      this.setState({propertyData: response.result});
    }
  }

  getAllByBillingPeriodCostType = async (billingPeriodId, costTypeId) => {
    await APIClient({
      method: 'GET',
      url: config.receipt + "getAllBy?billingPeriodId=" + billingPeriodId + "&costTypeId=" + costTypeId,
      headers: {
        "Content-Type": "application/octet-stream",
        "Authorization": Autoload.Utils.getAuthToken(),
      }
    })
      .then((response) => {
        response.data.result.map((receipt)=> {
          let rec = receipt;
          rec.receiptDate = moment(receipt.receiptDate).format('DD.MM.YYYY');
          return rec;
        })
        this.setState({
          receipts: response.data.result
        })
      })
      .catch((error) => {
        console.log("getAllByBillingPeriodCostType: ", error);
      });
  }

  _handleReceiptsDropDownResponse = (response) => {
    if (response.status === false) {
      Autoload.Redirection.redirection_error();
      // Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Receipts not found against this property!');
    }

    if (response.result.length > 0) {
      let formattedArr = response.result
        .filter((receipt) => checkProperty(receipt, 'utilityBillingRelevant') == true)
        .map((receipt) => ({
          id: checkProperty(receipt, 'receiptId'), // id
          receiptName: checkProperty(receipt, 'name'),
          date: moment(checkProperty(receipt, 'receiptDate')).format('DD.MM.YYYY'),
          // date: moment(checkProperty(receipt, 'date')).format('DD.MM.YYYY'),
          proRataDays: 123,
        }));

      this.setState({
        receiptsDropDown: formattedArr,
      });
    }
  };

  getHeatingCosts = (addEmptyHC = false) => {
    // loader(true);
    this.setState({heatingCostsLoader: true});
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'heatingCosts?utilityBillingId=' + this.props.currentDataId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleHeatingCostsResponse',
      { addEmptyHC },
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  _handleHeatingCostsResponse = (response) => {
    console.log('_handleHeatingCostsResponse: ', response.result);
    if (!response.status) {
      Autoload.Redirection.redirection_error();
    }

    if (response.result.length > 0) {
      let formatted = response.result.map((hc, i) => {
        // console.log(hc)
        return ({
          id: checkProperty(hc, 'id'),
          err: false,
          msg: '',
          heatingCost: Autoload.MainHelper.convertToCurrencyFromNumber(checkProperty(hc, 'heatingCost'), true),
          heatingCostType: checkProperty(hc, 'heatingCostType'),
          systemHeatingCostTypeId: hc.systemHeatingCostTypeId,
          displayOrder: checkProperty(hc, 'displayOrder'),
        })
      });

      this.setState({ costPositions: formatted }, () => {
        this.calculateTotalOnAmountChange();
        loader(false);
        if (response.other.addEmptyHC) this.addEmptyHeatingCost();
      });
    } else {
      this.setState({ costPositions: this.state.costPositions.filter((i) => i === 0) }, () => loader(false));
    }

    this.setState({heatingCostsLoader: false});
  };
  getCpOptions = () => {
    this.setState({heatingCostsLoader: true});
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'heatingCostSystemTypes?propertyId='+this.props.propertySelected.id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleCpOptionsResponse',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  _handleCpOptionsResponse = (response) => {
    if (response.status === false) {
      Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_utility_error);
    }
    this.setState({ cpOptions: Array.isArray(response.result) ? response.result : [], co2TaxReturnLandlordShareSystemId: response.result.find((systemHeatingCost)=> systemHeatingCost.heatingCostTypeName === "CO2-Steuer Erstattung Vermieteranteil")?.id }, () => loader(false));
    this.getHeatingCosts();
  };

  getSelectedReceipts = () => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'receiptAssignments?costPositionId=' + this.props.cpId.id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleSelectedReceiptsResponse',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  _handleSelectedReceiptsResponse = (response) => {
    if (response.status === false && response.message !== 'Not Found') {
      Autoload.Redirection.redirection_error();
      // Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Error Saving receipt assignment :  ' + response.message);
    }
    console.log('_handleGetSelectedReceipt: ', response);
    this.setState({
      selectedReceipt: response.result 
    })
  }

  // _handleSelectedReceiptsResponse(response) {
  //   if (response.status == false) {
  //     Autoload.Redirection.redirection_error();
  //     // Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Receipt assignment not found against cost position!');
  //   }

  //   if (response.result.length > 0) {
  //     let updatedData = response.result.map((receipt) => ({
  //       // costPositionId: checkProperty(receipt,'costPositionId'),
  //       proRataDays: checkProperty(receipt, 'proRataDays'),
  //       receiptAssignmentId: checkProperty(receipt, 'receiptAssignmentId'),
  //       id: checkProperty(receipt, 'receiptId'),
  //       receiptName: checkProperty(receipt, 'receiptName'),
  //     }));
  //     console.log(updatedData);
  //     this.setState({ selectedReciptsArr: updatedData, oldSelectedReceipt: updatedData }, () => loader(false));
  //   } else loader(false);
  // }

  updateCostSplit = () => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'updateHeatingCostSplit/' + this.props.currentDataId,
      'PUT',
      null,
      null,
      false,
      true,
      false,
      this,
      'updateHeatingCostSplit',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  updateHeatingCostSplit(response) {
    !response.status && console.log('Update Consumptions Failed');
    window.open(window.location.href, '_self');
    this.resetForm();
  }
  getTotalCost = () => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'utilityBillings/' + this.props.currentDataId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleGetUtilityResponse',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  _handleGetUtilityResponse(response) {
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_utility_not_found);
    }
    this.setState(
      {
        // totalCost: {
        //   ...this.state.totalCost,
        //   value: Autoload.MainHelper.convertToCurrencyFromNumber(
        //     checkProperty(response.result, 'totalHeatingCost'),
        //     true
        //   ),
        // },
        selectedEnergy: this.props.propertySelected.energyFuelType,  
        // {
        //   id: checkProperty(checkProperty(response.result, 'heatingEnergyType'), 'id'),
        //   fuelTypeName: checkProperty(checkProperty(response.result, 'heatingEnergyType'), 'fuelTypeName'),
        //   conversionkWh: Autoload.MainHelper.convertToCurrencyFromNumber(
        //     checkProperty(checkProperty(response.result, 'heatingEnergyType'), 'conversionkWh'),
        //     false
        //   ),
        // },
        selectedAllocation: this.props.propertySelected.centralWarmWaterAllocationKey,
        // response.result.centralWarmWaterAllocationKeyId ? this.state.allocationOptions.find((option)=> {
        //   return option.id == response.result.centralWarmWaterAllocationKeyId &&
        //   {
        //     allocationKeyId: option.id,
        //     allocationKeyName: option.allocationKeyName,
        //     conversionkWh: Autoload.MainHelper.ConverterCurrencyDe(option.conversionkWh + '', false),
        //     units: option.units,
        //     propertyId: option.propertyId,
        //     metered: option.metered.toString(),
        //     systemAllocationKey: option.systemAllocationKey.toString(),
        //     type: option.type
        //   }
        // }) : this.state.selectedAllocation
        checkboxone: response.result.hasCentralWarmWaterAmount ? false : true,
        unitAmount: {
          ...this.state.unitAmount,
          value: this.props.propertySelected.centralWarmWaterAllocationKey && response.result.energyAmountWarmWater ? response.result.energyAmountWarmWater : null,
        },
        amount: {
          ...this.state.amount,
          value: response.result.heatingEnergyAmount ? response.result.heatingEnergyAmount : null,
        },
        utilityBillingData: response.result,
        totalCO2Emissions: response.result.totalCO2Emissions,
        isCO2TaxActivated: response.result.isCO2TaxActivated,
        billingPeriod: response.result.billingPeriod,
        isCO2TaxMainlyUsedForBusiness: response.result.isCO2TaxMainlyUsedForBusiness,
        isCO2TaxPreservationObject: response.result.isCO2TaxPreservationObject
      },
      () => loader(false)
    );
  }

  getEnergyOptions = () => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'systemFuelTypes',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleGetEnergyOptions',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  _handleGetEnergyOptions = (response) => {
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_system_fuel_not_found);
    }

    this.setState({ energyOptions: response.result }, () => loader(false));
  };

  getAllocationOptions = () => {
    GlobalServices._getAllocationKeysByProperty(this, '_handleGetAllocationOptions', this.props.propertySelected);
  };
  _handleGetAllocationOptions = (response) => {
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_system_fuel_not_found);
    }

    this.setState({ allocationOptions: response.result.filter((allocationKey)=> allocationKey.meterType !== 'PROPERTY') }, () => loader(false));
  };

  renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Bitte geben Sie hier die Gesamtsumme Ihrer Heizkosten ein oder fügen Sie einzelne Positionen Ihrer Heizkosten
      hinzu
    </Tooltip>
  );
  renderEnergyTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Tragen Sie hier die Gesamtmenge des verbrauchten Brennstoffs ein.
    </Tooltip>
  );
  renderWärmemengeWarmwasserboilerTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Tragen Sie hier die Gesamtwärmemenge des zentralen Warmwasserboilers ein.
    </Tooltip>
  );
  onInputChange = (field, value) => {
    this.setState({ [field]: { ...this.state[field], value: value, err: false } });
  };

  onCreateReceiptSelect=(id)=>{
    setTimeout(() => this.onReceiptSelect('', this.state.receipts.find((receipt)=> receipt.id === id)), 1000);
  }

  onReceiptSelect = (selectedList, selectedItem) => {
    if(selectedItem !== undefined){
    //for direct api call
    // // console.log('receipt selection', selectedList, selectedItem)
    // let addedReceipt = {
    //     ...selectedItem,
    //     costPositionId: this.props.data.id,
    //     receiptAssignmentId: null,
    // };
    // // console.log('addedReceipt',addedReceipt)
    // Autoload.HttpRequestAxios.HttpRequest(
    //     Autoload.Setting.swagger_mocked_api_baseUrl + 'receiptAssignments',
    //     'POST',
    //     addedReceipt,
    //     null,
    //     false,
    //     true,
    //     false,
    //     this,
    //     '_handlePostReceipt',
    //     null,
    //     false,
    //     '',
    //     {
    //         Authorization: Autoload.Utils.getAuthToken(),
    //     }
    // );

    //for just state
    // this.setState({ selectedReciptsArr: selectedList }, () => console.log('state', this.state.selectedReciptsArr))

    //for storing data and save over button
    // let found = this.state.oldSelectedReceipt.find((receipt) => receipt.id === selectedItem.id);
    // let found2 = this.state.receiptAdded.find((receipt) => receipt.id === selectedItem.id);
    // if (
    //   (!found || (found && Object.keys(found).length === 0)) &&
    //   (!found2 || (found2 && Object.keys(found2).length === 0))
    // ) {
    //   this.setState(
    //     {
    //       selectedReciptsArr: selectedList,
    //       receiptAdded: [...this.state.receiptAdded, selectedItem],
    //       receiptDeleted: this.state.receiptDeleted.filter((rpt) => rpt.id !== selectedItem.id),
    //     },
    //     () => {
    //       console.log('receipt selected and new receipt added', this.state);
    //     }
    //   );
    // } else {
    //   this.setState(
    //     {
    //       selectedReciptsArr: selectedList,
    //       receiptDeleted: this.state.receiptDeleted.filter((rpt) => rpt.id !== selectedItem.id),
    //     },
    //     () => {
    //       console.log('receipt selected and NO new receipt added', this.state);
    //     }
    //   );
    // }
    
      loader(true);
      let addedReceipt = {
        receiptId: selectedItem.id,
        receiptName: selectedItem.name,
        proRataDays: 123,
        costPositionId: this.props.cpId.id,
        id: null
      };
      console.log('addedReceipt: ', addedReceipt);
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url + 'receiptAssignments',
        'POST',
        addedReceipt,
        null,
        false,
        true,
        false,
        this,
        '_handlePostReceipt',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    }
  };
  _handlePostReceipt = (response) => {
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
    }
    this.getSelectedReceipts();
    loader(false);
  };

  // updateCostPosition=()=>{
  //   let payload = {
  //     id: checkProperty(this.props.data, 'id'),
  //     utilityBillingId: checkProperty(this.props.data, 'utilityBillingId'),
  //     amount: Autoload.MainHelper.ConverterLocaleNumber(amount, 'de')
  //       ? Autoload.MainHelper.ConverterLocaleNumber(amount, 'de')
  //       : 0,
  //     costTypeId: costTypeId,
  //     costTypeName: costTypeName,
  //     allocationKeyId: allocationKeyId,
  //     allocationKeyName: allocationKeyName,
  //     consumptionDataAvailable: meterData,
  //     costTypeIdentifier: Array.isArray(costType) ? costType[0].costTypeIdentifier : costType.costTypeIdentifier,
  //     allcationKeyUnits: 'mWh',
  //     orderNo,
  //     vatAmount: vatAmount,
  //     laborCost: laborCost
  //   };

  //   Autoload.HttpRequestAxios.HttpRequest(
  //     Autoload.Setting.ub_api_base_url + 'costPositions/' + this.props.cpId.id,
  //     'PUT',
  //     payload,
  //     null,
  //     false,
  //     true,
  //     false,
  //     this,
  //     '_handleSaving',
  //     null,
  //     false,
  //     '',
  //     {
  //       Authorization: Autoload.Utils.getAuthToken(),
  //     }
  //   );
  // }

  onReceiptRemove = (selectedList, removedItem) => {
    //for direct api save
    // // console.log('romoved', removedItem.receiptId)
    // // this.setState({dataValues:{...this.state.dataValues, selectedReceipt: selectedList }},()=>this.updateStore())
    // Autoload.HttpRequestAxios.HttpRequest(
    //     Autoload.Setting.swagger_mocked_api_baseUrl + 'receiptAssignment/' + removedItem.receiptId,
    //     "DELETE",
    //     null, null, false, true, false, this,
    //     "_handleDeleteReceipt",
    //     null, false, "",
    //     {
    //         'Authorization': Autoload.Utils.getAuthToken()
    //     }
    // );
    // let receipt=this.state.selectedReciptsArr.find(record=>record.id===removedItem.id)

    //for direct state save
    // this.setState({ selectedReciptsArr: selectedList }, () => console.log('state', this.state.selectedReciptsArr))

    //for save on button
    // console.log('arrays', this.state.selectedReciptsArr, removedItem, this.state.oldSelectedReceipt);
    // let found = this.state.oldSelectedReceipt.find((receipt) => receipt.id === removedItem.id);
    // let found2 = this.state.receiptDeleted.find((receipt) => receipt.id === removedItem.id);
    // if (found && Object.keys(found).length > 0 && (!found2 || (found2 && Object.keys(found2).length === 0))) {
    //   this.setState(
    //     {
    //       selectedReciptsArr: selectedList,
    //       receiptDeleted: [...this.state.receiptDeleted, found],
    //       receiptAdded: this.state.receiptAdded.filter((rpt) => rpt.id !== removedItem.id),
    //     },
    //     () => {
    //       console.log('receipt removed and it was in original data', this.state);
    //     }
    //   );
    // } else {
    //   this.setState(
    //     {
    //       selectedReciptsArr: selectedList,
    //       receiptAdded: this.state.receiptAdded.filter((rpt) => rpt.id !== removedItem.id),
    //     },
    //     () => {
    //       console.log('receipt removed and it was NOT in original data', this.state);
    //     }
    //   );
    // }
    loader(true);
    let receipt = this.state.selectedReceipt.find((record) => record.receiptId === removedItem.id);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'receiptAssignments/' + receipt.id,
      'DELETE',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleDeleteReceipt',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  _handleDeleteReceipt(response) {
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
    }
    // console.log('response of delete receipt', response)
    this.getSelectedReceipts();
    loader(false);
  }

  addEmptyHeatingCost = () => {
    loader(true);
    let oldArr = this.state.costPositions;
    oldArr.push({
      id: 0,
      err: false,
      msg: '',
      heatingCost: '0,00 €',
      heatingCostType: this.state.cpOptions.length > 0 ? this.state.cpOptions[0].heatingCostTypeName : '',
      systemHeatingCostTypeId: this.state.cpOptions.length > 0 ? this.state.cpOptions[0].id : null,
      displayOrder: this.state.costPositions.length + 1
    });
    this.setState({ costPositions: oldArr }, () => loader(false));
  };
  onCpChange = (field, id, value, onBlur=true) => {
    let updatedArr = this.state.costPositions.map((arr) => {
      if (arr.id === id) {
        return {
          ...arr,
          [field]: field === 'heatingCostType' ? value.heatingCostTypeName : value,
          systemHeatingCostTypeId: field === 'heatingCostType' ? value.id : arr.systemHeatingCostTypeId
        };
      } else {
        return arr;
      }
    });
    this.setState({ costPositions: updatedArr }, ()=> {
      if(onBlur){
        let cpPutData = this.state.costPositions
        .filter((i) => i.id !== 0)
        .map((cp) => ({
          id: cp.id,
          heatingCostType: cp.heatingCostType,
          heatingCost: Autoload.MainHelper.convertCurrencyToNumber(cp.heatingCost ? cp.heatingCost : 0),
          systemHeatingCostTypeId: cp.systemHeatingCostTypeId,
          heatingCostTypeName: cp.heatingCostType,
          displayOrder: cp.displayOrder
        }));
        if (cpPutData.length) {
          this.putHeatingCosts(cpPutData);
        }
      }
    });
  };

  addHeatingCost = (isValidatedPost = false, addNewHeatingCost=false, heatingCostName=null) => {
    loader(true);

    let body = this.state.costPositions.find((i) => i.id === 0),
      validated = false,
      error = { err: false, msg: '' };
    console.log('addHeatingCost: ', body, body && (body.heatingCostType !== '' || body.heatingCost !== '') && Autoload.MainHelper.convertCurrencyToNumber(body.heatingCost));
    if (body && (body.heatingCostType !== '' || body.heatingCost !== '') && (Autoload.MainHelper.convertCurrencyToNumber(body.heatingCost) === 0 || Autoload.MainHelper.convertCurrencyToNumber(body.heatingCost))) {
      validated = true;
    } else error = { err: true, msg: lang.newKeys.text_heating_cost_type_error_msg };

    if (validated || isValidatedPost) {
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url + 'heatingCosts?utilityBillingId=' + this.props.currentDataId,
        'POST',
        { 
          heatingCostType: addNewHeatingCost ? heatingCostName : body.heatingCostType, 
          heatingCostTypeName: addNewHeatingCost ? heatingCostName : body.heatingCostType, 
          heatingCost: addNewHeatingCost ? 0 : Autoload.MainHelper.convertCurrencyToNumber(body.heatingCost), 
          systemHeatingCostTypeId: addNewHeatingCost ? null : body.systemHeatingCostTypeId ? body.systemHeatingCostTypeId : null,
          displayOrder: addNewHeatingCost ? (this.state.costPositions[this.state.costPositions.length - 1].displayOrder + 1) : body.displayOrder
        },
        null,
        false,
        true,
        false,
        this,
        'handleHeatingCostsPostResponse',
        { isValidatedPost, addNewHeatingCost },
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    } else {
      if (body) {
        this.setState(
          {
            costPositions: this.state.costPositions.map((i) => {
              if (i.id === body.id) return { ...i, ...error };
              else return i;
            }),
          },
          () => {
            loader(false);
          }
        );
      } else this.addEmptyHeatingCost();
    }
  };
  handleHeatingCostsPostResponse(response) {
    if (response.status) {
      if (!response.other.isValidatedPost) {
        response.other.addNewHeatingCost && this.getCpOptions();
        this.getHeatingCosts(true);
      }
    } else {
      Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_adding_heat_cost);
      loader(false);
    }
  }

  deleteHeatingCost = (id) => {
    loader(true);
    if (id) {
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url + 'heatingCosts/' + id,
        'DELETE',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleDeleteCostPosition',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    } else {
      let updatedArr = this.state.costPositions.filter((arr) => arr.id !== id);
      this.setState({ costPositions: updatedArr }, () => {
        this.calculateTotalOnAmountChange();
        loader(false);
      });
    }
  };
  handleDeleteCostPosition(response) {
    if (response.status) {
      this.getHeatingCosts();
      this.getCpOptions();
    } else {
      Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_delete_heat_cost);
      loader(false);
    }
  }

  calculateTotalOnAmountChange = () => {
    const {isCO2TaxActivated, co2TaxReturnLandlordShareSystemId} = this.state;
    // console.clear()
    let sumOfAmounts = 0;

    this.state.costPositions.filter((cp)=> isCO2TaxActivated === false ? cp.systemHeatingCostTypeId !== co2TaxReturnLandlordShareSystemId : cp).forEach((arr) => {
      console.log(arr)
      console.log(arr.heatingCost.replace('.', '').replace(',', '.').split('€')[0] * 1)
      // console.log('each value', Autoload.MainHelper.ConverterLocaleNumber(arr.heatingCost, 'de'), sumOfAmounts);
      sumOfAmounts = sumOfAmounts + (arr.heatingCost.replace('.', '').replace(',', '.').split('€')[0] * 1);
    });
    console.log(sumOfAmounts)
    console.log(Autoload.MainHelper.convertToCurrencyFromNumber(sumOfAmounts, true))
    // console.log('total cost cal', sumOfAmounts, Autoload.MainHelper.ConverterCurrencyDe(sumOfAmounts + '', true));
    this.setState({
      totalCost: {
        ...this.state.totalCost,
        value: sumOfAmounts,
      },
    });
  };
  onAmountBlur = (field, id, value) => {
    console.log('onblur', value);
    let updatedArr = this.state.costPositions.map((arr) => {
      if (arr.id === id) {
        return {
          ...arr,
          [field]: value,
        };
      } else {
        return arr;
      }
    });
    this.setState({ costPositions: updatedArr }, () => {
      this.calculateTotalOnAmountChange();
      
      let cpPutData = this.state.costPositions
      .filter((i) => i.id !== 0)
      .map((cp) => ({
        id: cp.id,
        heatingCostType: cp.heatingCostType,
        heatingCost: Autoload.MainHelper.convertCurrencyToNumber(cp.heatingCost ? cp.heatingCost : 0),
        systemHeatingCostTypeId: cp.systemHeatingCostTypeId,
        heatingCostTypeName: cp.heatingCostType,
        displayOrder: cp.displayOrder
      }));
      if (cpPutData.length) {
        this.putHeatingCosts(cpPutData);
      }
    });
  };
  validateCp = (data, field, fieldName, arr) => {
    let invalidErr, emptyErr;
    emptyErr = emptyFieldValidation(data[field].replaceAll('€', '').trim());
    invalidErr = invalidSize(data[field].replaceAll('€', '').trim());
    if (emptyErr) {
      let updatedSingleRecord = { ...data, err: true, msg: `${lang.general.text_enter} ${fieldName}` };
      let updatedRecord = arr.map((rc) => {
        if (rc.id == updatedSingleRecord.id) {
          return updatedSingleRecord;
        } else {
          return rc;
        }
      });
      this.setState({ data: updatedRecord });
      return true;
    } else if (invalidErr) {
      let updatedSingleRecord = { ...data, err: true, msg: `${lang.general.text_invalid} ${fieldName}` };
      let updatedRecord = arr.map((rc) => {
        if (rc.id == updatedSingleRecord.id) {
          return updatedSingleRecord;
        } else {
          return rc;
        }
      });
      this.setState({ data: updatedRecord });
      return true;
    } else {
      let updatedSingleRecord = { ...data, err: false, msg: '' };
      let updatedRecord = arr.map((rc) => {
        if (rc.id == updatedSingleRecord.id) {
          return updatedSingleRecord;
        } else {
          return rc;
        }
      });
      this.setState({ data: updatedRecord });
      return false;
    }
  };
  //use this function on submit when need validations
  handleSubmit = async (e) => {
    e.preventDefault();
    const { costPositions } = this.state;
    const {isMobile, isTablet} = this.props;
    let errors = [];
    let err;
    for (let i = 0; i < costPositions.length; i++) {
      err = await this.validateCp(costPositions[i], 'heatingCost', 'amount', costPositions);
      errors.push(err);
    }
    let isInvalid = errors.find((item) => item == true);
    if (isInvalid) {
      //alert('invalid heating cost data');
      this.setState({ errMsg: err });
    } else {
      // if (Setting.UB_debug) {
      let errs = [];
      let totalCostErr,
        energyErr = false,
        allocationErr = false;
      // if (this.state.costPositions.length === 0) {
      //   cpErr = true;
      //   errs.push('cost positions');
      // }
      console.log('this.state.totalCost.value: ',this.state.totalCost.value);
      if (this.state.totalCost.value === null || this.state.totalCost.value === 0 || this.state.totalCost.value === undefined || this.state.totalCost.value === '') {
        totalCostErr = true;
        this.setState({totalCost: {
          ...this.state.totalCost,
          msg: 'Summe Heizkosten',
          err: true
        }})
        errs.push('Summe Heizkosten');
      }
      console.log('this.state.amount.value: ', this.state.amount.value);
      if ((this.state.amount.value === null || this.state.amount.value === undefined) ||
        (this.state.selectedEnergy.id === '' ||
        this.state.selectedEnergy.fuelTypeName === '' ||
        this.state.selectedEnergy.conversionkWh === '')
      ) {
        console.log('this.state.amount.value: ', this.state.amount.value === null);
        energyErr = true;
        errs.push('Energieverbrauch');
        this.setState({
          amount: {
            ...this.state.amount,
            msg: 'Verbrauch erforderlich',
            err: this.state.amount.value === null || this.state.amount.value === undefined ? true : false
          },
          selectedEnergyErr: (this.state.selectedEnergy === null || this.state.selectedEnergy === undefined || this.state.selectedEnergy.fuelTypeName === '') ? true : false
        })
      }
      console.log('this.state.unitAmount.value: ', this.state.unitAmount.value);
      if (
        this.props.propertySelected.heatingType === 'central with warm water' &&
        (this.state.selectedAllocation === null ||
        this.state.selectedAllocation?.allocationKeyId === '' ||
        //this.state.selectedAllocation.id === '' ||
        this.state.selectedAllocation?.allocationKeyName === '' ||
        this.state.selectedEnergy.conversionkWh === '' ||
        (this.state.unitAmount.value === null || this.state.unitAmount.value === undefined))
      ) {
        allocationErr = !this.state.checkboxone ? true : false;
        !this.state.checkboxone && errs.push('Wärmemenge Warmwasserboiler');
        !this.state.checkboxone && this.setState({
          unitAmount: {
            ...this.state.unitAmount,
            msg: 'Verbrauch erforderlich',
            err: this.state.unitAmount.value === undefined || this.state.unitAmount.value === null ? true : false
          },
          selectedAllocationErr: this.state.selectedAllocation && this.state.selectedAllocation?.allocationKeyName === '' ? true : false
        })
      }
      if(this.props.propertySelected.heatingType === 'central with warm water' && (this.state.selectedAllocation?.allocationKeyName === null || this.state.selectedAllocation?.allocationKeyName === undefined || this.state.selectedAllocation?.allocationKeyName === '') && this.state.checkboxone === false){
        showNotification({
          message: "Bitte wählen Sie einen Verteilerschlüssel für den zentralen Warmwasserboiler oder wählen Sie \"kein Verbrauch verfügbar\".",
          icon: <img src={RedCross} />
        });
        return;
      }
      if (totalCostErr || energyErr || allocationErr) {
        this.setState({openDataFehlen: true});
        return;
      }else{
        this.saveData(e);
      }
      // }
    }

    //this.saveData(e);
  };

  saveData = (e) => {
    const {propertySelected, cpId} = this.props;
    const {propertyData} = this.state;
    e.preventDefault();

    // if (Setting.UB_debug) {
    let totalCostErr,
      // cpErr,
      energyErr = false;
    let errs = [];
    // if (this.state.costPositions.length === 0) {
    //   cpErr = true;
    //   errs.push('Invalid or empty cost positions ');
    // }
    if (this.state.totalCost.value === null || this.state.totalCost.value === 0) {
      totalCostErr = true;
      errs.push('Invalid or empty total heating cost ');
    }
    if (
      this.state.selectedEnergy.id === '' ||
      this.state.selectedEnergy.fuelTypeName === '' ||
      this.state.selectedEnergy.conversionkWh === ''
    ) {
      energyErr = true;
      errs.push('Invalid or empty energy consumption');
    }
    // if (totalCostErr || energyErr) {
    //   toast.dark(`data missing: ${errs}`, {
    //     position: 'top-center',
    //     autoClose: false,
    //     className: 'neoverv-toast',
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    //   return;
    // }
    // }

    //api to save and delete recipts (could be optional)
    this.manipulateReciept();

    this.state.costPositions.filter((i) => i.id === 0).length > 0 && this.addHeatingCost(true);

    loader(true);
    //api to save cost positions
    let cpPutData = this.state.costPositions
      .filter((i) => i.id !== 0)
      .map((cp) => ({
        id: cp.id,
        heatingCostType: cp.heatingCostType,
        heatingCost: Autoload.MainHelper.convertCurrencyToNumber(cp.heatingCost ? cp.heatingCost : 0),
        systemHeatingCostTypeId: cp.systemHeatingCostTypeId,
        heatingCostTypeName: cp.heatingCostType,
        displayOrder: cp.displayOrder
      }));
    if (cpPutData.length) {
      console.log('heatingCosts_PUT: ', cpPutData, this.state.costPositions);
      this.putHeatingCosts(cpPutData);
    }
    // update ub data
    let utilityData = {
      ...this.state.utilityBillingData,
      //totalHeatingCost: Autoload.MainHelper.convertCurrencyToNumber(this.state.totalCost.value),
      heatingCostsAllocated: true,
      isCO2TaxActivated: this.state.isCO2TaxActivated,
      totalCO2Emissions: this.state.totalCO2Emissions !== NaN ? this.state.totalCO2Emissions : null,
      isCO2TaxMainlyUsedForBusiness: this.state.isCO2TaxMainlyUsedForBusiness,
      isCO2TaxPreservationObject: this.state.isCO2TaxPreservationObject,
      // heatingEnergyType: {
      //   id: this.state.selectedEnergy.id,
      //   fuelTypeName: this.state.selectedEnergy.fuelTypeName,
      //   conversionkWh: Autoload.MainHelper.convertCurrencyToNumber(this.state.selectedEnergy.conversionkWh),
      // },
      hasCentralWarmWaterAmount: this.state.checkboxone ? false : true,
      energyAmountWarmWater: this.state.checkboxone ? null : this.state.unitAmount && this.state.unitAmount.value ? this.state.unitAmount.value : null,
      //centralWarmWaterAllocationKeyId: this.state.checkboxone ? '' : this.state.selectedAllocation.allocationKeyId,
      //centralWarmWaterAllocationKey: this.state.selectedAllocation
    };
    console.log(this.state.amount.value)
    utilityData.heatingEnergyAmount = this.state.amount && this.state.amount.value ? this.state.amount.value : null;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'utilityBillings/' + this.props.currentDataId,
      'PUT',
      utilityData,
      null,
      false,
      true,
      false,
      this,
      '_handleUtilityPut',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      },
      false,
      false
    );
      // update property data
    let propertyDataForUpdate= {
      ...propertyData,
      accountId: propertySelected.accountId,
      name: propertySelected.name,
      propertyAdress: {
        streetno: propertySelected.propertyAddress,
        city: propertySelected.propertyCity,
        zipCode: propertySelected.propertyZipCode,
      },
      totalRentingSpace: propertySelected.totalRentingSpace,
      numberOfRentalUnits: propertySelected.numberOfRentalUnits,
      cutOffDate: propertySelected.cutOffDate,
      heatingType: propertySelected.heatingType,
      heatingTemperature: propertySelected.heatingTemperature,
      consumptionShare: propertySelected.consumptionShare,
      receiptEmail: propertySelected.receiptEmail,
      meterReadingsEmail: propertySelected.meterReadingsEmail,
      meterReadingConducted: propertySelected.meterReadingConducted,
      propertyType: propertySelected.propertyType,
      energyFuelType: {
        id: this.state.selectedEnergy.id,
        fuelTypeName: this.state.selectedEnergy.fuelTypeName,
        conversionkWh: this.state.selectedEnergy.conversionkWh,
        conversionCO2EmissionskgBykWh: this.state.selectedEnergy.conversionCO2EmissionskgBykWh,
      },
      centralWarmwaterAllocationKey: this.state.checkboxone ? propertySelected.centralWarmWaterAllocationKey : this.state.selectedAllocation,
      heatedFloorSpace: this.state.heatedFloorSpace,
      id: propertySelected.id,
      isConsumptionSectionHidden: propertySelected.isConsumptionSectionHidden,
      isDocumentSectionHidden: propertySelected.isDocumentSectionHidden,
      isMessageSectionHidden: propertySelected.isMessageSectionHidden
    };
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'properties/' + propertyDataForUpdate.id,
      'PUT',
      propertyDataForUpdate,
      null,
      false,
      true,
      false,
      this,
      '_handlePropertyPut',
      {updatePropertyData: propertyDataForUpdate},
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
    //update cp data
    let payload = {
      id: cpId.id,
      utilityBillingId: this.props.currentDataId,
      amount: this.state.totalCost.value,
      costTypeId: cpId.costTypeId,
      costTypeName: cpId.costTypeName,
      allocationKeyId: cpId.allocationKeyId,
      allocationKeyName: cpId.allocationKeyName,
      consumptionDataAvailable: cpId.meterData,
      costTypeIdentifier: cpId.costTypeIdentifier,
      allcationKeyUnits: cpId.allcationKeyUnits,
      orderNo: cpId.orderNo,
      vatAmount: cpId.vatAmount,
      laborCost: cpId.laborCost
    };

    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'costPositions/' + this.props.cpId.id,
      'PUT',
      payload,
      null,
      false,
      true,
      false,
      this,
      '_handleCostPositionSaving',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  _handleCostPositionSaving(response){
    if(response.status && response.statuscode === 200){
      this.props.callGetDataApi();
    }
  }

  _handlePropertyPut=(response)=>{
    console.log('_handlePropertyPut: ', response);
    if(response.status){
      this.updateProperties(response.other.updatePropertyData)
    }
  }

  updateProperties(updated_property_response) {
    let {propertySelected} = this.props;
    propertySelected={
      ...propertySelected,
      heatingType: Autoload.MainHelper.checkProperty(updated_property_response, 'heatingType'),
      heatingTemperature: Autoload.MainHelper.checkProperty(updated_property_response, 'heatingTemperature'),
      heatingTemp: Autoload.MainHelper.checkProperty(updated_property_response, 'heatingTemperature'),
      consumptionValue: Autoload.MainHelper.checkProperty(updated_property_response, 'consumptionShare', 0),
      consumptionShare: Autoload.MainHelper.checkProperty(updated_property_response, 'consumptionShare', 0),
      energyFuelType: Autoload.MainHelper.checkProperty(updated_property_response, 'energyFuelType', null),
      centralWarmWaterAllocationKey: Autoload.MainHelper.checkProperty(updated_property_response, 'centralWarmwaterAllocationKey', null),
      heatedFloorSpace: Autoload.MainHelper.checkProperty(updated_property_response, 'heatedFloorSpace', null)
    }
    this.props.changeCurrentProperty(propertySelected);
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
  }

  _handleCpPut = (response) => {
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_save_heat_cost);
      loader(false);
    }
    this.getCpOptions();
  };

  _handleUtilityPut = (response) => {
    if (response.status) {
      showNotification({
        message: 'Die Änderungen wurden übernommen.',
        icon: <img src={GreenTick} alt='green-tick'/>,
        //style: {marginLeft: !this.props.isTablet && '24.5%', width: '100%'},
      });
      console.log('apis completed');
      this.props.toggle();
    }
    this.state.openRedirectAlert ? this.props.history.push('/property/heatingCostInformation') : setTimeout(() => {
      this.props.setRunUpdateHeatingCostSplit(true);
      this.props.callUpdate(response.status && response.statuscode === 200);
      this.props.updateCalculationStatus(true);
      //this.props.callGetDataApi();
      //window.open(window.location.href, '_self');
    }, 500); //this.updateCostSplit();
    loader(false);
  };
  manipulateReciept = () => {
    (this.state.receiptAdded.length > 0 || this.state.receiptDeleted.length > 0) && loader(true);

    if (this.state.receiptAdded.length > 0) {
      this.state.receiptAdded.forEach((receipt, i) => {
        let addedReceipt = {
          receiptId: checkProperty(receipt, 'id'),
          receiptName: checkProperty(receipt, 'receiptName'),
          // receiptDate: checkProperty(receipt, 'date'),
          proRataDays: 123,
          costPositionId: this.props.cpId,
          receiptAssignmentId: null,
        };

        Autoload.HttpRequestAxios.HttpRequest(
          Autoload.Setting.ub_api_base_url + 'receiptAssignments',
          'POST',
          addedReceipt,
          null,
          false,
          true,
          false,
          this,
          '_handlePostReceipts',
          null,
          false,
          '',
          {
            Authorization: Autoload.Utils.getAuthToken(),
          }
        );
      });
    }
    //api to delete receipts
    if (this.state.receiptDeleted.length > 0) {
      this.state.receiptDeleted.forEach((receipt) => {
        Autoload.HttpRequestAxios.HttpRequest(
          Autoload.Setting.ub_api_base_url + 'receiptAssignments/' + receipt.receiptAssignmentId,
          'DELETE',
          null,
          null,
          false,
          true,
          false,
          this,
          '_handleDeleteReceipts',
          null,
          false,
          '',
          {
            Authorization: Autoload.Utils.getAuthToken(),
          }
        );
      });
    }
  };
  _handlePostReceipts = (response) => {
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Error occured while saving receipt assignment!');
    }
    loader(false);
  };
  _handleDeleteReceipts = (response) => {
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Error occured while deleting receipt assignment!');
    }
    loader(false);
  };

  energyChange = (option) => {
    const {amount, utilityBillingData} = this.state;
    let calculateTotalCO2Emissions = option.fuelTypeName === 'Heizleistung (kWh)' ? NaN : (amount.value * option.conversionkWh) * option.conversionCO2EmissionskgBykWh;
    console.log('energyChange: ', calculateTotalCO2Emissions, option.conversionkWh, option.conversionCO2EmissionskgBykWh, amount.value, (amount.value * option.conversionkWh) * option.conversionCO2EmissionskgBykWh);
    this.setState({
      selectedEnergy: {
        id: option.id,
        fuelTypeName: option.fuelTypeName,
        conversionkWh: option.conversionkWh,
        conversionCO2EmissionskgBykWh: option.conversionCO2EmissionskgBykWh,
      },
      selectedEnergyErr: false,
      totalCO2Emissions: calculateTotalCO2Emissions
    });
  };

  allocationChange = (option) => {
    this.setState({
      selectedAllocation: {
        id: option.id,
        allocationKeyName: option.allocationKeyName,
        conversionkWh: option.conversionkWh,
        units: option.units,
        propertyId: option.propertyId,
        metered: option.metered,
        systemAllocationKey: option.systemAllocationKey,
        type: option.type
      },
      selectedAllocationErr: false
    });
  };
  handleEnterKeyPress = (e) => {
    if (e.charCode == 13) {
      e.preventDefault();
      this.handleSubmit(e);
    }
  };
  resetForm = () => {
    this.setState(this.initialState);
  };

  toggleAddReceiptModal = (cp) => {
    if (cp !== undefined) {
      this.setState({currentCp: cp});
    }
    this.setState({ addReceiptOpen: !this.state.addReceiptOpen });
    this.getAllByBillingPeriodCostType(this.billingPeriodId, this.costTypeId);
    this.props.callApis();
  };
  
  bind_list_receipt_data = () => {
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.rm_api_base_url + 'property/' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_list_data',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handle_get_list_data(response) {
    if (this.state.currentCp) {
      //this.onReceiptSelect({ id: response.result[0]?.id, name: response.result[0]?.name }, this.state.currentCp);
    }
  }

  toggleViewDetailsModal = (recordId) => {
    console.log('recordId: ', recordId);
    this.props.toggleViewDetailsModal(recordId)
  }

  saveHeatingCost=(heatingCost)=>{
    const {addHeatingCostModalCostPositionId, costPositions} = this.state;
    if(addHeatingCostModalCostPositionId && addHeatingCostModalCostPositionId !== 0){
      let cpPutData = costPositions.filter((i) => i.id !== 0)
      .map((cp) => {
        if(cp.id === addHeatingCostModalCostPositionId){
          return {
            id: cp.id,
            heatingCostType: heatingCost,
            heatingCostTypeName: heatingCost,
            heatingCost: Autoload.MainHelper.convertCurrencyToNumber(cp.heatingCost ? cp.heatingCost : 0),
            systemHeatingCostTypeId: null,
            displayOrder: cp.displayOrder
          }
        }else{
          return {
            id: cp.id,
            heatingCostType: cp.heatingCostType,
            heatingCostTypeName: cp.heatingCostType,
            heatingCost: Autoload.MainHelper.convertCurrencyToNumber(cp.heatingCost ? cp.heatingCost : 0),
            systemHeatingCostTypeId: cp.systemHeatingCostTypeId,
            displayOrder: cp.displayOrder
          }
        }
      });
      if (cpPutData.length) {
        this.putHeatingCosts(cpPutData);
      }
    }else{
      this.addHeatingCost(false, true, heatingCost);
    } 
  }

  putHeatingCosts=(cpPutData, stopGetCall=false)=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'heatingCosts?utilityBillingId=' + this.props.currentDataId,
      'PUT',
      cpPutData,
      null,
      false,
      true,
      false,
      this,
      '_handleCpPut',
      {stopGetCall},
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleHeatingCpPut(response){
    if(response.status && response.statuscode === 200){
      if(response.other.stopGetCall === false){
        this.getHeatingCosts();
      }
    }
  }

  postCO2TaxReimbursementValue = () => {
    const {utilityBillingData, isCO2TaxActivated, amount, unitAmount, totalCost, totalCO2Emissions, isCO2TaxMainlyUsedForBusiness, isCO2TaxPreservationObject, propertyData} = this.state;
    
    let payload = {
      ...utilityBillingData,
      heatingEnergyAmount: amount?.value,
      energyAmountWarmWater: unitAmount?.value,
      totalHeatingCost: totalCost?.value,
      totalCO2Emissions: totalCO2Emissions,
      isCO2TaxMainlyUsedForBusiness,
      isCO2TaxPreservationObject,
      isCO2TaxActivated,
      property: propertyData
    }
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'CO2TaxReimbursementValue',
      'POST',
      payload,
      null,
      false,
      true,
      false,
      this,
      '_handleGetCO2TaxReimbursementValue',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  _handleGetCO2TaxReimbursementValue(response){
    if(response.status && response.statuscode === 200){
      //console.log('_handleGetCO2TaxReimbursementValue: ', response);
      let {costPositions, co2TaxReturnLandlordShareSystemId} = this.state;
      costPositions = costPositions.map((cp)=> {
        if(cp.systemHeatingCostTypeId === co2TaxReturnLandlordShareSystemId){
          cp.heatingCost = accounting.formatMoney(response.result, { symbol: "€",  format: "%v %s", precision: 2, thousand: '.', decimal: ',' });
          return cp;
        }else{
          return cp;
        }
      });
      console.log('_handleGetCO2TaxReimbursementValue: ', costPositions);
      this.setState({costPositions}, ()=> this.calculateTotalOnAmountChange());
    }
  }

  createEmptyHeatingCostPosition=()=>{
    const {cpOptions, costPositions} = this.state;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'heatingCosts?utilityBillingId=' + this.props.currentDataId,
      'POST',
      { 
        heatingCostType: cpOptions[0].heatingCostTypeName, 
        heatingCostTypeName: cpOptions[0].heatingCostTypeName, 
        heatingCost: 0, 
        systemHeatingCostTypeId: cpOptions[0].id ? cpOptions[0].id : null,
        displayOrder: costPositions.length > 0 ? 
        (costPositions.length === 1 ? 
          (costPositions[0].displayOrder === 99 ? 1 : (costPositions[0].displayOrder + 1)) : 
          (costPositions[costPositions.length - 2].displayOrder + 1)) 
        : 1
      },
      null,
      false,
      true,
      false,
      this,
      'handleCreateEmptyHeatingCostPosition',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleCreateEmptyHeatingCostPosition(response){
    if(response.statuscode === 200 && response.status){
      this.getHeatingCosts();
    }
  }

  render() {
    const { open, toggle, className, cpId, propertySelected, utilityData } = this.props;
    const { totalCost, amount, addReceiptOpen, selectedReceipt, unitAmount, openAlert, openDataFehlen, selectedAllocation, selectedAllocationErr, selectedEnergyErr, selectedEnergy, openRedirectAlert, totalCostInFocus, energyConsumptionInFocus, waterHeatConsumptionInFocus, totalCO2Emissions, utilityBillingData, isCO2TaxActivated, addHeatingCostModalOpen, billingPeriod, isCO2TaxMainlyUsedForBusiness, isCO2TaxPreservationObject, heatedFloorSpace, heatedFloorSpaceInFocus, co2TaxReturnLandlordShareSystemId, costPositions, dropdownStateOne, heatingCostsLoader, heatingCostHover, addHeatingCostModalCostPositionId } = this.state;

    console.log('utilityData: ', billingPeriod);
    console.log('co2TaxReturnLandlordShareSystemId: ', co2TaxReturnLandlordShareSystemId, costPositions);
    return (
      <>
      {addReceiptOpen && (
        <ReceiptDetailModal
          history={this.props.history}
          record={null}
          parent={this}
          onCreateReceiptSelect={(id)=> this.onCreateReceiptSelect(id)}
          className="add-edit-receipt-modal"
          edit={false}
          open={addReceiptOpen}
          toggle={(id) => this.toggleAddReceiptModal(id)}
          utilityData={this.props.utilityData}
          timeFrame={this.props.timeFrame}
          billingRelevant
          selectedCostType={[{id: cpId.costTypeId}]}
          cp={this.props.data}
        />
      )}
      {openAlert &&
      <AlertModal
        heading={lang.utility.text_confirm_alert}
        open={openAlert}
        onCancel={()=> this.setState({checkboxone: false, openAlert: false})}
        cancel
        rightButtonText={'Bestätigen'}
        text={<span 
          style={{
            fontSize: '14px',
            lineHeight: '24px',
            color: 'rgb(14, 14, 29)',
            fontWeight: '400',
            fontFamily: 'Inter, sans-serif',
          }}>Wenn kein Verbrauch verfügbar ist, wird die Wärmmenge für Warmwasser automatisch abgeschätzt. Dies ist nur in Ausnahmefällen zulässig <a href="https://hilfe.hellohousing.de/de/articles/6779156-warum-muss-der-warmeverbrauch-fur-die-zentrale-warmwasserhitzung-separat-erfasst-werden" target="_blank">(mehr erfahren)</a>.</span>} 
        onClick={(e) => {
          this.setState({
            checkboxone: true,
            openAlert: false,
            // selectedAllocation: {
            //   allocationKeyName: '',
            //   conversionkWh: '',
            // },
            // unitAmount: {
            //   value: '',
            //   msg: '',
            //   err: false,
            // }
          });
        }}
        toggle={() => {
          this.setState({checkboxone: false, openAlert: false})
        }}
        />
    }
    {openRedirectAlert &&
    <AlertModal
        heading={'Werte speichern'}
        open={openRedirectAlert}
        onCancel={()=> this.setState({openRedirectAlert: false})}
        cancel
        text={'Sollen die aktuellen Änderungen gespeichert werden?'} 
        onClick={(e) => {
          this.handleSubmit(e);
        }}
        toggle={() => {
          this.setState({openRedirectAlert: false});
        }}
        middle
        onMiddle={()=>{
          this.setState({openRedirectAlert: false});
          this.props.history.push('/property/heatingCostInformation');
        }}
        middleText={'Nein, weiter'}
        rightButtonText={'Ja, weiter'}
        />}
    {openDataFehlen &&
      <AlertModal
        heading={'Daten fehlen'}
        open={openDataFehlen}
        rightButtonText={'Speichern'}
        leftButtonText={'Zurück'}
        cancel
        text={propertySelected.heatingType === 'central with warm water' ? 'Zur Verteilung der Heizkosten sind die Angabe der Heizkosten, des Energieverbrauch und der Wärmemenge Warmwasser notwendig. Wollen Sie dennoch fortfahren?' 
        : 'Zur Verteilung der Heizkosten sind die Angabe der Heizkosten und des Energieverbrauch notwendig. Wollen Sie dennoch fortfahren?'
        // <>
        //   {totalCost.err &&
        //     <span style={{fontSize: '12px', color: '#dc3545'}}>
        //       {totalCost.msg} <br />
        //     </span>
        //   }
        //   {(selectedEnergyErr || amount.err) &&
        //     <span style={{fontSize: '12px', color: '#dc3545'}}>
        //       Energieverbrauch: Bitte
        //       {selectedEnergyErr && ' Energieträger'}
        //       {selectedEnergyErr && amount.err && ' und'}
        //       {amount.err &&  ' ' + 'Verbrauch'} angeben <br />
        //     </span>
        //   }
        //   {(selectedAllocationErr || unitAmount.err) &&
        //     <span style={{fontSize: '12px', color: '#dc3545'}}>
        //       Wärmemenge Warmwasserboiler: Bitte
        //       {selectedAllocationErr && ' Einheit'}
        //       {selectedAllocationErr && unitAmount.err && ' und'}
        //       {unitAmount.err && ' ' + 'Verbrauch'} angeben
        //     </span>
        //   }
        // </>
        } 
        onClick={(e) => {
          this.saveData(e);
          this.setState({openDataFehlen: false});
        }}
        toggle={() => {
          this.setState({openDataFehlen: false});
        }}
        onCancel={() => this.setState({openDataFehlen: false})}
      />
      // <AlertModal
      //   heading={'Daten fehlen'}
      //   open={openDataFehlen}
      //   text={<>
      //     {totalCost.err &&
      //       <span style={{fontSize: '12px', color: '#dc3545'}}>
      //         {totalCost.msg} <br />
      //       </span>
      //     }
      //     {(selectedEnergyErr || amount.err) &&
      //       <span style={{fontSize: '12px', color: '#dc3545'}}>
      //         Energieverbrauch: Bitte
      //         {selectedEnergyErr && ' Energieträger'}
      //         {selectedEnergyErr && amount.err && ' und'}
      //         {amount.err &&  ' ' + 'Verbrauch'} angeben <br />
      //       </span>
      //     }
      //     {(selectedAllocationErr || unitAmount.err) &&
      //       <span style={{fontSize: '12px', color: '#dc3545'}}>
      //         Wärmemenge Warmwasserboiler: Bitte
      //         {selectedAllocationErr && ' Einheit'}
      //         {selectedAllocationErr && unitAmount.err && ' und'}
      //         {unitAmount.err && ' ' + 'Verbrauch'} angeben
      //       </span>
      //     }
      //   </>
      //   } 
      //   onClick={(e) => {
      //     e.preventDefault();
      //     this.setState({openDataFehlen: false});
      //   }}
      //   toggle={() => {
      //     this.setState({openDataFehlen: false});
      //   }}
      // />
    }
      <CustomModal
        heading={lang.utility.text_calculate_heating_cost}
        headingClassName={'ub-heating-cost-modal-heading'}
        open={open}
        toggle={() => {
          this.resetForm();
          toggle();
        }}
        className={className}
      >
        <div style={{marginBottom: '20px'}}>
          <span style={{fontWeight: '400'}}>{`Geben Sie die Heizkosten und den Energieverbrauch der Abrechnungsperiode (${utilityData && utilityData.date}) an.`}</span>
        </div>
        <Form onSubmit={this.handleSubmit} ref={(ref) => { this.form = ref; }} noValidate>
          {/*this.saveData*/}
          <p className="heating-cost-text ub-heating-cost-modal-heating-costs">{lang.utility.text_Heating_costs}</p>
          <Form.Group>
            <div className="cp-cost-icons">
              <Form.Label className="cp-total-cost-label"> {lang.utility.text_total_costs} </Form.Label>
              {/* <OverlayTrigger placement="top" delay={{ show: 450, hide: 400 }} overlay={this.renderTooltip}>
                <img src={Help} alt="help" width="16px" height="16px" />
              </OverlayTrigger> */}
            </div>
            {/*   */}
            <NumericFormat 
            className='form-control'
            value={totalCost.value}
            thousandSeparator="." 
            decimalSeparator=","
            onValueChange={(values, sourceInfo) => {
              this.onInputChange('totalCost', values.floatValue)
            }}
            onKeyPress={this.handleEnterKeyPress}
            placeholder='0,00 €'
            suffix=' €'
            // onFocus={()=> this.setState({totalCostInFocus: true})}
            // onBlur={()=> this.setState({totalCostInFocus: false})}
            decimalScale={2}
            fixedDecimalScale={true}
            allowNegative={true}
            disabled={true}
            />
            <Form.Control.Feedback className="err" type="invalid">
              {totalCost.msg}
            </Form.Control.Feedback>
          </Form.Group>
          {heatingCostsLoader === false &&
          <Row>
            <Col xs={6}>
              <Form.Label className="cp-cost-position-label">{lang.newKeys.text_cost_positions}</Form.Label>
            </Col>
            <Col xs={4}>
              <Form.Label className="cp-amount-label"> {lang.general.text_amount} </Form.Label>
            </Col>
            <Col xs={2}></Col>
          </Row>}
          {heatingCostsLoader ?
          <Group style={{height: '100px'}} position='center'>
            <Loader size={'sm'}/>
          </Group>
          :
          this.state.costPositions.filter((cp)=> isCO2TaxActivated === false ? cp.systemHeatingCostTypeId !== co2TaxReturnLandlordShareSystemId : cp)
          .sort((a,b)=> a.displayOrder - b.displayOrder).map((cp, i) => (
              <Row key={cp.id}>
                <Col xs={6}>
                  <Form.Group>
                    {/* <Form.Label className="cp-cost-position-label">{lang.newKeys.text_cost_positions}</Form.Label> */}
                    {cp.systemHeatingCostTypeId === co2TaxReturnLandlordShareSystemId ?
                    <TooltipMaterialUI title='CO2-Steuer Erstattung Vermieteranteil wird durch hellohousing ermittelt.'>
                      <UncontrolledDropdown
                        id="keyid"
                        onBlur={() => {
                          this.setState({
                            dropdownStateOne: null
                          });
                        }}
                        onClick={()=>{
                          this.setState({
                            dropdownStateOne: dropdownStateOne === cp.id ? null : cp.id
                          });
                        }}
                      >
                        <DropdownToggle className="dropdown-select form-control" disabled={cp.systemHeatingCostTypeId === co2TaxReturnLandlordShareSystemId}>
                          <span className="cp_costtypename"> {cp.heatingCostType}</span>
                          <span>
                            {' '}
                            {this.state.dropdownStateOne !== cp.id ? (
                              <img src={downArrow} alt="" />
                            ) : (
                              <img src={uparrow} alt="" />
                            )}
                          </span>
                        </DropdownToggle>
                        <DropdownMenu className="cp-menu">
                          {this.state.cpOptions.sort((a,b)=> a.heatingCostTypeName.toLowerCase().localeCompare(b.heatingCostTypeName.toLowerCase()))
                          .filter((option)=> option.id !== co2TaxReturnLandlordShareSystemId)
                          .map((option, i) => (
                            <DropdownItem
                              key={i}
                              onClick={() => {
                                this.onCpChange('heatingCostType', cp.id, option);
                              }}
                              className="cpRow-options"
                            >
                              <span style={{ fontSize: 12 }}>{option.heatingCostTypeName}</span>
                            </DropdownItem>
                          ))}
                          <DropdownItem
                              key={this.state.cpOptions.length}
                              onClick={() => {
                                this.setState({addHeatingCostModalOpen: true, addHeatingCostModalCostPositionId: cp.id});
                                //this.onCpChange('heatingCostType', cp.id, option);
                              }}
                              className="cpRow-options"
                            >
                              <Group>
                                <ColorButton
                                text={'Kostenart hinzufügen'}
                                className={'gray-btn'}
                                onClick={() => null}
                                />
                              </Group>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </TooltipMaterialUI>
                    :
                    <UncontrolledDropdown
                        id="keyid"
                        onBlur={() => {
                          this.setState({
                            dropdownStateOne: null
                          });
                        }}
                        onClick={()=>{
                          this.setState({
                            dropdownStateOne: dropdownStateOne === cp.id ? null : cp.id
                          });
                        }}
                      >
                        <DropdownToggle className="dropdown-select form-control" disabled={cp.systemHeatingCostTypeId === co2TaxReturnLandlordShareSystemId}>
                          <span className="cp_costtypename"> {cp.heatingCostType}</span>
                          <span>
                            {' '}
                            {this.state.dropdownStateOne !== cp.id ? (
                              <img src={downArrow} alt="" />
                            ) : (
                              <img src={uparrow} alt="" />
                            )}
                          </span>
                        </DropdownToggle>
                        <DropdownMenu className="cp-menu">
                          {this.state.cpOptions.filter((option)=> option.id !== co2TaxReturnLandlordShareSystemId)
                          .map((option, i) => (
                            <DropdownItem
                              key={i}
                              onClick={() => {
                                this.onCpChange('heatingCostType', cp.id, option);
                              }}
                              className="cpRow-options"
                            >
                              <span style={{ fontSize: 12 }}>{option.heatingCostTypeName}</span>
                            </DropdownItem>
                          ))}
                          <DropdownItem
                              key={this.state.cpOptions.length}
                              onClick={() => {
                                this.setState({addHeatingCostModalOpen: true, addHeatingCostModalCostPositionId: cp.id});
                                //this.onCpChange('heatingCostType', cp.id, option);
                              }}
                              className="cpRow-options"
                              style={{padding: 0}}
                            >
                              <Group>
                                <ColorButton
                                text={'Kostenart hinzufügen'}
                                className={'gray-btn'}
                                onClick={() => null}
                                />
                              </Group>
                          </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>}
                    
                  </Form.Group>
                </Col>
                <Col xs={4}>
                  <Form.Group>
                    {/* <Form.Label className="cp-amount-label"> {lang.general.text_amount} </Form.Label> */}
                    <Form.Control
                      type="text"
                      id="cp-amount-modal"
                      name="cp-amount-modal"
                      isInvalid={cp.err}
                      placeholder={
                        cp.heatingCost
                          ? Autoload.MainHelper.ConverterCurrencyDe(cp.heatingCost + '', true,)
                          : '0,00€'
                      }
                      onChange={(e) =>
                        this.onCpChange(
                          'heatingCost',
                          cp.id,
                          Autoload.MainHelper.convertCurrencyToNumber(e.target.value),
                          false
                        )
                      }
                      onBlur={(e) => {
                        // console.log('e.target', e.target.value)
                        if (!e.target.value && cp.heatingCost) {
                          console.log(cp.heatingCost)
                          e.target.value = Autoload.MainHelper.convertToCurrencyFromNumber(Autoload.MainHelper.convertCurrencyToNumber(cp.heatingCost), true);
                        } else {
                          console.log(e.target.value)
                          this.onAmountBlur(
                            'heatingCost',
                            cp.id,
                            Autoload.MainHelper.convertToCurrencyFromNumber(e.target.value, true)
                          );
                          e.target.value = Autoload.MainHelper.convertToCurrencyFromNumber(Autoload.MainHelper.convertCurrencyToNumber(e.target.value), true);
                        }
                      }}
                      disabled={cp.systemHeatingCostTypeId === co2TaxReturnLandlordShareSystemId}
                    />
                    <Form.Control.Feedback className="err" type="invalid">
                      {cp.msg}
                    </Form.Control.Feedback>
                    {this.state.errMsg && <p className="heatingDropdown_errormsg"> Ungültige Eingabe</p>}
                  </Form.Group>
                </Col>
                <Col xs={2} className="dustbin-icon">
                  {cp.systemHeatingCostTypeId !== co2TaxReturnLandlordShareSystemId &&
                  <img
                    src={TrashIcon}
                    alt="help"
                    width="16px"
                    height="16px"
                    onClick={() => utilityData.isSecured === false && this.deleteHeatingCost(cp.id)}
                    style={{ cursor: utilityData.isSecured === false && 'pointer' }}
                  />}
                </Col>
              </Row>
          ))
          }
          {heatingCostsLoader === false &&
          <ColorButton
            onClick={(e) => {
              e.preventDefault();
              this.createEmptyHeatingCostPosition();
            }}
            className="gray-btn  add-cp-btn"
            text={lang.newKeys.text_add_cp}
            disabled={utilityData.isSecured}
          />}
          <Form.Group className="cp-multiselect">
            <Form.Label className="cp-receipts-label"> {lang.receipt.text_receipts} </Form.Label>
            {/* <Multiselect
                            className="cp_multiselect_id"
                            style={{
                                chips: { background: '#F2F2FC', borderRadius: '4px', color: '#111147' },
                                multiselectContainer: { marginRight: '0px', width: '100%' },
                                inputField: { marginRight: '0px', width: '83px', overflowX: 'ellipses' },
                            }}
                            tableView={true}
                            secondColumnKey="date"
                            tableHeaders={[{ heading: 'receipt' }, { heading: 'date' }]}
                            // ref={this.multiselectRef}
                            options={this.state.receiptsDropDown} // Options to display in the dropdown
                            selectedValues={this.state.selectedReciptsArr} // Preselected value to persist in dropdown
                            onSelect={this.onReceiptSelect} // Function will trigger on select event
                            onRemove={this.onReceiptRemove} // Function will trigger on remove event
                            displayValue="receiptName"
                            // placeholder="Select"
                            closeIcon="cancel"
                            avoidHighlightFirstOption={true}
                            showCheckbox={true}
                            // disable={this.state.multiselectEnable}
                            closeOnSelect={false}
                            hidePlaceholder={true}
                            renderCustomButton={
                                <ColorButton
                                    className="gray-btn utitlity-add-cost-button"
                                    text="Add receipt"
                                    onClick={() => redirection('/receipt')}
                                />
                            }
                        /> */}
            <div style={{fontSize: '14px'}}>
              <MultiselectDropdown
                className="cost-allocation-receipts"
                tableView={true}
                newTable={true}
                viewDetails={true}
                displayValue2="receiptDate"
                placeholder={lang.newKeys.text_select}
                // tableHeaders={[{ heading: 'receipt' }, { heading: 'date' }]}
                // ref={this.multiselectRef}
                options={this.state.receipts} // Options to display in the dropdown
                selectedValues={selectedReceipt && this.state.receipts.filter((receipt) => selectedReceipt.find((selected) => (selected.costPositionId === this.costPositionId && selected.receiptId === receipt.id)))} // Preselected value to persist in dropdown
                // options={this.props.receiptsArr} // Options to display in the dropdown
                // selectedValues={this.props.cpId.selectedReceipt == null ? '' : this.props.cpId.selectedReceipt} // Preselected value to persist in dropdown
                onSelect={this.onReceiptSelect} // Function will trigger on select event
                onRemove={this.onReceiptRemove} // Function will trigger on remove event
                displayValue="name"
                toggleViewDetailsModal={this.toggleViewDetailsModal}
                // placeholder="Select"
                // closeIcon="cancel"
                // avoidHighlightFirstOption={true}
                // showCheckbox={true}
                // disable={this.state.multiselectEnable}
                // closeOnSelect={false}
                // hidePlaceholder={true}
                disable={utilityData.isSecured}
                multiSelect
                endListContent={
                  <div
                  className="gray-btn utitlity-add-cost-button"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 40,
                    cursor: 'pointer',
                    border: '1px solid #eee  ',
                  }}
                  onClick={() => this.toggleAddReceiptModal(this.props.cpId)}
                >
                  {lang.receipt.text_add_receipt}
                </div>
                }
              />
            </div>
          </Form.Group>
          <div className="cp-cost-icons">
            <p className="heating-cost-text ub-heating-cost-modal-energy-consumption">{lang.utility.text_Energy_consumption}</p>
            {/* <OverlayTrigger placement="top" delay={{ show: 450, hide: 400 }} overlay={this.renderEnergyTooltip}>
              <img src={Help} alt="help" width="16px" height="16px" />
            </OverlayTrigger> */}
          </div>
          <Row>
            <Col xs={6}>
              <Form.Group>
                <Form.Label className="cp-cost-position-label"> {lang.newKeys.text_Consumption_type} </Form.Label>
                <UncontrolledDropdown
                  id="keyid2"
                  onBlur={() => {
                    this.setState({
                      dropdownStateTwo: document
                        .getElementById('keyid2')
                        .firstElementChild.getAttribute('aria-expanded'),
                    });
                  }}
                  onFocus={() => {
                    this.setState({
                      dropdownStateTwo: document
                        .getElementById('keyid2')
                        .firstElementChild.getAttribute('aria-expanded'),
                    });
                  }}
                >
                  <DropdownToggle className={`dropdown-select form-control ${selectedEnergyErr && 'invalid'}`} disabled={utilityData.isSecured}>
                    <span> {this.state.selectedEnergy.fuelTypeName}</span>
                    <span>
                      {' '}
                      {this.state.dropdownStateTwo === 'true' ? (
                        <img src={downArrow} alt="" />
                      ) : (
                        <img src={uparrow} alt="" />
                      )}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu className="cp-menu">
                    {this.state.energyOptions.sort((a,b)=> a.fuelTypeName > b.fuelTypeName).sort((a,b)=> { return a.fuelTypeName === 'Energieverbrauch (kWh)' ? -1 : b.fuelTypeName === 'Energieverbrauch (kWh)' ? 1 : 0 })
                    .map((option) => (
                      <DropdownItem
                        onClick={() => {
                          this.energyChange(option);
                        }}
                        className="cpRow-options"
                      >
                        <span className=" ">{option.fuelTypeName}</span>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
                {!selectedEnergy.fuelTypeName && selectedEnergyErr && <span style={{
                  width: '100%',
                  marginTop: '0.25rem',
                  fontSize: '80%',
                  color: '#dc3545'
                  }}>Energieträger erforderlich</span>}
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group>
                <Form.Label className="cp-amount-label"> {lang.utility.text_Consumption} </Form.Label>
                {/* <Form.Control
                  type="text"
                  id="cp-amount-modal"
                  name="cp-amount-modal"
                  isInvalid={amount.err}
                  placeholder="0,00"
                  value={this.state.amount.value}
                  onKeyPress={this.handleEnterKeyPress}
                  onChange={(e) => this.onInputChange('amount', e.target.value)}
                  disabled={utilityData.isSecured}
                  onBlur={(e) =>
                    this.onInputChange(
                      'amount',
                      formatNumber(this.state.amount.value)
                    )
                  }
                /> */}
                <NumericFormat 
                  className='form-control'
                  value={this.state.amount.value}
                  thousandSeparator="." 
                  decimalSeparator=","
                  onValueChange={(values, sourceInfo) => {
                    this.onInputChange('amount', values.floatValue);
                  }}
                  onKeyPress={this.handleEnterKeyPress}
                  placeholder='0,00'
                  onFocus={()=> this.setState({energyConsumptionInFocus: true})}
                  onBlur={(e)=> {
                    if(isCO2TaxActivated === true){
                      const {selectedEnergy} = this.state;
                      let value = accounting.unformat(e.target.value, ',');
                      let calculateTotalCO2Emissions = (selectedEnergy.fuelTypeName === 'Heizleistung (kWh)') ? NaN : ((value * selectedEnergy.conversionkWh) * selectedEnergy.conversionCO2EmissionskgBykWh);
                      console.log('calculateTotalCO2Emissions: ', calculateTotalCO2Emissions, selectedEnergy, selectedEnergy.fuelTypeName === 'Heizleistung (kWh)');
                      this.setState({totalCO2Emissions: calculateTotalCO2Emissions}, ()=> this.postCO2TaxReimbursementValue());  
                    }
                    this.setState({energyConsumptionInFocus: false});
                  }}
                  decimalScale={energyConsumptionInFocus ? 5 : 2}
                  fixedDecimalScale={energyConsumptionInFocus ? false : true}
                  allowNegative={false}
                  disabled={utilityData.isSecured}
                />
                <Form.Control.Feedback className="err" type="invalid">
                  {amount.msg}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          {propertySelected.heatingType === 'central with warm water' &&
            <><div className="cp-cost-icons" style={{marginTop: 20}}>
            <p className="heating-cost-text ub-heating-cost-modal-heat-quantity-hot-water">{lang.utility.text_heat_quantity_hot_water_boiler}</p>
            {/* <OverlayTrigger placement="top" delay={{ show: 450, hide: 1000 }} overlay={this.renderWärmemengeWarmwasserboilerTooltip}>
              <img src={Help} alt="help" width="16px" height="16px" />
            </OverlayTrigger> */}
          </div>  
          <Row>
            <Col xs={6}>
              <Form.Group>
                <Form.Label className="cp-cost-position-label"> {lang.newKeys.text_unit} </Form.Label>
                <UncontrolledDropdown
                  id="keyid2"
                  onBlur={() => {
                    this.setState({
                      dropdownStateTwo: document
                        .getElementById('keyid2')
                        .firstElementChild.getAttribute('aria-expanded'),
                    });
                  }}
                  onFocus={() => {
                    this.setState({
                      dropdownStateTwo: document
                        .getElementById('keyid2')
                        .firstElementChild.getAttribute('aria-expanded'),
                    });
                  }}
                >
                  <DropdownToggle disabled={utilityData.isSecured || this.state.checkboxone} className={`dropdown-select form-control ${selectedAllocationErr && 'invalid'}`}>
                    <span style={{minWidth: 0, overflow: 'hidden', whiteSpace:'nowrap', textOverflow: 'ellipsis'}}> {this.state?.selectedAllocation?.allocationKeyName}</span>
                    <span style={{paddingRight: 10}}> {this.state?.selectedAllocation?.units && this.state?.selectedAllocation?.units !== '-' ? `(${this.state?.selectedAllocation?.units})` : ''}</span>
                    <span>
                      {' '}
                      {this.state.dropdownStateTwo === 'true' ? (
                        <img src={downArrow} alt="" />
                      ) : (
                        <img src={uparrow} alt="" />
                      )}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu className="cp-menu">
                    {this.state.allocationOptions.map((option) => option.type === 'WARMWATER' && option.allocationKeyName !== 'Warmwasserverbrauch' && (
                      <DropdownItem
                        onClick={() => {
                          this.allocationChange(option);
                        }}
                        className="cpRow-options"
                      >
                        {console.log('option: ', option)}
                        <span className=" ">{`${option.allocationKeyName} ${option.units && option.units !== '-' ? `(${option.units})` : ''}`}</span>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
                {!selectedAllocation?.allocationKeyName && selectedAllocationErr && !this.state.checkboxone && <span style={{
                  width: '100%',
                  marginTop: '0.25rem',
                  fontSize: '80%',
                  color: '#dc3545'
                  }}>Einheit erforderlich</span>}
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group>
                <Form.Label className="cp-amount-label"> {lang.utility.text_Consumption} </Form.Label>
                {/* <Form.Control
                  type="text"
                  id="cp-amount-modal"
                  name="cp-amount-modal"
                  isInvalid={unitAmount.err}
                  placeholder="0,00"
                  value={this.state.unitAmount.value}
                  onKeyPress={this.handleEnterKeyPress}
                  onChange={(e) => this.onInputChange('unitAmount', e.target.value)}
                  disabled={utilityData.isSecured || this.state.checkboxone}
                /> */}
                <NumericFormat 
                  className='form-control'
                  value={this.state.unitAmount.value}
                  thousandSeparator="." 
                  decimalSeparator=","
                  onValueChange={(values, sourceInfo) => {
                    this.onInputChange('unitAmount', values.floatValue);
                  }}
                  onKeyPress={this.handleEnterKeyPress}
                  placeholder='0,00'
                  onFocus={()=> this.setState({waterHeatConsumptionInFocus: true})}
                  onBlur={()=> this.setState({waterHeatConsumptionInFocus: false})}
                  decimalScale={waterHeatConsumptionInFocus ? 5 : 2}
                  fixedDecimalScale={waterHeatConsumptionInFocus ? false : true}
                  allowNegative={false}
                  disabled={utilityData.isSecured || this.state.checkboxone}
                />
                <Form.Control.Feedback className="err" type="invalid">
                  {unitAmount.msg}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Form.Group controlId="formBasicCheckboxOne" className="form-check-box">
                <Checkbox
                  checked={this.state.checkboxone}
                  onClick={() => {
                    this.state.checkboxone ? this.setState({checkboxone: false, selectedAllocation: propertySelected.centralWarmWaterAllocationKey}) : this.setState({checkboxone: true, openAlert: true})
                  }}
                  style={{
                    lineHeight: '22px',
                    color: this.state.checkboxone ? '#5555FF' : '#9A9AB5',
                    padding: 0,
                    borderRadius: 0,
                    width: '14px',
                    height: '14px',
                    marginLeft: '1px'
                  }}
                  disabled={utilityData.isSecured}
                />
                <Form.Label className="cp-amount-label ub-heating-cost-modal-disable-heat-quantity-hot-water" style={{marginLeft: '8px'}}>kein Verbrauch verfügbar</Form.Label>
              </Form.Group>
            </Col>
          </Row>
          </>}
          {(dayjs(billingPeriod?.endDate).year() >= 2023) &&
          <>
            <div className="cp-cost-icons" style={{marginTop: '15px'}}>
              <p className="heating-cost-text ub-heating-cost-modal-co2-tax"><span>CO<span style={{verticalAlign: 'sub', fontSize: '8px'}}>2</span>-Steuer</span></p>
            </div>
            <Row style={{marginTop: '-5px'}}>
              <Col xs={6}>
                <Form.Group>
                  <Form.Label className="cp-amount-label"><span>CO<span style={{verticalAlign: 'sub', fontSize: '8px'}}>2</span>-Emissionen</span></Form.Label>
                  <NumericFormat 
                    className='form-control'
                    value={totalCO2Emissions}
                    thousandSeparator="." 
                    decimalSeparator=","
                    onValueChange={(values, sourceInfo) => {
                      if(isCO2TaxActivated === true){
                        this.setState({totalCO2Emissions: values.floatValue});
                      }
                    }}
                    onKeyPress={this.handleEnterKeyPress}
                    onBlur={(e)=> {
                      console.log('onBlur: ', e.target.value);
                      let value = e.target.value;
                      if(!value){
                        let calculateTotalCO2Emissions = (selectedEnergy.fuelTypeName === 'Heizleistung (kWh)') ? NaN : ((this.state.amount.value * selectedEnergy.conversionkWh) * selectedEnergy.conversionCO2EmissionskgBykWh);
                        console.log('onBlur: ', calculateTotalCO2Emissions);
                        this.setState({totalCO2Emissions: calculateTotalCO2Emissions}, ()=> this.postCO2TaxReimbursementValue());
                      }else{
                        this.postCO2TaxReimbursementValue();
                      }
                    }}
                    placeholder='0,00 kg'
                    suffix={' kg'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    disabled={utilityData.isSecured || (isCO2TaxActivated === false)}
                  />
                  <Form.Control.Feedback className="err" type="invalid">
                    {amount.msg}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              {/* <Col xs={6}>
                <Form.Group>
                  <Form.Label style={{marginBottom: '7px'}} className="cp-amount-label"><span>Beheizte Wohnfläche</span></Form.Label>
                  <NumericFormat 
                    className='form-control'
                    value={heatedFloorSpace}
                    thousandSeparator="." 
                    decimalSeparator=","
                    onValueChange={(values, sourceInfo) => this.setState({heatedFloorSpace: values.floatValue})}
                    onKeyPress={this.handleEnterKeyPress}
                    placeholder='0,00 m²'
                    suffix={' m²'}
                    onFocus={()=> this.setState({heatedFloorSpaceInFocus: true})}
                    onBlur={()=> this.setState({heatedFloorSpaceInFocus: false})}
                    decimalScale={heatedFloorSpaceInFocus ? 5 : 2}
                    fixedDecimalScale={heatedFloorSpaceInFocus ? false : true}
                    allowNegative={false}
                    disabled={utilityData.isSecured || (isCO2TaxActivated === false)}
                  />
                  <Form.Control.Feedback className="err" type="invalid">
                    {amount.msg}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col> */}
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Group controlId="formBasicCheckboxOne" style={{marginBottom: '5px'}} className="form-check-box">
                  <Checkbox
                    checked={isCO2TaxMainlyUsedForBusiness}
                    onClick={() => this.setState({isCO2TaxMainlyUsedForBusiness: !isCO2TaxMainlyUsedForBusiness}, ()=> this.postCO2TaxReimbursementValue())}
                    style={{
                      lineHeight: '22px',
                      color: isCO2TaxActivated === false ? (isCO2TaxMainlyUsedForBusiness ? '#5555FF' : '#dadae6') : (isCO2TaxMainlyUsedForBusiness ? '#5555FF' : '#9A9AB5'),
                      padding: 0,
                      opacity: isCO2TaxActivated === false ? (isCO2TaxMainlyUsedForBusiness ? 0.5 : 1) : 1,
                      background: isCO2TaxActivated === false && '#f7f7fa',
                      borderRadius: 0,
                      width: '14px',
                      height: '14px',
                      marginLeft: '1px'
                    }}
                    disabled={utilityData.isSecured || (isCO2TaxActivated === false)}
                  />
                  <Form.Label className="cp-amount-label ub-heating-cost-modal-co2-tax-property-commercial-tenants-check" style={{marginLeft: '8px'}}>Immobilie überwiegend an Gewerbe vermietet</Form.Label>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Group controlId="formBasicCheckboxOne" style={{marginBottom: '5px'}} className="form-check-box">
                  <Checkbox
                    checked={isCO2TaxPreservationObject}
                    onClick={() => this.setState({isCO2TaxPreservationObject: !isCO2TaxPreservationObject}, ()=> this.postCO2TaxReimbursementValue())}
                    style={{
                      lineHeight: '22px',
                      color: isCO2TaxActivated === false ? (isCO2TaxPreservationObject ? '#5555FF' : '#dadae6') : (isCO2TaxPreservationObject ? '#5555FF' : '#9A9AB5'),
                      padding: 0 ,
                      opacity: isCO2TaxActivated === false ? (isCO2TaxPreservationObject ? 0.5 : 1) : 1,
                      background: isCO2TaxActivated === false && '#f7f7fa',
                      borderRadius: 0,
                      width: '14px',
                      height: '14px',
                      marginLeft: '1px'
                    }}
                    disabled={utilityData.isSecured || (isCO2TaxActivated === false)}
                  />
                  <Form.Label className="cp-amount-label ub-heating-cost-modal-co2-tax-property-preservation-check" style={{marginLeft: '8px'}}>Immobilie unter Denkmalschutz / Erhaltungssatzung</Form.Label>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Group controlId="formBasicCheckboxOne" className="form-check-box">
                  <Checkbox
                    checked={isCO2TaxActivated === false}
                    onClick={() => {
                      if(!isCO2TaxActivated === true) {
                        const {selectedEnergy} = this.state;
                        let calculateTotalCO2Emissions = (amount.value * selectedEnergy.conversionkWh) * selectedEnergy.conversionCO2EmissionskgBykWh;
                        this.setState({isCO2TaxActivated: !isCO2TaxActivated, totalCO2Emissions: !totalCO2Emissions ? calculateTotalCO2Emissions : totalCO2Emissions}, ()=> this.calculateTotalOnAmountChange());
                      }else{
                        this.setState({isCO2TaxActivated: !isCO2TaxActivated}, ()=> this.calculateTotalOnAmountChange());
                      }
                    }}
                    style={{
                      lineHeight: '22px',
                      color: isCO2TaxActivated === false ? '#5555FF' : '#9A9AB5',
                      padding: 0,
                      borderRadius: 0,
                      width: '14px',
                      height: '14px',
                      marginLeft: '1px'
                    }}
                    disabled={utilityData.isSecured}
                  />
                  <Form.Label className="cp-amount-label ub-heating-cost-modal-co2-tax-disable-co2-tax-check" style={{marginLeft: '8px'}}>keine C0<span style={{verticalAlign: 'sub', fontSize: '8px'}}>2</span>-Steuer ausweisen</Form.Label>
                </Form.Group>
              </Col>
            </Row>
          </>}
          <Row>
            <Col xs={12}>
              <span className="navigate-button" onClick={(e) => {
                e.preventDefault();
                this.setState({openRedirectAlert: true});
                }}><img src={UpRightArrow} style={{width: '10px', height: '10px', marginRight: '5px', marginTop: '-2px'}}/>{'Klicken Sie hier, um zu den Grundeinstellungen der Heizungsanlage zu gelangen.'}
              </span>
            </Col>
          </Row>
          <div className="cancel_add_btn_container">
            <div>
              <ColorButton
                onClick={(e) => {
                  e.preventDefault();
                  this.resetForm();
                  toggle();
                }}
                className="gray-btn"
                text={lang.general.text_cancel}
              />
            </div>
            <div>
              <ColorButton
                buttonConfig={{ type: 'submit' }}
                className="purple-btn cp-btn"
                text={lang.meter.text_save}
                disabled={utilityData.isSecured}
              />
            </div>
          </div>
        </Form>
        {addHeatingCostModalOpen &&
        <AddHeatingCostModal
        open={addHeatingCostModalOpen}
        toggle={() => this.setState({addHeatingCostModalOpen: false})}
        saveHeatingCost={(heatingCost) => this.setState({addHeatingCostModalOpen: false}, ()=> this.saveHeatingCost(heatingCost))}
        />}
      </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    currentDataId: state.UtilityBillingReducer.currentBillingId,
    propertySelected: state.generalReducer.property,
  };
};
const actions = {
  changeCurrentProperty,
  setRunUpdateHeatingCostSplit
};
export default connect(mapStateToProps, actions)(HeatingCostModal);
