import React, { Component } from 'react';
import Autoload from '../../../../neoverv/Autoload';
import { connect } from 'react-redux';
import { hideNotification, showNotification } from '@mantine/notifications';
import RightArrowIcon from '../../../../assets/images/module/general/RightArrowIcon.svg';
import GrayTick from '../../../../assets/images/module/general/GrayTick.svg';
import SelectArrowDown from '../../../../assets/images/module/general/SelectArrowDown.svg';
import UtilityBillingAssignedIcon from '../../../../neoverv/components/SVGs/utilityBillingAssigned';
import SelectArrowUp from '../../../../assets/images/module/general/SelectArrowUp.svg';
import GreenTick from '../../../../assets/images/module/general/GreenTick.svg';
import MinimizeIcon from '@material-ui/icons/Minimize';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
//import Papa from 'papaparse';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Stack, Group, Select, Accordion } from '@mantine/core';
import { Col, Form, Row } from 'react-bootstrap';
import { ColorButton, SwitchContainer } from '../../../../neoverv';
import TemperatureNotificationModal from '../../../../neoverv/components/Module/General/CustomModal/TemperatureNotificationModal';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const pStyle= {
    fontFamily: 'Inter, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    color: 'rgb(14, 14, 29)'
}
const tableStyle= {
    border: 'none',
    padding: '5px 0px',
    textAlign: 'center'
}
const tableHeaderStyle= {
    // fontWeight: '500', 
    // lineHeight: '16px',
    // fontFamily: 'Inter, sans-serif',
    // whiteSpace: 'nowrap',
    // width: '200px',
    // maxWidth: '200px',
    // textAlign: 'left'
    whiteSpace: 'nowrap',
    border: 'none',
    paddingBottom: '0px',
    fontSize: '12px',
    fontWeight: '400',
    color:'#727293',
    width: '80px',
    width: '200px',
    maxWidth: '200px',
    textAlign: 'left'
}
export class TenantAdministrateConsumption extends Component {
  constructor(props) {
    super(props);
    this.state = {
        parsedData: [],
        tableColumnNames: [],
        tableRowValues: [
            {
                name: 'Heizung',
                checked: false,
            },
            {
                name: 'Warmwasser',
                checked: false,
            }
        ],
        selectedColumns: [],
        expandModal: false,
        hover:false,
        data: [],
        propertyData: null,
        allocationKeyData: [],
        oldSelectedHeatingConsumptionAllocationKey: null,
        oldSelectedWarmWaterConsumptionAllocationKey: null,
        selectedHeatingConsumptionAllocationKey: null,
        selectedWarmWaterConsumptionAllocationKey: null,
        openTemperatureEmptyNotificationModal: false,
        minimize: true
    };
  }

  componentDidMount = () => {
    const {propertyData} = this.props;
    this.getAllocationKeys();
    //this.props.getPropertyData();
    if(this.props.propertyData){
        this.componentDidMountStuff();
    }
  };

  componentDidMountStuff=()=>{
    const {propertyData} = this.props;
    let tableRowValues = this.state.tableRowValues;
    tableRowValues.map((row)=> {
        if(row.name === 'Heizung'){
            if(propertyData.heatingConsumptionAllocationKey && propertyData.heatingConsumptionAllocationKey.length !== 0){
                row.checked = true;
            }else{
                this.getHeatingAllocationKeys(true);
            }
        }
        if(row.name === 'Warmwasser'){
            if(propertyData.warmWaterConsumptionAllocationKey && propertyData.warmWaterConsumptionAllocationKey.length !== 0){
                row.checked = true;
            }else{
                propertyData.isWarmWaterConsumptionAllocationKeyRequired && this.getHeatingAllocationKeys(false, true);
            }
        }
    });

    this.setState({
        propertyData: propertyData,
        selectedHeatingConsumptionAllocationKey: propertyData.heatingConsumptionAllocationKey,
        oldSelectedHeatingConsumptionAllocationKey: propertyData.heatingConsumptionAllocationKey,
        selectedWarmWaterConsumptionAllocationKey: propertyData.warmWaterConsumptionAllocationKey,
        oldSelectedWarmWaterConsumptionAllocationKey: propertyData.warmWaterConsumptionAllocationKey,
        tableRowValues
    });
  }

  componentDidUpdate(prevProps){
    if(prevProps.propertyData !== this.props.propertyData){
        this.componentDidMountStuff();
    }
  }

  getPropertyData =()=>{
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.property_api_base_url + 'properties/' + this.props.propertySelected.id,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        'handle_get_property_data',
        null,
        false,
        '',
        {
          'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
  }

  handle_get_property_data(response){
    if(response.status){
        let tableRowValues = this.state.tableRowValues;
        tableRowValues.map((row)=> {
            if(row.name === 'Heizung'){
                if(response.result.heatingConsumptionAllocationKey && response.result.heatingConsumptionAllocationKey.length !== 0){
                    row.checked = true;
                }else{
                    this.getHeatingAllocationKeys(true);
                }
            }
            if(row.name === 'Warmwasser'){
                if(response.result.warmWaterConsumptionAllocationKey && response.result.warmWaterConsumptionAllocationKey.length !== 0){
                    row.checked = true;
                }else{
                    response.result.isWarmWaterConsumptionAllocationKeyRequired && this.getHeatingAllocationKeys(false, true);
                }
            }
        });

        this.setState({
            propertyData: response.result,
            selectedHeatingConsumptionAllocationKey: response.result.heatingConsumptionAllocationKey,
            oldSelectedHeatingConsumptionAllocationKey: response.result.heatingConsumptionAllocationKey,
            selectedWarmWaterConsumptionAllocationKey: response.result.warmWaterConsumptionAllocationKey,
            oldSelectedWarmWaterConsumptionAllocationKey: response.result.warmWaterConsumptionAllocationKey,
            tableRowValues
        });
    }
  }

  putPropertyData =(enableWarmwaterSwitch=null, isConsumptionSectionHidden=null, isDocumentSectionHidden=null, isMessageSectionHidden=null)=>{
    hideNotification('save_property_data');
    let payload = this.state.propertyData;
    payload.heatingConsumptionAllocationKey = this.state.selectedHeatingConsumptionAllocationKey;
    payload.warmWaterConsumptionAllocationKey =  enableWarmwaterSwitch !== null ? (enableWarmwaterSwitch ? this.state.selectedWarmWaterConsumptionAllocationKey : null) : (payload.isWarmWaterConsumptionAllocationKeyRequired ? this.state.selectedWarmWaterConsumptionAllocationKey : null);
    payload.isWarmWaterConsumptionAllocationKeyRequired = enableWarmwaterSwitch !== null ? enableWarmwaterSwitch : payload.isWarmWaterConsumptionAllocationKeyRequired;
    payload.isConsumptionSectionHidden = isConsumptionSectionHidden !== null ? isConsumptionSectionHidden : payload.isConsumptionSectionHidden;
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.property_api_base_url + 'properties/' + this.props.propertySelected.id,
        'PUT',
        payload,
        null,
        false,
        true,
        false,
        this,
        'handle_put_property_data',
        null,
        false,
        '',
        {
          'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
  }

  handle_put_property_data(response){
    if(response.status){
        showNotification({
            id: 'save_property_data',
            message: 'Änderung übernommen',
            icon: <img src={GreenTick} alt='green-tick'/>,
        });
        this.setState({oldSelectedHeatingConsumptionAllocationKey: this.state.selectedHeatingConsumptionAllocationKey, oldSelectedWarmWaterConsumptionAllocationKey: this.state.selectedWarmWaterConsumptionAllocationKey}, ()=> this.props.getPropertyData());
    }
  }

onSelect = (name, value=true)=> {
    const {allocationKeyData, selectedHeatingConsumptionAllocationKey, selectedWarmWaterConsumptionAllocationKey} = this.state;
    let tableRowValues = this.state.tableRowValues;
    tableRowValues.map((row)=> {
        if(row.name === name){
            row.checked = value && value.length !== 0 ? true : false;
        }
    });
    this.setState({tableRowValues}); 
    if(name === 'Heizung'){
        this.setState({oldSelectedHeatingConsumptionAllocationKey: selectedHeatingConsumptionAllocationKey, selectedHeatingConsumptionAllocationKey: allocationKeyData.find((alloc)=> alloc.id === value)}, ()=> this.putPropertyData());
    }
    if(name === 'Warmwasser'){
        this.setState({oldSelectedWarmWaterConsumptionAllocationKey: selectedWarmWaterConsumptionAllocationKey, selectedWarmWaterConsumptionAllocationKey: allocationKeyData.find((alloc)=> alloc.id === value)},
        ()=> {
            if(allocationKeyData.find((alloc)=> alloc.id === value).allocationKeyName === 'Warmwasserverbrauch' && (this.props.propertySelected.heatingTemperature === null || this.props.propertySelected.heatingTemperature === 0)){
                this.setState({openTemperatureEmptyNotificationModal: true, selectedWarmWaterConsumptionAllocationKey: this.state.oldSelectedWarmWaterConsumptionAllocationKey});
            }else{
                this.putPropertyData();
            }
        });
    }
}

handleMouseEnter=()=>{
    this.setState({hover: true});
  }
handleMouseLeave=()=>{
this.setState({hover: false});
}

  getAllocationKeys = ()=> {
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.property_api_base_url+"AllocationKeys?propertyId="+this.props.propertySelected.id+"&offset=0&fetch=100",
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        '_handleGetAllocationKeysResponse',
        null,
        false,
        '',
        {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
        }
    );
  }
  _handleGetAllocationKeysResponse = (response)=>{
    console.log('_handleGetAllocationKeysResponse: ', response);
    if(response.status == true){
        let data = response.result.filter((item)=> {
            if((item.type === 'ROOM_HEATING' || item.type === 'WARMWATER') && item.meterType === 'RENTAL_UNIT'){
                return item;
            }
        }).map((item)=> {
            return {
                label: `${item.allocationKeyName} ${(item.units && item.units !== '-') ? `(${item.units})` : ''}`,
                value: item.id,
                type: item.type
            }
        });
        // data.push({
        //     label: 'kein zentrales Warmwasser',
        //     value: '0',
        //     type: 'WARMWATER'
        // })
        console.log('data', data);
        this.setState({data, allocationKeyData: response.result});
    }
  }

  getHeatingAllocationKeys = (roomHeatingAllocationKeysNull= false, warmWaterAllocationKeysNull= false)=> {
    Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.meter_api_base_url+"meters/heatingAllocationKeys?propertyId="+this.props.propertySelected.id,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        '_handleGetHeatingAllocationKeysResponse',
        {roomHeatingAllocationKeysNull, warmWaterAllocationKeysNull},
        false,
        '',
        {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
        }
    );
  }
  _handleGetHeatingAllocationKeysResponse = (response)=>{
    console.log('_handleGetHeatingAllocationKeysResponse: ', response);
    if(response.status == true){
        if(response.other.roomHeatingAllocationKeysNull){
            let tableRowValues = this.state.tableRowValues;
            tableRowValues.map((row)=> {
                if(row.name === 'Heizung' && response.result.roomHeatingAllocationKey && response.result.roomHeatingAllocationKey.length !== 0){
                    row.checked = true;
                }    
            });
            response.result.roomHeatingAllocationKey && response.result.roomHeatingAllocationKey.length !== 0 && this.setState({
                selectedHeatingConsumptionAllocationKey: this.state.selectedHeatingConsumptionAllocationKey === null ? response.result.roomHeatingAllocationKey : this.state.selectedHeatingConsumptionAllocationKey,
                tableRowValues
            }, ()=> this.putPropertyData());
            
        }else if(response.other.warmWaterAllocationKeysNull){
            let tableRowValues = this.state.tableRowValues;
            tableRowValues.map((row)=> {
                if(row.name === 'Warmwasser' && response.result.warmWaterAllocationKey && response.result.warmWaterAllocationKey.length !== 0){
                    row.checked = true;
                }
            });
            response.result.warmWaterAllocationKey && response.result.warmWaterAllocationKey.length !== 0 && this.setState({
                selectedWarmWaterConsumptionAllocationKey: this.state.selectedWarmWaterConsumptionAllocationKey === null ? response.result.warmWaterAllocationKey : this.state.selectedWarmWaterConsumptionAllocationKey,
                tableRowValues
            }, ()=> this.putPropertyData());
        }
    }
  }
  
  render() {
    const {isMobile, isTablet, propertySelected} = this.props;
    const email = 'receipt-1f298e4c-685f-4373-9359-37b70958c3cc@upload.hellohousing.de';
    const {tableColumnNames, tableRowValues, expandModal, data, propertyData, selectedHeatingConsumptionAllocationKey, selectedWarmWaterConsumptionAllocationKey, oldSelectedHeatingConsumptionAllocationKey, oldSelectedWarmWaterConsumptionAllocationKey, openTemperatureEmptyNotificationModal, minimize} = this.state;
    console.log('render: ', selectedWarmWaterConsumptionAllocationKey);
    console.log('propertyData: ', propertyData, propertySelected);
    console.log('data: ', data);
    return (
        <div 
        style={{
          width: '100%',
          background: 'white',
          boxShadow: '0 1px 3px rgba(0,0,0,.08)', //!propertyData?.isConsumptionSectionHidden ? 'none' : '0 1px 3px rgba(0,0,0,.08)', 
          borderRadius: '4px'
          }}>
            <Accordion
            classNames={{
                label: 'tenant-administration-accordion-label',
                contentInner: 'tenant-administration-accordion-inner-content',
                control: 'tenant-administration-accordion-control',
                icon: 'tenant-administration-accordion-icon',
            }}

            styles={{
                content: {
                    display: propertyData?.isConsumptionSectionHidden && 'none',
                    boxShadow: propertyData?.isConsumptionSectionHidden && 'none',
                }
            }}
            iconPosition='right'
            //icon={null}
            >
                <Accordion.Item 
                label={
                <Group
                position='apart'
                style={{
                    padding: '12.1px 24px',
                    borderBottom: !propertyData?.isConsumptionSectionHidden && minimize === false ? '1px solid #dadae6' : 'none',
                    borderTopLeftRadius: '4px',
                    borderTopRightRadius: '4px',
                    borderBottomLeftRadius: minimize && '4px',
                    borderBottomRightRadius: minimize && '4px',
                    background: 'white',
                    cursor: 'pointer'
                }}
                onClick={()=> this.setState({minimize: !minimize})}
                noWrap>
                    <Group noWrap className='tenant-administration-display-consumptions'>
                        <h5 
                        style={{
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#0e0e1d',
                            marginBottom: '0px'
                        }}
                        className={'tenant-administration-display-distribution-key-heading tooltips'}>
                            Verbrauchswerte anzeigen
                        </h5>
                        <SwitchContainer
                        checked={!propertyData?.isConsumptionSectionHidden}
                        switchText={'aktivieren'}
                        disabled={false}
                        onChange={()=> this.putPropertyData(null, !propertyData?.isConsumptionSectionHidden)}
                        />
                    </Group>
                    {/* <MinimizeIcon
                    style={{color: '#727293', marginTop: '-10px', cursor: 'pointer'}}
                    onClick={()=> this.setState({minimize: !minimize})}
                    /> */}
                    {!propertyData?.isConsumptionSectionHidden && (!minimize ? <ExpandMoreIcon style={{color: '#13135C'}} /> : <NavigateNextIcon style={{color: '#13135C'}} />)}
                </Group>}>
                    {<div style={{padding: '12.1px 24px 30px',}}>
                        <Group className='modal-sub-headings'>
                            <span className='tenant-administration-assign-distribution-key-heading tooltips'>Verteilerschlüssel zuweisen</span>
                        </Group>
                        <Table style={{marginTop: '10px'}}>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{...tableStyle, ...tableHeaderStyle}}>
                                        <span>Verbräuche</span>
                                    </TableCell>
                                    <TableCell style={{...tableStyle, ...{width: '60px'}}}>
                                        
                                    </TableCell>
                                    <TableCell style={{...tableStyle, ...tableHeaderStyle,}}>
                                        <span>Zuweisungsschlüssel</span>
                                    </TableCell>
                                </TableRow>

                                {tableRowValues.map((row)=> 
                                <TableRow>
                                <TableCell style={{...tableStyle}}>
                                    <Form.Group>
                                    <Form.Control
                                    id="meter-number"
                                    name="meter-number"
                                    type="text"
                                    placeholder={row.name}
                                    value={row.name}
                                    style={{height: '36px', paddingBottom: '6px', background: 'white'}}
                                    disabled
                                    />
                                    </Form.Group>
                                </TableCell>
                                <TableCell style={{...tableStyle, ...{width: isMobile && '14%', padding: '0 5px'}}}>
                                    <div style={{marginTop: '-14px', width: 'auto'}}>
                                        <img src={RightArrowIcon} style={{transform: 'scaleX(-1)'}} width={isMobile ? 28 : 33} height={isMobile ? 28.5 : 33.5} />
                                    </div>
                                </TableCell>
                                <TableCell style={{...tableStyle}}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Form.Group style={{width: '100%'}}>
                                            {
                                            <Select
                                            placeholder={"Wählen"}
                                            searchable
                                            nothingFound="Nichts gefunden"
                                            data={row.name === 'Heizung' ? data.filter((dt)=> dt.type === 'ROOM_HEATING' && dt.label !== 'Heizkostenverteiler (Einheitswert)') : data.filter((dt)=> dt.type === 'WARMWATER')}
                                            value={row.name === 'Heizung' ? selectedHeatingConsumptionAllocationKey && selectedHeatingConsumptionAllocationKey.id : selectedWarmWaterConsumptionAllocationKey && selectedWarmWaterConsumptionAllocationKey.id}
                                            style={{height: '36px'}}
                                            rightSection={<img src={SelectArrowDown} size={14} style={{cursor: 'pointer'}} />}
                                            onChange={(value)=> this.onSelect(row.name, value)}
                                            styles={{
                                                input:{
                                                    background: 'white',
                                                    fontSize: '1rem',
                                                    cursor: 'pointer'
                                                },
                                                rightSection: { pointerEvents: 'none' }
                                            }}
                                            disabled={row.name === 'Warmwasser' && !propertyData?.isWarmWaterConsumptionAllocationKeyRequired}
                                            //onBlur={(e)=> e.target.value.length !== 0 && (row.name === 'Heizung' ? oldSelectedHeatingConsumptionAllocationKey ? (oldSelectedHeatingConsumptionAllocationKey.allocationKeyName !== e.target.value && this.putPropertyData()) : this.putPropertyData() : oldSelectedWarmWaterConsumptionAllocationKey ? (oldSelectedWarmWaterConsumptionAllocationKey.allocationKeyName !== e.target.value && this.putPropertyData()) : this.putPropertyData())}
                                            />}
                                        </Form.Group>
                                        <div style={{marginTop: '-14px', marginLeft: '8px'}}>
                                            {row.name === 'Heizung' ? (selectedHeatingConsumptionAllocationKey && selectedHeatingConsumptionAllocationKey.id ? UtilityBillingAssignedIcon('#5555FF') : UtilityBillingAssignedIcon('#9A9AB5')) : (selectedWarmWaterConsumptionAllocationKey && selectedWarmWaterConsumptionAllocationKey.id ? UtilityBillingAssignedIcon('#5555FF') : UtilityBillingAssignedIcon('#9A9AB5')) //!row.checked ? UtilityBillingAssignedIcon('#9A9AB5') : UtilityBillingAssignedIcon('#5555FF')
                                            }
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        <SwitchContainer
                        className="warmwater-switch"
                        onChange={() => propertyData?.isWarmWaterConsumptionAllocationKeyRequired === false ? this.putPropertyData(true) : this.putPropertyData(false)}
                        checked={propertyData?.isWarmWaterConsumptionAllocationKeyRequired}
                        switchText={<span className='warmwater-switch-label tooltips'>{'Warmwasserverbräuche aktivieren'}</span>}
                        disabled={false}
                        />
                        {openTemperatureEmptyNotificationModal &&
                        <TemperatureNotificationModal
                        open={openTemperatureEmptyNotificationModal}
                        toggle={()=> this.setState({openTemperatureEmptyNotificationModal: false})}
                        reRoute={()=> this.props.history.push(`/property/heatingCostInformation`)}
                        />}
                        
                    </div>}
                </Accordion.Item>
            </Accordion>
            
            
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
  };
};

export default connect(mapStateToProps)(TenantAdministrateConsumption);
