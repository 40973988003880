import React, { Component, forwardRef } from 'react';
import { browserName } from "react-device-detect";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { connect } from 'react-redux';
import { Stack, Group } from '@mantine/core';
import Logo from '../../../../assets/images/module/general/Logo.svg';
import MessaginTemplateContent from '../../../../assets/messaging-templates.json';
import Autoload from '../../../../neoverv/Autoload';

const { loader } = Autoload.MainHelper;
class NewMessageInnerContent extends Component {  
    state={
        message: null,
        recipient: null
    }

    constructor(props){
      super(props);
    }
  
    componentDidMount(){
        var loaderWhole = document.getElementById('hello-housing-loader');
        loaderWhole.style.display = 'block';
        var loaderBackground = document.getElementById('hh_loader_bg');
        var loader = document.getElementById('hh_loader_spinner');
        loaderBackground.style.background = 'white';
        loaderBackground.style.opacity = '1';
        loader.style.zIndex = '99999';
        console.log('window.message: ', MessaginTemplateContent.find((template)=> template['template-identifier'] === 'utility-billing-message-template'));
        let messageFromTemplate = MessaginTemplateContent.find((template)=> template['template-identifier'] === 'utility-billing-message-template');
        let messageBody = messageFromTemplate.body.replaceAll('<a href="#" target="_self">', '{{');
        messageBody = messageBody.replaceAll('<a href="" target="_self">', '{{');
        messageBody = messageBody.replaceAll('</a>', '}}');
        messageFromTemplate.body = messageBody;
        this.setState({
            message: window.message ? 
            window.message 
            : 
            messageFromTemplate,
            recipient: window.message?.recipients?.length > 0 ? window.message?.recipients.find((recipient=> recipient.id === window.recipientId)) : null
        }, ()=> this.downloadPdfFromServer()
        );
    }
  
    async printDocument() {
        //const { customImageBase64 } = this.state;
        const input = document.getElementById(`divToPrint0`);
        const logo = document.getElementById('logo');
        const logoWidth = this.props.companyLogoEnable ? this.props.accountData.pictureLocation !== null ? logo.offsetWidth : 75 : 75;
        const logoHeight = this.props.companyLogoEnable ? this.props.accountData.pictureLocation !== null ? logo.offsetHeight : 38 : 38;
        // const closeWindow = ()=> {
        //   var allowDownloadSafari = document.getElementById('allow_download');
        //   allowDownloadSafari.style.display = browserName === 'Safari' && 'block'; 
        //   var spinner = document.getElementById('hh_loader_spinner');
        //   browserName !== 'Safari' ? loader(false) : spinner.style.display = 'none';
        //   browserName !== 'Safari' && window.close();
        // }
        await html2canvas(logo, {
            scale: 4
          }).then(async(canvas) => {
            const imgData = canvas.toDataURL('image/png');//this.props.companyLogoEnable ? (customImageBase64 !== null ? customImageBase64 : canvas.toDataURL('image/png'))  : canvas.toDataURL('image/png');
            var doc = new jsPDF("p", "mm", 'a4');
            console.log('imgData: ', imgData);
            doc.addImage(imgData, 'JPEG', 24.6, 12, 25, 13, undefined, 'FAST');

            doc.html(input, {
                callback: async function (doc) {
                  //downloadStatus.textContent=`PDF-Dokumente erstellen ${count > pages ? pages : count} / ${pages}`;
                  //await files.push(doc.output('arraybuffer'));
                  doc.save(`testPdf.pdf`, {returnPromise: true})
                  // doc.save(`${propertyName}-${rentalUnitName}-${tenantName ? tenantName.replace(/,/g, '_') : 'Mieter'}.pdf`, {returnPromise: true}).then(
                  //   setTimeout(() => {
                  //    // closeWindow();
                  // }, 500))
                },
                margin: [13, 23, 12, 12],
                x: 2,
                y: 0,
                autoPaging: 'text',
                html2canvas: {useCORS: true, scale: 0.258
                  , height: 0 },
            })
            
            
            /////////////////////
            // doc.setFontSize(8);
            // var text = `${this.props.accountData.addressCompany && (this.props.accountData.addressCompany + ', ')}${this.props.accountData.addressStreet && (this.props.accountData.addressStreet + ',')} ${(this.props.accountData.addressZipCode)} ${this.props.accountData.addressCity}`,
            // xOffset = doc.internal.pageSize.width/2; 
            // var pageSize = doc.internal.pageSize;
            // var pageHeight = pageSize.height
            //   ? pageSize.height
            //   : pageSize.getHeight();
            
            // doc.setTextColor('#828282');
            // this.props.currentSubscription.subscriptionKey.split('_')[0] !== 'STARTER' && doc.text(text, 
            // xOffset, 
            // pageHeight - 30,
            // {align: 'center'});
            // /////////////////////
            // var pageWidth = pageSize.width
            // ? pageSize.width
            // : pageSize.getWidth();
            // doc.setFontSize(8);
            // doc.setTextColor('#000000');
            // doc.text(`Seite ${count} von ${pages}`, 
            // pageWidth - 40, 
            // 26,
            // {align: 'center'});
            ////////////////////
            // doc.html(input, {
            //     callback: async function (doc) {
            //       //downloadStatus.textContent=`PDF-Dokumente erstellen ${count > pages ? pages : count} / ${pages}`;
            //       await files.push(doc.output('arraybuffer'));
            //       // doc.save(`${propertyName}-${rentalUnitName}-${tenantName ? tenantName.replace(/,/g, '_') : 'Mieter'}.pdf`, {returnPromise: true}).then(
            //       //   setTimeout(() => {
            //       //    // closeWindow();
            //       // }, 500))
            //     },
            //     margin: [0,24,0,24],
            //     x: 10,
            //     y: 15,
            //     autoPaging: 'text',
            //     html2canvas: {useCORS: true, scale: 0.505
            //       , height: 0 },
            // })
            
            // downloadStatus.textContent=`PDF-Dokumente zusammenfügen...`;
            // setTimeout(() => {
            //     const pdfBytes = this.mergePdfs(this.state.files);
            //     pdfBytes.then(function(result) {
            //         var blob = new Blob([result], {type: "application/pdf"});
            //         var link = document.createElement('a');
            //         link.href = window.URL.createObjectURL(blob);
            //         //var fileName = reportName;
            //         link.download = `${propertyName}-${rentalUnitName}-${tenantName ? tenantName.replace(/,/g, '_') : 'Mieter'}.pdf`;
            //         link.click();
            //         setTimeout(() => {
            //             closeWindow();
            //         }, 500);
            //     })
            //     console.log('pdfBytes: ', pdfBytes);
            // }, delay);
          });
    }

    downloadPdfFromServer=()=>{
        console.log('downloadPdfFromServer:');
        const data = new FormData();
        const closeWindow = ()=> {
            var allowDownloadSafari = document.getElementById('allow_download');
            allowDownloadSafari.style.display = browserName === 'Safari' && 'block'; 
            var spinner = document.getElementById('hh_loader_spinner');
            browserName !== 'Safari' ? loader(false) : spinner.style.display = 'none';
            browserName !== 'Safari' && window.close();
        }
        let html = document.getElementById('divToPrint0');
        let doc = document.implementation.createHTMLDocument("New Document");
        try {
          doc.body.appendChild(html);
          const blob = new Blob([doc.documentElement.innerHTML], {
            type: 'text/html'
           });
           
          const fileName = `messagingTemplate-${this.props.propertySelected.name}-${new Date().valueOf()}}`;
          let file = new File([blob], fileName);
          file.originalname = fileName;
          console.log('downloadPdfFromServer: ', file);
        // var a = document.createElement("a");
        // document.body.appendChild(a);
        // let url = window.URL.createObjectURL(file);
        // a.href = url;
        // a.download = 'test.html';
        // a.click();
        // window.URL.revokeObjectURL(url);

           data.append('file', file);
          fetch('https://dev.hellohousing.de/api/reporting-api/pdf',
            {
              method: 'POST',
              body: data,
              headers: {
                Authorization: Autoload.Utils.getAuthToken(),
              }
            }).then((response)=> response.blob()
            .then(data => {
              //download progress
              var a = document.createElement("a");
              document.body.appendChild(a);
              let url = window.URL.createObjectURL(data);
              a.href = url;
              a.download = 'test.pdf';
              a.click();
              window.URL.revokeObjectURL(url);
              setTimeout(() => {
                closeWindow();
              }, 500);
            }));
        } catch (e) {
          console.log('downloadPdfFromServer: ', e);
        }
    }
  
    render() {
      const {message, recipient} = this.state;
      const {profile, propertySelected, accountData} = this.props;
      console.log('tes: ', accountData);
      return (
        <>
        <div>
            <img src={Logo} id='logo' style={{marginLeft: '11mm'}}/>
        </div>
        <div id='divToPrint0' style={{margin: '12mm 11mm'}}>
          <Stack>
            <Group style={{width: '100%'}} position='right'>
                <div style={{height: '33mm', maxHeight: '33mm', display: 'flex', justifyContent: 'flex-end'}}>
                    <div>
                        <div><span style={{fontFamily: 'Arial', fontSize: '9pt'}}>{accountData.addressCompany}</span></div>
                        <div><span style={{fontFamily: 'Arial', fontSize: '9pt'}}>{accountData.addressStreet}</span></div>
                        <div><span style={{fontFamily: 'Arial', fontSize: '9pt'}}>{`${accountData.addressZipCode} ${accountData.addressCity}`}</span></div>
                        <div><span style={{fontFamily: 'Arial', fontSize: '8pt'}}>{`Tel: ${accountData.companyPhoneNumber}`}</span></div>
                        <div><span style={{fontFamily: 'Arial', fontSize: '8pt'}}>{`E-Mail: ${accountData.companyEmail}`}</span></div>
                    </div>
                </div>
            </Group>
            <Stack style={{width: '85mm', marginTop: '-12mm'}} spacing={0}>
                <Group>
                    <span style={{fontSize: '8pt', fontFamily: 'Arial', lineHeight: '5.5mm'}}>{`${accountData.addressCompany ? accountData.addressCompany+', ' : 'Meine Firma'+', '}${accountData.addressStreet ? accountData.addressStreet+', ' : 'Am Weg 1'+', '}${accountData.addressZipCode ? accountData.addressZipCode : '98765'} ${accountData.addressCity ? accountData.addressCity : 'ERP-Hausen'}`}</span>
                </Group>
                <Group style={{marginTop: '1.5mm', height: '16mm'}}/>
                <Stack style={{marginTop: '1mm', height: '21mm'}} align='flex-start' spacing={0}>
                    <div><span style={{fontSize: '9pt', fontFamily: 'Arial', lineHeight: '11pt'}}>{recipient ? '' : `Max Mustermann AG`}</span></div>
                    <div><span style={{fontSize: '9pt', fontFamily: 'Arial', lineHeight: '11pt'}}>{recipient ? recipient.name : `Abteilung Testbrief`}</span></div>
                    <div><span style={{fontSize: '9pt', fontFamily: 'Arial', lineHeight: '11pt'}}> {recipient ? recipient.street : 'Musterstrasse 99'}</span></div>
                    <div><span style={{fontSize: '9pt', fontFamily: 'Arial', lineHeight: '11pt'}}>{`${recipient ? recipient.zipCode : '12345'} ${recipient ? recipient.city : 'Testhausen'}`}</span></div>
                </Stack>
            </Stack>
            <Stack style={{marginTop: '20mm', //marginLeft: '11mm', 
            fontSize: '9pt', fontFamily: 'Arial'}} dangerouslySetInnerHTML={{__html: message?.body?.replace(/\\/, '')}}>
            </Stack>
          </Stack>
        </div>
        </>
      );
    }
  }
  
const mapStateToProps = (state) => {
return {
    propertySelected: state.generalReducer.property,
    rentalUnits: state.tenantDataReducer.rentalUnits,
    currentSubscription: state.generalReducer.currentSubscription,
    accountData: state.generalReducer.accountData,
    profile: state.generalReducer.profile,
};
};
  
export default connect(mapStateToProps)(NewMessageInnerContent);