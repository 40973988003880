import React from 'react';
import { Stack, Modal } from '@mantine/core';
import CustomModal from '.';


const TileDetailsModal= (props) => {
    console.log('TileDetailsModal: ', props.tile);
    return (
      <CustomModal
      open={props.open}
      centered
      overlayOpacity={0.8}
      toggle={() => props.toggle()}
      heading={'Aushang'}
      backdropClassName={'backdrop-for-tile-detail-modal'}
      classNames={{
        title: 'tenant-portal-preview-Modal-title',
        body: 'tenant-portal-preview-Modal-body',
        header: 'tenant-portal-preview-Modal-header',
        modal: 'tenant-portal-preview-Modal-modal'
      }}
      style={{minWidth: '880px'}}>
        <Stack style={{width: '100%', minHeight: '300px', maxHeight: '100vh'}}>
          {props.tile?.title && <span className='tile-preview-title'>{props.tile?.title}</span>}
          <div className='tile-preview-body' style={{overflowY: 'scroll', height: '100%'}} dangerouslySetInnerHTML={{__html: props.tile.body}}/>
        </Stack>
      </CustomModal>
    )
}

export default TileDetailsModal;
