import React, { Component } from 'react';
import { ColorButton, UtilityCostAllocationRow, HeadingContainer, Divider, CostPositionModal } from '../../../../index';
import { connect } from 'react-redux';
import { setCostPositions, addCostPosition, setUtilityBillingData } from '../../../../../redux/action/UtilityBilling/UtilityBillingActions';
import {
  setRunUpdateReceiptAssignment,
  setRunUpdateTenantOccupations,
  setRunUpdateConsumptions
} from '../../../../../redux/action/General/generalActions';
import MobileUtilityCostAllocation from './MobileUtilityCostAllocation';
import Autoload from '../../../../Autoload';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import GlobalServices from '../../../../helpers/services/GlobalServices';
import moment from 'moment';
import CrossIcon from '../../../../../assets/images/module/general/CrossIcon.svg';
import { ShowModal, ReceiptDetailModal, ImageModal } from '../../../../index';
import { config } from '../../../../config/Setting';
import LockIcon from '../../../../../assets/images/module/general/LockIconRed.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Skeleton } from '@mantine/core';
import HeadingContainerNew from '../HeadingContainerNew';
import breadcrumbsContent from '../../../../../assets/breadcrumbs.json';
import HelpLogo from '../../../../../assets/images/module/general/book.svg';
import TourIcon from '../../../../../assets/images/module/general/TourIcon.svg';
import AlertModal from '../CustomModal/AlertModal';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var langnewKeys = Setting[Setting.language_default].newKeys;
const { checkProperty, yearDifferenceMoment, loader } = Autoload.MainHelper;
const tableHeaderStyling = {
  borderBottom: 'none',
  fontSize: '12px',
  fontWeight: '500',
  lineHeight: '20px',
  color: '#727293',
  paddingBottom: '0px',
  fontFamily: 'Inter,sans-serif'
}
let EOL = 0;

const MOBILE_SIZE = 766;

const SortableTableRow = SortableElement(({ cp, index, getCostPositions, setCostPositionsAfterUpdate, getTableData, getReceiptsByProperty, heatingCostPositions, isMobile, isTablet, costTypeArr, allocationKeys, getAllocationKeysByProperty, receiptArr, billingPeriodId, utilityData, updateGetAllByBillingPeriodCostType, currentDataId, updateRecieptsInChild, getCostTypes, toggleViewDetailsModal, editReceiptOpen, skeletonLoadingTrue, skeletonLoadingFalse, history, opentotalOccupantsCountNullAlert, getCostPositionById, updateReceiptAssignments }) => {
  return (
    <UtilityCostAllocationRow
    updateCp={()=> getCostPositions()}
    updateCostPositionsAfterPut={(updatedCp)=> setCostPositionsAfterUpdate(updatedCp)}
    updateTable={()=> getTableData()}
    updateReceipts={()=> getReceiptsByProperty()}
    data={cp}
    index={index}
    length={heatingCostPositions?.length}
    isMobile={isMobile}
    isTablet={isTablet}
    costTypesArr={costTypeArr}
    allocationsArr={allocationKeys}
    fetchAllocationKeysByProperty={()=> getAllocationKeysByProperty()}
    receiptsArr={receiptArr}
    utilityBillingId={cp?.utilityBillingId}
    timeFrame={utilityData?.date}
    costTypeId={cp?.costTypeId}
    billingPeriodId={billingPeriodId}
    utilityData={utilityData}
    updateGetAllByBillingPeriodCostType={()=> updateGetAllByBillingPeriodCostType()}
    currentDataId={currentDataId}
    updateRecieptsInChild={updateRecieptsInChild}
    costTypesBackFunction={() =>
      getCostTypes()
    }
    toggleViewDetailsModal={(recordId)=> toggleViewDetailsModal(recordId)}
    editReceiptOpen={editReceiptOpen}
    skeletonLoadingTrue={()=> skeletonLoadingTrue()}
    skeletonLoadingFalse={()=> skeletonLoadingFalse()}
    history={history}
    updateMeterData={(meterData, costPositionId)=> setCostPositionsAfterUpdate(cp, meterData)}
    opentotalOccupantsCountNullAlert={()=> opentotalOccupantsCountNullAlert()}
    getCostPositionById={()=> getCostPositionById(cp?.id)}
    updateReceiptAssignments={()=> updateReceiptAssignments()}
    />
  );
});

const SortableTableBody = SortableContainer(({items, getCostPositions, getTableData, getReceiptsByProperty, heatingCostPositions, isMobile, isTablet, costTypeArr, allocationKeys, getAllocationKeysByProperty, receiptArr, billingPeriodId, utilityData, updateGetAllByBillingPeriodCostType, currentDataId, updateRecieptsInChild, getCostTypes, toggleViewDetailsModal, editReceiptOpen, skeletonLoadingTrue, skeletonLoadingFalse, history, setCostPositionsAfterUpdate, opentotalOccupantsCountNullAlert, getCostPositionById, updateReceiptAssignments}) => {
  return (
    <TableBody>
      {items.map((value, index) => (
        <SortableTableRow key={`item-${value.id}`} index={index} value={value} 
        cp={value}
        getCostPositions={()=> getCostPositions()}
        setCostPositionsAfterUpdate={(updatedCp)=> setCostPositionsAfterUpdate(updatedCp)}
        getTableData={()=> getTableData()}
        getReceiptsByProperty={()=> getReceiptsByProperty()}
        heatingCostPositions={heatingCostPositions}
        isMobile={isMobile}
        isTablet={isTablet}
        costTypeArr={costTypeArr}
        allocationKeys={allocationKeys}
        getAllocationKeysByProperty={()=> getAllocationKeysByProperty()}
        receiptArr={receiptArr}
        billingPeriodId={billingPeriodId}
        utilityData={utilityData}
        updateGetAllByBillingPeriodCostType={()=> updateGetAllByBillingPeriodCostType()}
        currentDataId={currentDataId}
        updateRecieptsInChild={updateRecieptsInChild}
        getCostTypes={getCostTypes}
        toggleViewDetailsModal={(recordId)=> toggleViewDetailsModal(recordId)}
        editReceiptOpen={editReceiptOpen}
        skeletonLoadingTrue={()=> skeletonLoadingTrue()}
        skeletonLoadingFalse={()=> skeletonLoadingFalse()}
        history={history}
        opentotalOccupantsCountNullAlert={opentotalOccupantsCountNullAlert}
        getCostPositionById={(id)=> getCostPositionById(id)}
        updateReceiptAssignments={()=> updateReceiptAssignments()}/>
      ))}
    </TableBody>
  );
});

export class UtilityCostAllocation extends Component {
  state = {
    AddCostTypeModal: false,
    currentRecord: {},
    editReceiptOpen: false,
    showModal: false,
    imageOpen: false,
    isMobile: window.innerWidth <= MOBILE_SIZE,
    allocationKeys: [],
    receipts: [],
    costTypes: [],
    heatingCostPositions: [],
    costPositionData: [],
    utilityData: {
      date: '',
      recordStatus: 'last',
      isSecured: false
    },
    openCPModal: false,
    costTypeArr: [],
    billingPeriodId: '',
    updateRecieptsInChild: false,
    skeletonLoading: false,
    opentotalOccupantsCountNullAlert: false,
    openFreezeModal: false,
    freezeAlertHeading: '',
    freezeAlertMessage: '',
    receiptArr: []
  };

  componentDidMount() {
    this.componentDidMountStuff();
    //this.updateTenantOccupations();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }
  componentDidUpdate(prevProps){
    if(prevProps.utilityBillingData.length === 0 && this.props.utilityBillingData.length > 0){
      this.componentDidMountStuff();
    }
  }

  componentDidMountStuff=()=>{
    const {runUpdateTenantOccupations} = this.props;
    let data = this.props.utilityBillingData.find((ub) => ub.id == this.props.currentDataId);
    this.setState({billingPeriodId: data?.billingPeriodId, skeletonLoading: true});
    let utilityDate = data?.date;
    if(utilityDate?.includes('T')){
      let dates = data?.date.split(' - ');
      utilityDate = `${moment(dates[0]).format('DD.MM.YYYY')} - ${moment(dates[1]).format('DD.MM.YYYY')}`
    }
    if (data !== undefined) {
      this.setState({ utilityData: { date: utilityDate, recordStatus: data?.recordStatus, isSecured: data?.isSecured } },()=> {
        if(data?.isSecured === false){
          if(this.props.propertySelected.propertyType === 'CONDOMINIUM'){
            if(runUpdateTenantOccupations){
              this.updateTenantOccupations();
            }else{
              this.updateConsumptions();
            }
          }else{
            this.updateConsumptions();
          }
          this.getCostTypes();
        }else{
          this.getCostTypes();
          GlobalServices._getAllocationKeysByProperty(this, '_handleAllocationKeysResponse', this.props.propertySelected);
          this._getReceiptsByProperty();
          this._getCostPositions(); 
        }
        
      });
    }
  }

  updateConsumptions = () => {
    this.setState({skeletonLoading: true});
    const {runUpdateConsumptions, runUpdateReceiptAssignment} = this.props;
    //loader(true);
    if(runUpdateConsumptions){
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url + 'updateConsumptions/' + this.props.currentDataId,
        'PUT',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleUpdateConsumptionsResponse',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    }else{
      this.runAfterUpdateConsumptions();
    }
    
  };
  handleUpdateConsumptionsResponse = (response) => {
    !response.status && console.log('Update Consumptions Failed');

    //loader(false);
    this.runAfterUpdateConsumptions();

    this.props.setRunUpdateConsumptions(false);
  };

  runAfterUpdateConsumptions=()=>{

    this.setState({skeletonLoading: false});
    // this._getHeatingCostPositions();
    GlobalServices._getAllocationKeysByProperty(this, '_handleAllocationKeysResponse', this.props.propertySelected);
    
    if(this.props.runUpdateReceiptAssignment){
      this.updateReceiptAssignments();
    }else{
      this.setState({skeletonLoading: true});
      this._getReceiptsByProperty();
    }
  }

  // updateConsumptionsByCostPositionId = (costPositionIds) => {
  //   this.setState({skeletonLoading: true});
  //   //loader(true);
  //   Autoload.HttpRequestAxios.HttpRequest(
  //     Autoload.Setting.ub_api_base_url + 'updateConsumptions/' + this.props.currentDataId+'?costPositionIds='+costPositionIds,
  //     'PUT',
  //     null,
  //     null,
  //     false,
  //     true,
  //     false,
  //     this,
  //     'handleUpdateConsumptionsByCostPositionIdResponse',
  //     {costPositionId: costPositionIds},
  //     false,
  //     '',
  //     {
  //       Authorization: Autoload.Utils.getAuthToken(),
  //     }
  //   );
  // };

  // handleUpdateConsumptionsByCostPositionIdResponse = (response) => {
  //   !response.status && console.log('Update Consumptions Failed');

  //   //loader(false);
  //   this.setState({skeletonLoading: false});
  //   // this._getHeatingCostPositions();
  //   //GlobalServices._getAllocationKeysByProperty(this, '_handleAllocationKeysResponse', this.props.propertySelected);
  //   //this.updateReceiptAssignments();
    
  //   //this._getCostPositions();
  // };

  updateTenantOccupations = () => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'updateTenantOccupations/' + this.props.currentDataId,
      'PUT',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleUpdateTenantOccupationsResponse',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handleUpdateTenantOccupationsResponse = (response) => {
    const {setRunUpdateTenantOccupations} = this.props;
    !response.status && console.log('Update Tenant Occupations Failed');
    this.updateConsumptions();
    setRunUpdateTenantOccupations(false);
  };

  _getReceiptsByProperty =()=> {
    if(this.props.propertySelected && this.props.propertySelected.propertyType === 'REGULAR'){
      GlobalServices._getReceiptsByProperty(this, '_handleReceiptsResponse', this.props.propertySelected);
    }else{
      this._getCostPositions();
    }
  }

  _getHeatingCostPositions = () => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url +
      'costPositions?filter=heating&offset=0&results=100&utilityBillingId=' +
      this.props.currentDataId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleHeatingCostPositionResponse',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  _handleHeatingCostPositionResponse = (response) => {
    if (response.status === false) {
      Autoload.Redirection.redirection_error();
    }
    let costTypeIds = [];

    if (Array.isArray(response.result) && response.result.length > 0) {
      response.result.forEach((cp) => {
        costTypeIds.push(checkProperty(cp, 'costTypeId'));
      });
    }

    GlobalServices._getCostTypeByProperty(this, '_handleCostTypeResponse', this.props.propertySelected, costTypeIds);
  };

  _handleCostTypeResponse = (response) => {
    if (response.status === false && response.message !== 'Not Found') {
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }

    let filtered = [];

    if (response.result.length > 0) {
      filtered = response.result.filter(function (e) {
        return this.indexOf(checkProperty(e, 'id')) < 0;
      }, response.other);
    }

    this.setState({ costTypes: filtered });
  };
  _handleAllocationKeysResponse(response) {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    this.setState({ allocationKeys: response.result.filter((key)=> key.meterType !== 'PROPERTY') });
  }
  _handleReceiptsResponse(response) {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    if (response.result.length > 0) {
      let formattedArr = response.result
        .filter((receipt) => checkProperty(receipt, 'utilityBillingRelevant') === true)
        .map((receipt) => ({
          id: checkProperty(receipt, 'id'),
          receiptName: checkProperty(receipt, 'name'),
          date: moment(checkProperty(receipt, 'receiptDate')).format('DD.MM.YYYY'),
          proRataDays: 123,
          selectedCostTypes: checkProperty(receipt, 'selectedCostTypes'),
        }));
      // console.log('formated receipt', formattedArr)
      this.setState({
        receipts: formattedArr,
        receiptArr: response.result
      });
    }
    this._getCostPositions();
  }

  getCostTypes = () => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url +
      'costTypes?offset=0&fetch=100&propertyId=' +
      this.props.propertySelected.id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleCostTypesResponse',
      {},
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  _handleCostTypesResponse(response) {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    let costTypeArr = response.result.filter((ct) => ct.costTypeIdentifier === 'NON_HEATING' || ct.costTypeIdentifier === 'NON_HEATING_INDIVIDUAL');

    this.setState({ costTypeArr });
  }
  _getCostPositions = (addCp) => {
    //loader(true);
    addCp && this.setState({skeletonLoading: true});
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url +
      'costPositions?filter=non-heating&offset=0&results=100&utilityBillingId=' +
      this.props.currentDataId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleCostPositionResponse',
      { addCp },
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  _handleCostPositionResponse = (response) => {
    if (response.status === false && response.message !== 'Not Found') {
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      ); 
      this.setState({skeletonLoading: false});
    }
    
    let data = [];
    if (response.result.length > 0) {
      data = response.result.map((cp, id) => {
        // if(this.props.propertySelected.propertyType === 'CONDOMINIUM' && checkProperty(cp, 'allocationKeyName') === 'Personenanzahl' && !this.props.propertySelected.totalOccupantsCount){
        //   console.log('_handleHeatingCostPositionResponse: ', cp, this.props.propertySelected);
        //   this.setState({opentotalOccupantsCountNullAlert: true});
        // }
        return {
          id: checkProperty(cp, 'id'),
          costTypeId: checkProperty(cp, 'costTypeId'),
          costTypeName: checkProperty(cp, 'costTypeName'),
          amount: checkProperty(cp, 'amount'),
          allocationKeyId: checkProperty(cp, 'allocationKeyId'),
          allocationKeyName: checkProperty(cp, 'allocationKeyName'),
          meterData: checkProperty(cp, 'consumptionDataAvailable'),
          selectedReceipt: [],
          utilityBillingId: checkProperty(cp, 'utilityBillingId'),
          orderNo: checkProperty(cp, 'orderNo'),
          allocationKeyType: checkProperty(cp, 'allocationKeyType'),
          vatAmount: cp.vatAmount,
          laborCost: cp.laborCost
        };
      });
      // costPositions = [...data];
      this.setState({ heatingCostPositions: data }, ()=> this.getTableData());
      this.setState({ costPositionData: data }, () => {
        this.props.setCostPositions(data);
        if (response.result.length == 0 && response.other.addCp) {
          this.addCostPosition();
        }
        EOL = 0;
        //calling selected receipt api for each cp
        // response.result.map((cp, i) => {
        //   Autoload.HttpRequestAxios.HttpRequest(
        //     Autoload.Setting.ub_api_base_url + 'receiptAssignments/' + checkProperty(cp, 'id'),
        //     'GET',
        //     null,
        //     null,
        //     false,
        //     true,
        //     false,
        //     this,
        //     '_handleGetReceiptResponse',
        //     {
        //       cpId: checkProperty(cp, 'id'),
        //       cp: cp,
        //       EOL: i + 1 === response.result.length,
        //       addCp: response.other.addCp,
        //     },
        //     false,
        //     '',
        //     {
        //       Authorization: Autoload.Utils.getAuthToken(),
        //     }
        //   );
        // });
        response.other.addCp && this.addCostPosition();
      });
    } else {
      this.setState({ heatingCostPositions: [] }, ()=> this.getTableData());
      //loader(false)
    };
    this.setState({skeletonLoading: false});
  };

  getCostPositionById = (costPositionId)=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'costPositions/' + costPositionId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetCostPositionByIdResponse',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleGetCostPositionByIdResponse (response){
    if(response.status && response.statuscode === 200){
      this.setCostPositionsAfterUpdate(response.result);
    }
  }

  _handleGetReceiptResponse = (response) => {
    //EOL += 1;
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      // Autoload.DataHandlerGeneral.GeneralErrorMessage(
      //   true,
      //   lang.newKeys.text_error_not_found
      //   // + response.message
      // );
      //loader(false);
      if(response.other.EOL){
        this.setState({skeletonLoading: false});
      }
    }else{
      if (response.result.length > 0) {
        let updatedCp = {
          ...response.other.cp,
          meterData: response.other.cp?.consumptionDataAvailable,
          selectedReceipt: response.result.map((receipt) => ({
            proRataDays: checkProperty(receipt, 'proRataDays'),
            receiptAssignmentId: checkProperty(receipt, 'id'),
            id: checkProperty(receipt, 'receiptId'),
            receiptName: checkProperty(receipt, 'receiptName'),
          })),
        };
        console.log(updatedCp);
        this.setState({
          heatingCostPositions: [...this.state.heatingCostPositions.filter((i) => i.id !== updatedCp.id), updatedCp],
        });
        this.setState(
          { costPositionData: [...this.state.costPositionData.filter((i) => i.id !== updatedCp.id), updatedCp] },
          () => {
            if (response.other.EOL) {
              // if (response.other.EOL) {
              console.log('local cost positions', this.state.costPositionData);
              this.props.setCostPositions(this.state.costPositionData);
              if (response.other.addCp) {
                this.addCostPosition();
              }
              //loader(false);
              this.setState({skeletonLoading: false});
            }
          }
        );
      } else {
        if (response.other.EOL) {
          console.log('local cost positions', this.state.costPositionData);
          this.props.setCostPositions(this.state.costPositionData);
          if (response.other.addCp) {
            this.addCostPosition();
          }
          //loader(false);
          this.setState({skeletonLoading: false});
        }
      }
    } 
  };

  saveCostPositions = () => {
    let btnElement = document.getElementById('add-cost-position-new-btn');
    let data = this.props.utilityBillingData.find((ub) => ub.id === this.props.currentDataId);

    let costpositions = data && data?.billingData && data?.billingData.costPosition ? data?.billingData.costPosition : [];
    // console.log('all non heating cp', costpositions)
    let payload = {
      id: null,
      utilityBillingId: data?.id,
      amount: 0,
      costTypeId: '',
      costTypeName: '',
      allocationKeyId: '',
      allocationKeyName: '',
      consumptionDataAvailable: '',
      allocationKeyType: '',
      costTypeIdentifier: 'NON_HEATING',
      allcationKeyUnits: '',
    };
    Autoload.HttpRequestAxios.HttpRequest(
      // Autoload.Setting.swagger_mocked_api_baseUrl + 'costPositions',
      Autoload.Setting.ub_api_base_url + 'costPositions',
      'POST',
      payload,
      null,
      false,
      true,
      false,
      this,
      '_handleSaveCP',
      {},
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
    // costpositions.forEach((cp, i) => {
    //   if (cp.id === 0) {
    //     //post request
    //     // if(requiredFields(cp, ['amount', 'costTypeId', 'allocationKeyId','costTypeName','allocationKeyName'])){
    //     // console.log('call post api')
    //     let payload = {
    //       id: null,
    //       utilityBillingId: checkProperty(cp, 'utilityBillingId'),
    //       amount: 0,
    //       costTypeId: checkProperty(cp, 'costTypeId'),
    //       costTypeName: checkProperty(cp, 'costTypeName'),
    //       allocationKeyId: checkProperty(cp, 'allocationKeyId'),
    //       allocationKeyName: checkProperty(cp, 'allocationKeyName'),
    //       consumptionDataAvailable: checkProperty(cp, 'meterData'),
    //       allocationKeyType: '',
    //       costTypeIdentifier: 'NON_HEATING',
    //       allcationKeyUnits: ''
    //     };
    //     Autoload.HttpRequestAxios.HttpRequest(
    //       // Autoload.Setting.swagger_mocked_api_baseUrl + 'costPositions',
    //       Autoload.Setting.ub_api_base_url + 'costPositions',
    //       'POST',
    //       payload,
    //       null,
    //       false,
    //       true,
    //       false,
    //       this,
    //       '_handleSaveCP',
    //       { loopEnd: i === this.state.costPositionData.length - 1 },
    //       false,
    //       '',
    //       {
    //         Authorization: Autoload.Utils.getAuthToken(),
    //       }
    //     );
    //   } else {
    //     //put request
    //     // console.log('call put api')
    //     let payload = {
    //       id: checkProperty(cp, 'id'),
    //       utilityBillingId: checkProperty(cp, 'utilityBillingId'),
    //       amount: checkProperty(cp, 'amount'),
    //       costTypeId: checkProperty(cp, 'costTypeId'),
    //       costTypeName: checkProperty(cp, 'costTypeName'),
    //       allocationKeyId: checkProperty(cp, 'allocationKeyId'),
    //       allocationKeyName: checkProperty(cp, 'allocationKeyName'),
    //       consumptionDataAvailable: checkProperty(cp, 'meterData'),

    //     };
    //     Autoload.HttpRequestAxios.HttpRequest(
    //       // Autoload.Setting.swagger_mocked_api_baseUrl + 'costPositions/' + cp.id,
    //       Autoload.Setting.ub_api_base_url + 'costPositions/' + cp.id,
    //       'PUT',
    //       payload,
    //       btnElement.children[0],
    //       false,
    //       true,
    //       false,
    //       this,
    //       '_handleSaveCP',
    //       { loopEnd: i === this.state.costPositionData.length - 1 },
    //       false,
    //       '',
    //       {
    //         Authorization: Autoload.Utils.getAuthToken(),
    //       }
    //     );
    //   }
    // });
  };
  _handleSaveCP = (response) => {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }
    
    // if (response.other.loopEnd) {
    this._getCostPositions(true);
    // }
    // console.log('put cp', response)
  };

  addCostPosition = () => {
    let data = {
      id: 0,
      costTypeName: '',
      costTypeId: '',
      costType: '',
      amount: '',
      allocationKeyId: '',
      allocationKeyName: '',
      allocationKey: '',
      meterData: '',
      // receipt: [{ file: '' }],
      selectedReceipt: [],
      utilityBillingId: this.props.currentDataId,
    };
    this.props.addCostPosition(data);
  };

  getTableLabels = () => {
    let data = this.props.utilityBillingData.find((ub) => ub.id === this.props.currentDataId);

    // if (data && data?.billingData && data?.billingData.costPosition && data?.billingData.costPosition.length > 0) {
    return (
      <TableRow //className="table-heading utility-cost-head"
        className='utility-table-row-head'
        style={{boxShadow: 'none'}}
        >
          <TableCell/>
          <TableCell //className="cp-label-cost d-none d-md-table-cell"
          className='ub-table-head-cell-cost-type'
          style={tableHeaderStyling}
          >{lang.utility.text_cost_type}</TableCell>
          <TableCell //className="cp-label-amount d-none d-md-table-cell"
          className='ub-table-head-cell-amount'
          style={tableHeaderStyling}
          >{lang.utility.text_amount}</TableCell>
          <TableCell //className="cp-label-key d-none d-md-table-cell"
          className='ub-table-head-cell-allocation-key'
          style={tableHeaderStyling}
          >{lang.utility.text_allocation_key}</TableCell>
          <TableCell //className="cp-label-meter d-none d-md-table-cell"
          className='ub-table-head-cell-consumption'
          style={tableHeaderStyling}
          >{lang.utility.text_meter_distributor_value}</TableCell>
          {this.props.propertySelected.propertyType === 'REGULAR' ?
          <TableCell //className="cp-label-receipt d-none d-md-table-cell"
          className='ub-table-head-cell-receipt'
          style={tableHeaderStyling}
          >{lang.utility.text_Receipt}</TableCell>
          :
          <TableCell/>}
        </TableRow>
    );
    // }
  };
  setCostPositionsAfterUpdate=(cp, meterData=null)=>{
    console.log('setCostPositionsAfterUpdate: ', cp);
    let {costPositionData, heatingCostPositions} = this.state;
    costPositionData = costPositionData.map((costPosition)=> costPosition.id === cp.id ?
    {
      ...cp,
      id: checkProperty(cp, 'id'),
      costTypeId: checkProperty(cp, 'costTypeId'),
      costTypeName: checkProperty(cp, 'costTypeName'),
      amount: checkProperty(cp, 'amount'),
      allocationKeyId: checkProperty(cp, 'allocationKeyId'),
      allocationKeyName: checkProperty(cp, 'allocationKeyName'),
      meterData: meterData ? meterData : checkProperty(cp, 'consumptionDataAvailable'),
      selectedReceipt: [],
      utilityBillingId: checkProperty(cp, 'utilityBillingId'),
      orderNo: checkProperty(cp, 'orderNo'),
      allocationKeyType: checkProperty(cp, 'allocationKeyType'),
      laborCost: cp.laborCost,
      vatAmount: cp.vatAmount
    }
    : 
    costPosition);

    heatingCostPositions = heatingCostPositions.map((costPosition)=> costPosition.id === cp.id ?
    {
      id: checkProperty(cp, 'id'),
      costTypeId: checkProperty(cp, 'costTypeId'),
      costTypeName: checkProperty(cp, 'costTypeName'),
      amount: checkProperty(cp, 'amount'),
      allocationKeyId: checkProperty(cp, 'allocationKeyId'),
      allocationKeyName: checkProperty(cp, 'allocationKeyName'),
      meterData: meterData ? meterData : checkProperty(cp, 'consumptionDataAvailable'),
      selectedReceipt: [],
      utilityBillingId: checkProperty(cp, 'utilityBillingId'),
      orderNo: checkProperty(cp, 'orderNo'),
      allocationKeyType: checkProperty(cp, 'allocationKeyType'),
      laborCost: cp.laborCost,
      vatAmount: cp.vatAmount
    }
    :
    costPosition);
    this.setState({costPositionData, heatingCostPositions}, ()=> {
      this.props.setCostPositions(heatingCostPositions);
    });
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({heatingCostPositions}) => ({
      heatingCostPositions: arrayMoveImmutable(heatingCostPositions, oldIndex, newIndex).map((cp, index)=> {
        cp.orderNo = index + 6;
        return cp;
      }),
      skeletonLoading: true
    }), ()=>this.updateAllCostPositions());
  };

  updateAllCostPositions=()=>{
    const {heatingCostPositions} = this.state;
    if(heatingCostPositions.length > 0){
      this.setState({skeletonLoading: true, updatedCostPositions: 0},()=>{
        heatingCostPositions.map((cp, index)=> {
          let payload = {
            id: cp.id,
            utilityBillingId: cp.utilityBillingId,
            amount: cp.amount,
            costTypeId: cp.costTypeId,
            costTypeName: cp.costTypeName,
            allocationKeyId: cp.allocationKeyId,
            allocationKeyName: cp.allocationKeyName,
            consumptionDataAvailable: cp.meterData,
            costTypeIdentifier: cp.costTypeIdentifier,
            allcationKeyUnits: cp.allcationKeyUnits,
            orderNo: cp.orderNo,
            vatAmount: cp.vatAmount,
            laborCost: cp.laborCost
          };

          Autoload.HttpRequestAxios.HttpRequest(
            Autoload.Setting.ub_api_base_url + 'costPositions/' + checkProperty(cp, 'id'),
            'PUT',
            payload,
            null,
            false,
            true,
            false,
            this,
            'handleUpdateAllCostPositions',
            {index, length: heatingCostPositions.length},
            false,
            '',
            {
              Authorization: Autoload.Utils.getAuthToken(),
            }
          );
        });
      });
    }
  }

  handleUpdateAllCostPositions(response){
    let {updatedCostPositions} = this.state;
    updatedCostPositions = updatedCostPositions + 1;
    this.setState({updatedCostPositions: response.other.length === updatedCostPositions ? 0 : updatedCostPositions, skeletonLoading: response.other.length === updatedCostPositions ? false : true});
  }

  getTableData = () => {
    let data = this.props.utilityBillingData.find((ub) => ub.id === this.props.currentDataId);
    const {utilityData, updateRecieptsInChild, skeletonLoading} = this.state;
    const {isMobile, isTablet} = this.props;
    console.log('COSTTYPES :', this.state.heatingCostPositions);
    console.log('RECEIPTS :', this.state.receipts);
    if (data && data?.billingData && data?.billingData.costPosition && data?.billingData.costPosition.length === 0) {
      this.addCostPosition();
    }
    
    return (
      skeletonLoading === false &&
      this.state.heatingCostPositions.length > 0 && 
      <SortableTableBody 
      useDragHandle
      items={this.state.heatingCostPositions}
      getCostPositions={()=> this._getCostPositions()}
      setCostPositionsAfterUpdate={this.setCostPositionsAfterUpdate}
      getTableData={()=> this.getTableData()}
      getReceiptsByProperty={()=> this._getReceiptsByProperty()}
      heatingCostPositions={this.state.heatingCostPositions}
      isMobile={isMobile}
      isTablet={isTablet}
      costTypeArr={this.state.costTypeArr}
      allocationKeys={this.state.allocationKeys}
      getAllocationKeysByProperty={()=> GlobalServices._getAllocationKeysByProperty(this, '_handleAllocationKeysResponse', this.props.propertySelected)}
      receiptArr={this.state.receiptArr} // this.state.receiptArr.filter((receipt)=> receipt.utilityBillingRelevant)
      billingPeriodId={this.state.billingPeriodId}
      utilityData={utilityData}
      updateGetAllByBillingPeriodCostType={()=> this.updateGetAllByBillingPeriodCostType()}
      currentDataId={this.props.currentDataId}
      updateRecieptsInChild={this.state.updateRecieptsInChild}
      getCostTypes={this.getCostTypes}
      toggleViewDetailsModal={(recordId)=> this.toggleViewDetailsModal(recordId)}
      editReceiptOpen={this.state.editReceiptOpen}
      skeletonLoadingTrue={()=> this.setState({skeletonLoading: true})}
      skeletonLoadingFalse={()=> this.setState({skeletonLoading: false})}
      history={this.props.history}
      opentotalOccupantsCountNullAlert={()=> this.setState({opentotalOccupantsCountNullAlert: true})}
      getCostPositionById={(cpId)=> this.getCostPositionById(cpId)}
      updateReceiptAssignments={()=> {
        if(this.props.runUpdateReceiptAssignment){
          this.updateReceiptAssignments();
        }else{
          this.setState({skeletonLoading: true});
          this._getReceiptsByProperty();
        }
      }}
      onSortEnd={this.onSortEnd}
      // updateCp={this._getCostPositions}
      //updateCostPositionsAfterPut={this.setCostPositionsAfterUpdate}
      // updateTable={this.getTableData}
      // updateReceipts={this._getReceiptsByProperty} 
      />

      // this.state.heatingCostPositions 
      //   // data &&
      //   // data?.billingData &&
      //   // data?.billingData.costPosition &&
      //   // data?.billingData.costPosition
      //   //.sort((a, b) => (a['orderNo'] || '').toString().localeCompare((b['orderNo'] || '').toString()))
      //   .map((cp, index) => {
      //     return !this.state.isMobile ? (
      //     <UtilityCostAllocationRow
      //       updateCp={this._getCostPositions}
      //       updateCostPositionsAfterPut={this.setCostPositionsAfterUpdate}
      //       updateTable={this.getTableData}
      //       updateReceipts={this._getReceiptsByProperty}
      //       data={cp}
      //       index={index}
      //       length={this.state.heatingCostPositions.length}
      //       isMobile={isMobile}
      //       isTablet={isTablet}
      //       costTypesArr={this.state.costTypeArr}
      //       allocationsArr={this.state.allocationKeys}
      //       fetchAllocationKeysByProperty={()=> GlobalServices._getAllocationKeysByProperty(this, '_handleAllocationKeysResponse', this.props.propertySelected)}
      //       receiptsArr={this.state.receiptArr.filter((receipt)=> receipt.utilityBillingRelevant)}
      //       utilityBillingId={cp.utilityBillingId}
      //       timeFrame={utilityData.date}
      //       costTypeId={cp.costTypeId}
      //       billingPeriodId={this.state.billingPeriodId}
      //       utilityData={this.state.utilityData}
      //       updateGetAllByBillingPeriodCostType={this.updateGetAllByBillingPeriodCostType}
      //       currentDataId={this.props.currentDataId}
      //       updateRecieptsInChild={updateRecieptsInChild}
      //       costTypesBackFunction={() =>
      //         this.getCostTypes()
      //       }
      //       toggleViewDetailsModal={this.toggleViewDetailsModal}
      //       editReceiptOpen={this.state.editReceiptOpen}
      //       skeletonLoadingTrue={()=> this.setState({skeletonLoading: true})}
      //       skeletonLoadingFalse={()=> this.setState({skeletonLoading: false})}
      //       history={this.props.history}
      //       updateMeterData={(meterData, costPositionId)=> this.setCostPositionsAfterUpdate(cp, meterData)}
      //       opentotalOccupantsCountNullAlert={()=> this.setState({opentotalOccupantsCountNullAlert: true})}
      //       getCostPositionById={()=> this.getCostPositionById(cp.id)}
      //       updateReceiptAssignments={()=> this.updateReceiptAssignments()}
      //       //updateConsumptionsByCostPositionId={(costPositionIds)=> this.updateConsumptionsByCostPositionId(costPositionIds)}
      //     />
      //     ) : (
      //       <MobileUtilityCostAllocation
      //         row={cp}
      //         costTypesArr={this.state.costTypes}
      //         allocationsArr={this.state.allocationKeys}
      //         receiptsArr={this.state.receipts}
      //         isMobile
      //         updateCp={this._getCostPositions}
      //         // utilityBillingId={this.props.currentBillingId}
      //         costTypesBackFunction={() =>
      //           GlobalServices._getCostTypeByProperty(this, '_handleCostTypeResponse', this.props.propertySelected)
      //         }
      //       />
      //     );
      //   })
    );
  };

  toggleAddCostTypeModal = () => {
    if (this.state.isMobile) this.setState({ AddCostTypeModal: !this.state.AddCostTypeModal });
  };
  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };
// Receipt Show Model Related --- Start
  closeShow = () => {
    this.setState({ showModal: false });
  };

  toggleViewDetailsModal = (recordId) => {
    this.getRecord(recordId);
  }
  getRecord = (id, openShow=true) => {
    console.log('getRecord: id:', id);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.api_base_url + config.receipts + id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handle_get_record',
      {id, openShow},
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        'Content-Type': 'text/xml',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
    // setCurrentRecord(record)
  };

  _handle_get_record = (response) => {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        langnewKeys.text_error_not_found
        // + response.message
      );
    }
    console.log('_handle_get_record: response:', response);
    this.setState({currentRecord: response.result}, ()=> this.openShow(response.other.id, response.other.openShow))
  }

  closeEditReceipt = () => {
    this.setState({ editReceiptOpen: false }, ()=> this.updateGetAllByBillingPeriodCostType());
  };

  handleEditClick = () => {
    this.closeShow();
    setTimeout(() => this.toggleEditReceiptModal(), 500);
  };

  toggleEditReceiptModal = () => {  
    this.setState({ editReceiptOpen: !this.state.editReceiptOpen });
  };

  updateGetAllByBillingPeriodCostType = ()=> {
    this.state.editReceiptOpen === false && this.state.updateRecieptsInChild === false ? this.setState({updateRecieptsInChild: true}) :
    this.state.updateRecieptsInChild && this.setState({updateRecieptsInChild:  false})
  }

  toggleImageModal = () => {
    this.setState({ imageOpen: !this.state.imageOpen });
  };

  openShow = (id, openShow) => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';

    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.rm_api_base_url + 'attachment/' + id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handle_get_show_file',
      {id, openShow},
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  
  _handle_get_show_file = (response) => {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        langnewKeys.text_error_not_found
        // + response.message
      );
    }

    if(response && response.result.includes('pdf')){
      fetch(Autoload.Setting.rm_api_base_url + 'attachment/' + response.other.id +'/download',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          'Authorization': Autoload.Utils.getAuthToken()
        }
      })
            .then(response => {
                response.blob()
                .then(blob => {
                  console.log('blob: ', blob)
                  
                  this.setState({
                    currentRecord: { ...this.state.currentRecord, file_name: blob.size > 0 ? blob : '' },
                    showModal: true,
                  });
                  })
                  var loader = document.getElementById('hello-housing-loader');
                  loader.style.display = 'none';
              })
    } else {
      this.setState({
        currentRecord: { ...this.state.currentRecord, file_name: response.result != null ? response.result : '' },
        showModal: response.other.openShow && true,
      });
      var loader = document.getElementById('hello-housing-loader');
      loader.style.display = 'none';
    }
  };
  // Receipt Show Model Related --- End

  bind_list_receipt_data = () => {
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.rm_api_base_url + 'property/' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_list_data',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handle_get_list_data(response) {
    console.log(response, 'handle get list bind receipt');
    console.log(this.state.currentCp);
    if (this.state.currentCp) {
      this.onReceiptSelect({ id: response.result[0]?.id, name: response.result[0]?.name }, this.state.currentCp);
    }
  }
  renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <span>Betriebskostenabrechnung ist festgeschrieben und wird nicht automatisch aktualisiert. </span><span 
      onClick={() => 
        this.setState({
          openFreezeModal: true, 
          freezeAlertHeading: this.state.utilityData.isSecured ? lang.utility.text_freeze_alert_heading : lang.utility.text_unfreeze_alert_heading, 
          freezeAlertMessage: this.state.utilityData.isSecured ? lang.utility.text_freeze_alert_message : (<span>{lang.utility.text_unfreeze_alert_message} <a href="https://wissen.hellohousing.de/was-bedeutet-eine-festschreibung-der-betriebskostenabrechnung" target="_blank">(mehr erfahren)</a>.</span>)
        })}
      style={{cursor: 'pointer', fontWeight: 'bold', color: '#5555FF'}}>
          Festschreibung aufheben.
      </span>
    </Tooltip>
  );

  updateReceiptAssignments = () => {
    this.setState({skeletonLoading: true});
    if(this.props.propertySelected && this.props.propertySelected.propertyType === 'REGULAR'){
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url + 'updateReceiptAssignmentsAndCostPositions?utilityBillingId=' + this.props.currentDataId,
        'PUT',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleUpdateReceiptAssignmentsResponse',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    }else{
      this._getReceiptsByProperty();
    }
  };
  handleUpdateReceiptAssignmentsResponse = (response) => {
    !response.status && console.log('Update Receipt Assigments Failed');
    console.log('workingUpdateReceiptAssignments');
    this._getReceiptsByProperty();
    this.props.setRunUpdateReceiptAssignment(false);
  };

  render() {
    const { heatingType } = this.props.propertySelected;
    const { className, propertySelected } = this.props;
    const { utilityData, showModal, currentRecord, editReceiptOpen, imageOpen, skeletonLoading, opentotalOccupantsCountNullAlert, heatingCostPositions, openFreezeModal, freezeAlertHeading, freezeAlertMessage } = this.state;
    const {isMobile, isTablet} = this.props;
    console.log('heatingCostPositions: ', heatingCostPositions);
    console.log('skeletonLoading: ', skeletonLoading);
    return (
      <div className="view-utility-billing"
      >
        <HeadingContainerNew
          heading={lang.utility.text_utility_billing}
          subheading={<>
            <span>{utilityData.date}</span>
            {utilityData.isSecured && 
            <OverlayTrigger placement="bottom" delay={{ show: 450, hide: 1200 }} overlay={this.renderTooltip}>
                <img src={LockIcon} width='16px' style={{marginBottom: 4, marginLeft: 4}}/>
            </OverlayTrigger>}
          </>}
          className="profile-heading tenant-head utility-billing-head"
          headingIcon={CrossIcon}
          iconHandler={
            () => {
              this.props.setUtilityBillingData([]);
              this.props.history.push('/utility-billing');
            }
            // this.goBackIcon()
          }
          subheadingClassName="utility-subheading"
          subheadingbadge={
            <span className={utilityData.recordStatus === 'last' ? 'last-period-badge' : 'no-recent-period-bagde'}>
              {utilityData.recordStatus === 'last' ? lang.utility.text_last_billing_period : ''}
            </span>
          }
          rightContent={
            <div className="btn-container utility-bility-button">
              {propertySelected.propertyType === 'REGULAR' && 
              <ColorButton
                onClick={() => {
                  this.props.goBack();
                  this.props.history.push(`/utility-billing/utility?currentBillingId=${this.props.currentDataId}&ub-step=heating-cost`, { isEdit: true });
                }}
                text={lang.utility.text_go_back}
                // className={`gray-btn ${
                //   (heatingType.toLowerCase() === 'decentralized' || this.props.isEdit) && 'disable'
                // } `}
                className={`gray-btn `}
              // buttonConfig={{ disabled: heatingType.toLowerCase() === 'decentralized' || this.props.isEdit }}
              />}
              <ColorButton
                onClick={() => {
                  // this.moveForward();
                  if (!skeletonLoading) {
                  this.props.goNext();
                  this.props.history.push(`/utility-billing/utility?currentBillingId=${this.props.currentDataId}&ub-step=billing-statements`, { isEdit: true });
                  }
                }}
                text={lang.utility.text_next_utility_bills}
                className="purple-btn mr-1"
                showLoader={skeletonLoading}
              />
            </div>
          }
          breadcrumbs={<div style={{display: 'flex'}}>
          {breadcrumbsContent.map((item)=> item.area.includes('ub-step=utility-cost') && 
          item.breadcrumbs.map((breadcrumb)=>
          breadcrumb.type === 'tour' ? breadcrumb.condition === 'rental_unit_created' && breadcrumb.view === 'desktop' &&
          <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('startTour', breadcrumb.id)}>
            <Skeleton visible={skeletonLoading}>
              <img src={TourIcon} style={{marginTop: '-3px'}}/>
              <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
            </Skeleton>
          </div>
          :
          breadcrumb.view === 'desktop' &&
          breadcrumb.condition === propertySelected.propertyType &&
          <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('showArticle', breadcrumb.id)}>
            <Skeleton visible={skeletonLoading}>
              <img src={HelpLogo} style={{marginTop: '-3px'}}/>
              <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px'}}>{breadcrumb.label}</span>
            </Skeleton>
          </div>
          ))}
        </div>}
        />

        <div className=" mobile-top-btn-section btn-container utility-bility-button">
          {propertySelected.propertyType === 'REGULAR' && 
          <ColorButton
            onClick={() => {
              this.props.goBack();
              this.props.history.push(`/utility-billing/utility?currentBillingId=${this.props.currentDataId}&ub-step=heating-cost`);
            }}
            text={lang.utility.text_go_back}
            // className={`gray-btn ${(heatingType.toLowerCase() === 'decentralized' || this.props.isEdit) && 'disable'} `}
            className={`gray-btn`}
          // buttonConfig={{ disabled: heatingType.toLowerCase() === 'decentralized' || this.props.isEdit }}
          />}
          <ColorButton
            onClick={() => {
              // this.moveForward();
              if(!skeletonLoading){
                this.props.goNext();
                this.props.history.push(`/utility-billing/utility?currentBillingId=${this.props.currentDataId}&ub-step=billing-statements`);
              }
            }}
            text={lang.utility.text_next_utility_bills}
            className="purple-btn mr-1"
            showLoader={skeletonLoading}
          />
        </div>

        <Divider className="utilitiy--divider" />

        <div className="cost-allocation-section">
          <div className="tooltips ub-other-cost-distribution-headline">
            <h5 className="utility-cost-allocation">{`${propertySelected.propertyType === 'REGULAR' ? '2' : '1'}. ${propertySelected.propertyType === 'REGULAR' ? 'Weitere Nebenkosten verteilen' : 'Nebenkosten erfassen'}`}</h5>
          </div>
          <p className="utility-cost-allocation-subheading">
            {`${propertySelected.propertyType === 'REGULAR' ? 'Hier geben Sie alle weiteren Nebenkosten an und können extern erstellte Heizkosten umlegen.' : 'Hier geben Sie alle Nebenkosten an und können extern erstellte Heizkosten erfassen.'}`}
            {/* Bitte wählen Sie die Kosten, die Sie umlegen möchten, und bestimmen Sie deren Höhe. */}
            {/* {lang.utility.text_cost_allocation_desc} */}
          </p>
          <div className={isMobile ? 'mobile-utiltiy-cost' : 'utility-cost-table'}>
          {this.state.skeletonLoading ? 
            <>
              <Skeleton visible={this.state.skeletonLoading} style={{width: '100%', height: '16px', marginTop: '20px', marginBottom: '8px'}}/>
              {[...Array(5)].map((e, i) => <Skeleton visible={this.state.skeletonLoading} style={{height: '75px', marginBottom: '8px'}}/>)}
              <Skeleton visible={this.state.skeletonLoading} style={{width: '200px', height: '37px', marginTop: '20px'}}></Skeleton>
            </>
            :
            <TableContainer
            className='ub-table-container'
            //style={{height: '100vh'}}
            >
            <Table //className={`table borderless ${className} mb-0`}
            style={{borderCollapse: 'separate', borderSpacing: '0px 8px'}}
            >
              {!isMobile ? 
              <TableHead>{this.getTableLabels()}</TableHead> : null}
              {/* <TableBody //</Table>className={isMobile ? 'mobile-cost-table-rows' : ''}
              >{this.getTableData()}</TableBody> */}
              {this.getTableData()}
              <ShowModal
                onEditClick={this.handleEditClick}
                onExpansionClick={this.toggleImageModal}
                record={currentRecord}
                className="show-receipt-modal"
                open={showModal}
                closeShow={this.closeShow}
              />
              <ImageModal
                img={currentRecord && currentRecord.file_name !== '' ? currentRecord.file_name : Receipt}
                className="expandImageModal"
                open={imageOpen}
                toggle={this.toggleImageModal}
              />
            </Table>
            <div id="add-cost-position-new-btn">
              <ColorButton
                // buttonConfig={{disabled:true}}
                text={lang.utility.text_add_cost_position}
                disabled={utilityData.isSecured}
                className="gray-btn add-cost-position"
                onClick={(e) => {
                  e.preventDefault();
                  !isMobile ? this.saveCostPositions() : this.setState({ openCPModal: true });
                }}
              />
          </div>
            </TableContainer>}
          </div>
          
        </div>

        <CostPositionModal
          data={{
            id: 0,
            costTypeName: '',
            costTypeId: '',
            costType: '',
            amount: '',
            allocationKeyId: '',
            allocationKeyName: '',
            allocationKey: '',
            meterData: '',
            // receipt: [{ file: '' }],
            selectedReceipt: [],
            utilityBillingId: this.props.currentDataId,
          }}
          edit={false}
          open={this.state.openCPModal}
          closeShowModal={() => this.setState({ openCPModal: false })}
          costTypesArr={this.state.costTypes}
          allocationsArr={this.state.allocationKeys}
          receiptsArr={this.state.receipts}
          isMobile
          updateCp={this._getCostPositions}
          // utilityBillingId={this.props.currentBillingId}
          costTypesBackFunction={() =>
            GlobalServices._getCostTypeByProperty(this, '_handleCostTypeResponse', this.props.propertySelected)
          }
        />
        {editReceiptOpen && (
            <ReceiptDetailModal
              history={this.props.history}
              record={currentRecord}
              parent={this}
              className="add-edit-receipt-modal"
              edit={true}
              open={editReceiptOpen}
              costAllocation={true}
              toggle={() => this.closeEditReceipt()}
              refreshData={()=> this.getRecord(currentRecord.id, false)}
            />
          )}
          {opentotalOccupantsCountNullAlert &&
          <AlertModal
          open={opentotalOccupantsCountNullAlert}
          heading={'Gesamtpersonenanzahl angeben'}
          headingClassName={'tooltips property-cut-off-date-alert'}
          text={<span>Um den Verteilerschlüssel <span style={{fontWeight: '500'}}>Personenanzahl</span> zu nutzen, müssen Sie zunächst die Personenanzahl des Gesamthauses angeben.</span>}
          cancel
          rightButtonText={'Anzahl angeben'}
          toggle={()=> this.setState({opentotalOccupantsCountNullAlert: false})}
          onClick={()=> this.props.history.push('/property/generalInformation')}
          onCancel={()=>this.setState({opentotalOccupantsCountNullAlert: false})}
          />}
          {openFreezeModal &&
          <AlertModal
          heading={freezeAlertHeading}
          open={openFreezeModal}
          dataSecured={utilityData.isSecured}
          cancel
          onCancel={()=> this.setState({openFreezeModal: false})}
          text={
            <span style={{fontSize: '14px'}}>
              {freezeAlertMessage}
            </span>
          }         
          onClick={(e) => {
            e.preventDefault();
            this.setState({openFreezeModal: false});
            this.lockUtilityBilling(this.props.currentDataId, utilityData.isSecured);
          }}
          toggle={() => {
            this.setState({openFreezeModal: false});
          }}
          />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
    propertySelected: state.generalReducer.property,
    runUpdateTenantOccupations: state.generalReducer.runUpdateTenantOccupations,
    runUpdateReceiptAssignment: state.generalReducer.runUpdateReceiptAssignment,
    runUpdateConsumptions: state.generalReducer.runUpdateConsumptions,
  };
};
const actions = {
  setCostPositions,
  addCostPosition,
  setUtilityBillingData,
  setRunUpdateReceiptAssignment,
  setRunUpdateTenantOccupations,
  setRunUpdateConsumptions,
};
export default connect(mapStateToProps, actions)(UtilityCostAllocation);
