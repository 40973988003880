import React, { Component } from 'react';
import {
  Tooltip, 
  IconButton, 
  FormControl, 
  Select, 
  InputLabel, 
  MenuItem,
  Divider,
  Drawer,
  OutlinedInput
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Box, Center, Group, Loader, Stack, Text, TextInput, Notification, Skeleton } from "@mantine/core";
import {
  ColorButton,
  ReceiptRecord,
  ReceiptDetailModal,
  AddPropertyModal,
  SearchContainer,
  SortContainer,
  SwitchContainer,
  FiltersContainer,
  ReceiptEmailModal,
} from '../../../../neoverv';
import PageHeader from '../../../elements/module/general/PageHeader';
import PageHeaderNew from '../../../elements/module/general/PageHeaderNew';
import Autoload from '../../../../neoverv/Autoload';
import EmptyState from '../../../../neoverv/components/Module/General/EmptyStateContainer/EmptyStateContainer';
import EmptyIcon from '../../../../assets/images/EmptyReciept.svg';
import { connect } from 'react-redux';
import { setReceipts } from '../../../../redux/action/Receipt/ReceiptActions';
import ReceiptDataHandler from '../../../../neoverv/helpers/DataHandler/Receipt';
import GlobalServices from '../../../../neoverv/helpers/services/GlobalServices';
import GeneralDataHandler from '../../../../neoverv/helpers/DataHandler/General';
import MeterDataHandler from '../../../../neoverv/helpers/DataHandler/Meter';
import moment from 'moment';
import DownloadIcon from '../../../../neoverv/components/SVGs/downloadIcon';
import { downloadExcel } from 'react-export-table-to-excel';
import EmailIcon from '@material-ui/icons/Email';
import { CSVLink } from "react-csv";
import { showNotification } from '@mantine/notifications';
import RedTick from '../../../../assets/images/module/general/RedTick.svg';
import breadcrumbsContent from '../../../../../src/assets/breadcrumbs.json';
import HelpLogo from '../../../../assets/images/module/general/book.svg';
import TourIcon from '../../../../assets/images/module/general/TourIcon.svg';
import AddPropertyTypeModal from '../../../../neoverv/components/Module/General/CustomModal/AddPropertyTypeModal';
import BuyPremiumModal from '../../../../neoverv/components/Module/General/CustomModal/BuyPremiumModal';
import { formatNumber } from '../../../../helpers/stringsManipulations';
import {setRefreshReceiptDocumentsData} from '../../../../redux/action/General/generalActions';
import { browserName } from "react-device-detect";
import RangeDatePicker from '../../../../neoverv/components/Module/General/CustomModal/DateRangePicker';
import dayjs from 'dayjs';
import accounting from 'accounting';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Route } from 'react-router-dom';
import TenantRentalIncomeTracking from '../User/TenantRentalIncomeTracking';
import ReceiptsList from './ReceiptsList';
import PaymentsList from './PaymentsList';
import TaxDeclarationScreen from './TaxDeclarationScreen';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
const { filterBySearchBar, filterBySwitchBtn, filterByDate, loader } = Autoload.MainHelper;
const TABLET_SIZE = 1200;
const MOBILE_SIZE = 500;

const routes = [
  {
    name: 'receiptsRecord',
    className: 'tooltips receipts-record',
    route: '/accounting/receipts',
    label: 'Belege',
    heading: 'Belege',
    headingClassName: 'receipt-management-heading'
  },
  {
    name: 'paymentsRecord',
    className: 'tooltips payments-record',
    route: '/accounting/bank',
    label: 'Bank',
    heading: 'Bank',
    headingClassName: 'payments-management-heading'
  },
  {
    name: 'anlageV',
    className: 'tooltips anlage-v-tab',
    route: '/accounting/anlage-v',
    label: 'Anlage V',
    heading: 'Anlage V',
    headingClassName: 'anlage-v-heading'
  }
];

class List extends Component {

  state={
    isMobile: window.innerWidth <= MOBILE_SIZE
  }

  changePage = (route, state) => {
    this.props.history.push(route, state);
  };

  componentDidMount(){
    if(this.props.location.pathname === '/accounting'){
      this.changePage('/accounting/receipts');
    }
  }

  componentDidUpdate(){
    if(this.props.location.pathname === '/accounting'){
      this.changePage('/accounting/receipts');
    }
  }

  renderTabs=()=>{
    let pathname = this.props.location.pathname;
    return (
    <ButtonGroup 
    style={{
      background: '#e9e9f0',
      height: '36px',
      width: 'auto',
      padding: '0px 7px',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center'
    }}
    >
    {routes.map((btn, idx) => {
      return (
        <Button
          key={idx}
          style={{
            minWidth: '110px',
            height: '26px',
            margin: '3% auto',
            paddingTop: '1%',
            paddingBottom: '1%',
            borderRadius: '4px',
            background: btn.route === pathname ? 'white' : 'transparent',
            color: '#252563',
            border: 'none',
            boxShadow: 'none',
            fontWeight: '400'
          }}
          onClick={() => {
            this.changePage(btn.route);
          }}
        >
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <span className={btn.className}>
              {btn.label}
            </span>
          </div>
        </Button>
      );
    })}
    </ButtonGroup> 
    )
  }

  render() {
    const {propertySelected} = this.props;
    const {isMobile} = this.state;
    let pathname = this.props.location.pathname;
    return (
      <>
        <div id="empty-receipt-page"></div>
        {isMobile && 
        <div className='mb-3'>
          {this.renderTabs()}
        </div>}
        <div>
          <PageHeaderNew
          heading={!isMobile && routes.find((r)=> r.route === pathname)?.heading}
          hideHeadingContainer={isMobile}
          headingClassName={routes.find((r)=> r.route === pathname)?.headingClassName}
          headingContainerMarginBottom={0}
          secondRowContentMarginBottom={0}
          hideDivider={true}
          rightContent={!isMobile && this.renderTabs()}
          />

          {/* <Route path={this.props.match.url} exact component={ReceiptsList} /> */}
          <Route path={this.props.match.url + '/receipts'} exact //component={ReceiptsList} 
          render={(props)=> <ReceiptsList {...props} isMobile={isMobile}/>}/>
          <Route path={this.props.match.url + '/bank'} exact component={PaymentsList} />
          <Route path={this.props.match.url + '/anlage-v'} exact component={TaxDeclarationScreen} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
    properties: state.generalReducer.allProperties,
    currentSubscription: state.generalReducer.currentSubscription,
    refreshReceiptDocumentsData: state.generalReducer.refreshReceiptDocumentsData
  };
};
const actions = {
  setReceipts,
  setRefreshReceiptDocumentsData
};

export default connect(mapStateToProps, actions)(List);
