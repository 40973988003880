import { Modal } from '@mantine/core';
import React, { Component } from 'react';
//import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import PdfViewer from '../PdfViewer/PdfViewer';

export class TenantPortalTilePreviewModal extends Component {
  render() {
    const { open, toggle, className, modalContent} = this.props;

    return (
      // <Modal 
      // size="lg" 
      // backdropTransition={{
      //   mountOnEnter: true,
      //   timeout: 0
      // }} 
      // modalTransition={{timeout: 0}}
      // isOpen={open} 
      // toggle={() => toggle()} 
      // className={`image-modal ${className}`}>
      //   <ModalHeader toggle={() => toggle()} className="modal-header"></ModalHeader>
      //   <ModalBody style={{width: 'fit-content', margin: 'auto'}}>
      //     {/* <div style={{backgroundColor: 'rgb(247, 247, 250)', padding: '100px 100px', borderRadius: '4px'}}> */}
      //       <div className="main-div" style={{ position: 'relative', top: 0, left: 0, paddingTop: 0 }}>
      //         {modalContent}
      //       </div>
      //     {/* </div> */}
      //   </ModalBody>
      // </Modal>
      <Modal
      opened={open}
      centered
      overlayOpacity={0.8}
      onClose={() => toggle()}
      classNames={{
        title: 'tenant-portal-preview-Modal-title',
        body: 'tenant-portal-preview-Modal-body',
        header: 'tenant-portal-preview-Modal-header',
        modal: 'tenant-portal-small-preview-Modal-modal'
      }}
      styles={{
        header: {
          display: 'none'
        },
        modal: {
          backgroundColor: 'transparent'
        }
      }}>
        {modalContent}
      </Modal>
    );
  }
}

export default TenantPortalTilePreviewModal;
