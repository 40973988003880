import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Col, Row } from 'react-bootstrap';
import { updateComments } from '../../../../../redux/action/UtilityBilling/UtilityBillingActions';
import CustomModal from '../CustomModal/index';
import Autoload from '../../../../Autoload';
import { formatNumber } from "../../../../../helpers/stringsManipulations";
import {
  TableCell,
  TableRow
} from '@material-ui/core';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];

const { checkProperty } = Autoload.MainHelper;
const MOBILE_SIZE = 766;

export class UtilityBillsTableRow extends Component {

  tableBodyCellStyling = {
    color: '#0e0e1d',
    padding: '5px 9px 5px 9px',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    lineHeight: '20px',
    border: '1px solid #DADAE6',
    //textTransform: 'capitalize',
    height: '48px',
    minWidth: '90px',
    // borderBottom: 'none',
    // borderRight: 'none'
  }

  state = {
    costType: '',
    totalCost: '',
    allocationKey: '',
    totalValue: '',
    tenantValue: '',
    unit: '',
    tenantCostShare: '',
    comment: '',
    openModal: false,
    isMobile: window.innerWidth <= MOBILE_SIZE
  };
  toggleShowModal = (id) => {
    if (this.state.isMobile) this.setState({ openModal: !this.state.openModal });
  };
  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  componentDidMount() {
    if(this.props.messagePreview){
      this.tableBodyCellStyling.fontSize = '7pt';
      this.tableBodyCellStyling.height = '0px'
      this.tableBodyCellStyling.padding = '1mm'
      this.tableBodyCellStyling.lineHeight = '11pt'
      this.tableBodyCellStyling.minWidth = '40px';
    }
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    const { billRow } = this.props;
    this.setState(
      {
        costType: checkProperty(billRow, 'costType'),
        totalCost: checkProperty(billRow, 'totalCost'),
        allocationKey: checkProperty(billRow, 'allocationKey'),
        totalValue: checkProperty(billRow, 'totalValue'),
        tenantValue: checkProperty(billRow, 'tenantValue'),
        unit: checkProperty(billRow, 'unit'),
        tenantCostShare: checkProperty(billRow, 'tenantCostShare'),
        comment: checkProperty(billRow, 'comment'),
      },
      () => console.log('this.state', this.state)
    );
  }
  updateCommentLocalState = (comment, statement) => {
    console.log(statement);
    this.props.updateComments(this.props.tenantID, this.props.billRow.id, comment);
    this.props.updateCommentLocalState(this.props.tenantID, statement.id, {
      comment,
      id: statement.id,
      costPositionId: statement.costPositionId,
      billingStatementId: statement.billingStatementId,
      unit: statement.unit,
      totalConsumption: statement.totalValue,
      tenantConsumption: statement.tenantValue,
      tenantCostShare: statement.tenantCostShare,
    });
  };

  render() {
    const { isMobile } = this.state;
    // console.log('each row', this.props);
    const { index, tableLength, billingTemplate, hideTenantZeroCostsSwitch, billRow } = this.props;
    const { costType, totalCost, allocationKey, totalValue, tenantValue, unit, tenantCostShare } = this.props.billRow;
    const { comment } = this.state;
    console.log('tenantCostShare: ', billRow);
    return (
      <>
        {(hideTenantZeroCostsSwitch ? (billRow?.costTypeIdentifier === 'NON_HEATING' ? tenantCostShare > 0 : true) : true) &&
        <TableRow
          onClick={() => this.toggleShowModal()}
          style={{maxWidth: billingTemplate ? 'auto' : '1050px'}}
        >
          <TableCell
            style={{...this.tableBodyCellStyling, ...{borderBottom: tableLength === (index + 1) && '1px solid #DADAE6', whiteSpace: 'normal'}}}
          >
            {costType}
          </TableCell>
          <TableCell
            style={{...this.tableBodyCellStyling, ...{textAlign: 'right', borderBottom: tableLength === (index + 1) && '1px solid #DADAE6'}}}
          >
            {' '}
            {/* {Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(totalCost.toFixed(2) + '', false)} € */}
            {formatNumber(totalCost, true)}
          </TableCell>
          <TableCell
          style={{...this.tableBodyCellStyling, ...{borderBottom: tableLength === (index + 1) && '1px solid #DADAE6', whiteSpace: 'normal'}}}
          >
            {costType === 'Heizung & Heizungswartung' ? '-' : `${allocationKey} ${(unit && unit !== '-') ? `(${unit})` : ''}`}
          </TableCell>
          <TableCell
          style={{...this.tableBodyCellStyling, ...{textAlign: 'right', borderBottom: tableLength === (index + 1) && '1px solid #DADAE6'}}}
          >
            {/*{console.log("costType", costType)}*/}
            {allocationKey === 'Individuelle Zuweisung' ? '-' : costType === 'Heizung & Heizungswartung' ? '-' : formatNumber(totalValue)}
          </TableCell>
          <TableCell
          style={{...this.tableBodyCellStyling, ...{textAlign: 'right', borderBottom: tableLength === (index + 1) && '1px solid #DADAE6'}}}
          >
            {allocationKey === 'Individuelle Zuweisung' ? '-' : costType === 'Heizung & Heizungswartung' ? '-' : formatNumber(tenantValue)}
          </TableCell>
          <TableCell
          style={{...this.tableBodyCellStyling, ...{textAlign: 'right', textTransform: 'none', borderBottom: tableLength === (index + 1) && '1px solid #DADAE6'}}}
          >
            {costType === 'Heizung & Heizungswartung' ? '-' : unit}
          </TableCell>
          <TableCell
          style={{...this.tableBodyCellStyling, ...{textAlign: 'right', fontWeight: 'bold', borderBottom: tableLength === (index + 1) && '1px solid #DADAE6'}}}
          >
            {' '}
            {/* {Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(tenantCostShare.toFixed(2) + '', false)} € */}
            {costType === 'Heizung & Heizungswartung' ? '-' : formatNumber(tenantCostShare, true)}
          </TableCell>
          <TableCell
          style={{...this.tableBodyCellStyling, ...{borderBottom: tableLength === (index + 1) && '1px solid #DADAE6', borderRight: this.props.billingTemplate && '1px solid #DADAE6'}}}
          >
            {this.props.billingTemplate || this.props.isSecured ? (
              <p style={{ minWidth: this.props.messagePreview ? '62px' : '120px', maxWidth: '160px', textTransform: 'none', wordBreak: 'break-word', whiteSpace: 'normal', marginBottom: 0 }}> {comment}</p>
            ) : (
              <Form.Control
                type="text"
                placeholder={lang.utility.text_add_comment}
                value={comment}
                onBlur={(e) => this.updateCommentLocalState(e.target.value, this.props.billRow)}
                onChange={(e) => this.setState({comment: e.target.value})}
                disabled={this.props.isSecured}
                className='billTable-comment-form-control'
                style={{
                  border: 'none',
                  outline: 'none',
                  background: 'no-repeat',
                  boxShadow: 'none',
                  padding: 0,
                  color: '#727293',
                }}
              />
            )}
          </TableCell>
        </TableRow>}
        {isMobile && (
          <CustomModal
            className="utility-bills-modal"
            open={this.state.openModal}
            toggle={() => {
              this.toggleShowModal();
            }}
            heading="Cost type details"
          >
            <>
              <Row className="utility-step-two-three">
                <Col xs={7} className="costBased-user1">
                  {lang.utility.text_Cost_type}
                </Col>
                <Col xs={5} className="costBased_value bold-user">
                  {costType}
                </Col>
              </Row>
              <Row className="utility-step-two-three">
                <Col xs={7} className="costBased-user1">
                  {lang.utility.text_utiiity_allocation_key}
                </Col>
                <Col xs={5} className="costBased_value">
                  {`${allocationKey} ${(unit && unit !== '-') ? `(${unit})` : ''}`}
                </Col>
              </Row>
              <Row className="utility-step-two-three">
                <Col xs={7} className="costBased-user1">
                  {lang.utility.text_Total_cost}
                </Col>
                <Col xs={5} className="costBased_value">
                  {Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(totalCost + '', false)}
                </Col>
              </Row>
              <Row className="utility-step-two-three">
                <Col xs={7} className="costBased-user1">
                  {lang.utility.text_utility_total_value}
                </Col>
                <Col xs={5} className="costBased_value">
                  {totalValue}
                </Col>
              </Row>
              <Row className="utility-step-two-three">
                <Col xs={7} className="costBased-user1">
                  {lang.utility.text_utility_tenant_value}
                </Col>
                <Col xs={5} className="costBased_value">
                  {tenantValue}
                </Col>
              </Row>
              <Row className="utility-step-two-three">
                <Col xs={7} className="costBased-user1">
                  {lang.utility.text_utility_unit}
                </Col>
                <Col xs={5} className="costBased_value">
                  {unit}
                </Col>
              </Row>
              <Row className="utility-step-two-three">
                <Col xs={7} className="costBased-user1">
                  {lang.utility.text_Tenant_Cost_share}
                </Col>
                <Col xs={5} className="costBased_value">
                  {Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(tenantCostShare + '', false)}
                </Col>
              </Row>
              <div className="billTable-comment">
                {/* <Form.Label>{lang.utility.text_utility_comment}</Form.Label> */}
                <Form.Control
                  disabled={this.props.isSecured || this.props.billingTemplate}
                  type="text"
                  placeholder={lang.utility.text_add_comment}
                  value={comment}
                  onBlur={(e) => this.updateCommentLocalState(e.target.value, this.props.billRow)}
                  onChange={(e) => this.setState({comment: e.target.value})}
                />
              </div>
            </>
          </CustomModal>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};
const actions = {
  updateComments,
};
export default connect(mapStateToProps, actions)(UtilityBillsTableRow);
