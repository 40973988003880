import React, { useState, useRef, useEffect } from 'react';
import { usePdf } from '@mikecousins/react-pdf';
import { Button } from 'reactstrap';
import { ChevronRight, ChevronLeft, ZoomIn, ZoomOut} from '@material-ui/icons';
import ExpandIcon from '../../../../../assets/images/module/general/ExpandIcon.svg';
import { Group, Stack } from '@mantine/core';
import { formatNumber } from '../../../../../helpers/stringsManipulations';
import dayjs from 'dayjs';
import accounting from 'accounting';

const MOBILE_SIZE = 992;
const SMALLER_MOBILE_SIZE = 500;
const PreviewMessageTenantPortal = ({messageData, selectedRecipient, senderEmail, senderProfile, billingPeriod, rentalUnits, propertySelected, selectedBillingStatement, accountData, meterReadingsSwitch, compositionOfHeatingCostsSwitch, costSplitWarmWaterAndHeatingSwitch, splitBaseConsumptionCostsSwitch, heatingFilter, senderAddress, rentalPayment, rents, profile, tenantRemainingRentObject, vatSwitch, isPreTaxInfoHidden, currentSubscription, laborCostSwitch, energyConsumptionCO2EmissionsSwitch, compareHeatingConsumptionBarChartSwitch, energyLevelsInfoSwitch, arbitraryBoardInfoSwitch, energyAdviceCenterInfoSwitch, co2TaxInfoSwitch, multipleTenants}) => {
    console.log('PreviewMessage: ', messageData, selectedRecipient, senderProfile);

    const [newMessageBody, setNewMessageBody] = useState();
    const [newMessageSubject, setNewMessageSubject] = useState();
    const [rentalUnit, setRentalUnit] = useState();

    const replaceAllPlaceHolders=(type)=>{
      let newMessageDataBody= type === 'body' ? messageData.body : messageData.subject;
      let rentalUnit = rentalUnits.find((ru)=> ru.tenantsData.find((tenant)=> tenant.id === selectedRecipient.tenantId) !== undefined);
      let tenant = rentalUnit?.tenantsData.find((tenant)=> tenant.id === selectedRecipient.tenantId);
      let rent = null;
      if(messageData.templateType === 'RentalPayment'){
        let findRentalPaymentRent = rents.find((rt)=> (dayjs(rt.startDate).isSame(rentalPayment.rentalMonth) || dayjs(rt.startDate).isBefore(rentalPayment.rentalMonth)) && (rt.endDate ? (dayjs(rt.endDate).isSame(rentalPayment.rentalMonth) || dayjs(rt.endDate).isAfter(rentalPayment.rentalMonth)) : true));
        rent = findRentalPaymentRent !== undefined ? findRentalPaymentRent : null;
      }else{
        let findRent = rents.find((rt)=> (dayjs(rt.startDate).isSame(new Date()) || dayjs(rt.startDate).isBefore(new Date())) && (rt.endDate ? (dayjs(rt.endDate).isSame(new Date()) || dayjs(rt.endDate).isAfter(new Date())) : true));
        rent = findRent !== undefined ? findRent : null;
      }

      const commercialTenantWithVatSwitch = vatSwitch && currentSubscription.subscriptionKey.split('_')[0] !== 'STARTER' && (selectedBillingStatement?.tenantOccupation.contractType === 'Default' || selectedBillingStatement?.tenantOccupation.contractType === 'commercial' || selectedBillingStatement?.tenantOccupation.contractType === 'self');
      const sumOfVAT = selectedBillingStatement?.totalVATAmount ? selectedBillingStatement?.totalVATAmount : 0;
      const netUtilityBillingCost = (selectedBillingStatement ? selectedBillingStatement?.totalAllocatedUtilityCosts : 0) - sumOfVAT;
      const VAT = (isPreTaxInfoHidden ? selectedBillingStatement?.totalAllocatedUtilityCosts : netUtilityBillingCost) * 0.19;
      const grossUtilityBillingCost = !isPreTaxInfoHidden ? (netUtilityBillingCost + VAT) : ((selectedBillingStatement ? selectedBillingStatement?.totalAllocatedUtilityCosts : 0) + VAT);
      const creditAmount = selectedBillingStatement ? selectedBillingStatement?.totalAdvancePayments - grossUtilityBillingCost : 0;

      console.log('rentalUnit: ', rentalUnit);
      setRentalUnit(rentalUnit);
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">Abrechnungszeitraum</a>', billingPeriod);
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">Betriebskosten</a>', commercialTenantWithVatSwitch ? accounting.formatMoney(grossUtilityBillingCost, '€', 2, '.', ',', '%v %s') : selectedBillingStatement?.totalAllocatedUtilityCosts !== null && formatNumber(selectedBillingStatement?.totalAllocatedUtilityCosts, true));
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">Guthaben / Nachzahlung</a>', commercialTenantWithVatSwitch ? accounting.formatMoney(creditAmount, '€', 2, '.', ',', '%v %s') : selectedBillingStatement?.totalAllocatedUtilityCosts !== null && selectedBillingStatement?.totalAdvancePayments !== null && `${formatNumber(selectedBillingStatement?.totalAdvancePayments - selectedBillingStatement?.totalAllocatedUtilityCosts, true)}`);
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">Empfänger</a>', selectedRecipient?.name);
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">Mieteinheit</a>', rentalUnit !== undefined ? rentalUnit?.name : 'Mieteinheit');
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">Objektadresse</a>', (propertySelected.propertyAddress && propertySelected.propertyZipCode && propertySelected.propertyCity) ? `${propertySelected.propertyAddress}, ${propertySelected.propertyZipCode} ${propertySelected.propertyCity}` : 'Objektadresse');
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">Vorauszahlungen</a>', selectedBillingStatement?.totalAdvancePayments !== null && formatNumber(selectedBillingStatement?.totalAdvancePayments, true));

      //new
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">Absender</a>', `${profile?.firstName} ${profile?.lastName}`);
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">Mieter</a>', tenant?.name);
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">Auszugsdatum</a>', tenant?.out);
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">Einzugsdatum</a>', tenant?.in);
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">erhaltene Miete</a>', rentalPayment ? accounting.formatMoney((rentalPayment.receivedNetRent + rentalPayment.receivedAdvancePayment), '€', 2, '.', ',', '%v %s') : 'erhaltene Miete');
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">Mietmonat</a>', rentalPayment ? dayjs(rentalPayment.rentalMonth).format('MMMM YYYY') : 'Mietmonat');
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">gesamte ausstehende Miete</a>', tenantRemainingRentObject !== null ? accounting.formatMoney(tenantRemainingRentObject, '€', 2, '.', ',', '%v %s') : 'gesamte ausstehende Miete');
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">Vermieter</a>', accountData?.addressCompany);
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">Vorauszahlung</a>', rent ? accounting.formatMoney(rent.prePayments, '€', 2, '.', ',', '%v %s') : rents.length > 0 ? accounting.formatMoney(rents[0].prePayments, '€', 2, '.', ',', '%v %s') : null);
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">Warmmiete</a>', rent ? accounting.formatMoney(rent.prePayments + rent.netRent, '€', 2, '.', ',', '%v %s') : rents.length > 0 ? accounting.formatMoney(rents[0].prePayments + rents[0].netRent, '€', 2, '.', ',', '%v %s') : null);
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">Kaltmiete</a>', rent ? accounting.formatMoney(rent.netRent, '€', 2, '.', ',', '%v %s') : rents.length > 0 ? accounting.formatMoney(rents[0].netRent, '€', 2, '.', ',', '%v %s') : null);

      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">Access Link</a>', `https://${window.location.href.includes('app') ? 'app' : 'dev'}.hellohousing-mieterportal.de/signup?${multipleTenants.find((mTenant)=> mTenant?.userId === selectedRecipient?.tenantId)?.passwordResetCode}`);

      newMessageDataBody= newMessageDataBody.replaceAll('<a href="" target="_self">Abrechnungszeitraum</a>', billingPeriod);
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="" target="_self">Betriebskosten</a>', commercialTenantWithVatSwitch ? accounting.formatMoney(grossUtilityBillingCost, '€', 2, '.', ',', '%v %s') : selectedBillingStatement?.totalAllocatedUtilityCosts !== null && formatNumber(selectedBillingStatement?.totalAllocatedUtilityCosts, true));
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="" target="_self">Guthaben / Nachzahlung</a>', commercialTenantWithVatSwitch ? accounting.formatMoney(creditAmount, '€', 2, '.', ',', '%v %s') : selectedBillingStatement?.totalAllocatedUtilityCosts !== null && selectedBillingStatement?.totalAdvancePayments !== null && `${formatNumber(selectedBillingStatement?.totalAdvancePayments - selectedBillingStatement?.totalAllocatedUtilityCosts, true)}`);
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="" target="_self">Empfänger</a>', selectedRecipient?.name);
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="" target="_self">Mieteinheit</a>', rentalUnit !== undefined ? rentalUnit?.name : 'Mieteinheit');
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="" target="_self">Objektadresse</a>', (propertySelected.propertyAddress && propertySelected.propertyZipCode && propertySelected.propertyCity) ? `${propertySelected.propertyAddress}, ${propertySelected.propertyZipCode} ${propertySelected.propertyCity}` : 'Objektadresse');
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="" target="_self">Vorauszahlungen</a>', selectedBillingStatement?.totalAdvancePayments !== null && formatNumber(selectedBillingStatement?.totalAdvancePayments, true));

      //new
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">Absender</a>', `${profile?.firstName} ${profile?.lastName}`);
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">Mieter</a>', tenant?.name);
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">Auszugsdatum</a>', tenant?.out);
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">Einzugsdatum</a>', tenant?.in);
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">erhaltene Miete</a>', rentalPayment ? accounting.formatMoney((rentalPayment.receivedNetRent + rentalPayment.receivedAdvancePayment), '€', 2, '.', ',', '%v %s') : 'erhaltene Miete');
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">Mietmonat</a>', rentalPayment ? dayjs(rentalPayment.rentalMonth).format('MMMM YYYY') : 'Mietmonat');
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">gesamte ausstehende Miete</a>', tenantRemainingRentObject !== null ? accounting.formatMoney(tenantRemainingRentObject, '€', 2, '.', ',', '%v %s') : 'gesamte ausstehende Miete');
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">Vermieter</a>', accountData?.addressCompany);
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">Vorauszahlung</a>', rent ? accounting.formatMoney(rent.prePayments, '€', 2, '.', ',', '%v %s') : rents.length > 0 ? accounting.formatMoney(rents[0].prePayments, '€', 2, '.', ',', '%v %s') : null);
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">Warmmiete</a>', rent ? accounting.formatMoney(rent.prePayments + rent.netRent, '€', 2, '.', ',', '%v %s') : rents.length > 0 ? accounting.formatMoney(rents[0].prePayments + rents[0].netRent, '€', 2, '.', ',', '%v %s') : null);
      newMessageDataBody= newMessageDataBody.replaceAll('<a href="#" target="_self">Kaltmiete</a>', rent ? accounting.formatMoney(rent.netRent, '€', 2, '.', ',', '%v %s') : rents.length > 0 ? accounting.formatMoney(rents[0].netRent, '€', 2, '.', ',', '%v %s') : null);

      newMessageDataBody= newMessageDataBody.replaceAll('<a href="" target="_self">Access Link</a>', `https://${window.location.href.includes('app') ? 'app' : 'dev'}.hellohousing-mieterportal.de/signup?${multipleTenants.find((mTenant)=> mTenant?.userId === selectedRecipient?.tenantId)?.passwordResetCode}`);
      console.log('newMessageData: ', newMessageDataBody);
      type === 'body' ? setNewMessageBody(newMessageDataBody) : setNewMessageSubject(newMessageDataBody);
    }
    
    const createPdf=()=>{
        //window.open(`/billingStatement2/${selectedBillingStatement.id}?heatingFilter=${heatingFilter}&meterReadings=${meterReadingsSwitch}&compositionOfHeatingCosts=${compositionOfHeatingCostsSwitch}&costSplitWarmWaterAndHeating=${costSplitWarmWaterAndHeatingSwitch}&splitBaseConsumptionCosts=${splitBaseConsumptionCostsSwitch}&vatSwitch=${vatSwitch}&laborCostSwitch=${laborCostSwitch}`, '_blank');
        window.open(`/billingStatement2/${selectedBillingStatement.id}?heatingFilter=${heatingFilter}&meterReadings=${meterReadingsSwitch}&compositionOfHeatingCosts=${compositionOfHeatingCostsSwitch}&costSplitWarmWaterAndHeating=${costSplitWarmWaterAndHeatingSwitch}&splitBaseConsumptionCosts=${splitBaseConsumptionCostsSwitch}&energyConsumptionCO2EmissionsSwitch=${energyConsumptionCO2EmissionsSwitch}&compareHeatingConsumptionBarChartSwitch=${compareHeatingConsumptionBarChartSwitch}&energyLevelsInfoSwitch=${energyLevelsInfoSwitch}&arbitraryBoardInfoSwitch=${arbitraryBoardInfoSwitch}&energyAdviceCenterInfoSwitch=${energyAdviceCenterInfoSwitch}&co2TaxInfoSwitch=${co2TaxInfoSwitch}&vatSwitch=${vatSwitch}&laborCostSwitch=${laborCostSwitch}&isPreTaxInfoHidden=${isPreTaxInfoHidden}`)
    }
    
    useEffect(() => {
      replaceAllPlaceHolders('body');
      replaceAllPlaceHolders('subject');
    },[selectedRecipient]);
  return (
    <div style={{width: '210mm', //height: '297mm', 
    background: 'white', //boxShadow: '0px 1px 2px #000', 
    padding: '23mm'}}>
      <Group spacing={2}>
            <span style={{fontSize: '16px', fontWeight: 600, fontFamily: 'Arial', textAlign: 'justify', color: '#000'}}>Betreff:</span>
            <div className={'message-card-title'} style={{fontSize: '16px', fontWeight: 600, fontFamily: 'Arial', textAlign: 'justify', color: '#000'}} dangerouslySetInnerHTML={{__html: newMessageSubject}}/>  
        </Group>
      <div style={{marginTop: '20mm', fontSize: '12px', fontFamily: 'Arial', textAlign: 'justify'}} dangerouslySetInnerHTML={{__html: newMessageBody}}/>
      <Stack spacing={10}>
        <Stack spacing={0}>
            {/* <span style={{fontFamily: 'Arial', fontSize: '9pt'}}>{`Mit freundlichen Grüßen`}</span> */}
            <span style={{fontFamily: 'Arial', fontSize: '9pt'}}>{accountData.addressCompany}</span>
            <span style={{fontFamily: 'Arial', fontSize: '9pt'}}>{accountData.addressStreet}</span>
            <span style={{fontFamily: 'Arial', fontSize: '9pt'}}>{`${accountData.addressZipCode} ${accountData.addressCity}`}</span>
        </Stack>
        <Stack spacing={0}>
          {accountData.companyPhoneNumber && <span style={{fontFamily: 'Arial', fontSize: '8pt'}}>{`Tel: ${accountData.companyPhoneNumber}`}</span>}
          {accountData.companyEmail && <span style={{fontFamily: 'Arial', fontSize: '8pt'}}>{`E-Mail: ${accountData.companyEmail}`}</span>}
        </Stack>
      </Stack>
      <Stack style={{fontSize: '12px', fontWeight: 400, marginTop: '20px'}} spacing={0}>
        {messageData.utilityBillingId &&
        <Group spacing={2}><span style={{fontWeight: 'bold'}}>Anhang:</span><span style={{cursor: 'pointer', marginLeft: '1px', color: '#5555FF'}} onClick={()=> createPdf()}>{`${propertySelected.name}-${rentalUnit?.name}-${selectedRecipient ? selectedRecipient.name.replace(/,/g, '_') : 'Mieter'}.pdf`}</span></Group>}
      </Stack>
    </div>
  );
};

export default PreviewMessageTenantPortal;