// Base libs
import { useEffect, useState } from "react";

// Mantine UI libs
import { useForm } from "@mantine/form";
import {
  Alert,
  Anchor,
  Box,
  Button, Card,
  Checkbox,
  Container,
  Grid,
  Group,
  Image,
  LoadingOverlay,
  PasswordInput,
  Popover,
  Progress,
  Stack,
  Text,
  TextInput
} from '@mantine/core';
import SignUpSlider from '../components/SignUpSlider';
import EyeOpened from "../neoverv/components/SVGs/eyeOpened";

// Configs etc
import { config } from "../neoverv/config/Setting";
import APIClient from "../neoverv/config/APIClient";
import { WarningRounded } from "@material-ui/icons";
import { hubspotClient } from "../helpers/hubspotClient";
import { showNotification } from "@mantine/notifications";
import RedCross from '../assets/images/module/general/RedCross.svg';
import GreenTick from '../assets/images/module/general/GreenTick.svg';


// Requirements for the password validation
const passwordRequirements = [
  { regex: /[0-9]/, label: 'Eine Nummer haben' },                                                 // one digit
  { regex: /[a-z]/, label: 'Mindestens einen Kleinbuchstaben haben' },                            // lowercase letter
  { regex: /[A-Z]/, label: 'Mindestens einen Großbuchstaben haben' },                             // uppercase letter
  { regex: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Ein Sonderzeichen enthalten (z. B. %, &, #)' }     // special chars
];

// Show password strength
const getStrength = (password) => {
  let multiplier = password.length > 5 ? 0 : 1;

  passwordRequirements.forEach((requirement) => {
    if (!requirement.regex.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (passwordRequirements.length + 1)) * multiplier, 10);
}

// Password requirement markup
const PasswordRequirement = ({ checks, label }) => {
  return (
    <Text
      color="white"
      sx={{ display: 'flex', alignItems: 'center', opacity: checks ? 1 : 0.5 }}
      mt={7}
      size="sm"
    >
      <Image width={15} src={`${process.env.PUBLIC_URL}/icons/icon-check-badge.svg`} alt="Check icon" />
      <Box ml={10}>{label}</Box>
    </Text>
  )
};

/**
 * SignUp component markup
 * @returns {JSX.Element}
 * @constructor
 */
const SignUp = (props) => {
  const unknownErrorString = 'Es ist ein technischer Fehler aufgetreten. Der hellohousing Service wurde informiert. Bitte haben Sie einige Minuten Geduld und wiederholen Sie dann den Vorgang.' //'Es ist ein unerwarteter Fehler aufgetreten.'; // TODO: move it to translations file
  const [loading, setLoading] = useState(false);
  const [passwordPopoverOpened, setPasswordPopoverOpened] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [emailValidateSuccess, setEmailValidateSuccess] = useState(null);
  const [passcode, setPasscode] = useState('');
  const nebenkostenabrechnung = window.location.href.includes('/nebenkostenabrechnung');
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
      terms: false,
      devPassword: ''
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Ungültige e-mail')
    }
  });
  const checks = passwordRequirements.map((requirement, index) => (
    <PasswordRequirement key={index} label={requirement.label} checks={requirement.regex.test(form.getInputProps('password').value)} />
  ));
  const strength = getStrength(form.getInputProps('password').value);
  const color = strength === 100 ? 'teal' : strength > 50 ? 'yellow' : 'red';

  useEffect(() => {    
    window.Intercom('boot', {  
      app_id: 'vvrs5bx3',
      Environment: window.location.hostname.includes('app') ? 'Production' : 'Staging',
    });
    console.log('this.props.history: ', document.referrer);
  }, []);
  // Send data to HubSpot
  const sendDataToHubspot = async () => {
    await hubspotClient.crm.contacts.basicApi.create({
      email: form.getInputProps('email').value
    })
      .then((response) => {
        console.log("HUBSPOT RESPONSE: ", response);
      })
      .catch((err) => {
        console.log("HUBSPOT ERROR: ", err);
      });
  }

  const verifyEmail = async () =>{
    setLoading(true);
    await APIClient({
      method: 'POST',
      url: config.signUpVerifyEmail,
      data: {
        email: form.getInputProps('email').value,
        passwordResetCode: passcode
      }
    }). then((response)=>{
        setEmailValidateSuccess(true);
        setSuccess(false);
        setLoading(false);
    }) .catch((e)=>{
      setApiError(unknownErrorString);
      var metadata = {
        error: e.toString(),
        'end point name': config.signUpVerifyEmail
      };
      window.Intercom('trackEvent', 'sign-up-failed', metadata);
      setLoading(false);

      showNotification({
        icon: <img src={RedCross} alt='red-tick'/>,
        message: 'Passcode ist nicht gültig.'
      })
    })
  }
  
  // Activate account
  const activateAccount = async (accountId) => {
    await APIClient({
      method: 'PUT',
      url: config.signUpActivateAccount,
      data: {
        accountId: accountId
      }
    })
      .then(async(response) => {
        // sendDataToHubspot();

        setTimeout(() => {
          if(response.status === 200 && response.data.status){
            window.Intercom('trackEvent', 'signed-up');
          }
          setSuccess(true);
          setLoading(false);
        }, 3000);
        
        // TODO: Uncomment to activate email verification
        // await APIClient({
        //   method: 'POST',
        //   url: config.signUpEmailVerificationInstructions,
        //   data: {
        //     email: form.getInputProps('email').value
        //   }
        // }). then((response)=>{
        //   console.log('signUpEmailVerificationInstructions: ', response);
        //   setSuccess(true);
        //   setLoading(false);
        // }) .catch((e)=>{
        //   setApiError(unknownErrorString);
        //   var metadata = {
        //     error: e.toString(),
        //     'end point name': config.signUpEmailVerificationInstructions
        //   };
        //   window.Intercom('trackEvent', 'sign-up-failed', metadata);
        //   setLoading(false);
        // })
      })
      .catch((e) => {
        setApiError(unknownErrorString);
        var metadata = {
          error: e.toString(),
          'end point name': config.signUpActivateAccount
        };
        window.Intercom('trackEvent', 'sign-up-failed', metadata);
        setLoading(false);
      });
  }
  
  // Set user subscription
  const setSubscription = async (accountId) => {
    await APIClient({
      method: 'PUT',
      url: config.signUpSubscription,
      data: {
        accountId: accountId,
        subscriptionType: "32bccff3-28b3-453a-a6ad-4023ebb8a734"
      }
    })
      .then(() => {
        activateAccount(accountId);
        
        //props.history.push(`${window.location.pathname}&event=starter`);
        window.dataLayer.push({ 
          event: 'awinStarter',
          apiData: null
        });
      })
      .catch((e) => {
        setApiError(unknownErrorString);
        var metadata = {
          error: e.toString(),
          'end point name': config.signUpSubscription
        };
        window.Intercom('trackEvent', 'sign-up-failed', metadata);
        setLoading(false);
      }); 
  }
  
  // Put user data into freshly created account
  const putUserData = async (accountId, password) => {
    
    await APIClient({
      method: 'PUT',
      url: config.signUpAccountDetails,
      data: {
        accountId: accountId, // proper ID from auth-api/signUp/account response. b8eec9e9-3f71-4f08-f58e-08d9b4156a4b in my case
        password: password, // password from form
        firstName: form.getInputProps('email').value.split('@')[0], // Set user name from email prefix
        company: '',
        address: '',
        city: '',
        zipCode: ''
      }
    })
      .then(async (response) => {

        if (response.status === 200) {
          console.log('putUserData: ', response);
          await window.Intercom("update", {
            api_base: "https://api-iam.intercom.io",
            app_id: "vvrs5bx3",
            email: form.getInputProps('email').value, // Email address,
            Environment: window.location.hostname.includes('app') ? 'Production' : 'Staging',
            'Number of properties': (0).toString(),
            'Number of condominiums': (0).toString(),
            'Rental Unit Created': false,
            'Tenant Created': false,
            'Receipt Created': false,
            'Rental Unit Created': false,
            'Receipt Created Automated': false,
            'Meter Reading Conducted': false,
            'Company': '',
            'Meter Created': false,
            'Utility Billing Created': false,
            'Account Deleted': false
          });
          setSubscription(accountId);
        } else {
          setApiError(unknownErrorString);
          setLoading(false);
        }
        
      })
      .catch((e) => {
        setApiError(unknownErrorString);
        var metadata = {
          error: e.toString(),
          'end point name': config.signUpAccountDetails
        };
        window.Intercom('trackEvent', 'sign-up-failed', metadata);
        setLoading(false);
      });
  }
  
  // Handle form submission
  const handleFormSubmit = async (values) => {
    if(values.password.length !== 0 && ((window.location.href.includes('dev') || window.location.href.includes('localhost')) ? values.devPassword === 'Testing9!' : true)) {
      if(strength === 100){
        setLoading(true);
    
        await APIClient({
          method: 'POST',
          url: config.signUpAccount,
          data: { email: values.email } 
        })
          .then(async (response) => {
            
            if (response.status === 200) {
              window.uet_report_conversion();
              const { accountId } = response.data.result;
              await window.Intercom('update', {  
                app_id: 'vvrs5bx3',  
                email: values.email,
                'Environment': window.location.hostname.includes('app') ? 'Production' : 'Staging',
                'Account Id': accountId
              });
              putUserData(accountId, values.password);
              try {
                window.gtag('event', 'user_sign_up', {
                  'app_name': 'helloHousingApp',
                });                
              } catch (error) {
                console.error('error-gtag: ', error);
              }
              
            } else {
              setApiError(unknownErrorString);
              setLoading(false)
            }
            
          })
          .catch((e) => {
            setApiError(unknownErrorString);
            setLoading(false);
            console.log('error: ', e);
            var metadata = {
              email: values.email,
              error: e.toString(),
              'end point name': config.signUpAccount
            };
            window.Intercom('trackEvent', 'sign-up-failed', metadata);
          });
      } else {
        showNotification({
          //title: 'Fehler',
          icon: <img src={RedCross} alt='red-tick'/>,
          message: 'Bitte wählen Sie ein sicheres Passwort.'//'Bitte wählen Sie ein starkes Passwort.'
        })
      }
    } else {
      showNotification({
        //title: 'Fehler',
        icon: <img src={RedCross} alt='red-tick'/>,
        message: 'Bitte geben Sie ein Passwort ein.'
      })
    }
  };
  console.log('window.location.href: ', window.location.href);
  // Render html
  return (
    <Container size="xl" style={{padding: 0, margin: 0}}>
      <Grid sx={{ height: "100vh", margin: 0, width: '100vw' }} align="center">

        {/* Slider */}
        <Grid.Col xs={12} md={4} sx={(theme) => ({ [`@media (max-width: ${theme.breakpoints.md}px)`]: { display: 'none' }, 
        height: "100%", padding: '0px 0px', background: '#F7F7FA' })}>
          {/* <SignUpSlider /> */}
          {/* <Stack spacing={36}>
            <Group style={{marginTop: '110px', padding: '0px 80px'}}>
              <Text size="lg" weight={600} style={{fontSize: '24.5px'}} color="#13135C">Nebenkostenabrechnung einfach gemacht.</Text>
            </Group>
            <Group>
              <Image width={'100%'} style={{position: 'absolute', top: 180, left: 0}} src={`${process.env.PUBLIC_URL}/nebenkosten.png`} alt="Nebenkosten" />
            </Group>
          </Stack> */}
          <Stack spacing={36}>
            <Group>
              <Image width={'44%'} style={{position: 'absolute', top: nebenkostenabrechnung ? 110 : 102, left: 0}} src={`${process.env.PUBLIC_URL}/${nebenkostenabrechnung ? 'nebenkosten.png' : 'signup-image.png'}`} alt="Nebenkosten" />
            </Group>
          </Stack>
        </Grid.Col>

        {/* Form */}
        <Grid.Col xs={12} sm={7} offsetSm={3} md={4} offsetMd={2} sx={(theme) => ({ [`@media (max-width: ${theme.breakpoints.md}px)`]: { paddingTop: 48, paddingBottom: 48 }, background: 'white' })}>
          <Stack align="center" justify="center" spacing={48}>
            {/* <Image width={155} src={`${process.env.PUBLIC_URL}/hello-housing-logo.svg`} alt="Hello Housing logo" /> */}
            
            <Stack spacing={36} sx={{ marginBottom: 56 }}>
              
              <form onSubmit={form.onSubmit((values) => handleFormSubmit(values))} id={'hellohousing-signup-form'} style={{ position: 'relative' }}>
                <LoadingOverlay visible={loading} />
                
                {/* Welcome text */}
                {success === null && (
                  <Stack spacing={0} sx={{ marginBottom: 40 }}>
                    <Text size="lg" weight={700} style={{fontSize: '30px'}} color="#13135C">Jetzt registrieren!</Text>
                    <Text size="sm" color="#13135C" style={{fontSize: '17px'}}>{nebenkostenabrechnung ? 'Erstellen Sie Ihr kostenloses Vermieter Konto, um Nebenkostenabrechnungen zu erstellen.' : 'Erstellen Sie Ihr kostenloses Vermieter Konto, um ihre Immobilie mit der hellohousing WebApp zu verwalten.'}</Text>
                  </Stack>
                )}

                {/* Form */}
                <Stack spacing={12}>

                  {/* Display API related errors */}
                  {apiError && !success && !emailValidateSuccess && (
                    <Alert color="red" title="Fehler" icon={<WarningRounded />}>
                      {apiError}
                    </Alert>
                  )}

                  {/* // TODO: Uncomment to activate email verification */}
                  {/* Display success message */}
                  {/* {success && (
                    <Card shadow="xs" p={24}>
                      <Stack spacing={4}>
                        <Text size="lg" weight={700} color="#13135C">Validate Email!</Text>
                        <Text size="sm" color="#13135C">Enter 6 digit code sent to your email address.</Text>
                        <TextInput
                            label="Passcode"
                            value={passcode}
                            onChange={(e)=> setPasscode(e.target.value)}
                        />
                        <Button component="a" //variant="gradient" 
                        onClick={()=> verifyEmail()} 
                        //gradient={{ from: '#5555FF', to: '#4949F5', deg: 90 }} 
                        style={{background: '#13135C'}}
                        sx={{ "&:hover": { color: "white", textDecoration: "none" } }} mt={24}>Validate</Button>
                      </Stack>
                    </Card>
                  )} */}

                  {/* Display success message */}
                  {/* // TODO: Uncomment to activate email verification */}
                  {emailValidateSuccess && ( //emailValidateSuccess &&
                    <Card shadow="xs" p={24}>
                      <Stack spacing={4}>
                        <Text size="lg" weight={700} color="#13135C">Validate Email!</Text>
                        <Text size="sm" color="#13135C">Enter 6 digit code sent to your email address.</Text>
                        <TextInput
                            label="Passcode"
                            value={passcode}
                            onChange={(e)=> setPasscode(e.target.value)}
                        />
                        <Button component="a" //variant="gradient" 
                        onClick={()=> verifyEmail()} 
                        //gradient={{ from: '#5555FF', to: '#4949F5', deg: 90 }} 
                        style={{background: '#13135C'}}
                        sx={{ "&:hover": { color: "white", textDecoration: "none" } }} mt={24}>Validate</Button>
                      </Stack>
                    </Card>
                  )}

                  {/* Display success message */}
                  {success && (
                    <Card shadow="xs" p={24}>
                      <Stack spacing={4}>
                        <Text size="lg" weight={700} color="#13135C">Sie haben es geschafft!</Text>
                        <Text size="sm" color="#13135C">Ihr Konto wurde erstellt - jetzt können Sie sich einloggen.</Text>
                        <Button component="a" href="/login" //variant="gradient" 
                        onClick={()=> window.Intercom('shutdown')} 
                        //gradient={{ from: '#5555FF', to: '#4949F5', deg: 90 }} 
                        style={{background: '#13135C'}}
                        sx={{ "&:hover": { color: "white", textDecoration: "none" } }} mt={24} type="submit">Einloggen</Button>
                      </Stack>
                    </Card>
                  )}

                  {/* Hide form on success */}
                  {success === null && (
                    <>
                      <TextInput type="email" label="E-Mail Adresse" styles={{ label: { color: "#727293" } }} required {...form.getInputProps('email')} />

                      {/* Password input with strength popover */}
                      <Popover
                        opened={passwordPopoverOpened}
                        target={
                          <PasswordInput 
                            label="Passwort" {...form.getInputProps('password')}
                            styles={{ label: { color: "#727293" } }}
                            visibilityToggleIcon={({ reveal, size }) =>
                              reveal ? <EyeOpened size={size} /> : <EyeOpened size={size} />
                            }
                            required 
                          />
                        }
                        position={window.innerWidth < 993 ? "top" : "left"}
                        placement="center"
                        trapFocus={false}
                        transition="pop"
                        onFocusCapture={() => setPasswordPopoverOpened(true)}
                        onBlurCapture={() => setPasswordPopoverOpened(false)}
                        styles={(theme) => ({
                          popover: { width: '100%', backgroundColor: theme.colors.brandDarkText },
                          arrow: { backgroundColor: theme.colors.brandDarkText, border: 'none' },
                          body: { border: 'none' }
                        }) }
                        withArrow
                      >
                        <Progress color={color} value={strength} size={7} style={{ marginBottom: 16, border: '1px solid white' }} />
                        <PasswordRequirement label="Mindestens 8 Zeichen lang sein" checks={form.getInputProps('password').value.length > 8} />
                        {checks}
                      </Popover>

                      <PasswordInput 
                        label="Dev Passwort" {...form.getInputProps('devPassword')}
                        styles={{ label: { color: "#727293" } }}
                        visibilityToggleIcon={({ reveal, size }) =>
                          reveal ? <EyeOpened size={size} /> : <EyeOpened size={size} />
                        }
                        required 
                      />

                      <Checkbox
                        sx={{ alignItems: "flex-start", "input": { marginTop: 4, background: '#F7F7FA' }, "svg": { top: 4 } }}
                        label={<Text size="sm" color={'#13135C'} sx={{ cursor: 'pointer', fontSize: '15px' }}>Mit der Anmeldung zu hellohousing erkläre mich mit den <Anchor href="https://hellohousing.de/agb/">AGB</Anchor>s, <Anchor href="https://hellohousing.de/avv/">AVV</Anchor>s und der <Anchor href="https://hellohousing.de/datenschutz/">Datenschutzerklärung</Anchor> einverstanden.</Text>}
                        required
                        {...form.getInputProps('terms', { type: 'checkbox' })}
                      />
                      <Button //variant="gradient" //gradient={{ from: '#5555FF', to: '#4949F5', deg: 90 }} 
                      className="register-hellohousing-user-nebenkostenabrechnung"
                      style={{width: '120px', background: '#13135C'}} type="submit">Registrieren</Button>

                      <Group position="apart" sx={{ marginTop: 24 }}>
                        <Text size="sm" sx={{fontSize: '15px', color: '#13135C', fontWeight: '400'}}>Sie haben bereits ein Konto? <Anchor href="/login" style={{fontWeight: '500'}}>Hier einloggen!</Anchor></Text>
                        
                      </Group>
                    </>
                  )}
                </Stack>
                  
              </form>
              
            </Stack>
          </Stack>
        </Grid.Col>

      </Grid>
    </Container>
  );
};

export default SignUp;