import * as ActionTypes from '../../types/general';
const initialState = {
  property: {},
  profile: {},
  allProperties: [],
  profilePic: '',
  updatedPropertyData: [],
  userDataSignup: {},
  verificationStatus: null,
  account_api_id: null,
  userInfo:{},
  stateModal:false,
  fuelTypes: [],
  meterReadingsToPrint: [],
  subscription: [],
  currentSubscription: null,
  currentSubscriptionWithPaymentError: null,
  companyLogoEnable: false,
  companyLogo: null,
  accountData: null,
  selectedSubscriptionForPaymentConfirmation: null,
  // Document Mgmt.
  addFileModal: false,
  files: [],
  currentFolderId: null,
  sharedFolder: false,
  refreshDocumentsData: null,
  fileType: 'General',
  referencedObjectId: null,
  // Tenant Document Mgmt.
  addTenantDocument: false,
  tenantFiles: [],
  tenantCurrentFolderId: null,
  refreshTenantDocumentsData: null,
  // Receipt Document Mgmt.
  addReceiptDocument: false,
  receiptFiles: [],
  receiptCurrentId: null,
  refreshReceiptDocumentsData: null,
  publishMessageId: null,
  refreshMessagesData: null,
  currentMessageData: null,
  runUpdateTenantOccupations: true,
  runUpdateReceiptAssignment: true,
  runUpdateConsumptions: true,
  runUpdateHeatingCostSplit: true,
  selectedTaxYearForTaxDeclaration: null
};

export const generalReducer = (state = initialState, action) => {

  console.log('action: ', action.type);
  switch (action.type) {
    case ActionTypes.ACCOUNT_INFO_APIDATA:
      return { ...state, account_api_id: action.payload };

    case ActionTypes.CUSTOM_MODAL_STATE:
      return { ...state, stateModal: action.payload };
      
    case ActionTypes.STEPS_COUNTER_SIGNUP:
      return { ...state, step: action.payload };

    case ActionTypes.USER_INFO_SIGNUP:
      return { ...state, userDataSignup: { ...state.userDataSignup, ...action.payload } };

    case ActionTypes.CLEAR_USER_INFO_SIGNUP:
      return { ...state, userDataSignup: {} };

    case ActionTypes.VERIFICATION_STATUS:
      return { ...state, verificationStatus: action.payload };

    case ActionTypes.NEW_PICTURE_PROPERTY_DATA:
      return { ...state, updatedPropertyData: action.payload };

    case ActionTypes.CHANGE_PROPERTY:
      return { ...state, property: action.payload };

    case ActionTypes.PROFILE_PICTURE:
      return { ...state, profilePic: action.payload };

    case ActionTypes.SET_PROFILE_DATA:
      return { ...state, profile: action.payload };

    case ActionTypes.SET_PROPERTY_LIST:
      return { ...state, allProperties: action.payload };

    case ActionTypes.SET_FUEL_TYPES_LIST:
    return { ...state, fuelTypes: action.payload };

    case ActionTypes.SET_DELETE_PROPERTY_DATA:
      // console.log('reducer delete property')
      const new_filtered_array = state.allProperties.filter((item) => item.id != action.payload);
      // console.log('reducer new array', state.allProperties.filter((item) => item.id != action.payload));
      return {
        ...state,
        allProperties: new_filtered_array,
        property: new_filtered_array.length > 0 ? new_filtered_array[0] : {},
      };

    case ActionTypes.ADD_NEW_PROPERTY_DATA:
      // console.log(action.payload, 'payload');
      let add_new_Property = [...state.allProperties];
      add_new_Property.push(action.payload);
      return { ...state, allProperties: add_new_Property };

    case ActionTypes.SET_UPDATE_PROPERTY_DATA:
      // console.log(action.payload, 'payload');
      // console.log(state, 'state');
      let editIndex;
      let new_allProperty = [...state.allProperties];
      for (let i = 0; i < new_allProperty.length; i++) {
        if (new_allProperty[i].id === action.payload.id) {
          editIndex = i;
          break;
        }
      }
      new_allProperty[editIndex] = action.payload;
      return { ...state, allProperties: new_allProperty };

    case ActionTypes.RESET_DATA:
      return {
        ...state,
        profile: {},
        property: {},
        allProperties: [],
      };
    case ActionTypes.UPDATE_USER_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      };
    case ActionTypes.UPDATE_PROPERTY_INFO:
      return {
        ...state,
        property: {
          ...state.property,
          ...action.payload,
        },
      };
    case ActionTypes.UPDATE_PROFILE_PICTURE:
      return {
        ...state,
        profile: {
          ...state.profile,
          profilePicture: action.payload
        }
      }
    case ActionTypes.METER_READINGS_TO_PRINT:
      console.log('METER_READINGS_TO_PRINT: ', action.payload);
      return {
        ...state,
        meterReadingsToPrint: action.payload
      }

    case ActionTypes.SET_SUBSCRIPTION_DATA:
      return { ...state, subscription: action.payload };
    case ActionTypes.SET_CURRENT_SUBSCRIPTION_DATA:
      return { ...state, currentSubscription: action.payload };
    case ActionTypes.SET_CURRENT_SUBSCRIPTION_WITH_PAYMENT_ERROR_DATA:
      return { ...state, currentSubscriptionWithPaymentError: action.payload };
    case ActionTypes.SET_SELECTED_SUBSCRIPTION_FOR_PAYMENT_CONFIRMATION:
      return { ...state, selectedSubscriptionForPaymentConfirmation: action.payload };
    case ActionTypes.UPDATE_SUBSCRIPTION_DATA:
    return {
      ...state,
      subscription: {
        ...state.subscription,
        ...action.payload,
      },
    };
    case ActionTypes.SET_COMPANY_LOGO:
      return { ...state, companyLogoEnable: action.payload };
    case ActionTypes.UPDATE_COMPANY_LOGO:
      return { ...state, companyLogo: action.payload };
    case ActionTypes.SET_ACCOUNT_DATA:
      return { ...state, accountData: action.payload };
    //DOCUMENTS MGMT.
    case ActionTypes.ADD_FILE_MODAL:
      return { ...state, addFileModal: action.payload };
    case ActionTypes.SET_FILES:
      return { ...state, files: action.payload };
    case ActionTypes.SET_CURRENT_FOLDER_ID:
      return { ...state, currentFolderId: action.payload };
    case ActionTypes.SET_SHARED_FOLDER:
    return { ...state, sharedFolder: action.payload };
    case ActionTypes.SET_REFRESH_DOCUMENTS_DATA:
      return { ...state, refreshDocumentsData: action.payload };
    //TENANT DOCUMENTS
    case ActionTypes.ADD_TENANT_DOCUMENT:
      return { ...state, addTenantDocument: action.payload };
    case ActionTypes.SET_TENANT_FILES:
      return { ...state, tenantFiles: action.payload };
    case ActionTypes.SET_TENANT_CURRENT_FOLDER_ID:
      return { ...state, tenantCurrentFolderId: action.payload };
    case ActionTypes.SET_REFRESH_TENANT_DOCUMENTS_DATA:
      return { ...state, refreshTenantDocumentsData: action.payload };
    case ActionTypes.SET_FILE_TYPE:
      return { ...state, fileType: action.payload };
    //RECEIPT DOCUMENTS
    case ActionTypes.ADD_RECEIPT_DOCUMENT:
      return { ...state, addReceiptDocument: action.payload };
    case ActionTypes.SET_RECEIPT_FILES:
      return { ...state, receiptFiles: action.payload };
    case ActionTypes.SET_RECEIPT_CURRENT_ID:
      return { ...state, receiptCurrentId: action.payload };
    case ActionTypes.SET_REFRESH_RECEIPT_DOCUMENTS_DATA:
      return { ...state, refreshReceiptDocumentsData: action.payload };
    //Messages
    case ActionTypes.SET_PUBLISH_MESSAGE_ID:
      return { ...state, publishMessageId: action.payload };
    case ActionTypes.SET_REFRESH_MESSAGES_DATA:
      return { ...state, refreshMessagesData: action.payload };
    case ActionTypes.SET_CURRENT_MESSAGE_DATA:
      return { ...state, currentMessageData: action.payload };
    case ActionTypes.SET_RUN_UPDATE_TENANT_OCCUPATIONS:
      return { ...state, runUpdateTenantOccupations: action.payload };
    case ActionTypes.SET_RUN_UPDATE_RECEIPT_ASSIGNMENT:
      return { ...state, runUpdateReceiptAssignment: action.payload };
    case ActionTypes.SET_RUN_UPDATE_CONSUMPTIONS:
      return { ...state, runUpdateConsumptions: action.payload };

    case ActionTypes.SET_RUN_UPDATE_HEATING_COST_SPLIT:
      return { ...state, runUpdateHeatingCostSplit: action.payload };

    case ActionTypes.SET_SELECTED_TAX_YEAR_FOR_TAX_DECLARATION:
      return { ...state, selectedTaxYearForTaxDeclaration: action.payload };
    default:
      return state;
  }
};
