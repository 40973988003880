import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import avatar4 from '../../../../assets/images/module/general/house-log.png';
import UnitsIcon from '../../../../assets/images/module/general/UnitsIcon.svg';
import BlueUnitsIcon from '../../../../assets/images/module/general/BlueEmptyTenant.svg';
import AreaIcon from '../../../../assets/images/module/general/AreaIcon.svg';
import CalendarIcon from '../../../../assets/images/module/general/CalendarIcon.svg';
import AddIcon from '../../../../assets/images/module/general/AddIcon.svg';
import plan from '../../../../assets/images/module/general/planDashboard.svg';
import skyscraper from '../../../../assets/images/module/general/skyscraperDashboard.svg';
import DotsIcon from '../../../../assets/images/module/general/DotsIcon.svg';
import PlayIcon from '../../../../assets/images/module/general/PenIcon.svg';
import GreyIcon from '../../../../assets/images/EmptyReciept.svg';
import PartnerDashboardIcon from '../../../../assets/images/module/general/PartnerDashboardIcon.svg';
import meterLogoEmpty from '../../../../assets/images/module/general/blueEmptyMeter.svg';
import utilitylogoEmpty from '../../../../assets/images/module/general/TapIconBlue.svg';
import HelpLogo from '../../../../assets/images/module/general/book.svg';
import Hlogo from '../../../../assets/images/module/general/house-log.png';
import AddPropertyModal from '../../../../neoverv/components/Module/General/CustomModal/AddPropertyModal';
import { ImageModal, MeterModal, ShowModal } from '../../../../neoverv';
import GeneralDataHandler from '../../../../neoverv/helpers/DataHandler/General';
import TenantInfo from '../User/TenantInfo';
import de from 'date-fns/locale/de';
import {
  setUtilityBillingData,
} from '../../../../redux/action/UtilityBilling/UtilityBillingActions';
import {
  getRentalUnitTenants,
  setCurrentTenant,
  updateFilteredList,
  DeleteUnit,
} from '../../../../redux/action/Tenant/TenantActions';
import { Account_info } from '../../../../redux/action/General/generalActions';
import {
  CustomImageCard,
  InfoField,
  CustomCard,
  BorderlessTable,
  TransparentButton,
  ColorButton,
  HeadingContainer,
  TiledContent,
  ReceiptDetailModal,
  EmptyStateDashboard,
  RentalUnitModal,
  RecordOptions,
  TenantsRecord,
  DeleteModal,
  MeterRecordOptions,
} from '../../../../neoverv';
import moment from 'moment';
import {
  RECEIPT_ROUTE,
  UTILITY_BILLING_ROUTE,
  METER_ROUTE,
  PROPERTY_ROUTE,
  TENANT_ROUTE,
  PARTNER_ROUTE,
} from '../../../../routes/module/RouteName';
import { connect } from 'react-redux';
import Autoload from '../../../../neoverv/Autoload';
import GlobalServices from '../../../../neoverv/helpers/services/GlobalServices';
import MeterDataHandler from '../../../../neoverv/helpers/DataHandler/Meter';
import UtilityBillingDataHandler from '../../../../neoverv/helpers/DataHandler/UtilityBillings';
import { Group, Skeleton } from '@mantine/core';

import { userData } from '../../../../helpers/userData';
import PropertyDetailsCard from '../../../../routes/dashboard/PropertyDetailsCard';
import { Progress } from '@mantine/core';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import firstStepContent from '../../../../../src/assets/first-step-content.json';
import UtilityBillingAssignedIcon from '../../../../neoverv/components/SVGs/utilityBillingAssigned';
import oneIcon from '../../../../assets/images/module/firstStepSectionIcons/1.svg'
import twoIcon from '../../../../assets/images/module/firstStepSectionIcons/2.svg'
import threeIcon from '../../../../assets/images/module/firstStepSectionIcons/3.svg'
import fourIcon from '../../../../assets/images/module/firstStepSectionIcons/4.svg'
import fiveIcon from '../../../../assets/images/module/firstStepSectionIcons/5.svg'
import sixIcon from '../../../../assets/images/module/firstStepSectionIcons/6.svg'
import sevenIcon from '../../../../assets/images/module/firstStepSectionIcons/7.svg'
import AddPropertyTypeModal from '../../../../neoverv/components/Module/General/CustomModal/AddPropertyTypeModal';
import { formatNumber } from '../../../../helpers/stringsManipulations';
import PartnerContent from '../../../../assets/partner-content.json';
import dayjs from 'dayjs';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty, loader, filterByDateUtility } = Autoload.MainHelper;
let rentalListData = [];
const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;
var utilityArr = [];
var currentBillingPeriod = {};
// var tenantID = '';
// var tenantObj = '';
// moment.locale('de');
var utilityArr = [];
var lastBillingPeriod = {};
class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      utilityBillingValues: false,
      addReceiptOpen: false,
      receiptsData: [
        { name: '', date: '', 
        //cost: '' 
      },
        { name: '', date: '', 
        //cost: '' 
      },
        { name: '', date: '', 
        //cost: '' 
      },
      ],
      rentalUnitsData: [],
      propertyImage: '',
      addUnitModal: false,
      utilityBillingRecords: [],
      meterBillingPeriods: [],
      deleteUnitModal: false,
      editUnit: false,
      editUnitModal: false,
      currentUnit: {},
      lastUtilityBillingData: {},
      currentBillingPeriodData: {},
      addPropertyModal: false,
      openMeterModal: false,
      newMeterData: null,
      rentalUnits: [],
      tenant: {},
      tenantID: '',
      lastBillingDataId: '',
      startUtility: false,
      currentBillingPeriodDate: '',
      currentBillingPeriod: null,
      lastBillingPeriod: null,
      lastBillingPeriodDate: '',
      BillingStatmentstenants: [],
      otherBillingPeriods: [],
      filteredReadings: [],
      isTablet: window.innerWidth <= TABLET_SIZE,
      isMobile: window.innerWidth <= MOBILE_SIZE,
      showModal: false,
      currentRecord: {},
      ImageOpen: false,
      editReceiptOpen: false,
      expanded: false,
      subExpanded: false,
      validateFirstSection: {
        property: false,
        rentalUnit: false,
        tenant: false,
        meter: false,
        meterReading: false,
        receipt: false,
        utilityBilling: false
      },
      firstStepIcons: [
        oneIcon,
        twoIcon,
        threeIcon,
        fourIcon,
        fiveIcon,
        sixIcon,
        sevenIcon
      ],
      skeletonLoading: false,
      skeletonUtilityLoading: false,
      skeletonMeterLoading: false,
      skeletonRentalUnitsLoading: false,
      skeletonReceiptLoading: false,
      addPropertyTypeModal: false,
      selectedPropertyType: '',
      dahsboardPartner: null
      // tenantId: '',
      // deleteUnitModal: false,
      // editUnit: false,
      // editUnitModal: false,
    };
    this.UtilityDataHandler = new UtilityBillingDataHandler();
  }
  seeAllOption = () => {
    return <h5 className="text-bold text-nl dark-purple-color mb-0">{lang.newKeys.text_see_all}</h5>;
  };
  toggleAddPropertyModal = () => {
    this.setState({ addPropertyModal: !this.state.addPropertyModal });
  };

  handleResize = () => {

    // TODO: this causes data refetch on the meters reading details screen...
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
    if (window.innerWidth <= TABLET_SIZE) {
      this.setState({ isTablet: true });
    } else {
      this.setState({ isTablet: false });
    }
    
  };

  componentDidMount() {
    
    window.Intercom("update", {
      api_base: "https://api-iam.intercom.io",
      app_id: "vvrs5bx3",
      'Number of properties': this.props.properties.length.toString(),
      'Number of condominiums': this.props.properties.filter((property)=> property.propertyType === 'CONDOMINIUM').length.toString(),
    });
    window.addEventListener('resize', this.handleResize);
    // console.log('propts', this.props)
    // this.props.Account_info(true);
    this.setState({
      skeletonLoading: true,
      skeletonUtilityLoading: true,
      skeletonMeterLoading: true,
      skeletonRentalUnitsLoading: true,
      skeletonReceiptLoading: true,
      dahsboardPartner: PartnerContent[Math.floor(Math.random()* (3 - 0) + 0)]
    })
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none'; //this.props.propertyData && Object.keys(this.props.propertyData).length === 0 ? 'none' : 'block';
    if (this.props.propertyData && typeof this.props.propertyData.cutOffDate == 'string') {
      // console.log(this.props.propertyData.cutOffDate.split(' '));
      // var cutOfPropertyDate = this.props.propertyData.cutOffDate.split(' ');
      let cutOffDateString = {
        day: parseInt(this.props.propertyData.cutOffDate.split(' ')[0]),
        month: this.props.propertyData.cutOffDate.split(' ')[1],
      };
      this.setState({
        cutOffDateString: cutOffDateString,
      });
    }
    if(this.props.propertyData && Object.keys(this.props.propertyData).length !== 0){
      this.bind_list_receipt_data(this);
      this.getRentalUnits();
      //this.getPropertyImage();
      this._fetchPropertyMeters();
      //this._fetchPropertyMeterReadings();
      // this._getCurrentUtillityBillings();
      this._getLastUtillityBillings();
      // this.meterMaster();
      // this.checkUtilityData();
      GlobalServices._getBilingPeriodsByProperty(this, '_handleMeterBillingPeriodResponse', this.props.propertySelected);
      GlobalServices._getRentalUnitsByProperty(this, '_handle_rental_unit_response', this.props.propertySelected);
    } else {
      setTimeout(() => {
        this.ShowLoader();
        loader.style.display = 'none';
      }, 1000);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    // this.checkUtilityData();
    if (prevProps.propertySelected !== this.props.propertySelected) {
      window.Intercom("update", {
        api_base: "https://api-iam.intercom.io",
        app_id: "vvrs5bx3",
        'Number of properties': this.props.properties.length.toString(),
        'Number of condominiums': this.props.properties.filter((property)=> property.propertyType === 'CONDOMINIUM').length.toString(),
      });
      // var loader = document.getElementById('hello-housing-loader');
      // loader.style.display = 'block';
      // console.log(this.props.propertyData, 'this.props.propertyData');
      //this.bind_list_receipt_data(this);
      // this.getRentalUnits();
      // this.getPropertyImage();
      // // this._getCurrentUtillityBillings();
      // this._getLastUtillityBillings();
      // this.meterMaster();
      // GlobalServices._getBilingPeriodsByProperty(
      //   this,
      //   '_handleMeterBillingPeriodResponse',
      //   this.props.propertySelected
      // );
      //GlobalServices._getBilingPeriodsByProperty(this, '_handleMeterBillingPeriodResponse', this.props.propertySelected);
      //GlobalServices._getRentalUnitsByProperty(this, '_handle_rental_unit_response', this.props.propertySelected);
    }
  }
  //@desc rental units response handler
  _handle_rental_unit_response(response) {
    // if (response.status === false && response.message !== 'Not Found') {
    //   // Autoload.Redirection.redirection_error();
    //   Autoload.DataHandlerGeneral.GeneralErrorMessage(
    //     true,
    //     lang.newKeys.text_error_not_found
    //     // + response.message
    //   );
    // }
    const rentalUnits = GeneralDataHandler.getRantalUnits(response.result);
    this.setState({ rentalUnits });
  }
  meterMaster() {
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.meter_api_base_url + 'meters?propertyId=' + propertyid + '&offset=0&results=100',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handlePropertyMeters',
      null,
      false,
      ''
    );
  }
  // _handlePropertyMeters(response) {
  //   // console.log(response, '_handlePropertyMeters');
  //   if (response.result.length > 0) {
  //     GlobalServices._getBilingPeriodsByProperty(
  //       this,
  //       '_handleMeterBillingPeriodResponse',
  //       this.props.propertySelected
  //     );
  //   }
  // }
  _handleMeterBillingPeriodResponse(response) {
    // console.log(response, '_handleMeterBillingPeriodResponse');

    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      let billingPeriods = MeterDataHandler.generateBilingPeriods(response.result);
      billingPeriods = billingPeriods
      // .filter((value, index, self) =>
      // index === self.findIndex((t) => (
      //   t.startDate === value.startDate && t.endDate === value.endDate
      // )))
      .map((billingPeriod) => ({
        ...billingPeriod,
        date: `${moment(checkProperty(billingPeriod, 'startDate')).format('DD.MM.YYYY')} - ${moment(
          checkProperty(billingPeriod, 'endDate')
        ).format('DD.MM.YYYY')}`,
      }));

      this.setState({ meterBillingPeriods: billingPeriods, otherBillingPeriods: billingPeriods.filter((item, index)=> (index !== 0 || index !== 1) && item)}, () => this._getUtillityBillings());
    }else{
      this.setState({skeletonMeterLoading: false});
    }
  }

  _getUtillityBillings = () => {
    // loader(true);
    utilityArr = [];
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'currentBillingPeriod?propertyId=' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleCurrentBillingPeriod',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  _handleCurrentBillingPeriod = (response) => {
    console.log('current utility billing api in utility ', response);
    if (response.status) {
      currentBillingPeriod = MeterDataHandler.generateBilingPeriodsObject(response.result, 'current');
      const billingPeriodStart = checkProperty(currentBillingPeriod, 'startDate');
      const billingPeriodEnd = checkProperty(currentBillingPeriod, 'endDate');

      this.setState({
        currentBillingPeriodDate: `${moment(billingPeriodStart).format('DD.MM.YYYY')} - ${moment(billingPeriodEnd).format('DD.MM.YYYY')}`,
        currentBillingPeriod
      });
    }
    if (response.status === false) {
      Autoload.Redirection.redirection_error();
    }
    //data for last billing period
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'lastBillingPeriod?propertyId=' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleLastBillingPeriod',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  _handleLastBillingPeriod = (response) => {
    if (response.status && response.statuscode === 200) {
      console.log('last', response.result)
      lastBillingPeriod = MeterDataHandler.generateBilingPeriodsObject(response.result, 'last');

      const billingPeriodStart = checkProperty(lastBillingPeriod, 'startDate');
      const billingPeriodEnd = checkProperty(lastBillingPeriod, 'endDate');

      this.setState({
        lastBillingPeriodDate: `${moment(billingPeriodStart).format('DD.MM.YYYY')} - ${moment(billingPeriodEnd).format('DD.MM.YYYY')}`,
        lastBillingPeriod
      });
    }



    // const otherBillingPeriods = this.state.meterBillingPeriods.filter(bp => bp.id !== currentBillingPeriod.id && bp.id !== lastBillingPeriod.id)
    // console.log(otherBillingPeriods)
    // this.setState({ otherBillingPeriods })

    if (response.status === false) {
      Autoload.Redirection.redirection_error();
    }

    //get all utilities
    //loader(true)
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'utilityBillings?PropertyId=' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleUtillityBilling',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };


  _handleUtillityBilling = (response) => {
    console.log(response, 'all utilities');
    // billingPeriods: _billingPeriods,
    //
    //loader(true)
    // if (response.status && response.statuscode === 200) {
    //   this.setState({
    //     validateFirstSection:{
    //       ...this.state.validateFirstSection,
    //       utilityBilling: response.result.length > 0
    //     },
    //   }, ()=> setTimeout(() => {
    //     this.setState({skeletonUtilityLoading: false});
    //   }, 800))
    //   // current billing periods
    //   if (Object.keys(this.state.currentBillingPeriod).length > 0) {
    //     let currentFound = {};

    //     currentFound = response.result.find(
    //       (record) => record.billingPeriod.id === this.state.currentBillingPeriod.id
    //     );

    //     if (currentFound && Object.keys(currentFound).length > 0) {
    //       utilityArr.push({
    //         ...this.state.currentBillingPeriod,
    //         ...this.UtilityDataHandler.createUtilityBillingObject(currentFound, 'current'),
    //       });
    //     } else {
    //       const date = this.state.currentBillingPeriod.startDate + ' - ' + this.state.currentBillingPeriod.endDate;
    //       utilityArr.push({
    //         billingPeriodId: this.state.currentBillingPeriod.id,
    //         propertyId: this.state.currentBillingPeriod.propertyId,
    //         startDate: this.state.currentBillingPeriod.startDate,
    //         endDate: this.state.currentBillingPeriod.endDate,
    //         type: 'current',
    //         recordStatus: 'current',
    //         date
    //       });
    //     }
    //   }
      
    //   // last billing periods
    //   if (Object.keys(this.state.lastBillingPeriod).length > 0) {
    //     let lastFound = {};

    //     lastFound = response.result.find(
    //       (record) => record.billingPeriod.id === this.state.lastBillingPeriod.id
    //     );

    //     if (lastFound && Object.keys(lastFound).length > 0) {
    //       utilityArr.push({
    //         ...this.state.lastBillingPeriod,
    //         ...this.UtilityDataHandler.createUtilityBillingObject(lastFound, 'last'),
    //       });
    //     } else {
    //       const date = this.state.lastBillingPeriod.startDate + ' - ' + this.state.lastBillingPeriod.endDate;
    //       utilityArr.push({
    //         billingPeriodId: this.state.lastBillingPeriod.id,
    //         propertyId: this.state.lastBillingPeriod.propertyId,
    //         startDate: this.state.lastBillingPeriod.startDate,
    //         endDate: this.state.lastBillingPeriod.endDate,
    //         type: 'last',
    //         recordStatus: 'last',
    //         date
    //       });
    //     }
    //   }
      

    //   if (response.result.length > 1) {
    //     let filterLastCurrent = response.result.filter(
    //       (record) => record.billingPeriod.id !== this.state.currentBillingPeriod.id && record.billingPeriod.id !== this.state.lastBillingPeriod.id
    //     );

    //     utilityArr = [
    //       ...utilityArr,
    //       ...filterLastCurrent.map((billingRow) => {

    //         return ({
    //           ...this.UtilityDataHandler.createUtilityBillingObject(billingRow),
    //           startDate: billingRow.billingPeriod.startDate,
    //           endDate: billingRow.billingPeriod.endDate,
    //         })
    //       }),
    //     ];
    //   } else if (this.state.otherBillingPeriods.length) {
    //     console.log('okokokokokokok')
    //     let filterLastCurrent = response.result.filter(
    //       (record) => record.billingPeriod.id !== this.state.currentBillingPeriod.id && record.billingPeriod.id !== this.state.lastBillingPeriod.id
    //     );



    //     if (filterLastCurrent.length) {
    //       console.log('filterLastCurrent')
    //       utilityArr = [
    //         ...utilityArr,
    //         ...filterLastCurrent.map((billingRow) => {

    //           return ({
    //             ...this.UtilityDataHandler.createUtilityBillingObject(billingRow),
    //             startDate: billingRow.billingPeriod.startDate,
    //             endDate: billingRow.billingPeriod.endDate,
    //           })
    //         }),
    //       ];
    //     } else {
    //       console.log('NofilterLastCurrent')
    //       const otherBillingPeriods = this.state.otherBillingPeriods.map(bp => ({
    //         billingPeriodId: bp.id,
    //         propertyId: bp.propertyId,
    //         startDate: bp.startDate,
    //         endDate: bp.endDate,
    //         type: bp.type,
    //         date: bp.startDate + ' - ' + bp.endDate,
    //         recordStatus: bp.type
    //       }))
    //       utilityArr = [...utilityArr, ...otherBillingPeriods]
    //     }
    //   }
    //   console.log(utilityArr)
    //   this.setState({ filteredReadings: utilityArr }, () => this.getBillingStatements());
    // }

    console.log('otherBillingPeriods: ', this.state.otherBillingPeriods);
    console.log('currentBillingPeriod1: ', this.state.currentBillingPeriod);
    console.log('lastBillingPeriod1: ', this.state.lastBillingPeriod);
    if (response.status && response.statuscode === 200) {
      // last billing period
      if (this.state.lastBillingPeriod && Object.keys(this.state.lastBillingPeriod).length > 0) {
        let lastFound = {};

        lastFound = response.result.find(
          (record) => record.billingPeriod.id === this.state.lastBillingPeriod.id
        );

        if (lastFound && Object.keys(lastFound).length > 0) {
          utilityArr.push({
            ...this.state.lastBillingPeriod,
            ...this.UtilityDataHandler.createUtilityBillingObject(lastFound, 'last'),
            isSecured: lastFound.isSecured,
            heatingCostsActivated: lastFound.heatingCostsActivated
          });
        } else {
          const date = this.state.lastBillingPeriod.startDate + ' - ' + this.state.lastBillingPeriod.endDate;
          utilityArr.push({
            billingPeriodId: this.state.lastBillingPeriod.id,
            propertyId: this.state.lastBillingPeriod.propertyId,
            startDate: this.state.lastBillingPeriod.startDate,
            endDate: this.state.lastBillingPeriod.endDate,
            type: 'last',
            recordStatus:'last',
            date,
            isSecured: this.state.lastBillingPeriod.isSecured,
            heatingCostsActivated: this.state.lastBillingPeriod.heatingCostsActivated
          });
        }
      }
      // current billing period
      if (this.state.currentBillingPeriod && Object.keys(this.state.currentBillingPeriod).length > 0) {
        let currentFound = {};

        currentFound = response.result.find(
          (record) => record.billingPeriod.id === this.state.currentBillingPeriod.id
        );

        if (currentFound && Object.keys(currentFound).length > 0) {
          utilityArr.push({
            ...this.state.currentBillingPeriod,
            ...this.UtilityDataHandler.createUtilityBillingObject(currentFound, 'current'),
            isSecured: currentFound.isSecured,
            heatingCostsActivated: currentFound.heatingCostsActivated
          });
        } else {
          const date = this.state.currentBillingPeriod.startDate + ' - ' + this.state.currentBillingPeriod.endDate;
          utilityArr.push({
            billingPeriodId: this.state.currentBillingPeriod.id,
            propertyId: this.state.currentBillingPeriod.propertyId,
            startDate: this.state.currentBillingPeriod.startDate,
            endDate: this.state.currentBillingPeriod.endDate,
            type: 'current',
            recordStatus:'current',
            date,
            isSecured: this.state.currentBillingPeriod.isSecured,
            heatingCostsActivated: this.state.currentBillingPeriod.heatingCostsActivated
          });
        }
      }

      if (response.result.length > 1) {
        let filterLastCurrent = response.result.filter(
          (record) => record.billingPeriod.id !== this.state.currentBillingPeriod?.id && record.billingPeriod.id !== this.state.lastBillingPeriod?.id
        );
        console.log('otherBillingPeriods: ', filterLastCurrent);

        if(filterLastCurrent.length){
          utilityArr = [
            ...utilityArr,
            ...filterLastCurrent.map((billingRow) => {
  
              return ({
                ...this.UtilityDataHandler.createUtilityBillingObject(billingRow),
                startDate: billingRow.billingPeriod.startDate,
                endDate: billingRow.billingPeriod.endDate,
                isSecured: billingRow.isSecured,
                heatingCostsActivated: billingRow.heatingCostsActivated
              })
            }),
          ];
        } else {
            console.log('NofilterLastCurrent')
            const otherBillingPeriods = this.state.otherBillingPeriods.map(bp => ({
              billingPeriodId: bp.id,
              propertyId: bp.propertyId,
              startDate: bp.startDate,
              endDate: bp.endDate,
              type: bp.type,
              date: bp.startDate + ' - ' + bp.endDate,
              recordStatus: bp.type,
              isSecured: bp.isSecured,
              heatingCostsActivated: bp.heatingCostsActivated
            }))
            utilityArr = [...utilityArr, ...otherBillingPeriods]
        }
      } else if (this.state.otherBillingPeriods.length) {
        console.log('okokokokokokok')
        let filterLastCurrent = response.result.filter(
          (record) => record.billingPeriod.id !== this.state.currentBillingPeriod.id && record.billingPeriod.id !== this.state.lastBillingPeriod.id
        );

    

        if (filterLastCurrent.length) {
          console.log('filterLastCurrent: ', filterLastCurrent)
          utilityArr = [
            ...utilityArr,
            ...filterLastCurrent.map((billingRow) => {

              return ({
                ...this.UtilityDataHandler.createUtilityBillingObject(billingRow),
                startDate: billingRow.billingPeriod.startDate,
                endDate: billingRow.billingPeriod.endDate,
                isSecured: billingRow.isSecured,
                heatingCostsActivated: billingRow.heatingCostsActivated
              })
            }),
          ];
        } else {
          console.log('NofilterLastCurrent')
          const otherBillingPeriods = this.state.otherBillingPeriods.map(bp => ({
            billingPeriodId: bp.id,
            propertyId: bp.propertyId,
            startDate: bp.startDate,
            endDate: bp.endDate,
            type: bp.type,
            date: bp.startDate + ' - ' + bp.endDate,
            recordStatus: bp.type,
            isSecured: bp.isSecured,
            heatingCostsActivated: bp.heatingCostsActivated
          }))
          utilityArr = [...utilityArr, ...otherBillingPeriods]
        }
      }
      console.log(utilityArr)
      this.setState({ filteredReadings: utilityArr }, () => this.getBillingStatements());
    }


    else {
      if (this.state.otherBillingPeriods.length) {
        utilityArr = [
          ...utilityArr,
          {
            billingPeriodId: this.state.currentBillingPeriod.id,
            propertyId: this.state.currentBillingPeriod.propertyId,
            startDate: this.state.currentBillingPeriod.startDate,
            endDate: this.state.currentBillingPeriod.endDate,
            type: this.state.currentBillingPeriod.type,
            recordStatus: this.state.currentBillingPeriod.type
          },
          {
            billingPeriodId: this.state.lastBillingPeriod.id,
            propertyId: this.state.lastBillingPeriod.propertyId,
            startDate: this.state.lastBillingPeriod.startDate,
            endDate: this.state.lastBillingPeriod.endDate,
            type: this.state.lastBillingPeriod.type,
            recordStatus: this.state.lastBillingPeriod.type
          },
        ];
        const otherBillingPeriods = this.state.otherBillingPeriods.map(bp => ({
          billingPeriodId: bp.id,
          propertyId: bp.propertyId,
          startDate: bp.startDate,
          endDate: bp.endDate,
          type: bp.type,
          recordStatus: bp.type
        }))
        utilityArr = [...utilityArr, ...otherBillingPeriods]


      } else {
        utilityArr = [
          ...utilityArr,
          {
            billingPeriodId: this.state.currentBillingPeriod.id,
            propertyId: this.state.currentBillingPeriod.propertyId,
            startDate: this.state.currentBillingPeriod.startDate,
            endDate: this.state.currentBillingPeriod.endDate,
            type: this.state.currentBillingPeriod.type,
          },
          {
            billingPeriodId: this.state.lastBillingPeriod.id,
            propertyId: this.state.lastBillingPeriod.propertyId,
            startDate: this.state.lastBillingPeriod.startDate,
            endDate: this.state.lastBillingPeriod.endDate,
            type: this.state.lastBillingPeriod.type,
          },
        ];
      }
      this.setState({ filteredReadings: utilityArr,
      }, ()=> setTimeout(() => {
        this.setState({skeletonUtilityLoading: false});
      }, 800));
    }
    //loader(false);
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    setTimeout(() => {
      this.setState({skeletonUtilityLoading: false});
    }, 800);
    this.validationForFirstSection();
  };

  getBillingStatements = () => {
    // loader(true);
    utilityArr.map((utility, i) => {
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url + 'billingStatements?utilityBillingId=' + utility.id,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        '_handleBillingStatementResponse',
        { utilityId: utility.id, loopEnd: i === utilityArr.length - 1 },
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    });
  };
  _handleBillingStatementResponse = (response) => {
    console.log('_handleBillingStatementResponse in utility', response);
    if (response.status === false) {
      Autoload.Redirection.redirection_error();
      // Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Billing statement not found');
    }
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      const tenants = this.UtilityDataHandler.currentBillingStatementsDataHandler(response.result);
      //add tenants data in utility array
      utilityArr = utilityArr.map((utility) => {
        if (utility.id === tenants[0].utilityBillingId) {
          return {
            ...utility,
            tableData: tenants,
          };
        } else {
          return utility;
        }
      });
      // if (response.other.loopEnd) {
      // console.log('loop ed', response.other.loopEnd);
      let sortedList = filterByDateUtility(utilityArr, 'date', false);
      console.log('sortedList', sortedList);
      this.props.setUtilityBillingData(sortedList);
      // this.setState({ filteredReadings: sortedList, BillingStatmentstenants: tenants });
      this.setState({
        BillingStatmentstenants: tenants,
      });
      // this.getFiles();
      // }
    }
    if (response.other.loopEnd) {
      // loader(false);
      //this.getFiles()
    }
  };

  getFiles = () => {
    //calling api for file url of each tableData row
    utilityArr.map((utility, indexMaster) => {
      checkProperty(utility, 'tableData', []).map((tableRow, i) => {
        Autoload.HttpRequestAxios.HttpRequest(
          Autoload.Setting.ub_api_base_url + `billingStatements/${tableRow.billingStatementId}/pdf`,
          'GET',
          null,
          null,
          false,
          true,
          false,
          this,
          '_handleBillingPDFResponse',
          {
            loopEnd: i === utility - 1,
            tableRowId: tableRow.id,
            utilityId: utility.id,
            isLastResponse: indexMaster === utilityArr.length && i + 1 === utility.tableData.length,
          },
          false,
          '',
          {
            Authorization: Autoload.Utils.getAuthToken(),
          }
        );
      });
    });
  };
  _handleBillingPDFResponse(response) {
    //find utility to which this file belongs
    let utility = utilityArr.find((utility) => utility.id === response.other.utilityId);
    //find table record in that utility to which this file belongs
    let tableRow =
      utility && utility.tableData ? utility.tableData.find((tenant) => tenant.id === response.other.tableRowId) : {};
    console.log('pdfResponse : ', response);
    //add that file
    let updatedRow = {
      ...tableRow,
      hasFile: response.result && !Array.isArray(response.result) ? true : false,
      fileURL: response.result && !Array.isArray(response.result) ? response.result : '',
    };
    //replace table row
    let updatedTable =
      utility && utility.tableData
        ? utility.tableData.map((row) => {
          if (row.id === updatedRow.id) {
            return updatedRow;
          } else {
            return row;
          }
        })
        : [];
    //replace utility bill
    let updatedUtility = utilityArr.map((util) => {
      if (util.id === utility.id) {
        return {
          ...util,
          tableData: updatedTable,
        };
      } else {
        return util;
      }
    });
    utilityArr = updatedUtility;
    // if(response.other.loopEnd) {
    let sortedList = filterByDateUtility(utilityArr, 'date', false);
    console.log('datee');
    // this.props.setUtilityBillingData(sortedList);
    this.setState({ filteredReadings: sortedList });
    // }
  }




  //previousbilling
  _getCurrentUtillityBillings = (id) => {
    const propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'previousBillingPeriod?billingPeriodId=' + id,
      // Autoload.Setting.property_api_base_url + 'currentBillingPeriod?propertyId=' + propertyid,  this.state.lastBillingDataId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleCurrentUtillityBilling',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  _handleCurrentUtillityBilling = (response) => {
    console.log('_handleCurrentUtillityBilling', response);
    // if (!response.status === true && !response.statuscode === 200) {
    //   // Autoload.Redirection.redirection_error();
    //   Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Error :  ' + response.message);
    // }
    let currentPeriodData = {
      date: `${moment(checkProperty(response.result, 'startDate')).format('DD.MM.YYYY')} - ${moment(
        checkProperty(response.result, 'endDate')
      ).format('DD.MM.YYYY')}`,
    };
    // let currentPeriodData = {
    //   date: '-',
    // };

    // let formatted = response.result.map((billingRow) => ({
    //   id: checkProperty(billingRow, 'utilityBillingId'),
    //   date: `${moment(checkProperty(checkProperty(billingRow, 'billingPeriod'), 'startDate')).format(
    //     'DD.MM.YYYY'
    //   )} - ${moment(checkProperty(checkProperty(billingRow, 'billingPeriod'), 'endDate')).format('DD.MM.YYYY')}`,
    //   tableData: [],
    //   billingData: {
    //     costPosition: [],
    //     tenantBills: [],
    //     overviewData: [],
    //   },
    //   recordStatus: 'other',
    //   //extra data received from api. storing it for future use incase we need it
    //   billingPeriodStart: checkProperty(checkProperty(billingRow, 'billingPeriod'), 'startDate'),
    //   billingPeriodEnd: checkProperty(checkProperty(billingRow, 'billingPeriod'), 'endDate'),
    //   billingPeriodId: checkProperty(checkProperty(billingRow, 'billingPeriod'), 'billingPeriodId'),
    //   energyAmountRoomHeatingKWH: checkProperty(billingRow, 'energyAmountRoomHeatingKWH'),
    //   energyAmountWarmWaterKWH: checkProperty(billingRow, 'energyAmountWarmWaterKWH'),
    //   heatingCostsAllocated: checkProperty(billingRow, 'heatingCostsAllocated'),
    //   heatingEnergyAmount: checkProperty(billingRow, 'heatingEnergyAmount'),
    //   heatingEnergyAmountKWH: checkProperty(billingRow, 'heatingEnergyAmountKWH'),
    //   nonHeatingCostsAllocated: checkProperty(billingRow, 'non-heatingCostsAllocated'),
    //   propertyId: checkProperty(billingRow, 'propertyId'),
    //   totalHeatingCost: checkProperty(billingRow, 'totalHeatingCost'),
    //   totalRoomHeatingCost: checkProperty(billingRow, 'totalRoomHeatingCost'),
    //   totalWarmWaterHeatingCost: checkProperty(billingRow, 'totalWarmWaterHeatingCost'),
    //   heatingEnergyType: checkProperty(billingRow, 'heatingEnergyType'),
    // // }));
    // let sortedList = filterByDateUtility(formatted, 'date', false);
    this.setState({ currentBillingPeriodData: currentPeriodData, currentBillingPeriodDataId: currentPeriodData.id });
  };
  _getLastUtillityBillings() {
    const propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'lastBillingPeriod?propertyId=' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleLastUtillityBilling',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }
  _handleLastUtillityBilling = (response) => {
    // if (response.statuscode !== 200) {
    //   Autoload.DataHandlerGeneral.GeneralErrorMessage(
    //     true,
    //     lang.newKeys.text_error_not_found
    //     // + response.message
    //   );
    // }
    // console.log('_handleLastUtillityBilling', response);

    var lastBillingData = {
      date: `${moment(checkProperty(response.result, 'startDate')).format('DD.MM.YYYY')} - ${moment(
        checkProperty(response.result, 'endDate')
      ).format('DD.MM.YYYY')}`,
    };
    var id = checkProperty(response.result, 'id');
    var propertyId = checkProperty(response.result, 'propertyId');
    // console.log(lastBillingData, 'lastBillingData');
    this._getCurrentUtillityBillings(id);
    this.setState({
      lastUtilityBillingData: lastBillingData,
      lastBillingDataId: id,
      lastBillingDataPropertyId: propertyId,
    });
  };
  createUtilityBillings(id) {
    loader(true);
    // const propertyid = this.props.propertySelected && this.props.propertySelected.id;

    const payload = {
      BillingPeriodId: id,
      propertyId: this.state.lastBillingDataPropertyId,
    };

    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'utilityBillings',
      'POST',
      payload,
      null,
      false,
      true,
      false,
      this,
      'handleCreateUtilityBillingsResponse',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }
  handleCreateUtilityBillingsResponse(response) {
    if (response.status && response.statuscode === 200 && response.result) {
      // this.setState({
      //   startUtility: true,
      // // });
      // this.navigateToBilling(false, response.result);
      this._navigateTo(`${UTILITY_BILLING_ROUTE}utility?currentBillingId=${response.result}`);
    } else {
      // Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.utility.text_error_billing_period);
    }

    loader(false);
  }


  navigateToBilling = (isEdit, billingId) => {
    // this.props.getUtillityBillings();

    // this.props.history.push(`/utility-billing/utility?currentBillingId=${isEdit ? this.props.data.id : billingId}`, {
    //   isEdit,
    // });
    console.log(isEdit, billingId, '============== edit / billingId ==================');
    // debugger;
    this.props.history.push(`/utility-billing/utility?currentBillingId=${billingId}&ub-step=heating-cost`, {
      isEdit,
    });
    // this.props.history.push('/utility-billing/utility', { isEdit });
  };

  //recipt api
  bind_list_receipt_data = () => {
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    propertyid !== undefined && 
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.rm_api_base_url + 'property/' + propertyid+'?fetch=3&offset=0',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_list_data',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handle_get_list_data(response) {
    // if (!response.status === true && !response.statuscode === 200) {
    //   // Autoload.Redirection.redirection_error();
    //   Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Error :  ' + response.message);
    // }
    const {isTablet} = this.state;
    if(response.status === true && response.statuscode === 200){
      var ResponseReciept = [
        { name: '', date: '', 
        //cost: '' 
      },
        { name: '', date: '', 
        //cost: ''
       },
        { name: '', date: '', 
        //cost: '' 
      },
      ];
      if (response.result.length > 0) {
        for (let i = 0; i < response.result.length; i++) {
          if (i === 3) {
            break;
          }
          const element = response.result[i];
          ResponseReciept[i].name = element.name;
          !isTablet && (ResponseReciept[i].cost = element.costs)
          ResponseReciept[i].date = moment(element.receiptDate).format('DD.MM.YYYY');
          ResponseReciept[i].id = element.id
        }
      }
      console.log('handle_get_list_data: ', response);
      this.setState({
        receiptsData: response.result,
        validateFirstSection:{
        ...this.state.validateFirstSection,
        receipt: response.result ? response.result.length > 0 : false
        },
        skeletonReceiptLoading: false
      });
    }
    else {
      this.setState({skeletonReceiptLoading: false})
    }
  }
  getRentalUnits = () => {
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'rentalUnits?offset=0&fetch=100&propertyId=' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_list_rental_unit',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handle_get_list_rental_unit = (response) => {
    // console.log('object', response.result.length);
    // if (!response.status === true && !response.statuscode === 200) {
    //   // Autoload.Redirection.redirection_error();
    //   Autoload.DataHandlerGeneral.GeneralErrorMessage(
    //     true,
    //     lang.newKeys.text_error_not_found
    //     // + response.message
    //   );
    // }
    // if (response.result.length === 0) {
    //   console.log('1 show loader');
    //   this.ShowLoader();
    // }

    // console.log('rental api', response.result);
    if (response.result && response.result.length > 0) {
      rentalListData = response.result.map((ru) => ({
        id: checkProperty(ru, 'id'),
        name: checkProperty(ru, 'name'),
        size: checkProperty(ru, 'space'),
        propertyId: checkProperty(ru, 'propertyId'),
        tenantsData: [],
      }));
      // console.log('rental api formated', rentalListData);

      // response.result.sort(function (a, b) {
      //   return a.name > b.name;
      // });
      // response.result.sort();
      // console.log('response.result', response.result);
      response.result.map((ru, i) => {
        Autoload.HttpRequestAxios.HttpRequest(
          Autoload.Setting.property_api_base_url + 'tenants?offset=0&fetch=100&rentalUnitId=' + ru.id,
          'GET',
          null,
          null,
          false,
          true,
          false,
          this,
          'handle_get_list_rental_tenants',
          { rentalData: ru },
          false,
          '',
          {
            'Content-Type': 'application/json',
            Authorization: Autoload.Utils.getAuthToken(),
          }
        );
      });
    } else {
      this.setState({
        rentalUnitsData: [],
        skeletonRentalUnitsLoading: false,
        skeletonMeterLoading: false
      });
      //this.ShowLoader();
    }
  };
  handle_get_list_rental_tenants = (response) => {
    // if (!response.status === true && !response.statuscode === 200) {
    //   // Autoload.Redirection.redirection_error();
    //   Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Error :  ' + response.message);
    // }
    // console.log('tenant api', response.other.rentalData.name, response.result);
    let tenantsData = response.result.sort(function (a, b) {
      return new Date(a.moveInDate) - new Date(b.moveInDate);
    });
    // console.log('tenant api sorted', response.other.rentalData.name, tenantsData);
    tenantsData = response.result.map((tenant, idx) => {
      console.log(tenant, idx, 'tenant current idx');
      // isVacant value
      let isVacant = tenant.vacant;
      let current = false;

      //console.log(dateCompare, moment(checkProperty(tenant, 'moveOutDate')).format('DD.MM.YYYY'),'dateCompare')
      // if(dateCompare==false&&response.result.moveOutDate!=='-'){
      if (
        (dayjs(tenant.moveInDate).isBefore(dayjs()) || dayjs(tenant.moveInDate).isSame(dayjs())) //logic for moveInDate
        && 
        (tenant.moveOutDate === null || dayjs(tenant.moveOutDate).isAfter(dayjs()) || dayjs(tenant.moveOutDate).isSame(dayjs())) //logic for moveOutDate
      ) { //isFirstVacantFound == false && isVacant == false
        //
        console.log('testingCurrentTenants: ', tenant);
        current = true;
      }else if(tenant.vacant && tenant.moveInDate === null && tenant.moveOutDate === null && response.result.length === 1){
        current = true;
      }

      return {
        id: checkProperty(tenant, 'id'),
        name: checkProperty(tenant, 'fullName'),
        email: checkProperty(tenant, 'email'),
        phone: checkProperty(tenant, 'phone'),
        in: moment(checkProperty(tenant, 'moveInDate')).format('DD.MM.YYYY'),
        out: checkProperty(tenant, 'moveOutDate')
          ? moment(checkProperty(tenant, 'moveOutDate')).format('DD.MM.YYYY')
          : '',
        type: checkProperty(tenant, 'contractType'),
        note: checkProperty(tenant, 'notes'),
        current: current,
        createdOn: checkProperty(tenant, 'createdOn'),
        vacant: checkProperty(tenant, 'vacant'),
        // rentalUnit: tenant.rentalUnit,
        rentalUnitId: checkProperty(tenant, 'rentalUnitId'),
        updatedOn: checkProperty(tenant, 'updatedOn'),
      };
    });
    // console.log('tenant api formated', response.other.rentalData.name, tenantsData);

    //action
    rentalListData = rentalListData.map((ru) => {
      if (ru.id == response.other.rentalData.id) {
        // console.log('worked', tenantsData);
        return {
          ...ru,
          tenantsData: [...tenantsData],
        };
      } else {
        return ru;
      }
    });
    // console.log(rentalListData[0].tenantsData, tenantsData, 'rentalListData');
    rentalListData.sort((a, b) => a.name.localeCompare(b.name));
    // console.log('final data', rentalListData);

    // rentalListData.push(rentalListData);
    this.setState({
      rentalUnitsData: rentalListData,
      skeletonRentalUnitsLoading: false,
      skeletonMeterLoading: false
    });
    // console.log('2 show loader');

    //this.ShowLoader();
    // this.props.Account_info(false);
  };
  getPropertyImage = () => {
    const propertyID = this.props.propertyData.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'properties/' + propertyID + '/picture',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_getPropertyPictureResponse',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  _getPropertyPictureResponse(response) {
    // if (!response.status === true && !response.statuscode === 200) {
    //   // Autoload.Redirection.redirection_error();
    //   Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Error :  ' + response.message);
    // }
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      this.setState({ propertyImage: response.result ? response.result : avatar4 });
    }
  }

  toggleAddReceiptModal = () => {
    this.setState({ addReceiptOpen: !this.state.addReceiptOpen });
  };

  _navigateTo(routeName) {
    this.props.history.push(routeName);
  }
  _navigateToTenant(routeName) {
    // this.props.history.push({
    //   pathname: routeName,
    //   state: { details: true },
    // });

    // <Route path={routeName} render={<TenantInfo />}></Route>;
    debugger;
    this.props.history.push(routeName);
  }

  toggleEditUnitModal = (id) => {
    this.setCurrentUnit(id);
    this.setState({ editUnitModal: !this.state.editUnitModal });
  };
  toggleDeleteUnitModal = (tenant) => {
    // const tenantID = id;
    // if (id !== undefined) {
    //   this.setCurrentUnit(id);
    // }
    // console.log(tenant, 'tenant in delete modal');
    if (tenant !== undefined) {
      this.setState({
        tenant: tenant,
        tenantID: tenant.id,
      });
    }

    // tenantID = tenant.id;
    // tenantObj = tenant;
    this.setState({ deleteUnitModal: !this.state.deleteUnitModal });
  };
  setCurrentUnit = (id) => {
    let data = this.state.rentalUnitsData.find((rc) => rc.id == id);
    this.setState({ currentUnit: data });
  };
  deleteUnit = (id) => {
    //call delete api for rental unit
    let btnelement = document.getElementById('unit-delete-modal-footer');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url + 'Tenants/' + id,
      'DELETE',
      null,
      btnelement.children[1],
      false,
      true,
      false,
      this,
      'handleDelete',
      { cancelbtn: btnelement.children[0], id: id },
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handleDelete = (response) => {
    // console.log(response , 'delete unit tenant api')

    //handle api response
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
    }

    //action to delete unit
    this.props.DeleteUnit(response.other.id);

    response.other.cancelbtn.click();

    this.getRentalUnits();
    // this.bind_rental_unit_list_data();
  };
  ShowLoader = () => {
    // console.log('show loader abc');
    // var loader = document.getElementById('hello-housing-loader');
    // loader.style.display = 'none';
    var emptyDiv = document.getElementById('empty-dashboard-page');
    emptyDiv.style.display = 'none';
    var dataDiv = document.getElementById('respond-dashboard-page');
    dataDiv.style.display = 'block';
    this.validationForFirstSection();
    this.setState({
      skeletonLoading: false,
      skeletonUtilityLoading: false,
      skeletonMeterLoading: false,
      skeletonRentalUnitsLoading: false,
      skeletonReceiptLoading: false
    })
  };

  _addNewMeter(data) {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    data.propertyId = this.props.propertySelected && this.props.propertySelected.id;
    const payload = MeterDataHandler.generateMeterData(data);
    let btnelement = document.getElementById('meter-button-container');
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.meter_api_base_url + 'meters',
      'POST',
      payload,
      btnelement.children[0],
      false,
      true,
      false,
      this,
      '_handleCreateMeter',
      { btnelement: btnelement },
      false,
      '',
      { 'Content-Type': 'application/json' }
    );
  }
  _handleCreateMeter(response) {
    // console.log(response, 'create meterx');
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      // Autoload.DataHandlerGeneral.GeneralErrorMessage(
      //   true,
      //   lang.newKeys.text_error_not_found
      //   // + response.message
      // );
    }

    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    this.meterMaster();
    this.setState({ openMeterModal: !this.state.openMeterModal });
  }

  tenantCardOnClick() {
    this._navigateTo(TENANT_ROUTE);
  }

  sum = function (items, prop) {
    return items.reduce(function (a, b) {
      return a + b[prop];
    }, 0);
  };

  openShow = (id) => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    this.setState({currentRecord: this.state.receiptsData.find((receipt)=> receipt.id === id)}, ()=> {
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.rm_api_base_url + 'attachment/' + id,
        'GET',
        null,
        null,
        false,
        true,
        false,
        this,
        '_handle_get_show_file',
        id,
        false,
        '',
        {
          'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    })
  };
  
  _handle_get_show_file = (response) => {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        lang.newKeys.text_error_not_found
        // + response.message
      );
    }

    if(response && response.result.includes('pdf')){
      fetch(Autoload.Setting.rm_api_base_url + 'attachment/' + response.other +'/download',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          'Authorization': Autoload.Utils.getAuthToken()
        }
      })
            .then(response => {
                response.blob()
                .then(blob => {
                  console.log('_handle_get_show_file: blob: ', blob)
                  
                  this.setState({
                    currentRecord: { ...this.state.currentRecord, file_name: blob.size > 0 ? blob : '' },
                    showModal: true,
                  });
                  })
                  var loader = document.getElementById('hello-housing-loader');
                  loader.style.display = 'none';
              })
    } else {
      this.setState({
        currentRecord: { ...this.state.currentRecord, file_name: response.result != null ? response.result : '' },
        showModal: true,
      });
      var loader = document.getElementById('hello-housing-loader');
      loader.style.display = 'none';
    }
  };

  closeShow = () => {
    this.setState({ showModal: false });
  };

  toggleImageModal = () => {
    this.setState({ ImageOpen: !this.state.ImageOpen });
  };

  handleEditClick = () => {
    this.closeShow();
    setTimeout(() => this.toggleEditReceiptModal(), 500);
  };

  toggleEditReceiptModal = () => {
    this.setState({ editReceiptOpen: !this.state.editReceiptOpen });
  };

  closeEditReceipt = () => {
    this.setState({ editReceiptOpen: false });
  };

  handleChange = () => {
    this.setState({expanded: !this.state.expanded});
  };

  intercomSearchUser = ()=> {
    let body= {
      "query":  {
        "field": "external_id",
        "operator": "~",
        "value": this.props.profile.id
      }
    }
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.intercom_contact_search,
      'POST',
      body,
      null,
      false,
      true,
      false,
      this,
      'handleIntercomSearchUser',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: 'Bearer dG9rOjg0NTkxMmRkX2I5ODdfNDg4Ml9hZWM1X2VlNzg2NjJiYWE2NToxOjA=',
        'Access-Control-Allow-Origin': '*'
      }
    );
  }

  handleIntercomSearchUser = (response) =>{
    console.log('handleIntercomSearchUser: ', response);
  }

  _fetchPropertyMeters() {
    const propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.meter_api_base_url + 'meters?propertyId=' + propertyid + '&offset=0&results=100',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handlePropertyMeters',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  //@desc meter master data response handler
  _handlePropertyMeters(response) {
    console.log('meter api response', response);

    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      this.setState({
        validateFirstSection:{
          ...this.state.validateFirstSection,
          meter: response.result.length > 0
        }
      })
    }
  }

  _fetchPropertyMeterReadings() {
    const propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.meter_api_base_url + 'readings?propertyId=' + propertyid + '&offset=0&results=100',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handlePropertyMeterReadings',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _handlePropertyMeterReadings(response) {
    console.log('readings api response', response);

    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      this.setState({
        validateFirstSection:{
          ...this.state.validateFirstSection,
          meterReading: response.result.length > 0
        }
      })
    }
  }

  validationForFirstSection = ()=>{
    const {properties, propertySelected} = this.props;
    console.log('validationForFirstSection: ', propertySelected && propertySelected.meterReadingConducted);
    this.setState({
      validateFirstSection:{
        ...this.state.validateFirstSection,
        property: properties.length > 0,
        rentalUnit: this.state.rentalUnitsData.length > 0,
        tenant: this.state.rentalUnitsData.find((ru, i) => ru.tenantsData.length === 1 ? ru.tenantsData[0].vacant !== true : (ru.tenantsData.length > 1 && ru.tenantsData[1].vacant !== true)) !== undefined ? true : false,
        meterReading: propertySelected ? propertySelected.meterReadingConducted : false
      }
    })
  }

  toggleAddPropertyTypeModal = () => {
    this.setState({ addPropertyTypeModal: !this.state.addPropertyTypeModal });
  };

  render() {
    const {
      propertyAdress,
      cutOffDate,
      name,
      propertyAddress,
      propertyName,
    } = this.props.propertyData ? this.props.propertyData : {};
    const { meterBillingPeriods, currentBillingPeriodData, lastUtilityBillingData, isTablet, isMobile, currentRecord, showModal, ImageOpen, editReceiptOpen, expanded, subExpanded, validateFirstSection, dahsboardPartner } = this.state;
    const {propertySelected} = this.props;
    var meterLinkData = meterBillingPeriods[1];
    // var PropDate = Autoload.MainHelper.checkProperty(
    //   Autoload.MainHelper.checkProperty(this.props.propertyData, 'cutOffDate'),
    //   'day'
    // );

    // var cutOfPropertyDate = cutOffDate.split(/(?<=^\S+)\s/);
    // var PropertyDate = Autoload.MainHelper.checkProperty(
    //   Autoload.MainHelper.checkProperty(this.props.propertyData, 'cutOffDate'),
    //   'day'
    // );
    console.log('render: currentRecord: ', currentRecord)
    return (
      <>
        <div id="empty-dashboard-page" className="empty-dashboard-page" style={{ display: 'none' }}></div>
        <div className="dashboard" id="respond-dashboard-page" style={{ display: 'block' }}>
          {/* Property Section */}

          <Row>
            <Col xs={12}>
              {/* Property Info */}
              <Skeleton visible={this.state.skeletonRentalUnitsLoading} style={{marginBottom: this.state.skeletonLoading && '24px'}}>
              {isMobile ? <PropertyDetailsCard isMobile={isMobile} propertySelected={this.props.propertySelected}/> :
              <CustomImageCard
                heading={this.props.properties.length > 0 ? name || propertyName : '-'}
                isMobile={isMobile}
                isTablet={isTablet}
                propertyCard={true}
                subheading={
                  this.props.properties.length > 0 ? (
                    propertyAdress && propertyAdress.streetno === '' ? (
                      <br />
                    ) : (
                      (propertyAdress && propertyAdress.streetno) || propertyAddress
                    )
                  ) : (
                    '-'
                  )
                }
                image={(this.props.propertySelected && this.props.propertySelected.pictureLocation) ? this.props.propertySelected.pictureLocation : Hlogo}
                topRightItem={
                  this.props.properties.length > 0 ? (
                    <ColorButton
                      text={lang.utility.text_edit}
                      onClick={() => {
                        // btnHandler();
                        this._navigateTo(PROPERTY_ROUTE);
                      }}
                      className="gray-btn edit-purple-btn"
                    />
                    // <Button onClick={() => this._navigateTo(PROPERTY_ROUTE)} className="edit-dashboard-btn">
                    //   {lang.utility.text_edit}
                    // </Button>
                  ) : 
                  (
                    <ColorButton
                      text={lang.general.text_add_property}
                      onClick={() => {
                        // btnHandler();
                        this.toggleAddPropertyTypeModal();
                      }}
                      className="purple-btn edit-purple-btn"
                    />
                  )
                }
                headingClass="pt-2"
                cardSize={isMobile ? 'md' : "xl"}
                className="dashboardCard"
              >
                <div className="py-4 property-info-tiles">
                  {/* //need to update area of unit and area of unit as new requirements */}
                  <Group spacing={'50px'}>
                  <InfoField
                    labelText={lang.property.generalinfo_Property_no_of_Units}
                    text={(this.props.propertySelected && this.props.propertySelected.propertyType === 'CONDOMINIUM') ?
                    this.props.propertySelected.numberOfRentalUnits
                    :this.state.rentalUnitsData.length > 0 ? this.state.rentalUnitsData.length : 0}
                    leftTextImage={{ img: UnitsIcon, style: { width: '16px', height: '16px' } }}
                    valueClassName="text-sm"
                  />
                  <InfoField
                    labelText={lang.property.generalinfo_Area_of_unit}
                    text={
                      this.props.propertySelected && this.props.propertySelected.propertyType === 'CONDOMINIUM' ?
                      formatNumber(this.props.propertySelected.totalRentingSpace, false) + ' m²'
                      :
                      this.props.properties.length > 0
                        ? formatNumber(this.state.rentalUnitsData.reduce(function (a, b) {
                          return a + b.size;
                        }, 0), false) + ' m²'
                        : '-'
                    }
                    leftTextImage={{ img: AreaIcon, style: { width: '16px', height: '16px' } }}
                    valueClassName="text-sm"
                  />
                  <InfoField
                    labelText={lang.property.generalinfo_cut_off_date}
                    text={
                      this.props.properties.length > 0
                        ? window.innerWidth <= MOBILE_SIZE
                          ? Autoload.MainHelper.checkProperty(
                            Autoload.MainHelper.checkProperty(this.props.propertyData, 'cutOffDate'),
                            'day'
                          ) +
                          // ||
                          // cutOfPropertyDate[0]
                          '.' +
                          ' ' +
                          Autoload.MainHelper.month_name(
                            moment()
                              .set(
                                'month',
                                parseInt(Autoload.MainHelper.checkProperty(cutOffDate, 'month', '12')) - 1
                              )
                              .format('MMM')
                          )
                          :
                          Autoload.MainHelper.checkProperty(cutOffDate, 'day') +
                          '.' +
                          ' ' +
                          Autoload.MainHelper.month_name(
                            moment()
                              .set(
                                'month',
                                parseInt(Autoload.MainHelper.checkProperty(cutOffDate, 'month', '12')) - 1
                              )
                              .format('MMMM')
                          )
                        : '-'
                    }
                    leftTextImage={{ img: CalendarIcon, style: { width: '16px', height: '16px' } }}
                    valueClassName="text-sm"
                  />
                  <InfoField
                    labelText={'Art der Immobilie'}
                    text={
                      this.props.propertySelected && this.props.propertySelected.propertyType === 'CONDOMINIUM' ? 'Eigentumswohnung / Gewerbeeinheit' : this.props.propertySelected.propertyType === 'REGULAR' ? 'Mehrparteienhaus' : '-'
                    }
                    leftTextImage={{ img: this.props.propertySelected && this.props.propertySelected.propertyType === 'CONDOMINIUM' ? plan : skyscraper, style: { width: '16px', height: '16px' } }}
                    valueClassName="text-sm"
                  />
                  </Group>
                </div>
              </CustomImageCard>}
              </Skeleton>
            </Col>
          </Row>
          
          <Row style={{ marginBottom: '20px' }} className="mobile-dashboard-view-row-one">
          
            <Col xs={12} md={12} lg={12}>
            <Skeleton visible={this.state.skeletonUtilityLoading}>
              <Accordion expanded={expanded} onChange={this.handleChange}>
              <AccordionSummary className={'dashboard-first-step-guide'} style={{background: 'white', borderRadius: '4px', boxShadow: expanded && 'none'}}>
                <Typography>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className='dashboard-first-step-guide-title'>Ihre ersten Schritte mit hellohousing</div>
                    <div className='dashboard-first-step-guide-title'>
                      <span style={{fontSize: '14px', color: '#13135C', fontWeight: '500'}}>{Object.values(this.state.validateFirstSection).filter((value)=> value !== false && value !== undefined).length} / {firstStepContent.length}</span>
                      {expanded ? <ExpandMoreIcon style={{color: '#13135C'}} /> : <NavigateNextIcon style={{color: '#13135C'}} />}
                    </div>  
                  </div>
                  <Progress value={(Object.values(this.state.validateFirstSection).filter((value)=> value !== false && value !== undefined).length / 7) * 100} size={'sm'} color={'#5353FE'} radius={`0px 0px ${expanded ? '0px 0px' : '4px 4px'}`} className={'dashboard-first-step-guide-progressbar'} />
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{background: 'white', borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px', boxShadow: '0 1px 3px rgba(0, 0, 0, 0.08)', padding: '16px 0px'}}>
                <Typography style={{padding: '0px 16px'}}>
                  {firstStepContent.map((item, index)=>
                  {
                    return <Accordion expanded={subExpanded === index} onChange={(event, isExpanded)=> this.setState({subExpanded: isExpanded ? index : false})} className={'dashboard-frist-step-sub-accordions'}>
                      <AccordionSummary className='first-step-accordion-content' style={{background: 'white'}}>
                        <Typography>
                          <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                              {validateFirstSection[item.stateValidationAttribute] ? 
                              <span className='first-step-done'>
                                {UtilityBillingAssignedIcon('#5555FF')}
                              </span>
                              : 
                              <div className='first-step-numbering'
                              style={{marginRight: '10px'}}
                              >
                                <img src={this.state.firstStepIcons[index]} alt={index}/>
                              </div>}
                              <span className='first-step-title' style={{opacity: subExpanded === index ? '1' : validateFirstSection[item.stateValidationAttribute] && '0.5'}}>
                                {item.title}
                              </span>
                            </div>
                            <div className='first-step-sub-accordions-icons'>
                              <NavigateNextIcon style={{color: '#13135C'}} />
                            </div>
                          </div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{background: 'white'}}>
                        <Typography style={{marginLeft: '25px'}}>
                          <div dangerouslySetInnerHTML={{__html: item.description}}/>
                          <div style={{display: 'flex'}}>
                            <div>
                              <ColorButton
                                text={item.buttonText}
                                className={'purple-btn'}
                                onClick={()=> this.props.history.push(item.buttonRoute)}
                              />
                            </div>
                            <div style={{display: 'flex', width: 'auto', alignItems: 'center', marginLeft: '20px', cursor: 'pointer'}} onClick={()=> window.Intercom('showArticle', item.articleNo)}>
                              <img src={HelpLogo} />
                              <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px', marginTop: '3px'}}>{item.buttonKnowledgeBase}</span>
                              {/* Hilfe-Center */}
                            </div>
                            {item.articleNo2 !== '' && item.buttonKnowledgeBase2 !== '' && 
                            <div style={{display: 'flex', width: 'auto', alignItems: 'center', marginLeft: '20px', cursor: 'pointer'}} onClick={()=> window.Intercom('showArticle', item.articleNo2)}>
                              <img src={HelpLogo} />
                              <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px', marginTop: '3px'}}>{item.buttonKnowledgeBase2}</span>
                              {/* Hilfe-Center */}
                            </div>}
                          </div>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  })}
                </Typography>
              </AccordionDetails>
              </Accordion>
            </Skeleton>
            </Col>
          
          </Row>
          {/* Receipts/Utility/Meter Section */}

          <Row style={{ marginBottom: '6px' }} className="mobile-dashboard-view-row-one">
            {/* Receipts */}
            <Col xs={12} md={4} lg={4} style={{paddingRight: isTablet && '2.5px'}}
            >
              <CustomCard
                heading={lang.general.text_receipt}
                className="billing-card reciept-card dashboard-first-row-cards"
                topRightItem={
                  <TransparentButton
                    text={lang.newKeys.text_see_all}
                    className="tc-dark-purple mb-0"
                    onClick={() => this._navigateTo(RECEIPT_ROUTE)}
                  />
                }
                skeletonLoading={this.state.skeletonReceiptLoading}
              >
                {this.props.properties.length > 0 ? (
                    // (
                    // this.state.receiptsData && this.state.receiptsData.name === '' &&
                    // this.state.receiptsData.date === '' &&
                    // this.state.receiptsData.cost === '') ||
                    // this.state.receiptsData &&
                    // this.state.receiptsData[0].name === '' 
                    !validateFirstSection.receipt
                    ? (
                    <EmptyStateDashboard
                      heading={lang.newKeys.text_empty_receipt_one}
                      subheading={lang.newKeys.text_empty_receipt_two}
                      className="emptystate-dashboard empty-state-btn-added"
                      icon={GreyIcon}
                      btnHandler={() => {
                        this.toggleAddReceiptModal();
                      }}
                      btnText={lang.receipt.text_add_receipt}
                      buttonDisable={true}
                    />
                  ) : (
                    <>
                      <BorderlessTable
                        data={this.state.receiptsData}
                        labels={isTablet ? [
                          lang.newKeys.text_dashboard_file_name,
                          lang.general.text_date
                        ]:
                        [
                          lang.newKeys.text_dashboard_file_name,
                          lang.general.text_date,
                          lang.newKeys.text_dashboard_cost,
                        ]}
                        isTablet={isTablet}
                        onClick={(id)=> this.openShow(id)}
                      />
                      <TransparentButton
                        text={lang.receipt.text_add_receipt}
                        leftImage={{ img: AddIcon }}
                        className="tc-dark-purple px-0 button-img-add-btn"
                        onClick={() => this.toggleAddReceiptModal()}
                      />
                    </>
                  )
                ) : (
                  <EmptyStateDashboard
                    heading={lang.newKeys.text_empty_receipt_three}
                    subheading={'Legen Sie zunächst eine Immobilie an, um Belege zu nutzen.'}//lang.newKeys.text_empty_receipt_two}
                    className='emptystate-dashboard empty-state-btn-added'
                    icon={GreyIcon}
                  />
                )}
              </CustomCard>
            </Col>

            {/* Utility */}
            <Col xs={12} md={4} lg={4} style={{paddingLeft: isTablet && '5px', paddingRight: isTablet && '5px'}}>
                <CustomCard
                  heading={lang.general.text_utilitybilling}
                  className="billing-card dashboard-first-row-cards"
                  skeletonLoading={this.state.skeletonUtilityLoading}
                  topRightItem={
                    <TransparentButton
                      text={lang.newKeys.text_see_all}
                      className="tc-dark-purple"
                      onClick={() => this._navigateTo(UTILITY_BILLING_ROUTE)}
                    />
                  }
                >
                  {this.props.properties.length > 0  && this.state.rentalUnitsData.length > 0 ? (
                    this.state.meterBillingPeriods.length === 0 ||
                      this.state.lastUtilityBillingData.date === null ||
                      currentBillingPeriodData.date === null ||
                      this.state.filteredReadings.find((item)=> !item.recordStatus) || !this.state.filteredReadings.some(obj => obj.hasOwnProperty('id')) ? (
                      <>
                        <EmptyStateDashboard
                          heading={'Es wurde noch keine Abrechnung erstellt.'}
                          subheading={'Hier sehen Sie später Ihre Abrechungen.'}
                          className="emptystate-dashboard empty-state-btn-added"
                          icon={utilitylogoEmpty}
                          btnHandler={() => this.props.history.push('/utility-billing')}
                          btnText={'Abrechnung erstellen'}
                          buttonDisable={true}
                        />
                      </>
                    ) : (
                      <div>
                        {this.state.filteredReadings.filter((bp)=> bp.id).map((bp, index) =>
                          // bp.recordStatus !== 'last' ? null : 
                          { 
                            let last = moment(bp.startDate).format('YYYY') === moment().subtract(1, 'year').format('YYYY');
                            let current = moment(bp.startDate).format('YYYY') === moment().format('YYYY');

                          
                            return index <= 1 &&
                            (bp.id && (bp.recordStatus === 'current' ||  bp.recordStatus === 'last') ?
                            <>
                              <div
                              onClick={()=> this.navigateToBilling(true, bp.id)}
                              style={{cursor: 'pointer'}}
                              >
                              <InfoField
                                // labelText="Last billing period"
                                labelText={bp.recordStatus === 'last' ? lang.utility.text_past_billing_period : bp.recordStatus === 'current' && lang.utility.text_current_billing_period}
                                labelStyle={bp.recordStatus === 'last' ? 
                                {
                                  backgroundColor: '#F2F2FC',
                                  color: '#5555FF',
                                  fontSize: '12px',
                                  fontWeight: '500',
                                  width: 'auto'
                                }
                              :
                              bp.recordStatus === 'current' ? {
                                  backgroundColor: '#F2F2FC',
                                  color: '#727293',
                                  fontSize: '12px',
                                  fontWeight: '500',
                                  width: 'auto'
                              }: {}}
                                text={
                                  `${moment(bp.startDate).format('DD.MM.YYYY')} - ${moment(bp.endDate).format('DD.MM.YYYY')}`
                                } //"01.01.19 - 31.12.19"
                                className="mb-3"
                                // rightTextImage={
                                //   bp.id && {
                                //     img: PlayIcon,
                                //     handler: () => {
                                //       this.navigateToBilling(true, bp.id);
                                //     },
                                //     style:{ cursor: 'pointer' }
                                //   }
                                // }
                              />
                              </div>
                              {bp.id ? (
                                <>
                                </>
                              ) : (
                                <ColorButton
                                  id="startUtilityBilling"
                                  onClick={() => {
                                    this.createUtilityBillings(bp.billingPeriodId);
                                  }}
                                  text={
                                    bp.id
                                      ? lang.general.text_edit
                                      : lang.utility.text_start_utility_billing
                                  }
                                  className={'purple-btn'}
                                />
                              )}

                              <div className="divider"></div>
                            </>
                            : 
                            bp.id && <>
                              <div
                              onClick={()=> bp.id ? this.navigateToBilling(true, bp.id) : this.props.history.push('/utility-billing')}
                              style={{cursor: 'pointer'}}
                              >
                              <InfoField
                                // labelText="Last billing period"
                                labelText={last ? lang.utility.text_past_billing_period : current ? lang.utility.text_current_billing_period : lang.newKeys.text_billing_period_dashboard}
                                labelStyle={last ? 
                                {
                                  backgroundColor: '#F2F2FC',
                                  color: '#5555FF',
                                  fontSize: '12px',
                                  fontWeight: '500',
                                  width: 'auto'
                                }
                              :
                              current ? {
                                  backgroundColor: '#F2F2FC',
                                  color: '#727293',
                                  fontSize: '12px',
                                  fontWeight: '500',
                                  width: 'auto'
                              }: {}}
                                text={
                                  `${moment(bp.startDate).format('DD.MM.YYYY')} - ${moment(bp.endDate).format('DD.MM.YYYY')}`
                                } //"01.01.19 - 31.12.19"
                                className="mb-3"
                                // rightTextImage={
                                //   bp.id && {
                                //     img: PlayIcon,
                                //     handler: () => {
                                //       this.navigateToBilling(true, bp.id);
                                //     },
                                //     style:{ cursor: 'pointer' }
                                //   }
                                // }
                              />
                              </div>
                              {/* {bp.id ? (
                                <>
                                </>
                              ) : (
                                <ColorButton
                                  id="startUtilityBilling"
                                  onClick={() => {
                                    this.createUtilityBillings(bp.billingPeriodId);
                                  }}
                                  text={
                                    bp.id
                                      ? lang.general.text_edit
                                      : lang.utility.text_start_utility_billing
                                  }
                                  className={'purple-btn'}
                                />
                              )} */}

                              <div className="divider"></div>
                            </>)
                          }
                        )}
                        {/* {this.state.filteredReadings.map((bp, id) =>
                          bp.recordStatus !== 'other' ? null
                            : id === 1 ? <>
                              <div
                              onClick={()=>this.navigateToBilling(true, bp.id)}
                              style={{cursor: 'pointer'}}
                              >
                              <InfoField
                                // labelText="Last billing period"
                                labelText={lang.newKeys.text_billing_period_dashboard}
                                labelStyle={{
                                  backgroundColor: '#F2F2FC',
                                  color: '#727293',
                                  fontSize: '12px',
                                  fontWeight: '500',
                                  width: 'auto'
                                }}
                                text={
                                  `${moment(bp.startDate).format('DD.MM.YYYY')} - ${moment(bp.endDate).format('DD.MM.YYYY')}`
                                } //"01.01.19 - 31.12.19"
                                labelClassName="text-xs"
                                className="mb-3"
                                // rightTextImage={
                                //   bp.id && {
                                //     img: PlayIcon,
                                //     handler: () => {
                                //       this.navigateToBilling(true, bp.id);
                                //     },
                                //     style:{ cursor: 'pointer' }
                                //   }
                                // }
                              />
                              </div>
                              {bp.id ? (
                                <>
                                </>
                              ) : (
                                <ColorButton
                                  id="startUtilityBilling"
                                  onClick={() => {
                                    this.createUtilityBillings(bp.billingPeriodId);
                                  }}
                                  text={
                                    bp.id
                                      ? lang.general.text_edit
                                      : lang.utility.text_start_utility_billing
                                  }
                                  className={'purple-btn'}
                                />
                              )}

                              <div className="divider"></div>
                            </> : null
                        )} */}
                        {/* <InfoField
                          // labelText="billing period"
                          labelText={lang.newKeys.text_billing_period_dashboard}
                          text={
                            currentBillingPeriodData.date === 'Invalid Date - Invalid Date' ||
                              'InValid Date - InValid Date' ||
                              'invalid date - invalid date' ||
                              'Invalid date' ||
                              'Invalid date - Invalid date' ||
                              'Invalid Date'
                              ? 'nicht verfügbar'
                              : currentBillingPeriodData.date
                          }
                          //text={currentBillingPeriodData.date === 'Invalid Date' ? '-' : currentBillingPeriodData.date} //"01.01.19 - 31.12.19"
                          rightTextImage={
                            currentBillingPeriodData.date === 'Invalid Date - Invalid Date' ||
                              'InValid Date - InValid Date' ||
                              'invalid date - invalid date' ||
                              'Invalid date' ||
                              'Invalid date - Invalid date' ||
                              'Invalid Date'
                              ? ''
                              : {
                                img: PlayIcon,
                                handler: () => {
                                  this.navigateToBilling(true, this.state.currentBillingPeriodDataId);
                                },
                              }
                          }
                          labelClassName="text-xs"
                        /> */}
                      </div>
                    )
                  ) : (
                    <EmptyStateDashboard
                      heading={lang.newKeys.text_empty_utility_one}
                      subheading={this.props.properties.length === 0 ? lang.newKeys.text_empty_utility_two : 'Legen Sie zunächst Mieteinheiten an, um Betriebskosten abzurechnen.'}
                      className={`emptystate-dashboard empty-state-btn-added`}
                      icon={utilitylogoEmpty}
                    />
                  )}
                </CustomCard>
            </Col>

            {/* Meter Section */}
            {/* {propertySelected.propertyType === 'REGULAR' &&
            <Col xs={12} md={4} lg={4} style={{paddingLeft: isTablet && '2.5px'}}>
                <CustomCard
                  heading={lang.general.text_meter}
                  className="billing-card dashboard-first-row-cards"
                  skeletonLoading={this.state.skeletonMeterLoading}
                  topRightItem={
                    <TransparentButton
                      text={lang.newKeys.text_see_all}
                      className="tc-dark-purple"
                      onClick={() => this._navigateTo(METER_ROUTE)}
                    />
                  }
                >
                  {this.props.properties.length > 0 && this.state.rentalUnitsData.length > 0 ? (
                    (
                      <>
                        <div 
                          onClick={()=> this._navigateTo({
                            pathname: `${Autoload.Setting.get_meter_reading_annual_link(
                              meterBillingPeriods[0].id,
                              'c-r-0'
                            )}`,
                            data: { meterLinkData },
                          })}
                          style={{cursor: 'pointer'}}
                        >
                        <InfoField
                          labelText={lang.newKeys.text_billing_period}
                          text={meterBillingPeriods.length >= 1 ? meterBillingPeriods[0].date : '-'} //"01.01.19 - 31.12.19"
                          labelClassName="text-xs"
                          labelStyle={{
                            backgroundColor: '#F2F2FC',
                            color: '#727293',
                            fontSize: '12px',
                            fontWeight: '500',
                            width: 'auto'
                          }}
                          className="mb-3"
                        />
                        </div>
                        <div className="divider"></div>
                        <div
                        onClick={()=>this._navigateTo({
                          pathname: `${Autoload.Setting.get_meter_reading_annual_link(
                            meterBillingPeriods[1].id,
                            'c-r-0'
                          )}`,
                          data: { meterLinkData },
                        })}
                        style={{cursor: 'pointer'}}
                        >
                        <InfoField
                          labelText={lang.receipt.text_past_billing_period}
                          labelStyle={{
                            backgroundColor: '#F2F2FC',
                            color: '#5555FF',
                            fontSize: '12px',
                            fontWeight: '500',
                            width: 'auto'
                          }}
                          text={meterBillingPeriods.length >= 2 ? meterBillingPeriods[1].date : '-'} //"01.01.19 - 31.12.19"
                          labelClassName="text-xs"
                        />
                        </div>
                        <div className="divider"></div>
                      </>
                    )
                  ) : (
                    <EmptyStateDashboard
                      heading={lang.newKeys.text_empty_meter_one}
                      subheading={this.props.properties.length === 0 ? lang.newKeys.text_empty_meter_two : 'Legen Sie zunächst Mieteinheiten an, um Ablesungen durchzuführen.'}
                      className={`emptystate-dashboard empty-state-btn-added`}
                      icon={meterLogoEmpty}
                    />
                  )}
                </CustomCard>
            </Col>} */}

            {/* Partner Section */}
            {<Col xs={12} md={4} lg={4} style={{paddingLeft: isTablet && '2.5px'}}>
                <CustomCard
                  heading={<Group spacing={5}><img src={PartnerDashboardIcon}/><span>Partner</span></Group>}
                  className="billing-card dashboard-first-row-cards"
                  skeletonLoading={false}
                  topRightItem={
                    <TransparentButton
                      text={lang.newKeys.text_see_all}
                      className="tc-dark-purple"
                      onClick={() => this._navigateTo(PARTNER_ROUTE)}
                    />
                  }
                >
                  {dahsboardPartner &&
                  <Group onClick={()=> this.props.history.push('/partner?partnerId='+dahsboardPartner['partner-id'])} style={{paddingBottom: dahsboardPartner['partner-id'] === '20240001' ? '20px' : 0}} className='partner-dashboard-card-content' noWrap align={'flex-start'} spacing={isTablet ? 15 : isMobile ? 10 : 30}>
                    <img src={dahsboardPartner['dashboard-image']} width={'72%'} style={{minWidth: '50px', maxWidth: '70px'}}/>
                    <span>{dahsboardPartner['dashboard-text']}</span>
                  </Group>}
                  {dahsboardPartner &&
                  <Group position='right' style={{marginTop: '10px'}}>
                    <div>
                      <button onClick={()=> this.props.history.push('/partner?partnerId='+dahsboardPartner['partner-id'])} className='partner-dashboard-card-button'>
                        <span>{dahsboardPartner['dashboard-cta']}</span>
                      </button>
                    </div>
                  </Group>}
                </CustomCard>
            </Col>}
          </Row>

          {/* Rental_units header */}
          <Row>
            <Col xs={12}>
              <HeadingContainer
                heading={<Skeleton visible={this.state.skeletonRentalUnitsLoading}>{lang.newKeys.text_Rental_units}</Skeleton>}
                rightContent={
                  propertySelected.propertyType === 'REGULAR' &&
                  <div className={`d-flex align-items-center ${!isMobile && 'mb-3'}`}>
                    <Skeleton visible={this.state.skeletonRentalUnitsLoading}>
                    <TransparentButton
                      onClick={() => this.props.history.push('/user')}
                      text={lang.newKeys.text_see_all}
                      className="tc-dark-purple mb-0 seeAllMargin"
                    />
                    <ColorButton
                      // disabled={this.props.propertyData.length == 0}
                      disabled={this.props.propertySelected && Object.keys(this.props.propertySelected).length > 0 ? false : true}
                      onClick={() => this.setState({ addUnitModal: !this.state.addUnitModal })}
                      // style={{ marginLeft: '4 !important' }}
                      className={
                        this.props.properties.length > 0
                          ? this.state.meterBillingPeriods.length === 0 ||
                            this.state.lastUtilityBillingData.date === null ||
                            currentBillingPeriodData.date === null
                            ? this.state.rentalUnitsData.length > 0
                              ? 'gray-btn marginLeft_dashboard'
                              : 'purple-btn marginLeft_dashboard'
                            : 'gray-btn marginLeft_dashboard'
                          : 'gray-btn marginLeft_dashboard'
                      }
                      // className={this.props.propertyData.length === 0?'puple-btn ml-4':'gray-btn ml-4'}
                      text={lang.tenant.text_add_rental_unit}
                    />
                    </Skeleton>
                  </div>
                }
              />
            </Col>
          </Row>

          {/* Rental_units cards */}
          <Row className="mb-1">
            {this.state.rentalUnitsData.length > 0 ? (
              <>
                {this.state.rentalUnitsData.map((ru, i) => {
                  return Array.isArray(ru.tenantsData) && ru.tenantsData.length > 0 ? (
                    ru.tenantsData
                      .filter((tenant) => tenant.current)
                      //(tenant.moveOutDate === null || dayjs(tenant.moveOutDate).isAfter(dayjs()) || dayjs(tenant.moveOutDate).isSame(dayjs()))
                      .map((tenant, index) => {
                        // console.log(tenant, 'tenant id ');

                        return (
                          <>
                            <Col xs={12} md={6} key={'rud-c-' + i} 
                            style={{
                              paddingLeft: !isMobile && isTablet && (i+1)%2 === 0 && '3.25px',
                              paddingRight: !isMobile && isTablet && (i+1)%2 !== 0 && '3.25px'
                              }}>
                              <CustomCard
                                // style={{cursor:"pointer"}}
                                onClick={() => this.tenantCardOnClick()}
                                skeletonLoading={this.state.skeletonRentalUnitsLoading}
                                className="add-rental-dashboard-cards rentalunit-cards-mobile"
                                heading={ru.name}
                                topRightItem={
                                  tenant.type !== 'Default' && (
                                    <RecordOptions
                                      toggleEditModal={() =>
                                        this._navigateToTenant(TENANT_ROUTE + 'tenant/information?tenant=' + tenant.id)
                                      }
                                      toggleDeleteModal={() => this.toggleDeleteUnitModal(tenant)}
                                    />
                                  )
                                }
                              >
                                <TiledContent
                                  style={{ cursor: 'pointer' }}
                                  data={ru}
                                  tenant={tenant}
                                  onClick={() => this.tenantCardOnClick()}
                                />
                              </CustomCard>
                            </Col>
                          </>
                        );
                      })
                  ) : (
                    <Col xs={12}>
                      <Skeleton visible={this.state.skeletonRentalUnitsLoading}>
                        <EmptyStateDashboard
                          heading={lang.newKeys.text_empty_rentalUnit_one}
                          subheading={lang.newKeys.text_empty_rentalUnit_two}
                          className="no-rental-units"
                          icon={BlueUnitsIcon}
                          btnHandler={() => { }}
                          btnText={lang.general.text_add_property}
                        />
                      </Skeleton>
                    </Col>
                  );
                })}
              </>
            ) : (
              
              <Col xs={12}>
                <Skeleton visible={this.state.skeletonRentalUnitsLoading} style={{display: 'flex', justifyContent: 'center'}}>
                  <EmptyStateDashboard
                    heading={lang.newKeys.text_empty_rentalUnit_one}
                    subheading={this.props.properties.length === 0 ? lang.newKeys.text_empty_rentalUnit_two : 'Legen Sie Mieteinheiten an, um diese hier anzuzeigen.'}
                    className="no-rental-units empty-state-btn-added"
                    icon={BlueUnitsIcon}
                    btnHandler={() => this.setState({ addUnitModal: !this.state.addUnitModal })}
                    btnText={lang.tenant.text_add_rental_unit}
                    buttonDisable={this.props.properties.length > 0 ? true : false}
                  />
                </Skeleton>
              </Col>
            )}
            {/* {this.state.addUnitModal && ( */}
            <RentalUnitModal
              record={null}
              edit={false}
              open={this.state.addUnitModal}
              toggle={() => this.setState({ addUnitModal: !this.state.addUnitModal })}
              updateStore={this.getRentalUnits}
              className="add-rental-unit-modal"
            />
            {/* )} */}
            {/* {this.state.editUnitModal && ( */}
            <RentalUnitModal
              record={this.state.currentUnit}
              edit={true}
              open={this.state.editUnitModal}
              toggle={() => this.setState({ editUnitModal: !this.state.editUnitModal })}
              updateStore={this.getRentalUnits}
              className="add-rental-unit-modal"
            />
            {/* )} */}
            <DeleteModal
              open={this.state.deleteUnitModal}
              className="delete-rent-modal"
              toggle={this.toggleDeleteUnitModal}
              record={this.state.tenant}
              handleDelete={() => this.deleteUnit(this.state.tenantID)}
              // showInfo={[this.state.currentUnit.name, `${this.state.currentUnit.size} m²`]}
              type={lang.newKeys.text_single_tenant}
              showtext={false}
              typeText={'Sind Sie sicher, dass Sie diesen Mieter löschen wollen. Sämtliche Mieterdaten und hochgeladene Dokumente gehen unwiderruflich verloren.'}
              modalId="unit-delete-modal-footer"
              textShowModal={"Mietverhältnis löschen"}
              showDeleteText={false}
            />
          </Row>

          {this.state.addReceiptOpen &&
          <ReceiptDetailModal
            history={this.props.history}
            parent={this}
            record={null}
            className="add-edit-receipt-modal"
            edit={false}
            open={this.state.addReceiptOpen}
            toggle={() => this.toggleAddReceiptModal()}
          />}

          <ShowModal
            onEditClick={this.handleEditClick}
            onExpansionClick={this.toggleImageModal}
            record={currentRecord}
            className="show-receipt-modal"
            open={showModal}
            history={this.props.history}
            toggle={this.toggleShowModal}
            closeShow={this.closeShow}
          />
          <ImageModal
            img={currentRecord && currentRecord.file_name !== '' && currentRecord.file_name}
            className="expandImageModal"
            open={ImageOpen}
            toggle={this.toggleImageModal}
          />          
          {editReceiptOpen && 
          <ReceiptDetailModal
            history={this.props.history}
            record={currentRecord}
            parent={this}
            className="add-edit-receipt-modal"
            edit={true}
            open={editReceiptOpen}
            toggle={() => this.closeEditReceipt()}
          />}

          <div>
            {/* {this.state.openMeterModal && ( */}
            <MeterModal
history={this.props.history}
              meter={null}
              className="add-meter-modal"
              open={this.state.openMeterModal}
              addNewMeter={(newMeterData) => this._addNewMeter(newMeterData)}
              toggle={() => this.setState({ openMeterModal: !this.state.openMeterModal })}
              propertyId={this.props.propertySelected && this.props.propertySelected.id}
            />
            {/* )} */}
          </div>
        </div>

        <div>
          {this.state.addPropertyModal && (
            <AddPropertyModal
              className="add_proprty_modal"
              edit={false}
              open={this.state.addPropertyModal}
              toggle={() => {
                this.toggleAddPropertyModal();
              }}
              propertyType={this.state.selectedPropertyType}
            />
          )}
          {this.state.addPropertyTypeModal &&
          <AddPropertyTypeModal
            className="add_proprty_type_modal"
            open={this.state.addPropertyTypeModal}
            toggle={() => {
              this.toggleAddPropertyTypeModal();
            }}
            next={(selectedPropertyType)=> {
              this.setState({selectedPropertyType});
              this.toggleAddPropertyTypeModal();
              this.toggleAddPropertyModal();
            }}
          />
          }
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertyData: state.generalReducer.property,
    propertySelected: state.generalReducer.property,
    Loader: state.generalReducer.account_api_id,
    properties: state.generalReducer.allProperties,
    profile: state.generalReducer.profile,
  };
};
const actions = {
  setUtilityBillingData,
  getRentalUnitTenants,
  setCurrentTenant,
  updateFilteredList,
  DeleteUnit,
  Account_info,
};
export default connect(mapStateToProps, actions)(Dashboard);
