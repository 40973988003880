import React, { Component } from 'react';
import CustomModal from './index';
import {
  ColorButton,
  ValueText,
  SwitchContainer,
  DropFile,
  AddCostTypeModal,
  SelectDropdown,
  ImageModal
} from '../../../../index';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Help from '../../../../../assets/images/module/general/Help.svg';
import calendar from '../../../../../assets/images/module/general/CalendarIcon.svg';
import { connect } from 'react-redux';
import RangeDatePicker from './DateRangePicker';
import Autoload from '../../../../Autoload';
import moment from 'moment';
import GlobalServices from '../../../../helpers/services/GlobalServices';
import GeneralDataHandler from '../../../../helpers/DataHandler/General';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';

import { showNotification } from '@mantine/notifications';
import RedTick from '../../../../../assets/images/module/general/RedTick.svg';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import CloseButtonIcon from '../../../../../assets/images/module/general/CloseButtonIcon.svg';
import GoldenLockIcon from '../../../../../assets/images/module/general/GoldenLockIcon.svg';
import AddPages from '../../../../../assets/images/module/general/FileAdd.svg';
import RemovePages from '../../../../../assets/images/module/general/FileRemove.svg';

// Get receipts endpoint from new config
import { config } from '../../../../config/Setting';
import { Check } from "@material-ui/icons";
import { Alert, Box, Group, LoadingOverlay, NumberInput, Overlay, Popover, Stack, Text, Textarea, TextInput } from "@mantine/core";
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs';
import { NumericFormat } from 'react-number-format';
import PdfViewer from '../PdfViewer/PdfViewer';
import { Button } from 'reactstrap';
import { ChevronRight, ChevronLeft, ZoomIn, ZoomOut, NoteAdd} from '@material-ui/icons';
import ExpandIcon from '../../../../../assets/images/module/general/ExpandIcon.svg';
import UtilityBillingAssignedIcon from '../../../SVGs/utilityBillingAssigned';
import {setAddReceiptModal, setReceiptCurrentId, setReceiptFiles, setRefreshReceiptDocumentsData} from '../../../../../redux/action/General/generalActions';
import BuyPremiumModal from './BuyPremiumModal';
import { formatNumber } from '../../../../../helpers/stringsManipulations';
import html2canvas from 'html2canvas';
import jsPdf from 'jspdf';
import { PDFDocument, rgb } from 'pdf-lib';
import MultipleReceiptsUploadModal from './MultipleReceiptsUploadModal';
import AddMultipleDocumentsCofirmationModal from './AddMultipleDocumentsCofirmationModal';
import EXIF from 'exif-js';

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var { checkProperty, yearDifference, yearDifferenceMoment, loader } = Autoload.MainHelper;
dayjs.extend(customParseFormat);

const areEqual = (prevProps, nextProps) => (prevProps.billingRelevantSwitch === nextProps.billingRelevantSwitch && prevProps.file.size === nextProps.file.size);
const FilePreview = React.memo(({file, billingRelevantSwitch=false, edit, onExpand, costAllocation, isMobile, addFile, removePage}) => {
  const [imgScale, setImgScale] = React.useState(100);
  if (file) {
    let re = /(?:\.([^.]+))?$/;
    var extension = re.exec(!edit ? file.name : typeof(file) === 'object' ? file.name : file)[1];
    extension = extension && extension.split('?')[0];
  
    if (extension === 'jpg' || extension === 'png' || extension === 'jpeg') {
      const url = !edit ? URL.createObjectURL(file) : typeof(file) === 'object' ? URL.createObjectURL(file) : file;
      return (
      <div style={{width: `${imgScale}%`, textAlign:'center'}}>
        <img src={url} className="main-img" alt="no" />
        <div style={{textAlign: 'left', position: 'absolute', //bottom: !isMobile && 27, 
        top: isMobile ? 367 : 667, left: 4}}> 
          <nav>
              <Button size={'sm'} disabled={imgScale === 200} onClick={() => setImgScale(imgScale + 10)} children={<ZoomIn/>}/>
              {' '}
              <Button size={'sm'} disabled={imgScale === 100} onClick={() => setImgScale(imgScale - 10)} children={<ZoomOut/>}/>
              {' '}
              <Button size={'sm'} onClick={() => onExpand()} children={<img src={ExpandIcon}/>}/>
          </nav>
        </div>
      </div>
      );
    } else if (typeof(file) === 'object') {
      const url =URL.createObjectURL(file);
      return (
        <PdfViewer file={url} preview={true} costAllocation={!costAllocation} billingRelevantSwitch={billingRelevantSwitch} onExpand={()=> onExpand()} addFile={()=> addFile()} removePage={(pageNumber)=> removePage(pageNumber)}/>
      );
    } else {
      return (
        <Stack style={{width: '100%', height: isMobile ? '400px' : '700px'}} justify='center' align={'center'}>
          <Group style={{width: '100%', height: '100%'}} position='center' align={'center'}>
            <img src={Receipt} style={{objectFit: 'cover'}} className="main-img" alt="no" />
          </Group>
        </Stack>
      );
    }
  }
}, areEqual); 

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

class ReceiptDetailModal extends Component {
  initialState = {
    receiptName: '',
    receiptNameErr: false,
    amount: null,
    amountErr: false,
    netAmount: null,
    vatAmount: null,
    laborCost: null,
    date: new Date(),
    UtilityBillFilter: false,
    costTypeOptions: [],
    selectedCostType: [],
    timeframe: 'current',
    timeframeToSend: {},
    selectedFile: null,
    selectedFiles: [],
    deletedFile: false,
    editedFile: false,
    selectedFileErr: { state: false, msg: 'No file selected' },
    customDate: {
      start: null,
      end: null,
    },
    AddCostTypeModal: false,
    //disables multiselect
    multiselectEnable: true,
    cosTypeErr: false,
    isFormValid: false,
    errors: {
      title: null,
      amount: null,
      costType: null,
    },
    billingPeriods: [],
    imageOpen: false,
    currentStartDate: '',
    currentEndDate: '',
    previousStartDate: '',
    previousEndDate: '',
    imgScale: 100,
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    vatTypes: [
      {label: '19,00 %'},
      {label: '7,00 %'},
      {label: 'anderer'}
    ],
    taxCostTypes: [
      // {label: 'Abschreibungen für GWG'},
      {label: 'Schuldzinsen'},
      {label: 'Geldbeschaffungskosten'},
      {label: 'Erhaltungsaufwendungen'},
      {label: 'Laufende Betriebskosten'},
      {label: 'Verwaltungskosten'},
      {label: 'Sonstige Kosten'},

      {label: 'Erstattete Vorauszahlungen'},
      {label: 'Erhaltene Nachzahlungen'},
      {label: 'Vereinnahmte Kautionen / Mieten aus Vorjahren'},
      {label: 'Vom Finanzamt erstattete Umsatzsteuer'},
      {label: 'Öffentliche Zuschüsse'},
      {label: 'AfA Gebäude'},
      {label: 'AfA bewegliche Wirtschaftsgüter'},
      {label: 'An Finanzamt gezahlte Umsatzsteuer'},
      {label: 'Renten, dauernde Lasten'},
    ],
    selectedVatType: [],
    customVatType: '0%',
    selectedTaxCostType: [],
    aiLoader: false,
    hoverOnStarter: false,
    error: [],
    openPaidModal: false,
    vatInFocus: false,
    laborCostInFocus: false,
    netInFocus: false,
    vatPercentInFocus: false,
    amountInFocus: false,
    notes: null,
    receiptNumber: null,
    openPaidModalForTaxCostType: false,
    openMultipleReceiptsUploadModal: false,
    openAddMultipleDocumentsCofirmationModal: false,
    acceptedFiles: [],
    rejectedFiles: [],
    amountType: null,
    firstTimeAmountNegative: true,
    fileWasEdited: false
  };

  // currentStartDate;
  // currentEndDate;
  // previousStartDate;
  // previousEndDate; 

  constructor(props) {
    super(props);
    this.multiselectRef = React.createRef();
    this.state = this.initialState;
    props.propertySelected && this.getBillingPeriods('currentBillingPeriod');
    loader(true);

    this.fileInputRef = React.createRef();
  }

  formatDates = (billingPeriods) => {
    if(billingPeriods.length != 0){
      let filteredBillingPeriods= billingPeriods
      // .filter((value, index, self) =>
      // index === self.findIndex((t) => (
      //   t.startDate === value.startDate && t.endDate === value.endDate
      // )))
      this.setState({
        currentEndDate: dayjs(filteredBillingPeriods[0].endDate).format('DD.MM.YYYY'),
        currentStartDate: dayjs(filteredBillingPeriods[0].startDate).format('DD.MM.YYYY'),
        previousEndDate: filteredBillingPeriods[1] ? dayjs(filteredBillingPeriods[1].endDate).format('DD.MM.YYYY') : '',
        previousStartDate: filteredBillingPeriods[1] ? dayjs(filteredBillingPeriods[1].startDate).format('DD.MM.YYYY'): ''
      }, ()=> loader(false));
    }
  }

  toggleAddCostTypeModal = () => {
    this.setState({ AddCostTypeModal: !this.state.AddCostTypeModal });
  };

  componentDidMount() {
    const {transactionAssigment=null} = this.props; 
    console.log('window.location: ', window.location.pathname);
    // 1 call dropdown options api
    // this._calDropdownOption();
    // this.setState({ selectedCostType: this.props.selectedCostType });
    this.getCostTypes();

    if(transactionAssigment){
      console.log('componentDidMount: ', transactionAssigment);
      this.setState({
        receiptName: transactionAssigment.counterpartName,
        amount: transactionAssigment.amount,
        date: new Date(transactionAssigment.bankBookingDate),
        notes: transactionAssigment.purpose
      });
    }
    // console.log(this.props);
  }
  componentDidUpdate() {
    // this.state.costTypeOptions.length === 0 && this.getCostTypes();
  }

  getCostTypes(id=null) {
    console.log('getCostTypes: ', id);
    this.props.propertySelected && GlobalServices._getCostTypeByProperty(this, '_handleCostTypeDropDown', this.props.propertySelected, id);
  }

  _handleCostTypeDropDown(response) {
    console.log(response, 'get cost type response');
    // if (response.statuscode == 404) {
    //   this.setState({ multiselectEnable: false });
    // }
    if (response.statuscode === 500) {
      this.setState({ multiselectEnable: true });
    } else {
      const keys = GeneralDataHandler.getCostTypes(response.result);
      const costTypes = keys.filter((keys) => keys.useInReceiptMgmt === true);
      //  console.log(costTypes,'add cost type')
      //
      this.setState({ costTypeOptions: costTypes, multiselectEnable: false, selectedCostType: response.other ? [costTypes.find((costType)=> costType.id === response.other)] : [] });
    }
    console.log(this.state.costTypeOptions, 'cost type optioins');
  }

  _getReceiptDetails() {
    Autoload.HttpRequestAxios.HttpRequest(
      // Autoload.Setting.rm_api_base_url + 'receipts/' + this.props.record.id,
      Autoload.Setting.rm_api_base_url + 'receipt/' + this.props.record.id, // not according to swagger
      // Autoload.Setting.receipts + this.props.record.id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_getReceiptDetailsResponse',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _getReceiptDetailsResponse(response) {
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      const cost =
        checkProperty(response.result, 'costs') === ''
          ? checkProperty(response.result, 'cost')
          : checkProperty(response.result, 'costs');
      const date =
        checkProperty(response.result, 'receiptDate', '') === ''
          ? checkProperty(response.result, 'date', '')
          : checkProperty(response.result, 'receiptDate', '');
      const receiptPeriodStart = checkProperty(response.result, 'receiptPeriodStart', '');
      const receiptPeriodEnd = checkProperty(response.result, 'receiptPeriodEnd', '');

      //console.log('response recipet modal', response.result);

      this.setState(
        {
          receiptName: checkProperty(response.result, 'name'),
          receiptNameErr: false,
          date: this.formatDate(dayjs(date).format('DD.MM.YYYY')),
          receiptPeriodStart: this.formatDate(dayjs(receiptPeriodStart).format('DD.MM.YYYY')),
          receiptPeriodEnd: this.formatDate(dayjs(receiptPeriodEnd).format('DD.MM.YYYY')),
          utilityBillingRelevant: checkProperty(response.result, 'utilityBillingRelevant', ''),
          // amount: Autoload.MainHelper.ConverterCurrencyDe(cost + ''),
          amount: cost,
          selectedCostType: checkProperty(response.result, 'selectedCostTypes')
            ? checkProperty(response.result, 'selectedCostTypes').map((costType) => {
              return {
                id: checkProperty(costType, 'id'),
                costTypeName: checkProperty(costType, 'costTypeName'),
                propertyId: checkProperty(costType, 'propertyId'),
                systemCostType: checkProperty(costType, 'systemCostType'),
                useInReceiptMgmt: checkProperty(costType, 'useInReceiptMgmt'),
                heatingCostType: checkProperty(costType, 'heatingCostType'),
                allocationKey: {
                  allocationKeyId: checkProperty(costType.allocationKey, 'allocationKeyId'),
                  allocationKeyName: checkProperty(costType.allocationKey, 'allocationKeyName'),
                  propertyId: checkProperty(costType.allocationKey, 'propertyId'),
                  units: checkProperty(costType.allocationKey, 'units'),
                  metered: checkProperty(costType.allocationKey, 'metered'),
                  systemAllocationKey: checkProperty(costType.allocationKey, 'systemAllocationKey'),
                },
              };
            })
            : [],
          amountErr: false,
          UtilityBillFilter: checkProperty(response.result, 'utilityBillingRelevant', false),
          timeframe: this.setTimeFrame(
            checkProperty(response.result, 'receiptPeriodStart', '') +
            ' - ' +
            checkProperty(response.result, 'receiptPeriodEnd', '')
          ),
          selectedFile:
            checkProperty(response.result, 'file_name') === ''
              ? checkProperty(response.result, 'fileName')
              : checkProperty(response.result, 'file_name'),
          selectedFileErr: { state: false, msg: 'No file selected' },
          selectedVatType: [response.result.vatPercent && (response.result.vatPercent === 19 || response.result.vatPercent === 7) ? {label: `${response.result.vatPercent.toString()},00%`} : {label: 'anderer'}],
          customVatType: response.result.vatPercent && (response.result.vatPercent !== 19 && response.result.vatPercent !== 7) ? response.result.vatPercent : null,
          vatAmount: response.result.vatAmount,
          netAmount: response.result.netAmount,
          laborCost: response.result.laborCost,
          selectedTaxCostType: [response.result.taxCostType && {label: response.result.taxCostType}],
          receiptNumber: response.result.receiptNumber,
          notes: response.result.notes
          // selectedCostType: this.setSelectedCost(checkProperty(this.props.record, 'cost_type')),
          // customDate: (this.state.timeframe === 'custom') ? this.setCustomDate(checkProperty(this.props.record, 'time_frame')) : { start: null, end: null }
        },

        () => {
          const id = checkProperty(response.result, 'id', null);
          this._getReceiptFile(id);
          Autoload.MainHelper.cust_fb_element('cc_i_re_a', 250);
        }
      );
      console.log(
        this.state.selectedFile,
        this.state.timeframe,
        this.state.receiptPeriodStart,
        this.state.receiptPeriodEnd,
        'selectedFile'
      );
    }
  }

  _getReceiptFile(receiptId) {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.rm_api_base_url + 'attachment/' + receiptId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_getReceiptFileResponse',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  _getReceiptFileResponse(response) {
    console.log(response, 'edit reciept');
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }
    console.log('_getReceiptFileResponse: ', this.props.record);
    if (response.status === true && response.statuscode === 200 && response.result) {
      this.setState({ 
        editedFile: response.result, 
        selectedFile: this.props.record.file_name && [new File([this.props.record.file_name], this.props.record.fileName, {
          type: "application/pdf",
          lastModified: new Date().getTime()
        })]
      }, ()=> this.props.record.fileName.includes('pdf') === false && this.convertImageToPdf(this.props.record.file_name));
    }
  }

  resetForm = () => {
    this.setState({ ...this.initialState });
  };

  formatTimeframeToSend = () => {
    let frame = {};
    const {billingPeriods} = this.state;
    switch (this.props.utilityData ? this.props.utilityData.recordStatus : this.state.timeframe) {
      case 'last':
        frame = {
          start: dayjs(billingPeriods[1].startDate, billingPeriods[1].startDate.includes('T') ? 'YYYY-MM-DDT00:00:00' : 'DD.MM.YYYY').format('YYYY-MM-DD'),
          end: dayjs(billingPeriods[1].endDate, billingPeriods[1].startDate.includes('T') ? 'YYYY-MM-DDT00:00:00' : 'DD.MM.YYYY').format('YYYY-MM-DD')
        };
        break;
      case 'current':
        frame = {
          start: dayjs(billingPeriods[0].startDate, billingPeriods[1].startDate.includes('T') ? 'YYYY-MM-DDT00:00:00' : 'DD.MM.YYYY').format('YYYY-MM-DD'),
          end: dayjs(billingPeriods[0].endDate, billingPeriods[1].startDate.includes('T') ? 'YYYY-MM-DDT00:00:00' : 'DD.MM.YYYY').format('YYYY-MM-DD')
        };
        break;
      case 'custom':
        const { start } = this.state.customDate;
        // mm dd yy -- yyyy-mm-dd
        if (start != null) {
          let customStartDate = dayjs(this.state.customDate.start, 'DD.MM.YYYY').format('YYYY-MM-DD');
          let customEndDate = dayjs(this.state.customDate.end, 'DD.MM.YYYY').format('YYYY-MM-DD');
          frame = {
            start: customStartDate,
            end: customEndDate
          };
        } else {
          frame = {
            start: this.todayDateFrame(new Date()),
            end: this.todayDateFrame(new Date()),
          };
          this.setState({
            customDate: {
              start: this.todayDateFrame(new Date()),
              end: this.todayDateFrame(new Date()),
            },
          });
        }
        break;
      default:
        if(this.props.utilityData && this.props.utilityData.date) {
           let dateSplit= this.props.utilityData.date.split(' - ');
           frame = {
            start: dayjs(dateSplit[0], 'DD.MM.YYYY').format('YYYY-MM-DD'),
            end: dayjs(dateSplit[1], 'DD.MM.YYYY').format('YYYY-MM-DD'),
          };
        }
        break;
    }
    return frame;
  };

  todayDateFrame = (date) => {
    console.log(date, 'date dateToday');
    var dateMonth = date.getMonth() + 1;
    var dateDate = date.getDate();
    var dateToday = `${date.getFullYear()}-${dateMonth > 10 ? dateMonth : '0' + dateMonth}-${
      dateDate > 10 ? dateDate : '0' + dateDate
      }`;
    // console.log(dateToday,'dateToday')
    return dateToday;
  };
  formatFrameSend = (date) => {
    return date === '' ? new Date() : `${date.split('.')[2]}-${date.split('.')[0]}-${date.split('.')[1]}`;
  };
  formatFrame = (date) => {
    console.log(date, 'date formatFrame');
    return date === '' ? new Date() : `${date.split('.')[2]}-${date.split('.')[0]}-${date.split('.')[1]}`;
    // if (this.props.edit == true) {
    //   return date === '' ? new Date() : `${date.split('.')[2]}-${date.split('.')[0]}-${date.split('.')[1]}`;
    // } else return date === '' ? new Date() : `20${date.split('.')[2]}-${date.split('.')[0]}-${date.split('.')[1]}`;
  };

  setCustomDate = (timeFrame) => {
    if (timeFrame === '') {
      this.setState({ customDate: { start: null, end: null } });
    } else {
      let tempDate = timeFrame.split(' - ');
      this.setState({
        customDate: { start: dayjs(tempDate[0], 'YYYY-MM-DDT00:00:00').format('DD.MM.YYYY'), end: dayjs(tempDate[1], 'YYYY-MM-DDT00:00:00').format('DD.MM.YYYY') },
      });
      console.log(this.state.customDate, 'custom date to send ');
    }
  };

  setSelectedCost = (cost) => {
    let selectedCost;
    if (cost === '') {
      selectedCost = [];
    } else {
      selectedCost = this.state.costTypeOptions.filter((item) => cost === item.name);
    }
    return selectedCost;
  };

  setTimeFrame =  (frame) => {
    console.log(frame, '=====frame=======');
    if (frame === '') {
      return 'last';
    } else {
      const dates = frame.split(' - ');
      const periodStartDate = dates.length > 0 && dayjs(dates[0]).format('DD.MM.YYYY');
      const splitStartDate = periodStartDate.split('.');
      const periodEndDate = dates.length > 0 && dayjs(dates[1]).format('DD.MM.YYYY');
      const splitEndDate = periodEndDate.split('.');
      const {currentStartDate, currentEndDate, previousStartDate, previousEndDate} = this.state;
      console.log('setTimeFrame:', currentStartDate === periodStartDate, currentEndDate === periodEndDate);
      if (
        currentStartDate === periodStartDate && currentEndDate === periodEndDate
        // `${splitStartDate[1]}.${splitStartDate[2]}` === `${splitStartDate[1]}.${dayjs().get('year')}` &&
        // `${splitEndDate[1]}.${splitEndDate[2]}` === `${splitEndDate[1]}.${dayjs().get('year')}`
      ) {
        return 'current';
      }
      if (
        previousStartDate === periodStartDate && previousEndDate === periodEndDate
        // `${splitStartDate[1]}.${splitStartDate[2]}` === `${splitStartDate[1]}.${dayjs().get('year') - 1}` &&
        // `${splitEndDate[1]}.${splitEndDate[2]}` === `${splitEndDate[1]}.${dayjs().get('year') - 1}`
      ) {
        return 'last';
      }
      this.setCustomDate(frame);
      return 'custom';
      // switch (yearDifferenceMoment(periodStartDate)) {
      //   case 0:
      //     return 'current';
      //   case 1:
      //     return 'last';
      //   default:
      //     this.setCustomDate(frame);
      //     return 'custom';
      // }
    }
  };

  formatDate = (date) => {
    // // ;
    if (date === '') {
      return new Date();
    } else {
      let dateTemp = date.split('.');
      // date = date.replaceAll('.', '-')
      let d = new Date(dateTemp[2] + '-' + dateTemp[1] + '-' + dateTemp[0]);
      console.log('date format', d);
      return d;
      // let dateTemp = date.split('.');
      // return dateTemp[1] + '.' + dateTemp[0] + '.' + dateTemp[2];
    }
  };

  onDateChange = (field, date) => {
    console.log('onDateChange: ', date);
    this.setState({ [field]: date ? date : new Date() });
  };

  handleCheck = () => {
    this.setState({ UtilityBillFilter: !this.state.UtilityBillFilter });
  };

  calculateVatUsingAmount =(value, vatPercent)=> {
    return value - (value / parseFloat(`${1 + (vatPercent / 100)}`));
  }

  onInputChange = (field, value) => {
    console.log('onInputChange: ', field, value);
    const {customVatType, selectedVatType} = this.state;
    if(field === 'amount') {
      if(value){
        let vatPercent= parseFloat(selectedVatType.length > 0 && (selectedVatType[0]?.label === 'anderer' ? customVatType : selectedVatType[0]?.label));
        let vatAmount = this.calculateVatUsingAmount(value, vatPercent);
        this.setState({amount: value, vatAmount: vatAmount, netAmount: value - vatAmount, firstTimeAmountNegative: false});
      }else{
        this.setState({amount: value, vatAmount: '', netAmount: '', customVatType: null, selectedVatType: []});
      }
      
    } else if(field === 'netAmount'){
      //let vatPercent= parseInt(selectedVatType.length > 0 && (selectedVatType[0].label === 'anderer' ? customVatType : selectedVatType[0].label));
      //let amount = (value * parseFloat(`${1 + (vatPercent / 100)}`));
      let vatPercent = this.state.amount && (((this.state.amount / value) * 100) - 100);
      let vatAmount = this.state.amount && (this.state.amount - (this.state.amount / parseFloat(`${1 + (vatPercent / 100)}`)));
      this.setState({vatAmount: vatAmount, netAmount: value, customVatType: vatPercent, selectedVatType: [{label: 'anderer'}]});
    } else if(field === 'vatAmount'){
      //let vatPercent= parseInt(selectedVatType.length > 0 && (selectedVatType[0].label === 'anderer' ? customVatType : selectedVatType[0].label));
      let vatPercent = this.state.amount && Math.abs(((value / (value - this.state.amount)) * 100));
      let netAmount = this.state.amount && (value / parseFloat(`${vatPercent / 100}`));
      //let amount = netAmount + value;
      this.setState({vatAmount: value, netAmount: Math.round(netAmount * 100) / 100, customVatType: vatPercent, selectedVatType: [{label: 'anderer'}]});
    } else if(field === 'customVatType'){
      let vatPercent= parseFloat(value);
      let amount = this.state.amount;
      let vatAmount = this.state.amount && amount - (amount / parseFloat(`${1 + (vatPercent / 100)}`));
      let netAmount= amount - vatAmount
      console.log('customVatType: ', vatPercent, amount, vatAmount, netAmount);
      this.setState({customVatType: value, amount, vatAmount, netAmount});
    } else if(field === 'laborCost'){
      console.log('this.state.amount: ', this.state.amount);
      this.setState({ [field]: this.state.amount < 0 ? (value < 0 ? value : (value * -1)) : value });
    }
    else{
      this.setState({ [field]: value });
    }
  };

  onCostTypeSelect = (selectedList, selectedItem) => {
    this.setState({ selectedCostType: [selectedItem] });
  };
  onCostTypeRemove = (selectedList, removedItem) => {
    this.setState({ selectedCostType: [removedItem] });
  };

  onVatPercentageSelect = (selectedList, selectedItem) => {
    if(selectedItem.label === this.state.selectedVatType[0]?.label){
      this.setState({ selectedVatType: [], vatAmount: '', netAmount: '' });  
    }else{
      if(this.state.amount){
        let vatPercent= parseInt(selectedItem.label);
        let vatAmount = this.state.amount - (this.state.amount / parseFloat(1 + (vatPercent / 100)));
        let netAmount = this.state.amount - vatAmount;
        console.log('onVatPercentageSelect: ', vatAmount);
        this.setState({vatAmount: vatAmount, netAmount: netAmount, selectedVatType: [selectedItem]});
      }else{
        this.setState({ selectedVatType: [selectedItem] });
      }
    }
  };

  onVatPercentageRemove = (selectedList, selectedItem) => {
    if(selectedItem.label === this.state.selectedVatType[0]?.label){
      this.setState({ selectedVatType: [], vatAmount: '', netAmount: '' });  
    }else{
      if(this.state.amount){
        let vatPercent= parseInt(selectedItem.label);
        let vatAmount = this.state.amount - (this.state.amount / parseFloat(1 + (vatPercent / 100)));
        let netAmount = this.state.amount - vatAmount;
        this.setState({vatAmount: vatAmount, netAmount: netAmount, selectedVatType: [selectedItem]});
      }else{
        this.setState({ selectedVatType: [selectedItem] });
      }
    }
  };

  onTaxCostTypeSelect = (selectedList, selectedItem) => {
    const {currentSubscription} = this.props;
    if(currentSubscription.subscriptionKey.split('_')[0] === 'STARTER'){
      this.setState({openPaidModalForTaxCostType: true});
    }else{
      this.setState({ selectedTaxCostType: [selectedItem] });  
    }
  };
  onTaxCostTypeRemove = (selectedList, removedItem) => {
    this.setState({ selectedTaxCostType: [removedItem] });
  };

  handleFileChange = (acceptedFile, rejectedFile) => {
    console.log('handleFileChange: ', acceptedFile);

    const runConverIntoSingleFile=()=>{
      let {selectedFiles} = this.state;
      if(selectedFiles.length > 0){
        acceptedFile.map((af)=> selectedFiles.find((file)=> file.path === af.path) === undefined && selectedFiles.push(af));
        this.setState({selectedFiles, receiptName: acceptedFile[0].path.replace(/\.[^/.]+$/, '')},()=> {
          // callUploadForAiRecognition();
          this.convertImagesToPdf();
        });
      }else{
        this.setState({selectedFiles: acceptedFile, receiptName: acceptedFile[0].path ? acceptedFile[0].path.replace(/\.[^/.]+$/, '') : acceptedFile[0].name.replace(/\.[^/.]+$/, '')}, ()=> {
          // callUploadForAiRecognition();
          this.convertImagesToPdf();
        });
      }

      if (this.props.edit) {
        this.setState({ editedFile: true });
      }
    }

    console.log('acceptedFile: ', acceptedFile);
    if (acceptedFile.length > 0) {
      runConverIntoSingleFile();
    }
    if (rejectedFile.length > 0) {
      console.log('rejectedFile: ', rejectedFile);
      rejectedFile.map((file, index)=>
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          this.setState({error: [...this.state.error, {fileName: file.file.name, message: 'Datei zu groß'}]}, ()=> 
          {
            if(rejectedFile.length === (index+1)){
              showNotification({
                message: 
                <Stack spacing={2}>
                  <Group>Es ist ein Fehler aufgetreten. Das Dokument ist zu groß. Maximal erlaubte Dokumentgröße ist 100 MB.</Group>
                  {this.state.error.map((err)=> 
                    <Group noWrap spacing={2}>
                      <span style={{
                        // maxWidth: '290px', 
                        // overflowX: 'hidden', 
                        // textOverflow: 'ellipsis', 
                        // whiteSpace: 'nowrap'
                        }}>
                          {`Dateiname: ${err.fileName}`}
                      </span>
                      {/* <span>{'->'}</span>
                      <span style={{whiteSpace: 'nowrap', color: '#db1738'}}>{`${err.message}`}</span> */}
                    </Group>)}
                </Stack>,
                icon: <img src={RedCross} alt='red-cross'/>,
                id: 'fileSelectionError',
                autoClose: false
              });
              this.setState({error: []});
            }
          })
        }
        if (err.code === 'file-invalid-type') {
          this.setState({error: [...this.state.error, {fileName: file.file.name, message: 'Dateityp ungültig'}]}, ()=> 
          {
            if(rejectedFile.length === (index+1)){
              showNotification({
                message: 
                <Stack spacing={2}>
                  {this.state.error.map((err)=> <Group noWrap spacing={2}><span style={{maxWidth: '290px', overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{`Dateiname: ${err.fileName}`}</span><span>{'->'}</span><span style={{whiteSpace: 'nowrap', color: '#db1738'}}>{`${err.message}`}</span></Group>)}
                </Stack>,
                icon: <img src={RedCross} alt='red-cross'/>,
                id: 'fileSelectionError',
                autoClose: false
              });
              this.setState({error: []});
            }
          });
        }
      }));
    } else {
      this.setState({ selectedFileErr: { state: false } });
    }

  };

  async convertImageToPdf(file){
    const {edit, record} = this.props;
    let files= [];
    console.log('convertImageToPdf: ', file);

    const conversionCode=(conversionFile= null)=>{
      const reader = new FileReader();
      if(conversionFile){
        reader.onload = async function (event) {
          console.log('if');
            const imgData = event.target.result;
            const img = new Image();
  
            // Create a new jsPDF instance
            img.onload = async function() {
              // Get the image dimensions
              const imgWidth = img.width;
              const imgHeight = img.height;
  
              // Calculate dimensions based on aspect ratio
              var doc = new jsPdf("p", "pt", 'a4');
              const pdfWidth = doc.internal.pageSize.getWidth();
              const pdfHeight = doc.internal.pageSize.getHeight();
  
              let width, height;
  
              // Calculate the scaling factor to fit the image within the page
              const widthScale = pdfWidth / imgWidth;
              const heightScale = pdfHeight / imgHeight;
              const scale = Math.min(widthScale, heightScale);
  
              width = imgWidth * scale;
              height = imgHeight * scale;
  
              // Center the image on the PDF
              const x = (pdfWidth - width) / 2;
              const y = (pdfHeight - height) / 2;
  
              // Add the image to the PDF with calculated dimensions
              doc.addImage(imgData, 'JPEG', x, y, width, height);
              // Add the image to the PDF
              // doc.addImage(imgData, 'JPEG', 10, 10, 180, 160);
              console.log('imgData: ', imgData);
              //doc.addImage(imgData, 'JPEG', 20, 20, 555, 802, undefined, 'FAST');
              await files.push(doc.output('arraybuffer'));
            };
            img.src = imgData;
        };
  
        reader.readAsDataURL(conversionFile);
      }
    }

    if(file){
      if(file.toString().includes('https') && edit){
        fetch(Autoload.Setting.rm_api_base_url + 'attachment/' + record?.id +'/download',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          'Authorization': Autoload.Utils.getAuthToken()
        }
      })
      .then(response => {
          response.blob()
          .then(blob => {
            conversionCode(blob);
          })
        })
      }else{
        conversionCode(file);
      }
      
    }else{
      console.log('else');
      this.convertBlobToArrayBuffer(file).then((value)=> {
        files.push(value);
      });
    }

    console.log('files: ', files);

    // if(files.length > 0){
      setTimeout(() => {
        this.combineFiles(files, false);
      }, 1000);
    //}
  }

  async convertImagesToPdf(stopAiRecognition=false){
    const {selectedFiles}= this.state;
    let files= [];
    selectedFiles.map(async(file, index)=> {
      if(file.type.includes('image')){
        const reader = new FileReader();

        reader.onload = async function (event) {
            const imgData = event.target.result;
            const img = new Image();

            // Create a new jsPDF instance
            


            img.onload = async function() {
              // Get the image dimensions
              const imgWidth = img.width;
              const imgHeight = img.height;

              // Calculate dimensions based on aspect ratio
              var doc = new jsPdf("p", "pt", 'a4');
              const pdfWidth = doc.internal.pageSize.getWidth();
              const pdfHeight = doc.internal.pageSize.getHeight();

              let width, height;

              // Calculate the scaling factor to fit the image within the page
              const widthScale = pdfWidth / imgWidth;
              const heightScale = pdfHeight / imgHeight;
              const scale = Math.min(widthScale, heightScale);

              width = imgWidth * scale;
              height = imgHeight * scale;

              // Center the image on the PDF
              const x = (pdfWidth - width) / 2;
              const y = (pdfHeight - height) / 2;

              // Add the image to the PDF with calculated dimensions
              doc.addImage(imgData, 'JPEG', x, y, width, height);
              // Add the image to the PDF
              // doc.addImage(imgData, 'JPEG', 10, 10, 180, 160);
              console.log('imgData: ', imgData);
              //doc.addImage(imgData, 'JPEG', 20, 20, 555, 802, undefined, 'FAST');
              await files.push(doc.output('arraybuffer'));
            };
            img.src = imgData;
        };

        reader.readAsDataURL(file);
        // await html2canvas(file, {
        //   scale: 4
        // }).then(async(canvas) => {
        //   const imgData = canvas.toDataURL('image/png');
        //   var doc = new jsPdf("p", "pt", 'a4');
        //   console.log('imgData: ', imgData);
        //   doc.addImage(imgData, 'JPEG', 20, 20, 575, 822, undefined, 'FAST');
        //   await files.push(doc.output('arraybuffer'));
        // }).catch((e)=> console.error('convertImageToPdf: ', e));
      }else{
        this.convertBlobToArrayBuffer(file).then((value)=> {
          files.push(value);
        });
      }

      if(selectedFiles.length === (index + 1)){
        setTimeout(() => {
          this.combineFiles(files, stopAiRecognition);
        }, 1000);
      }
    });
    
    
  }

  async convertBlobToArrayBuffer(blob) {
    console.log('convertBlobToArrayBuffer', blob);
    const buf = await blob.arrayBuffer();
    return buf;
  }

  combineFiles=(files, stopAiRecognition=false)=>{
    console.log('combineFiles: ', this.props.record);
    const {selectedFiles} = this.state;
    const pdfBytes = this.mergePdfs(files);
    const fileName = this.props?.record?.fileName;
    const setState=(pdfFile)=> this.setState({selectedFile: [pdfFile], fileWasEdited: true}, ()=> {
      if(this.props.edit === false && this.props.currentSubscription.subscriptionKey.split('_')[0] !== 'STARTER'){
        stopAiRecognition == false && this.uploadForAIRecognition();
      }
    });
    pdfBytes.then(function(result) {
        var blob = new Blob([result], {type: "application/pdf"});
        const pdfFile = new File([blob], `${(selectedFiles[0]?.name ? selectedFiles[0]?.name : fileName) + (selectedFiles[0]?.name.includes('.pdf') ? '' : '.pdf')}`, {
          type: "application/pdf",
          lastModified: new Date().getTime()
        });
        console.log('uploadForAIRecognition: ', pdfFile);
        setState(pdfFile);
    })
    console.log('pdfBytes: ', pdfBytes);
  }

  async mergePdfs(pdfsToMerge) {
    console.log('mergePdfs', pdfsToMerge);
    const mergedPdf = await PDFDocument.create();

  const createInnerPromise = async (arrayBuffer) => {
    const pdf = await PDFDocument.load(arrayBuffer);
    return await mergedPdf.copyPages(pdf, pdf.getPageIndices());
  };

  const outerPromise = pdfsToMerge.map((arrayBuffer) => {
    const innerPromise = createInnerPromise(arrayBuffer);
    return innerPromise;
  });

  const resultOuterPromise = await Promise.all(outerPromise);

  let count = 0;
  let totalPages = 0;
  resultOuterPromise.forEach((pageArray, i) => {
    pageArray.forEach((page, index) => {
      totalPages = totalPages + 1;
    })
  });
  resultOuterPromise.forEach((pageArray, i) => {
    pageArray.forEach((page) => {
      // const { width, height } = page.getSize();
      // page.drawText(`Seite ${count+1} von ${totalPages}`, {
      //   x: width - 67,
      //   y: height - 26,
      //   size: 6,
      //   color: rgb(0.5, 0.5, 0.5),
      // })
      mergedPdf.addPage(page);
      // count = count + 1;
    });
  });

  return (await mergedPdf.save()).buffer;
  }

  removeImage = () => {
    console.log('remove');
    // this._deleteFile();
    this.setState({ selectedFile: null, selectedFiles: [] });
    if (this.props.edit) {
      this._deleteFile(this.props.record.id);
      // this.setState({ deletedFile: true });
    }
  };

  daysBetween = (date1, date2) => {
    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;
    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(date1 - date2);
    // Convert back to days and return
    return Math.round(differenceMs / ONE_DAY);
  };

  getBillingPeriods(periodType) {
    this.props.propertySelected && this.props.propertySelected.id !== undefined &&
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url +
      periodType +
      '?propertyId=' +
      this.props.propertySelected.id +
      '&offset=0&fetch=10',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      `_handle${periodType}Response`,
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _handlecurrentBillingPeriodResponse(response) {
    console.log('_handlecurrentBillingPeriodResponse', response);
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      response.result.period = `${lang.utility.text_current_billing_period} (${dayjs(response.result.startDate).format(
        'DD.MM.YYYY'
      )}-${dayjs(response.result.endDate).format('DD.MM.YYYY')})`;
      const billingPeriods = [...this.state.billingPeriods, response.result];
      this.currentBillingPeriodId = response.result.id;
      if (response.result.id === Autoload.Utils.getUrlParameterByName('billingId')) {
        this.setState({ currentBillingPeriod: { start: response.result.startDate, end: response.result.endDate } })
      }

      this.setState({ billingPeriods });
      this.getBillingPeriods('lastBillingPeriod');
    }else{
      loader(false);
    }
  }

  _handlelastBillingPeriodResponse(response) {
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      // response.result.period = `Last billing period (${dayjs(response.result.startDate).format('DD.MM.YYYY')}-${dayjs(
      //   response.result.endDate
      // ).format('DD.MM.YYYY')})`;

      response.result.period = `${lang.utility.text_last_billing_period} (${dayjs(response.result.startDate).format(
        'DD.MM.YYYY'
      )}-${dayjs(response.result.endDate).format('DD.MM.YYYY')})`;
      const billingPeriods = [...this.state.billingPeriods, response.result];
      this.lastBillingPeriodId = response.result.id;

      if (response.result.id === Autoload.Utils.getUrlParameterByName('billingId')) {
        this.setState({ lastBillingPeriod: { start: response.result.startDate, end: response.result.endDate } })
      }
      this.setState({ billingPeriods });
      this.getBillingPeriods('billingPeriods');
    }
  }

  _handlebillingPeriodsResponse(response) {
    // console.log('_handlebillingPeriodsResponse', response);
    let billingPeriods = [...this.state.billingPeriods];
    if (response.status === true && response.statuscode === 401) {
      localStorage.clear();
      window.location.reload();
    }

    if (response.status === true && response.statuscode === 200) {
      response.result.map((billingPeriod) => {
        if (billingPeriod.id !== this.currentBillingPeriodId && billingPeriod.id !== this.lastBillingPeriodId) {
          billingPeriod.period = `${dayjs(billingPeriod.startDate).format('DD.MM.YYYY')}-${dayjs(
            billingPeriod.endDate
          ).format('DD.MM.YYYY')}`;
          billingPeriods = [...billingPeriods, billingPeriod];
        }
        return null;
      });
      this.setState({ billingPeriods }, () => {
        this.state.billingPeriods.map((billingPeriod) => {
          if (this.urlBillingId === billingPeriod.id) {
            this.setState({ meterDurationText: billingPeriod.period, currentMeter: billingPeriod.id });
          }
          return null;
        });
      });
    }
    this.formatDates(billingPeriods);

    this.props.edit && this._getReceiptDetails();
  }

  saveChanges = () => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    let frame = this.formatTimeframeToSend();
    const selectedCostTypes = this.state.selectedCostType;

    var selectedCostTypes_tem = [];

    if (selectedCostTypes != null) {
      selectedCostTypes.map((costType) => {
        selectedCostTypes_tem.push({ id: costType.id });
      });
    }
    const data = {
      id: this.props.record.id,
      name: this.state.receiptName,
      costs: this.state.amount,//Autoload.MainHelper.ConverterLocaleNumber(this.state.amount, 'de'),
      receiptDate: `${dayjs(this.state.date).format('YYYY-MM-DD')}T00:00:00`,
      utilityBillingRelevant: this.state.UtilityBillFilter ? true : false,
      receiptPeriodStart: this.props.billingRelevant || this.state.UtilityBillFilter ? frame.start : `${dayjs(this.state.date).format('YYYY-MM-DD')}T00:00:00`,
      receiptPeriodEnd: this.props.billingRelevant || this.state.UtilityBillFilter ? frame.end : `${dayjs(this.state.date).format('YYYY-MM-DD')}T00:00:00`,
      propertyId: this.props.propertySelected.id,
      SelectedCostTypes: this.state.UtilityBillFilter ? selectedCostTypes_tem : [], //[...selectedProps, ...this.multiselectRef.current.state.selectedValues],
      isValidated: true,
      //New attributes
      vatPercent: this.state.selectedVatType[0]?.label === 'anderer' ? this.state.customVatType : parseInt(this.state.selectedVatType[0]?.label),
      vatAmount: checkProperty(this.state, 'vatAmount', null) ? this.state.vatAmount : null,
      netAmount: checkProperty(this.state, 'netAmount', null) ? this.state.netAmount : null,
      laborCost: this.state.laborCost,
      taxCostType: this.state.selectedTaxCostType[0]?.label,
      receiptNumber: this.state.receiptNumber,
      notes: this.state.notes
    };
    let btnelement = document.getElementById('receipt-add-edit-btns');

    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.api_base_url + config.receipts + this.props.record.id,
      'PUT',
      data,
      btnelement.children[1],
      false,
      true,
      false,
      this,
      'handleUpdateReceipt',
      { btnelement: btnelement, id: this.props.record.id },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handleUpdateReceipt(response) {
    const {transactionAssigmentScreen} = this.props;
    const {fileWasEdited} = this.state;
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';

    const close=()=>{
      this.props.setRefreshReceiptDocumentsData(true);
      var loader = document.getElementById('hello-housing-loader');
      loader.style.display = 'none';
      this.resetForm();
      this.props.toggle();
    }
    if (response.status === true) {
      if(transactionAssigmentScreen){
        this.props.onEditSave();
      }

      if(this.state.selectedFile && this.state.selectedFile.length > 0){
        showNotification({
          message: 'Die Änderungen wurden übernommen.',
          icon: <img src={GreenTick} alt='green-tick'/>,
        });
      }
      if (!this.state.editedFile) {
        this.resetForm();
        this.props.parent && this.props.parent.bind_list_receipt_data(true);
        this.props.toggle();
        return;
      }
      if(fileWasEdited){
        if(window.location.pathname.includes('/receipt')){
          if(this.state.selectedFile && this.state.selectedFile.length > 0 && !this.state.selectedFile.includes('https')){
            console.log('test: ', this.state.selectedFile);
            this.props.showFirstRowLoader();
            this.props.setAddReceiptModal(true);
            this.props.setReceiptCurrentId(response.other.id);
            this.props.setReceiptFiles([this.state.selectedFile[0]]);
            console.log('toggle');
            var elem = document.getElementById("uploadReceiptDocs");
            if (typeof elem.onclick == "function") {
                elem.onclick.apply(elem);
                var loader = document.getElementById('hello-housing-loader');
                loader.style.display = 'none';
                this.resetForm();
                console.log('toggle');
                this.props.toggle();
            }
          }else{
            close();
          }
        }else{
          const data = new FormData();
          this.state.selectedFile && this.state.selectedFile.length > 0 && data.append('files', this.state.selectedFile[0]);
          Autoload.HttpRequestAxios.HttpRequest(
            Autoload.Setting.rm_api_base_url + 'upload/' + response.other.id,
            'PUT',
            data,
            response.other.btnelement.children[1],
            false,
            true,
            false,
            this,
            'handleaddReceiptFileUpload',
            {},
            false,
            '',
            {
              'Content-Type': 'multipart/form-data',
              Authorization: Autoload.Utils.getAuthToken(),
            }
          );
        }
      }else{
        close();
      }
      
    } else {
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        //message: 'Die Änderungen wurden gespeichert.',
        icon: <img src={RedCross} alt='red-tick'/>
      });
    }
  }

  uploadForAIRecognition = ()=> {
    console.log('uploadForAIRecognition: ', this.state.selectedFile);
    this.setState({aiLoader: true});
    const data = new FormData();
    this.state.selectedFile && this.state.selectedFile.length > 0 && data.append('files', this.state.selectedFile[0]);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.rm_api_basic_base_url + 'Invoices/analyze',
      'POST',
      data,
      null,
      false,
      true,
      false,
      this,
      'handleUploadForAIRecognition',
      {},
      false,
      '',
      {
        'Content-Type': 'multipart/form-data',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  handleUploadForAIRecognition(response){
    if (response.status === true && response.statuscode === 200) {
      console.log('handleUploadForAIRecognition: ', this.state.currentStartDate, this.state.currentEndDate, this.state.previousStartDate, this.state.previousEndDate, dayjs(response.result.serviceStartDate).format('DD.MM.YYYY'));
      let vatAmount = response.result.totalTax ? 
        response.result.totalTax 
        : 
        ((response.result.taxRate && response.result.invoiceTotal) ? this.calculateVatUsingAmount(response.result.invoiceTotal, response.result.taxRate) : null);
      let netAmount = response.result.invoiceTotal && vatAmount ? response.result.invoiceTotal - vatAmount : null;
      let timeframe = (response.result.serviceStartDate && response.result.serviceEndDate) ?
        dayjs(response.result.serviceStartDate) >= (dayjs(this.state.currentStartDate, 'DD.MM.YYYY')) && dayjs(response.result.serviceEndDate) <= (dayjs(this.state.currentEndDate, 'DD.MM.YYYY')) ? 'current'
        :
        dayjs(response.result.serviceStartDate) >= (dayjs(this.state.previousStartDate, 'DD.MM.YYYY')) && dayjs(response.result.serviceEndDate).format('DD.MM.YYYY') <= (dayjs(this.state.previousEndDate, 'DD.MM.YYYY')) ? 'last'
        : 
        'custom'
        : 
        'current';
      
      this.setState({
        amount: response.result.invoiceTotal ? response.result.invoiceTotal : this.state.amount, 
        selectedVatType: response.result.taxRate && (response.result.taxRate === 7 || response.result.taxRate === 19) ? [{label: `${response.result.taxRate},00 %`}] : [],
        customVatType: response.result.taxRate && (response.result.taxRate !== 7 && response.result.taxRate !== 19) ? response.result.taxRate : null,
        vatAmount,
        netAmount,
        date: response.result.invoiceDate ? response.result.invoiceDate : new Date(),
        receiptName: response.result.vendorName ? response.result.vendorName : this.state.receiptName,
        timeframe,
        UtilityBillFilter: response.result.serviceStartDate && response.result.serviceEndDate,
        customDate: timeframe === 'custom' && {start: response.result.serviceStartDate ? dayjs(response.result.serviceStartDate).format('DD.MM.YYYY') : dayjs().format('DD.MM.YYYY'), end: response.result.serviceEndDate ? dayjs(response.result.serviceEndDate).format('DD.MM.YYYY') : dayjs().format('DD.MM.YYYY')},
        receiptNumber: response.result.invoiceId
      })
    }
    this.setState({aiLoader: false});
  }

  _deleteFile(receiptID) {
    console.log('delete receipt file');
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.rm_api_base_url + 'attachment/' + receiptID,
      'DELETE',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleDeleteFileResponse',
      {},
      false,
      '',
      {
        'Content-Type': 'multipart/form-data',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  _handleDeleteFileResponse(response) {
    if (response.status) {
      this.setState({ deletedFile: true });
      this.props.refreshData();
    }

    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
  }

  addReceipt = () => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    const btnelement = document.getElementById('receipt-add-edit-btns');
    const frame = this.formatTimeframeToSend();
    var selectedCostTypes_tem = [];
    const selectedCostTypes = this.props.selectedCostType ? this.props.selectedCostType : this.state.selectedCostType;
    if (selectedCostTypes != null) {
      selectedCostTypes.map((costType) => {
        selectedCostTypes_tem.push({ id: costType.id });
      });
    }
    
    const data = JSON.stringify({
      id: null,
      name: this.state.receiptName,
      receiptDate: `${dayjs(this.state.date).format('YYYY-MM-DD')}T00:00:00`,
      date: `${dayjs(this.state.date).format('YYYY-MM-DD')}T00:00:00`,
      costs: this.state.amount,//Autoload.MainHelper.ConverterLocaleNumber(this.state.amount, 'de'),
      cost: this.state.amount,//Autoload.MainHelper.ConverterLocaleNumber(this.state.amount, 'de'),
      utilityBillingRelevant: this.props.billingRelevant ? true : this.state.UtilityBillFilter ? true : false,
      receiptPeriodStart: this.props.billingRelevant || this.state.UtilityBillFilter ? `${frame.start}` : `${dayjs(this.state.date).format('YYYY-MM-DD')}T00:00:00`,
      receiptPeriodEnd: this.props.billingRelevant || this.state.UtilityBillFilter ? `${frame.end}` : `${dayjs(this.state.date).format('YYYY-MM-DD')}T00:00:00`,
      propertyId: this.props.propertySelected.id,
      selectedCostTypes: selectedCostTypes_tem,
      //New attributes
      vatPercent: this.state.selectedVatType[0]?.label === 'anderer' ? this.state.customVatType : parseInt(this.state.selectedVatType[0]?.label),
      vatAmount: checkProperty(this.state, 'vatAmount', null) ? this.state.vatAmount : null,
      netAmount: checkProperty(this.state, 'netAmount', null) ? this.state.netAmount : null,
      laborCost: this.state.laborCost,
      taxCostType: this.state.selectedTaxCostType[0]?.label,
      receiptNumber: this.state.receiptNumber,
      notes: this.state.notes
    });
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.rm_api_base_url + 'create',
      'POST',
      data,
      btnelement.children[1],
      false,
      true,
      false,
      this,
      'handleaddReceipt',
      { btnelement: btnelement, data },
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handleaddReceipt(response) {
    const {transactionAssigment=null} = this.props;
    var loader = document.getElementById('hello-housing-loader');
    if(this.state.selectedFile === null ){
      loader.style.display = 'none';
    }
    window.Intercom("update", {
      api_base: "https://api-iam.intercom.io",
      app_id: "vvrs5bx3",
      'Receipt Created': true
    });
    window.Intercom('trackEvent', 'receipt-created');
    if (response.status === true) {
      
      console.log('handleaddReceipt1:', response);
      if (this.state.selectedFile === null) {
        showNotification({
          //title: 'Erfolgreich!',
          message: 'Der Beleg wurde hinzugefügt.',
          icon: <img src={GreenTick} alt='green-tick'/>,
        });
        this.resetForm();
        //dashbaord redirect else same
        transactionAssigment === null && this.props.parent.bind_list_receipt_data(true);
        (transactionAssigment === null && this.props.receiptPage === true) && this.props.checkfilterToggleBtn(false);
        this.props.toggle({...JSON.parse(response.other.data), id: response.result});
        (transactionAssigment === null && this.props.onCreateReceiptSelect) && this.props.onCreateReceiptSelect(response.result);
        //  window.location.reload();
        return;
      }
      if(window.location.href.includes('/receipt')){
        this.props.showFirstRowLoader();
        this.props.setAddReceiptModal(true); 
        this.props.setReceiptCurrentId(response.result);
        this.props.setReceiptFiles([this.state.selectedFile[0]]);
        console.log('handleaddReceipt:');
        var elem = document.getElementById("uploadReceiptDocs");
        console.log('handleaddReceipt:', typeof elem.onclick);
        if (typeof elem.onclick == "function") {
            console.log('handleaddReceipt: inside');
            elem.onclick.apply(elem);
            var loader = document.getElementById('hello-housing-loader');
            loader.style.display = 'none';
            this.resetForm();
            this.props.toggle();
        }else{
          var loader = document.getElementById('hello-housing-loader');
          loader.style.display = 'none';
          this.resetForm();
          this.props.toggle();
        }
      }else{
        const data = new FormData();
        data.append('files', this.state.selectedFile[0]);
        Autoload.HttpRequestAxios.HttpRequest(
          Autoload.Setting.rm_api_base_url + 'upload/' + response.result,
          'PUT',
          data,
          response.other.btnelement.children[1],
          false,
          true,
          false,
          this,
          'handleaddReceiptFileUpload',
          {},
          false,
          '',
          {
            'Content-Type': 'multipart/form-data',
            Authorization: Autoload.Utils.getAuthToken(),
          }
        );
      }
    } else {
      // this.resetForm();
      // window.location.reload();
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        //message: 'Die Änderungen wurden gespeichert.',
        icon: <img src={RedCross} alt='red-tick'/>
      });
    }
  }

  handleaddReceiptFileUpload(response) {
    const {noParentAllListAvailable=false} = this.props;
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    this.resetForm();
    if(noParentAllListAvailable === false){
      this.props.parent.bind_list_receipt_data(true);
    }else{
      this.props.refreshData();
    }
    this.props.toggle();
  }
  
  
  // Very simple form validation with feedback
  validateFormData = () => {
    
    // Empty recipe title
    if (!this.state.receiptName) { 
      this.setState({ errors: { title: "Das Ausfüllen dieses Feldes ist erforderlich" } });
      return false;
    } else {
      this.setState({ errors: { title: null } });
    }

    // Empty recipe amount
    if (!this.state.amount) {
      this.setState({ errors: { amount: "Das Ausfüllen dieses Feldes ist erforderlich" } });
      return false;
    } else {
      this.setState({ errors: { amount: null } });
    }

    // Invalid recipe amount
    if (this.state.amount > 100000) {
      this.setState({ errors: { amount: "Ungültiger Betrag" } });
      return false;
    } else {
      this.setState({ errors: { amount: null } });
    }

    if (this.state.UtilityBillFilter) {
      if (this.state.selectedCostType.length === 0) {
        this.setState({ errors: { costType: "Das Ausfüllen dieses Feldes ist erforderlich" } });
        return false;
      } else {
        this.setState({ errors: { costType: null } });
      }
    }
    
    return true;
    
  }

  // Save Receipt
  handleSubmit = (e) => {
    e.preventDefault();
    
    const isValid = this.validateFormData();
    
    if (isValid) {
      if (this.props.edit) {
        this.saveChanges();
      } else {
        this.addReceipt();
      }
    }
  };
  //08.23.21 --> yy mm dd (js)
  formatReceiptDate = (date) => {
    console.log(date, 'date');
    return date === '' ? new Date() 
    : (dayjs(date, 'DD.MM.YY', true).isValid() ? dayjs(date, 'DD.MM.YY').format('DD.MM.YYYY') 
    : dayjs(date, 'DD.MM.YYYY').format('DD.MM.YYYY'));
  };

  renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {/* Time frame which the bill covers. */}
      {lang.newKeys.text_receipt_period_tooltip}
    </Tooltip>
  );

  handleEnterKeyPress = (e) => {
    if (e.charCode == 13) {
      e.preventDefault();
      this.handleSubmit(e);
    }
  };

  getFileExtension = (file)=>{
    if (file) {
      let re = /(?:\.([^.]+))?$/;
      var extension = re.exec(file)[1];
      extension = extension && extension.split('?')[0];
      console.log('getFileExtension: ', file);
      if (extension === 'jpg' || extension === 'png' || extension === 'jpeg') {
        return 'img';
      } else if (typeof(file) === 'object') {
        return 'pdf';
      }
    }
  }

  removeFile = (selected) => {
    console.log('removeFile: ', selected);
    let {selectedFiles} = this.state;
    selectedFiles = selectedFiles.filter((file)=> selected.path !== file.path);
    this.setState({selectedFiles});
  };

  removePageFromExistingPDF = async (pdfBytes, pageToRemove) => {
    // Load an existing PDF file into the PDFDocument
    const pdfDoc = await PDFDocument.load(pdfBytes);
  
    // Get the total number of pages in the document
    const totalPages = pdfDoc.getPageCount();
  
    console.log('totalPages: ', totalPages);
    if(totalPages > 1){
      // Check if the pageToRemove is within range
      if (pageToRemove >= 0 && pageToRemove < totalPages) {
        // Remove the specified page (0-indexed)
        pdfDoc.removePage(pageToRemove);
      } else {
        console.error("Invalid page number to remove.");
        return null;
      }

      // Serialize the PDFDocument to bytes (array of uint8)
      const newPdfBytes = await pdfDoc.save();

      // Return the modified PDF bytes (you can download it or show it in the UI)
      return newPdfBytes;
    }else{
      this.setState({selectedFile: null, selectedFiles: []});
      return null;
    }
    
  };

  removePageFromPDF = (pageToRemove) => {
    console.log('pageToRemove: ', pageToRemove);
    const {selectedFile, selectedFiles} = this.state;
    const {edit, record} = this.props;

    this.convertBlobToArrayBuffer(selectedFile[0]).then(async (pdfBytes)=> {
      const newPdfBytes = await this.removePageFromExistingPDF(pdfBytes, pageToRemove-1);

      if (newPdfBytes) {
        // Create a Blob and download the modified PDF
        const blob = new Blob([newPdfBytes], { type: 'application/pdf' });
        const pdfFile = new File([blob], `${edit ? record.fileName.includes('pdf') ? record.fileName : `${record.fileName}.pdf` : (selectedFiles[0]?.name+ (selectedFiles[0]?.name.includes('.pdf') ? '' : '.pdf'))}`, {
          type: "application/pdf",
          lastModified: new Date().getTime()
        });
        this.setState({selectedFile: [pdfFile], fileWasEdited: true}, ()=> 
          showNotification({
            message: 'Belegseite entfernt',
            icon: <img src={RedCross} alt='red-cross'/>,
            id: 'removePdfPage'
          })
        );
      }
    });

    
  };

  handleFileAdd=(e)=>{
    const {selectedFile} = this.state;
    let {selectedFiles} = this.state;
    const {edit, record} = this.props;
    console.log('handleFileAdd: ', e.target.files);
    if (e.target.files.length > 0) {
      const acceptedFiles = Array.from(e.target.files);
      const unprocessedFiles = acceptedFiles.filter(file => !file.type.startsWith('image/'));
      const processedFiles = acceptedFiles.filter(file => file.type.startsWith('image/')) // Filter to only process image files
      .map(file => 
        new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = function(event) {
            const img = new Image();
            img.src = event.target.result;

            img.onload = function() {
              // Get EXIF orientation data
              EXIF.getData(img, function() {
                const orientation = EXIF.getTag(this, "Orientation") || 1;

                console.log('orientation: ', orientation);

                // Create a canvas to apply orientation correction
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                let width = img.width;
                let height = img.height;

                // Adjust canvas size based on orientation
                if (orientation === 6 || orientation === 8) {
                  canvas.width = width;
                  canvas.height = height;
                } else {
                  canvas.width = width;
                  canvas.height = height;
                }

                // Apply the necessary rotation based on the EXIF orientation
                switch (orientation) {
                  case 6: // 90 degrees
                    // ctx.rotate(90 * Math.PI / 180);
                    // ctx.translate(0, -height);
                    
                    break;
                  case 8: // -90 degrees
                    // ctx.rotate(-90 * Math.PI / 180);
                    // ctx.translate(-width, 0);
                    break;
                  case 3: // 180 degrees
                    ctx.rotate(Math.PI);
                    ctx.translate(-width, -height);
                    break;
                  // case 2: ctx.translate(width, 0);     ctx.scale(-1,1); break;
                  // case 3: ctx.translate(width,height); ctx.rotate(Math.PI); break;
                  // case 4: ctx.translate(0,height);     ctx.scale(1,-1); break;
                  // case 5: ctx.rotate(0.5 * Math.PI);   ctx.scale(1,-1); break;
                  // case 6: ctx.rotate(0.5 * Math.PI);   ctx.translate(0,-height); break;
                  // case 7: ctx.rotate(0.5 * Math.PI);   ctx.translate(width,-height); ctx.scale(-1,1); break;
                  // case 8: ctx.rotate(-0.5 * Math.PI);  ctx.translate(-width,0); break;
                  default: // Normal orientation
                    break;
                }

                // Draw the corrected image onto the canvas
                ctx.drawImage(img, 0, 0, width, height);

                // Convert the canvas to a data URL with corrected orientation
                const correctedImageData = canvas.toDataURL("image/jpeg");

                // Convert base64 image data to Blob
                const byteString = atob(correctedImageData.split(',')[1]); // Remove the base64 prefix and decode
                const byteArray = new Uint8Array(byteString.length);

                // Populate the byteArray with the decoded bytes
                for (let i = 0; i < byteString.length; i++) {
                  byteArray[i] = byteString.charCodeAt(i);
                }

                // Create a Blob from the byteArray
                const imageBlob = new Blob([byteArray], { type: 'image/jpeg' });

                // Convert the Blob to a File
                const correctedFile = (orientation === 3 || orientation === 6 || orientation === 8) ? new File([imageBlob], file.name, {
                  type: 'image/jpeg',
                  lastModified: new Date().getTime(),
                })
                : file;

                // Resolve the promise with the corrected image data
                resolve(
                  //originalFile: file,
                  correctedFile
                );
              });
            };
          };
          reader.readAsDataURL(file);
        })
      );

      Promise.all(processedFiles).then((files) => {
        selectedFiles= [selectedFile[0], ...files, ...unprocessedFiles];
        this.setState({selectedFiles},()=> {
          this.convertImagesToPdf(true);
          showNotification({
            message: 'Belegseite(n) hinzugefügt',
            icon: <img src={GreenTick} alt='green-tick'/>,
            id: 'addPdfPage'
          });
        });
      });
    }
  }


  render() {
    const { open, toggle, className, edit, record, costAllocation, currentSubscription, accountData, usedSpaceInMB, memoryStatusExceededPackageWise, transactionAssigment=null } = this.props;
    const { timeframe, customDate, selectedCostType, imageOpen, selectedFile, receiptName, imgScale, isMobile, vatTypes, taxCostTypes, selectedVatType, selectedTaxCostType, customVatType, aiLoader, hoverOnStarter, openPaidModal, vatInFocus, amountInFocus, netInFocus, vatPercentInFocus, laborCostInFocus, costTypeOptions, notes, receiptNumber, openPaidModalForTaxCostType, selectedFiles, openMultipleReceiptsUploadModal, openAddMultipleDocumentsCofirmationModal, acceptedFiles, rejectedFiles, amountType, amount, firstTimeAmountNegative, netAmount, vatAmount, laborCost } = this.state;
    console.log('selectedVatType: ', selectedVatType);
    console.log('selectedCostType: ', selectedCostType);

    console.log('selectedFiles, selectedFile: ', selectedFile);

    console.log('record: ', selectedFile);
    return (
      <>
        <CustomModal
          heading={edit ? lang.receipt.text_edit_receipt : lang.receipt.text_add_receipt}
          headingClassName={edit ? 'receipt-management-edit-receipt' : 'receipt-management-add-receipt'}
          open={open}
          toggle={() => {
            this.resetForm();
            toggle();
          }}
          className={`${className} ${(selectedFile && selectedFile[0]) && !isMobile && 'receipt-main-modal'}`}//selectedFile || (record && record.file_name !== '')
        >
          <div id="form-parent-div">
            {isMobile ?
            <Stack spacing='xl' position='apart'>
              {(selectedFile || (record && record.file_name !== '')) &&
              <Stack className="main-div" style={{position: 'relative'}}>
                <LoadingOverlay visible={aiLoader} loader={<Stack spacing={0} align={'center'}><img src='/aiSearchIcon.gif' width={'100px'}/><span style={{color: '#5555FF'}}>Belegdaten werden ausgelesen ...</span></Stack>} overlayOpacity={0.6}/>
                <div style={{
                  background: 'grey', 
                  minWidth: '100%',
                  minHeight: '400px',
                  maxHeight: '400px',
                  overflow: edit ? (record && record.file_name !== '' ? 'scroll' : (selectedFile ? 'scroll' : 'hidden')) : selectedFile ? 'scroll' : 'hidden', 
                  borderRadius: '4px', 
                  border: '1px solid #DADAE6'}}>
                  {/* {this.getFilePreview(edit ? (record && record.file_name !== '' ? record.file_name : (selectedFile ? selectedFile[0] : Receipt)) : selectedFile ? selectedFile[0] : Receipt, this.props.billingRelevant ? true : this.state.UtilityBillFilter)} */}
                  <FilePreview
                  file={
                    // edit ? 
                    // (record && record.file_name !== '' ? record.file_name : (selectedFile ? selectedFile[0] : Receipt)) 
                    // : 
                    selectedFile ? selectedFile[0] : Receipt}
                  billingRelevantSwitch={this.props.billingRelevant ? true : this.state.UtilityBillFilter}
                  imgScale={imgScale}
                  edit={edit}
                  onExpand={()=> this.setState({imageOpen: true})}
                  costAllocation={costAllocation}
                  isMobile={isMobile}
                  addFile={()=> this.fileInputRef.current.click()}
                  removePage={(pageNumber)=> this.removePageFromPDF(pageNumber)}
                  />
                </div>
                <Group noWrap>
                  <Group>
                    <div>
                      <ColorButton
                      text={'Belegseite(n) ergänzen'}
                      className={'gray-btn'}
                      leftImage={{
                        img: AddPages, 
                        style: {
                          width: '16px',
                          height: '16px',
                          marginRight: '5px',
                          marginTop: '-2px'
                        }
                      }}
                      onClick={()=> this.fileInputRef.current.click()}
                      />
                    </div>
                  </Group>
                  <Group>
                    <div>
                      <ColorButton
                      text={'Belegseite löschen'}
                      buttonStyle={{minWidth: '167px'}}
                      className={'gray-btn'}
                      leftImage={{
                        img: RemovePages, 
                        style: {
                          width: '16px',
                          height: '16px',
                          marginRight: '5px',
                          marginTop: '-2px'
                        }
                      }}
                      onClick={()=> document.getElementById('remove-page').click()}
                      />
                    </div>
                  </Group>
                </Group>
                

              </Stack>}
              <Group style={{alignSelf: 'stretch'}}>
                <Form onSubmit={(e) => this.handleSubmit(e)} className="test" id='receipt-add-edit-form' style={{width: '100%', height: '100%'}}>
                  <Stack style={{height: '100%'}} justify='space-between'>
                    <input type="file" ref={this.fileInputRef} id='add-file-to-existing' accept="image/*,.pdf" multiple onChange={this.handleFileAdd}/>
                    <div className="modal-inner-body add-receipt-modal">
                      <Row>
                        <Col xs={12}>
                          <DropFile
                            filename={edit && this.state.receiptName}
                            err={this.state.selectedFileErr.state}
                            edit={edit}
                            accept={'image/*,application/pdf'}
                            receiptModal={true}
                            handleDrop={(acceptedFile, rejectedFile)=> memoryStatusExceededPackageWise ? this.setState({openPaidModal: true}) : this.handleFileChange(acceptedFile, rejectedFile)}
                            file={selectedFile}
                            removeFile={this.removeImage}
                            maxSize={20000000}
                            onClick={()=> this.setState({imageOpen: true})}
                            secondText={'JPG, PNG oder PDF, max. 20 MB'}
                            fileType={this.getFileExtension(edit ? (record && record.file_name !== '' ? record.file_name : Receipt) : selectedFile ? selectedFile[0].name : Receipt)}
                          />
                          {this.state.selectedFileErr.state && (
                            <ValueText className="error" text={this.state.selectedFileErr.msg} />
                          )}
                        </Col>
                      </Row>
                      {edit === false &&
                      <Row>
                        <Col xs={12}>
                          <Form.Group>
                            <Form.Label>
                              <Group spacing={10} align={'center'}>
                                {UtilityBillingAssignedIcon(currentSubscription.subscriptionKey.split('_')[0] === 'STARTER' ? '#9A9AB5' : '#5555FF')} 
                                <span>{`Automatische Belegerkennung (KI) ${currentSubscription.subscriptionKey.split('_')[0] === 'STARTER' ? 'ist nicht aktiviert.' : 'ist aktiviert.'}`}</span>
                              </Group>
                            </Form.Label>
                          </Form.Group>
                        </Col>
                      </Row>}
                      <Row style={{marginTop: '5px'}}>
                        <Col xs={12}>
                          <Form.Group>
                            <Form.Label> {lang.receipt.text_receipt_name} *</Form.Label>
                            <Form.Control
                              isInvalid={!!this.state.errors.title}
                              type="text"
                              placeholder=""
                              value={this.state.receiptName}
                              onChange={(e) => this.onInputChange('receiptName', e.target.value)}
                              onKeyPress={this.handleEnterKeyPress}
                            />
                            {
                              this.state.errors.title && (
                                <Alert color="red"><Text size="xs" color="red">{this.state.errors.title}</Text></Alert>
                              )
                            }
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row style={{marginTop: '5px'}}>
                        <Col xs={6}>
                          <Form.Group>
                            <Form.Label> {'Belegnummer (optional)'}</Form.Label>
                            <Form.Control
                              //isInvalid={!!this.state.errors.title}
                              type="text"
                              placeholder=""
                              value={null}
                              //onChange={(e) => this.onInputChange('receiptName', e.target.value)}
                              onKeyPress={this.handleEnterKeyPress}
                            />
                            {/* {
                              this.state.errors.title && (
                                <Alert color="red"><Text size="xs" color="red">{this.state.errors.title}</Text></Alert>
                              )
                            } */}
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group>
                            <Form.Label> {lang.receipt.text_date} </Form.Label>

                            <div className={`calendar-input`}>
                              <img src={calendar} alt="calendar" />
                              <DatePicker
                                locale="de"
                                formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                                dateFormat="dd.MM.yyyy"
                                customInput={<Form.Control required type="text" placeholder="TT.MM.JJJJ" />}
                                style={{ display: 'block !important' }}
                                placeholderText={'TT.MM.JJJJ'}
                                selected={new Date(this.state.date)}
                                onChange={(e) => this.onDateChange('date', e)}
                                popperModifiers={{
                                  preventOverflow: {
                                    enabled: true,
                                  },
                                }}
                              />
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row style={{marginTop: '5px'}}>
                        <Col xs={6}>
                          <Form.Group>
                            <Form.Label> {lang.receipt.text_amount} *</Form.Label>
                            <NumericFormat 
                            className='form-control'
                            value={this.state.amount}
                            thousandSeparator="." 
                            decimalSeparator=","
                            onValueChange={(values, sourceInfo) => {
                              this.onInputChange('amount', firstTimeAmountNegative ? -values.floatValue : values.floatValue);
                            }}
                            suffix={' €'}
                            onKeyPress={this.handleEnterKeyPress}
                            placeholder='0,00 €'
                            onFocus={()=> this.setState({amountInFocus: true})}
                            onBlur={()=> this.setState({amountInFocus: false})}
                            decimalScale={amountInFocus === false ? 2 : 5}
                            fixedDecimalScale={false}
                            allowNegative={true}
                            />
                            {/* <NumberInput
                              id="cc_i_re_a"
                              variant="unstyled"
                              className='form-control'
                              styles={{input: {marginTop: '-8px'}}}
                              precision={2}
                              decimalSeparator=","
                              min={0}
                              value={this.state.amount}
                              hideControls
                              placeholder='0,00€'
                              onChange={(value) => this.onInputChange('amount', value)}
                              onBlur={(e) =>
                                this.onInputChange(
                                  'amount',
                                  Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(this.state.amount + '', true)
                                )
                              }
                              onKeyPress={this.handleEnterKeyPress}
                            /> */}
                            {/* <Form.Control
                              id="cc_i_re_a"
                              type="text"
                              isInvalid={!!this.state.errors.amount}
                              placeholder="0,00€"
                              // required
                              value={this.state.amount}
                              onChange={(e) => this.onInputChange('amount', e.target.value)}
                              onBlur={(e) =>
                                this.onInputChange(
                                  'amount',
                                  Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(this.state.amount + '', true)
                                )
                              }
                              onKeyPress={this.handleEnterKeyPress}
                            /> */}
                            {
                              this.state.errors.amount && (
                                <Alert color="red"><Text size="xs" color="red">{this.state.errors.amount}</Text></Alert>
                              )
                            }
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Group noWrap position='left' align={'center'} style={{height: '100%', marginTop: '4px'}}>
                            <div style={{width: '100%', textAlign: 'center'}} className={this.state.amount < 0 ? 'add-receipt-modal-amount-negative' : 'add-receipt-modal-amount-negative-disabled'} onClick={()=> this.setState({amount: amount && -amount, netAmount: netAmount && -netAmount, vatAmount: vatAmount && -vatAmount, laborCost: laborCost && -laborCost})}>
                              Ausgabe
                            </div>
                            <div style={{width: '100%', textAlign: 'center'}} className={this.state.amount > 0 ? 'add-receipt-modal-amount-positive' : 'add-receipt-modal-amount-positive-disabled'} onClick={()=> this.setState({amount: amount && Math.abs(amount), netAmount: netAmount && Math.abs(netAmount), vatAmount: vatAmount && Math.abs(vatAmount), laborCost: laborCost && Math.abs(laborCost)})}>
                              Guthaben
                            </div>
                          </Group>
                        </Col>
                      </Row>
                      <span className='modal-sub-headings'>Mehrwertsteuer <span className='optional-label'>(optional)</span></span>
                      <Row style={{marginTop: '6px', marginBottom: '5px'}}>
                        <Col xs={4}>
                        <Form.Group>
                            <Form.Label>{'MWst.-Satz'}</Form.Label>
                            {selectedVatType[0]?.label !== 'anderer' &&
                            <SelectDropdown
                            options={vatTypes} // Options to display in the dropdown
                            selectedValue={selectedVatType.length > 0 && selectedVatType[0]} // Preselected value to persist in dropdown
                            onSelect={this.onVatPercentageSelect} // Function will trigger on select event
                            onRemove={this.onVatPercentageRemove} // Function will trigger on remove event
                            displayValue="label"
                            placeholder={lang.newKeys.text_select}
                            showSelectedHighlight={false}
                            ddHeaderClassName={`custom-select-dropdown ${selectedVatType.length > 0 && selectedVatType[0]?.label && 'custom-select-dropdown-pt-0'}`}
                            disable={this.state.amount ? false : true}
                            />}
                            {selectedVatType.length > 0 && selectedVatType[0]?.label === 'anderer' &&
                          //   <Form.Control
                          //   id="cc_i_re_a"
                          //   type="text"
                          //   //isInvalid={!!this.state.errors.amount}
                          //   placeholder="0,00%"
                          //   // required
                          //   value={customVatType}
                          //   onChange={(e) => this.onInputChange('customVatType', e.target.value)}
                          //   onBlur={(e) => customVatType && !customVatType.includes('%') && this.onInputChange('customVatType', `${e.target.value}%`)}
                          //   onKeyPress={this.handleEnterKeyPress}
                          // />
                          // <TextInput
                          // //className='form-control'
                          // placeholder="0%"
                          // value={customVatType}
                          // onChange={(e) => this.onInputChange('customVatType', e.target.value)}
                          // onBlur={(e) => customVatType && !customVatType.includes('%') ? this.onInputChange('customVatType', `${e.target.value}%`) : this.onInputChange('customVatType', e.target.value)}
                          // onKeyPress={this.handleEnterKeyPress}
                          // rightSection={
                          //   <div onClick={()=> this.setState({selectedVatType: [], customVatType: '0%'}, ()=> this.onInputChange('amount', this.state.amount))} class="mantine-Select-rightSection">
                          //     <div class="mantine-ActionIcon-transparent mantine-ActionIcon-root" type="button" tabindex="0">
                          //       <img src={CloseButtonIcon}/>
                          //     </div>
                          //   </div>
                          // }
                          // />
                          <Group className='form-control' noWrap spacing={3}>
                            <NumericFormat 
                            className='form-control form-control-transparent'
                            value={customVatType}
                            thousandSeparator="." 
                            decimalSeparator=","
                            onValueChange={(values, sourceInfo) => {
                              this.onInputChange('customVatType', values.floatValue);
                            }}
                            // onBlur={(values)=> {
                            //   this.onInputChange('customVatType', values.floatValue);
                            // }}
                            suffix={' %'}
                            onKeyPress={this.handleEnterKeyPress}
                            placeholder='0,00 %'
                            onFocus={()=> this.setState({vatPercentInFocus: true})}
                            onBlur={()=> this.setState({vatPercentInFocus: false})}
                            decimalScale={vatPercentInFocus === false ? 2 : 5}
                            fixedDecimalScale={false}
                            allowNegative={true}
                            />
                            <div onClick={()=> this.setState({selectedVatType: [], customVatType: '0%'}, ()=> this.onInputChange('amount', this.state.amount))} class="mantine-Select-rightSection">
                              <div class="mantine-ActionIcon-transparent mantine-ActionIcon-root" style={{marginTop: '-4px'}} type="button" tabindex="0">
                                <img src={CloseButtonIcon}/>
                              </div>
                            </div>
                          </Group>
                            }
                            {/* {
                              this.state.errors.amount && (
                                <Alert color="red"><Text size="xs" color="red">{this.state.errors.amount}</Text></Alert>
                              )
                            } */}
                          </Form.Group>
                        </Col>
                        <Col xs={4}>
                          <Form.Group>
                            <Form.Label>{'Netto-Betrag'}</Form.Label>
                            <NumericFormat 
                            className='form-control'
                            value={this.state.netAmount}
                            thousandSeparator="." 
                            decimalSeparator=","
                            onValueChange={(values, sourceInfo) => {
                              this.onInputChange('netAmount', values.floatValue);
                            }}
                            suffix={' €'}
                            onKeyPress={this.handleEnterKeyPress}
                            placeholder='0,00 €'
                            onFocus={()=> this.setState({netInFocus: true})}
                            onBlur={()=> this.setState({netInFocus: false})}
                            decimalScale={netInFocus === false ? 2 : 5}
                            fixedDecimalScale={false}
                            allowNegative={true}
                            disabled={this.state.amount ? false : true}
                            />
                            {/* {
                              this.state.errors.amount && (
                                <Alert color="red"><Text size="xs" color="red">{this.state.errors.amount}</Text></Alert>
                              )
                            } */}
                          </Form.Group>
                        </Col>
                        <Col xs={4}>
                        <Form.Group>
                            <Form.Label style={{whiteSpace: 'nowrap'}}>{'MwSt.-Betrag'}</Form.Label>
                            <NumericFormat 
                            className='form-control'
                            value={this.state.vatAmount}
                            thousandSeparator="." 
                            decimalSeparator=","
                            onValueChange={(values, sourceInfo) => {
                              this.onInputChange('vatAmount', values.floatValue);
                            }}
                            suffix={' €'}
                            onKeyPress={this.handleEnterKeyPress}
                            placeholder='0,00 €'
                            onFocus={()=> this.setState({vatInFocus: true})}
                            onBlur={()=> this.setState({vatInFocus: false})}
                            decimalScale={vatInFocus === false ? 2 : 5}
                            fixedDecimalScale={false}
                            allowNegative={true}
                            disabled={this.state.amount ? false : true}
                            />
                            {/* {
                              this.state.errors.amount && (
                                <Alert color="red"><Text size="xs" color="red">{this.state.errors.amount}</Text></Alert>
                              )
                            } */}
                          </Form.Group>
                        </Col>
                      </Row>
                      <span className='modal-sub-headings'>Lohnkosten <span className='optional-label'>(optional)</span></span>
                      <Row style={{marginTop: '6px', marginBottom: '5px'}}>
                        <Col xs={12}>
                          <Form.Group>
                            <Form.Label>{'Enthaltene Lohnkosten nach §35a EStG'}</Form.Label>
                            <NumericFormat 
                            className='form-control'
                            value={this.state.laborCost}
                            thousandSeparator="." 
                            decimalSeparator=","
                            onValueChange={(values, sourceInfo) => {
                              this.onInputChange('laborCost', values.floatValue);
                            }}
                            suffix={' €'}
                            onKeyPress={this.handleEnterKeyPress}
                            placeholder='0,00 €'
                            onFocus={()=> this.setState({laborCostInFocus: true})}
                            onBlur={()=> this.setState({laborCostInFocus: false})}
                            decimalScale={laborCostInFocus === false ? 2 : 5}
                            fixedDecimalScale={false}
                            allowNegative={true}
                            />
                            {/* {
                              this.state.errors.amount && (
                                <Alert color="red"><Text size="xs" color="red">{this.state.errors.amount}</Text></Alert>
                              )
                            } */}
                          </Form.Group>
                        </Col>
                      </Row>

                      <span className='modal-sub-headings'>Kostenart <span className='optional-label'>(optional)</span></span>
                      <Row style={{marginTop: '6px', marginBottom: '5px'}}>
                        <Col xs={12}>
                          <Form.Group>
                            <Form.Label>{'Steuerliche Kostenart'}</Form.Label>
                            <SelectDropdown
                            options={taxCostTypes.sort((a,b)=> a.label.toLowerCase().localeCompare(b.label.toLowerCase()))} // Options to display in the dropdown
                            selectedValue={selectedTaxCostType[0]} // Preselected value to persist in dropdown
                            onSelect={this.onTaxCostTypeSelect} // Function will trigger on select event
                            onRemove={this.onTaxCostTypeRemove} // Function will trigger on remove event
                            displayValue="label"
                            placeholder={lang.newKeys.text_select}
                            showSelectedHighlight={false}
                            removeLongTextHiding={true}
                            //disable={this.state.multiselectEnable}
                            />
                            {/* {
                              this.state.errors.amount && (
                                <Alert color="red"><Text size="xs" color="red">{this.state.errors.amount}</Text></Alert>
                              )
                            } */}
                          </Form.Group>
                        </Col>
                      </Row>

                      <span className='modal-sub-headings'>Betriebskosten</span>

                      <Row>
                        <Col xs={12}>
                          <SwitchContainer
                            disabled={false}
                            switchText={lang.receipt.text_utility_bill_relevant}
                            onColor="#5555FF"
                            offColor="#DADAE6"
                            onChange={this.props.billingRelevant ? () => {} : this.handleCheck}
                            checked={this.props.billingRelevant ? true : this.state.UtilityBillFilter}
                            height={16}
                            width={28}
                            handleDiameter={1.8}
                          />
                        </Col>
                      </Row>
                      {this.state.UtilityBillFilter && !this.props.costAllocation && 
                        (<>
                          <Row style={{marginTop: '10px'}}>
                            <Col xs={12}>
                              <Form.Group>
                                <Form.Label> {lang.receipt.text_cost_type} *</Form.Label>
                                {/* <Multiselect
                                  id="multiselect_id"
                                  style={{
                                    chips: { background: '#F2F2FC', borderRadius: '4px', color: '#111147' },
                                  }}
                                  value={{ label: selectedCostType }}
                                  ref={this.multiselectRef}
                                  options={this.state.costTypeOptions} // Options to display in the dropdown
                                  selectedValues={selectedCostType} // Preselected value to persist in dropdown
                                  onSelect={this.onCostTypeSelect} // Function will trigger on select event
                                  onRemove={this.onCostTypeRemove} // Function will trigger on remove event
                                  displayValue="costTypeName"
                                  placeholder="Select"
                                  closeIcon="cancel"
                                  avoidHighlightFirstOption={true}
                                  showCheckbox={true}
                                  disable={this.state.multiselectEnable}
                                  closeOnSelect={false}
                                  hidePlaceholder={true}
                                  renderCustomButton={
                                    <ColorButton
                                      className="gray-btn"
                                      text="Add cost type"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.toggleAddCostTypeModal();
                                      }}
                                    />
                                  }
                                /> */}
                                <SelectDropdown
                                  //multiSelect={true}
                                  // ref={this.multiselectRef}
                                  options={this.state.costTypeOptions.sort((a, b) => a.costTypeName.localeCompare(b.costTypeName))} // Options to display in the dropdown
                                  selectedValue={selectedCostType[0]} // Preselected value to persist in dropdown
                                  onSelect={this.onCostTypeSelect} // Function will trigger on select event
                                  onRemove={this.onCostTypeRemove} // Function will trigger on remove event
                                  displayValue="costTypeName"
                                  // placeholder="Select"
                                  placeholder={lang.newKeys.text_select}
                                  disable={this.state.multiselectEnable}
                                  // disable={false}
                                  endListContent={
                                    <ColorButton
                                      className="gray-btn"
                                      // text="Add cost type"
                                      text={lang.property.text_cost_addcosttype}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.toggleAddCostTypeModal();
                                      }}
                                    />
                                  }
                                  removeLongTextHiding={true}
                                />
                                {
                                  this.state.errors.costType && (
                                    <Alert color="red"><Text size="xs" color="red">{this.state.errors.costType}</Text></Alert>
                                  )
                                }
                              </Form.Group>
                            </Col>
                          </Row>
                          <div className="time-frame-section" style={{marginTop: '5px'}}>
                            <div className="time-frame-heading">
                              <h5> {lang.receipt.text_time_frame} </h5>
                              {/* <OverlayTrigger placement="top" delay={{ show: 450, hide: 400 }} overlay={this.renderTooltip}>
                                <img
                                  src={Help}
                                  alt="help"
                                  width="16px"
                                  height="16px"
                                  title={lang.newKeys.text_receipt_period_tooltip}
                                />
                              </OverlayTrigger> */}
                            </div>
                            <div className="time-frame-options">
                              <div
                                //className="single-time-option"
                                className={`single-time-option hello-housing-radio-options ${
                                  this.state.timeframe === 'current' ? `checked` : ``
                                  } `}
                              >
                                <Form.Check
                                  checked={this.state.timeframe === 'current'}
                                  id="current"
                                  type="radio"
                                  name="timeframe"
                                  label={lang.receipt.text_current_billing_period}
                                  onChange={(e) => this.onInputChange('timeframe', e.target.id)}
                                  onKeyPress={this.handleEnterKeyPress}
                                />
                                <span>
                                {this.state.currentStartDate} - {this.state.currentEndDate}
                                </span>
                              </div>
                              <div
                                // className="single-time-option"
                                className={`single-time-option hello-housing-radio-options ${
                                  this.state.timeframe === 'last' ? `checked` : ``
                                  } `}
                              >
                                <Form.Check
                                  checked={this.state.timeframe === 'last'}
                                  id="last"
                                  type="radio"
                                  name="timeframe"
                                  label={lang.receipt.text_last_billing_period}
                                  onChange={(e) => this.onInputChange('timeframe', e.target.id)}
                                  onKeyPress={this.handleEnterKeyPress}
                                />
                                <span>
                                {this.state.previousStartDate} - {this.state.previousEndDate}
                                </span>
                              </div>
                              <div
                                //className="single-time-option"
                                className={`single-time-option hello-housing-radio-options ${
                                  this.state.timeframe === 'custom' ? `checked` : ``
                                  } `}
                              >
                                <Form.Check
                                  checked={this.state.timeframe === 'custom'}
                                  id="custom"
                                  type="radio"
                                  name="timeframe"
                                  label={lang.receipt.text_custom}
                                  onChange={(e) => this.onInputChange('timeframe', e.target.id)}
                                  onKeyPress={this.handleEnterKeyPress}
                                />
                                <span style={{whiteSpace: 'nowrap'}}>
                                  {customDate.start && customDate.end
                                    ? `${this.formatReceiptDate(customDate.start)} – ${this.formatReceiptDate(
                                      customDate.end
                                    )}`
                                    : '-'}
                                </span>
                              </div>
                            </div>
                            {timeframe === 'custom' && (
                              <div className="custom-date-field">
                                <Form.Group>
                                  <Form.Label> {lang.receipt.text_time_frame} </Form.Label>
                                  <RangeDatePicker
                                    locale="de"
                                    customDate={this.state.customDate}
                                    setDate={(customDate) => this.setState({ customDate })}
                                  />
                                </Form.Group>
                              </div>
                            )}
                          </div>
                        </>
                        )}
                    </div>

                    <div className="center-spacing add-receipt-modal-footer" id="receipt-add-edit-btns">
                      <div>
                        <ColorButton
                        onClick={() => {
                          this.resetForm();
                          toggle();
                        }}
                        className="gray-btn"
                        text={lang.general.text_cancel}
                        />
                      </div>
                      <div>
                        <ColorButton
                          buttonConfig={{ type: 'submit' }}
                          className="purple-btn"
                          text={edit ? lang.receipt.text_mobile_save_receipt : lang.receipt.text_mobile_add_receipt}
                          disabled={this.state.isFormValid}
                        />
                      </div>
                    </div>
                  </Stack>
                </Form>
              </Group>
            </Stack>
            :
            <Group noWrap align={'flex-start'} spacing='xl' position='apart'>
              {(selectedFile && selectedFile[0]) && //selectedFile || (record && record.file_name !== '')
              <Group className="main-div" style={{position: 'relative'}}>
                <LoadingOverlay visible={aiLoader} loader={<Stack spacing={0} align={'center'}><img src='/aiSearchIcon.gif' width={'100px'}/><span style={{color: '#5555FF'}}>Belegdaten werden ausgelesen ...</span></Stack>} overlayOpacity={0.6}/>
                <div style={{
                  background: 'grey', 
                  minWidth: '700px',//'285px', 
                  maxWidth: '700px',//'285px', 
                  minHeight: '700px',//'431px', 
                  maxHeight: '700px',//'431px', 
                  overflow: edit ? (record && record.file_name !== '' ? 'scroll' : (selectedFile ? 'scroll' : 'hidden')) : selectedFile ? 'scroll' : 'hidden', 
                  borderRadius: '4px', 
                  border: '1px solid #DADAE6'}}>
                  {/* {this.getFilePreview(edit ? (record && record.file_name !== '' ? record.file_name : (selectedFile ? selectedFile[0] : Receipt)) : selectedFile ? selectedFile[0] : Receipt, this.props.billingRelevant ? true : this.state.UtilityBillFilter)} */}
                  <FilePreview
                  file={
                    // edit ? (record && record.file_name !== '' ? record.file_name : (selectedFile ? selectedFile[0] : Receipt)) 
                    // : 
                    selectedFile ? selectedFile[0] : Receipt}
                  billingRelevantSwitch={this.props.billingRelevant ? true : this.state.UtilityBillFilter}
                  imgScale={imgScale}
                  edit={edit}
                  onExpand={()=> this.setState({imageOpen: true})}
                  costAllocation={costAllocation}
                  addFile={()=> this.fileInputRef.current.click()}
                  removePage={(pageNumber)=> this.removePageFromPDF(pageNumber)}
                  />
                </div>
                <Stack spacing={5}>
                  <Group noWrap>
                    <Group>
                      <div>
                        <ColorButton
                        text={'Belegseite(n) ergänzen'}
                        className={'gray-btn'}
                        leftImage={{
                          img: AddPages, 
                          style: {
                            width: '16px',
                            height: '16px',
                            marginRight: '5px',
                            marginTop: '-2px'
                          }
                        }}
                        disabled={!(selectedFile && selectedFile[0])}
                        onClick={()=> this.fileInputRef.current.click()}
                        />
                      </div>
                    </Group>
                    <Group>
                      <div>
                        <ColorButton
                        text={'Belegseite löschen'}
                        buttonStyle={{minWidth: '167px'}}
                        className={'gray-btn'}
                        leftImage={{
                          img: RemovePages, 
                          style: {
                            width: '16px',
                            height: '16px',
                            marginRight: '5px',
                            marginTop: '-2px'
                          }
                        }}
                        disabled={!(selectedFile && selectedFile[0])}
                        onClick={()=> document.getElementById('remove-page').click()}
                        />
                      </div>
                    </Group>
                  </Group>
                </Stack>
              </Group>}
              <Group style={{minWidth: (selectedFile && selectedFile[0]) ? '430px' : '100%', maxWidth: (selectedFile && selectedFile[0]) ? '430px' : '100%', alignSelf: 'stretch'}}> 
              {/* selectedFile || (record && record.file_name !== '') */}
                <Form onSubmit={(e) => this.handleSubmit(e)} className="test" id='receipt-add-edit-form' style={{width: '100%', height: '100%'}}>
                  <Stack style={{height: '100%'}} justify='space-between'>
                  <input type="file" ref={this.fileInputRef} id='add-file-to-existing' accept="image/*,.pdf" multiple onChange={this.handleFileAdd}/>
                  <span className='modal-sub-headings'>Belegdaten</span>
                    <div className="modal-inner-body add-receipt-modal">
                      <Row>
                        <Col xs={12}>
                          {/* {this.state.selectedFile ? */}
                          <DropFile
                            filename={edit && this.state.receiptName}
                            err={this.state.selectedFileErr.state}
                            edit={edit}
                            receiptModal={true}
                            accept={'.jpg,.jpeg,.png,application/pdf'}
                            handleDrop={(acceptedFile, rejectedFile)=> memoryStatusExceededPackageWise ? this.setState({openPaidModal: true}) : acceptedFile.length > 1 ? this.setState({acceptedFiles: acceptedFile, rejectedFiles: rejectedFile, openAddMultipleDocumentsCofirmationModal: true}) : this.handleFileChange(acceptedFile, rejectedFile)}
                            file={this.state.selectedFile}
                            removeFile={this.removeImage}
                            secondText={'JPG, PNG oder PDF, max. 20 MB'}
                            maxSize={20000000}
                            multiple={false}
                            onClick={()=> this.setState({imageOpen: true})}
                            fileType={this.getFileExtension(edit ? (record && record.file_name !== '' ? record.file_name : Receipt) : selectedFile ? selectedFile[0].name : Receipt)}
                          />
                          {/* :
                          <DropFile
                          //accept={null}
                          filename={edit && this.state.receiptName}
                          accept={'.jpg,.jpeg,.png,application/pdf'}
                          edit={edit}
                          err={this.state.selectedFileErr.state}
                          //accept={'audio/*,video/*,image/*,application/*,application/pdf,text/*,.csv,.zip,.docx,.doc,.xls,.xlm,.dbf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pptx,.ppt'}
                          customProps={{ multiple: true }}
                          handleDrop={(acceptedFile, rejectedFile)=> memoryStatusExceededPackageWise ? this.setState({openPaidModal: true}) : this.handleFileChange(acceptedFile, rejectedFile)}
                          //file={selectedFiles}
                          file={[]}
                          receiptModal={true}
                          removeFile={this.removeFile}
                          multiSelect={true}
                          maxSize={20000000}
                          secondText={'JPG, PNG oder PDF, max. 20 MB'}
                          onClick={()=> this.setState({imageOpen: true})}
                          />} */}
                          {this.state.selectedFileErr.state && (
                            <ValueText className="error" text={this.state.selectedFileErr.msg} />
                          )}
                        </Col>
                      </Row>
                      {edit === false &&
                      <Row style={{marginTop: '-10px'}}>
                        <Col xs={12}>
                          <Form.Group>
                            <Form.Label>
                              <Popover
                              opened={currentSubscription.subscriptionKey.split('_')[0] === 'STARTER' && hoverOnStarter}
                              onClose={() => this.setState({hoverOnStarter: false})}
                              position="top"
                              placement="center"
                              withArrow
                              trapFocus={false}
                              closeOnEscape={false}
                              transition="pop-top-left"
                              width={300}
                              //styles={{ body: { pointerEvents: 'none' } }}
                              style={{width: '100%'}}
                              zIndex={9999}
                              target={
                                <Group spacing={5} align={'center'} onMouseEnter={()=> this.setState({hoverOnStarter: true})} onMouseLeave={()=> this.setState({hoverOnStarter: false})}>
                                  {UtilityBillingAssignedIcon(currentSubscription.subscriptionKey.split('_')[0] === 'STARTER' ? '#9A9AB5' : '#5555FF')} 
                                  <span style={{color: '#0E0E1D'}}>{`Automatische Belegerkennung (KI) ${currentSubscription.subscriptionKey.split('_')[0] === 'STARTER' ? 'ist nicht aktiviert.' : 'ist aktiviert.'}`}</span>
                                  {currentSubscription.subscriptionKey.split('_')[0] === 'STARTER' && <img src={GoldenLockIcon} style={{marginTop: '-2px'}}/>}
                                </Group>
                              }
                              >
                                <Stack style={{padding: '10px'}} spacing={15} onMouseEnter={()=> this.setState({hoverOnStarter: true})} onMouseLeave={()=> this.setState({hoverOnStarter: false})}>
                                  <Group>
                                    <span style={{color: 'rgb(85, 85, 255)', fontSize: '1rem'}}>Automatische Belegerkennung (KI)</span>
                                  </Group>
                                  <Group style={{justifyContent: 'center'}} noWrap>
                                    <img src={GoldenLockIcon} width='18px'/>
                                    <span style={{color: '#5f5f7a', fontSize: '11px', fontWeight: '400'}}>Sparen Sie Zeit mit <span style={{fontWeight: '500'}}>hellohousing Premium</span> und lassen Sie wichtige Belegdaten beim Hochladen automatisch auslesen.</span>
                                  </Group>
                                  {/* <Group style={{justifyContent: 'center'}}>
                                    
                                  </Group> */}
                                  <Group>
                                      <ColorButton
                                      text={currentSubscription ? (currentSubscription.subscriptionName === 'Starter' ? (accountData.isPremiumPlanTested === false ? '1 Monat kostenlos testen' : 'Jetzt freischalten') : currentSubscription.subscriptionName === 'Premium' ? (accountData.isProfessionalPlanTested === false ? '1 Monat kostenlos testen' : 'Jetzt freischalten') : 'Jetzt freischalten') : 'Jetzt freischalten'}
                                      onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})}
                                      className={'purple-btn'}
                                      />
                                  </Group>
                                </Stack>
                              </Popover>
                            </Form.Label>
                          </Form.Group>
                        </Col>
                      </Row>}
                      <Row style={{marginTop: '5px'}}>
                        <Col xs={12}>
                          <Form.Group>
                            <Form.Label> {lang.receipt.text_receipt_name} *</Form.Label>
                            <Form.Control
                              isInvalid={!!this.state.errors.title}
                              type="text"
                              placeholder=""
                              value={this.state.receiptName}
                              onChange={(e) => this.onInputChange('receiptName', e.target.value)}
                              onKeyPress={this.handleEnterKeyPress}
                            />
                            {
                              this.state.errors.title && (
                                <Alert color="red"><Text size="xs" color="red">{this.state.errors.title}</Text></Alert>
                              )
                            }
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row style={{marginTop: '5px'}}>
                        <Col xs={6}>
                          <Form.Group>
                            <Form.Label> {'Belegnummer (optional)'}</Form.Label>
                            <Form.Control
                              //isInvalid={!!this.state.errors.title}
                              type="text"
                              placeholder=""
                              value={receiptNumber}
                              onChange={(e) => this.onInputChange('receiptNumber', e.target.value)}
                              onKeyPress={this.handleEnterKeyPress}
                            />
                            {/* {
                              this.state.errors.title && (
                                <Alert color="red"><Text size="xs" color="red">{this.state.errors.title}</Text></Alert>
                              )
                            } */}
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group>
                            <Form.Label> {lang.receipt.text_date} </Form.Label>

                            <div className={`calendar-input`}>
                              <img src={calendar} alt="calendar" />
                              <DatePicker
                                locale="de"
                                formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                                dateFormat="dd.MM.yyyy"
                                customInput={<Form.Control required type="text" placeholder="TT.MM.JJJJ" />}
                                style={{ display: 'block !important' }}
                                placeholderText={'TT.MM.JJJJ'}
                                selected={new Date(this.state.date)}
                                onChange={(e) => this.onDateChange('date', e)}
                                popperModifiers={{
                                  preventOverflow: {
                                    enabled: true,
                                  },
                                }}
                              />
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row style={{marginTop: '6px', marginBottom: '5px'}}>
                        <Col xs={6}>
                          <Form.Group>
                            <Form.Label> {lang.receipt.text_amount} *</Form.Label>
                            <NumericFormat 
                            className='form-control'
                            value={this.state.amount}
                            thousandSeparator="." 
                            decimalSeparator=","
                            onValueChange={(values, sourceInfo) => {
                              this.onInputChange('amount', firstTimeAmountNegative ? -values.floatValue : values.floatValue);
                            }}
                            suffix={' €'}
                            onKeyPress={this.handleEnterKeyPress}
                            placeholder='0,00 €'
                            onFocus={()=> this.setState({amountInFocus: true})}
                            onBlur={()=> this.setState({amountInFocus: false})}
                            decimalScale={amountInFocus === false ? 2 : 5}
                            fixedDecimalScale={false}
                            allowNegative={true}
                            />
                            {/* <NumberInput
                              id="cc_i_re_a"
                              variant="unstyled"
                              className='form-control'
                              styles={{input: {marginTop: '-8px'}}}
                              precision={2}
                              decimalSeparator=","
                              min={0}
                              value={this.state.amount}
                              hideControls
                              placeholder='0,00€'
                              onChange={(value) => this.onInputChange('amount', value)}
                              onBlur={(e) =>
                                this.onInputChange(
                                  'amount',
                                  Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(this.state.amount + '', true)
                                )
                              }
                              onKeyPress={this.handleEnterKeyPress}
                            /> */}
                            {/* <Form.Control
                              id="cc_i_re_a"
                              type="text"
                              isInvalid={!!this.state.errors.amount}
                              placeholder="0,00€"
                              // required
                              value={this.state.amount}
                              onChange={(e) => this.onInputChange('amount', e.target.value)}
                              onBlur={(e) =>
                                this.onInputChange(
                                  'amount',
                                  Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(this.state.amount + '', true)
                                )
                              }
                              onKeyPress={this.handleEnterKeyPress}
                            /> */}
                            {
                              this.state.errors.amount && (
                                <Alert color="red"><Text size="xs" color="red">{this.state.errors.amount}</Text></Alert>
                              )
                            }
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Group noWrap position='left' align={'center'} style={{height: '100%', marginTop: '4px'}}>
                            <div style={{width: '100%', textAlign: 'center'}} className={this.state.amount < 0 ? 'add-receipt-modal-amount-negative' : 'add-receipt-modal-amount-negative-disabled'} onClick={()=> this.setState({amount: amount && -amount, netAmount: netAmount && -netAmount, vatAmount: vatAmount && -vatAmount, laborCost: laborCost && -laborCost})}>
                              Ausgabe
                            </div>
                            <div style={{width: '100%', textAlign: 'center'}} className={this.state.amount > 0 ? 'add-receipt-modal-amount-positive' : 'add-receipt-modal-amount-positive-disabled'} onClick={()=> this.setState({amount: amount && Math.abs(amount), netAmount: netAmount && Math.abs(netAmount), vatAmount: vatAmount && Math.abs(vatAmount), laborCost: laborCost && Math.abs(laborCost)})}>
                              Guthaben
                            </div>
                          </Group>
                        </Col>
                      </Row>
                      <span className='modal-sub-headings'>Mehrwertsteuer <span className='optional-label'>(optional)</span></span>
                      <Row style={{marginTop: '6px', marginBottom: '5px'}}>
                        <Col xs={4}>
                        <Form.Group>
                            <Form.Label>{'MWst.-Satz'}</Form.Label>
                            {selectedVatType[0]?.label !== 'anderer' &&
                            <SelectDropdown
                            options={vatTypes} // Options to display in the dropdown
                            selectedValue={selectedVatType.length > 0 && selectedVatType[0]} // Preselected value to persist in dropdown
                            onSelect={this.onVatPercentageSelect} // Function will trigger on select event
                            onRemove={this.onVatPercentageRemove} // Function will trigger on remove event
                            displayValue="label"
                            placeholder={lang.newKeys.text_select}
                            showSelectedHighlight={false}
                            ddHeaderClassName={`custom-select-dropdown ${selectedVatType.length > 0 && selectedVatType[0]?.label && 'custom-select-dropdown-pt-0'}`}
                            disable={this.state.amount ? false : true}
                            />}
                            {selectedVatType.length > 0 && selectedVatType[0]?.label === 'anderer' &&
                          //   <Form.Control
                          //   id="cc_i_re_a"
                          //   type="text"
                          //   //isInvalid={!!this.state.errors.amount}
                          //   placeholder="0,00%"
                          //   // required
                          //   value={customVatType}
                          //   onChange={(e) => this.onInputChange('customVatType', e.target.value)}
                          //   onBlur={(e) => customVatType && !customVatType.includes('%') && this.onInputChange('customVatType', `${e.target.value}%`)}
                          //   onKeyPress={this.handleEnterKeyPress}
                          // />
                          // <TextInput
                          // //className='form-control'
                          // placeholder="0%"
                          // value={customVatType}
                          // onChange={(e) => this.onInputChange('customVatType', e.target.value)}
                          // onBlur={(e) => customVatType && !customVatType.includes('%') ? this.onInputChange('customVatType', `${e.target.value}%`) : this.onInputChange('customVatType', e.target.value)}
                          // onKeyPress={this.handleEnterKeyPress}
                          // rightSection={
                          //   <div onClick={()=> this.setState({selectedVatType: [], customVatType: '0%'}, ()=> this.onInputChange('amount', this.state.amount))} class="mantine-Select-rightSection">
                          //     <div class="mantine-ActionIcon-transparent mantine-ActionIcon-root" type="button" tabindex="0">
                          //       <img src={CloseButtonIcon}/>
                          //     </div>
                          //   </div>
                          // }
                          // />
                          <Group className='form-control' noWrap spacing={3}>
                            <NumericFormat 
                            className='form-control form-control-transparent'
                            value={customVatType}
                            thousandSeparator="." 
                            decimalSeparator=","
                            onValueChange={(values, sourceInfo) => {
                              this.onInputChange('customVatType', values.floatValue);
                            }}
                            // onBlur={(values)=> {
                            //   this.onInputChange('customVatType', values.floatValue);
                            // }}
                            suffix={' %'}
                            onKeyPress={this.handleEnterKeyPress}
                            placeholder='0,00 %'
                            onFocus={()=> this.setState({vatPercentInFocus: true})}
                            onBlur={()=> this.setState({vatPercentInFocus: false})}
                            decimalScale={vatPercentInFocus === false ? 2 : 5}
                            fixedDecimalScale={false}
                            allowNegative={true}
                            />
                            <div onClick={()=> this.setState({selectedVatType: [], customVatType: '0%'}, ()=> this.onInputChange('amount', this.state.amount))} class="mantine-Select-rightSection">
                              <div class="mantine-ActionIcon-transparent mantine-ActionIcon-root" style={{marginTop: '-4px'}} type="button" tabindex="0">
                                <img src={CloseButtonIcon}/>
                              </div>
                            </div>
                          </Group>
                            }
                            {/* {
                              this.state.errors.amount && (
                                <Alert color="red"><Text size="xs" color="red">{this.state.errors.amount}</Text></Alert>
                              )
                            } */}
                          </Form.Group>
                        </Col>
                        <Col xs={4}>
                          <Form.Group>
                            <Form.Label>{'Netto-Betrag'}</Form.Label>
                            <NumericFormat 
                            className='form-control'
                            value={this.state.netAmount}
                            thousandSeparator="." 
                            decimalSeparator=","
                            onValueChange={(values, sourceInfo) => {
                              this.onInputChange('netAmount', values.floatValue);
                            }}
                            suffix={' €'}
                            onKeyPress={this.handleEnterKeyPress}
                            placeholder='0,00 €'
                            onFocus={()=> this.setState({netInFocus: true})}
                            onBlur={()=> this.setState({netInFocus: false})}
                            decimalScale={netInFocus === false ? 2 : 5}
                            fixedDecimalScale={false}
                            allowNegative={true}
                            disabled={this.state.amount ? false : true}
                            />
                            {/* {
                              this.state.errors.amount && (
                                <Alert color="red"><Text size="xs" color="red">{this.state.errors.amount}</Text></Alert>
                              )
                            } */}
                          </Form.Group>
                        </Col>
                        <Col xs={4}>
                        <Form.Group>
                            <Form.Label style={{whiteSpace: 'nowrap'}}>{'MwSt.-Betrag'}</Form.Label>
                            <NumericFormat 
                            className='form-control'
                            value={this.state.vatAmount}
                            thousandSeparator="." 
                            decimalSeparator=","
                            onValueChange={(values, sourceInfo) => {
                              console.log('onValueChange: ', values);
                              this.onInputChange('vatAmount', values.floatValue);
                            }}
                            suffix={' €'}
                            onKeyPress={this.handleEnterKeyPress}
                            placeholder='0,00 €'
                            onFocus={()=> this.setState({vatInFocus: true})}
                            onBlur={()=> this.setState({vatInFocus: false})}
                            decimalScale={vatInFocus === false ? 2 : 5}
                            fixedDecimalScale={false}
                            allowNegative={true}
                            disabled={this.state.amount ? false : true}
                            />
                            {/* {
                              this.state.errors.amount && (
                                <Alert color="red"><Text size="xs" color="red">{this.state.errors.amount}</Text></Alert>
                              )
                            } */}
                          </Form.Group>
                        </Col>
                      </Row>
                      <span className='modal-sub-headings'>Lohnkosten <span className='optional-label'>(optional)</span></span>
                      <Row style={{marginTop: '6px', marginBottom: '5px'}}>
                        <Col xs={12}>
                          <Form.Group>
                            <Form.Label>{'Enthaltene Lohnkosten nach §35a EStG'}</Form.Label>
                            <NumericFormat 
                            className='form-control'
                            value={this.state.laborCost}
                            thousandSeparator="." 
                            decimalSeparator=","
                            onValueChange={(values, sourceInfo) => {
                              this.onInputChange('laborCost', values.floatValue);
                            }}
                            suffix={' €'}
                            onKeyPress={this.handleEnterKeyPress}
                            placeholder='0,00 €'
                            onFocus={()=> this.setState({laborCostInFocus: true})}
                            onBlur={()=> this.setState({laborCostInFocus: false})}
                            decimalScale={laborCostInFocus === false ? 2 : 5}
                            fixedDecimalScale={false}
                            allowNegative={true}
                            />
                            {/* {
                              this.state.errors.amount && (
                                <Alert color="red"><Text size="xs" color="red">{this.state.errors.amount}</Text></Alert>
                              )
                            } */}
                          </Form.Group>
                        </Col>
                      </Row>

                      <span className='modal-sub-headings'>Kostenart <span className='optional-label'>(optional)</span></span>
                      <Row style={{marginTop: '6px', marginBottom: '5px'}}>
                        <Col xs={12}>
                          <Form.Group>
                            <Form.Label>{'Steuerliche Kostenart'}</Form.Label>
                            <SelectDropdown
                            options={taxCostTypes.sort((a,b)=> a.label.toLowerCase().localeCompare(b.label.toLowerCase()))} // Options to display in the dropdown
                            selectedValue={selectedTaxCostType[0]} // Preselected value to persist in dropdown
                            onSelect={this.onTaxCostTypeSelect} // Function will trigger on select event
                            onRemove={this.onTaxCostTypeRemove} // Function will trigger on remove event
                            displayValue="label"
                            placeholder={lang.newKeys.text_select}
                            showSelectedHighlight={false}
                            removeLongTextHiding={true}
                            currentSubscription={currentSubscription}
                            //disable={this.state.multiselectEnable}
                            />
                            {/* {
                              this.state.errors.amount && (
                                <Alert color="red"><Text size="xs" color="red">{this.state.errors.amount}</Text></Alert>
                              )
                            } */}
                          </Form.Group>
                        </Col>
                      </Row>

                      <span className='modal-sub-headings'>Betriebskosten</span>
                      <Row style={{marginTop: '6px', marginBottom: '5px'}}>
                        <Col xs={12}>
                          <SwitchContainer
                            disabled={false}
                            switchText={lang.receipt.text_utility_bill_relevant}
                            onColor="#5555FF"
                            offColor="#DADAE6"
                            onChange={this.props.billingRelevant ? () => {} : this.handleCheck}
                            checked={this.props.billingRelevant ? true : this.state.UtilityBillFilter}
                            height={16}
                            width={28}
                            handleDiameter={1.8}
                          />
                        </Col>
                      </Row>
                      {this.state.UtilityBillFilter && !this.props.costAllocation && 
                        (<>
                          <Row style={{marginTop: '10px'}}>
                            <Col xs={12}>
                              <Form.Group>
                                <Form.Label> {'Betriebskostenart'} *</Form.Label>
                                {/* <Multiselect
                                  id="multiselect_id"
                                  style={{
                                    chips: { background: '#F2F2FC', borderRadius: '4px', color: '#111147' },
                                  }}
                                  value={{ label: selectedCostType }}
                                  ref={this.multiselectRef}
                                  options={this.state.costTypeOptions} // Options to display in the dropdown
                                  selectedValues={selectedCostType} // Preselected value to persist in dropdown
                                  onSelect={this.onCostTypeSelect} // Function will trigger on select event
                                  onRemove={this.onCostTypeRemove} // Function will trigger on remove event
                                  displayValue="costTypeName"
                                  placeholder="Select"
                                  closeIcon="cancel"
                                  avoidHighlightFirstOption={true}
                                  showCheckbox={true}
                                  disable={this.state.multiselectEnable}
                                  closeOnSelect={false}
                                  hidePlaceholder={true}
                                  renderCustomButton={
                                    <ColorButton
                                      className="gray-btn"
                                      text="Add cost type"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.toggleAddCostTypeModal();
                                      }}
                                    />
                                  }
                                /> */}
                                <SelectDropdown
                                  //multiSelect={true}
                                  // ref={this.multiselectRef}
                                  options={this.state.costTypeOptions.sort((a, b) => a.costTypeName.localeCompare(b.costTypeName))} // Options to display in the dropdown
                                  selectedValue={selectedCostType[0]} // Preselected value to persist in dropdown
                                  onSelect={this.onCostTypeSelect} // Function will trigger on select event
                                  onRemove={this.onCostTypeRemove} // Function will trigger on remove event
                                  displayValue="costTypeName"
                                  // placeholder="Select"
                                  placeholder={lang.newKeys.text_select}
                                  disable={this.state.multiselectEnable}
                                  // disable={false}
                                  endListContent={
                                    <ColorButton
                                      className="gray-btn"
                                      // text="Add cost type"
                                      text={lang.property.text_cost_addcosttype}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.toggleAddCostTypeModal();
                                      }}
                                    />
                                  }
                                  removeLongTextHiding={true}
                                />
                                {
                                  this.state.errors.costType && (
                                    <Alert color="red"><Text size="xs" color="red">{this.state.errors.costType}</Text></Alert>
                                  )
                                }
                              </Form.Group>
                            </Col>
                          </Row>
                          <div className="time-frame-section" style={{marginTop: '5px'}}>
                            <Form.Group>
                            <div className="time-frame-heading">
                              {/* <h5> {lang.receipt.text_time_frame} </h5> */}
                              <Form.Label> {lang.receipt.text_time_frame}</Form.Label>
                              {/* <OverlayTrigger placement="top" delay={{ show: 450, hide: 400 }} overlay={this.renderTooltip}>
                                <img
                                  src={Help}
                                  alt="help"
                                  width="16px"
                                  height="16px"
                                  title={lang.newKeys.text_receipt_period_tooltip}
                                />
                              </OverlayTrigger> */}
                            </div>
                            <div className="time-frame-options">
                              <div
                                //className="single-time-option"
                                className={`single-time-option hello-housing-radio-options ${
                                  this.state.timeframe === 'current' ? `checked` : ``
                                  } `}
                              >
                                <Form.Check
                                  checked={this.state.timeframe === 'current'}
                                  id="current"
                                  type="radio"
                                  name="timeframe"
                                  label={lang.receipt.text_current_billing_period}
                                  onChange={(e) => this.onInputChange('timeframe', e.target.id)}
                                  onKeyPress={this.handleEnterKeyPress}
                                />
                                <span>
                                {this.state.currentStartDate} - {this.state.currentEndDate}
                                </span>
                              </div>
                              <div
                                // className="single-time-option"
                                className={`single-time-option hello-housing-radio-options ${
                                  this.state.timeframe === 'last' ? `checked` : ``
                                  } `}
                              >
                                <Form.Check
                                  checked={this.state.timeframe === 'last'}
                                  id="last"
                                  type="radio"
                                  name="timeframe"
                                  label={lang.receipt.text_last_billing_period}
                                  onChange={(e) => this.onInputChange('timeframe', e.target.id)}
                                  onKeyPress={this.handleEnterKeyPress}
                                />
                                <span>
                                {this.state.previousStartDate} - {this.state.previousEndDate}
                                </span>
                              </div>
                              <div
                                //className="single-time-option"
                                className={`single-time-option hello-housing-radio-options ${
                                  this.state.timeframe === 'custom' ? `checked` : ``
                                  } `}
                              >
                                <Form.Check
                                  checked={this.state.timeframe === 'custom'}
                                  id="custom"
                                  type="radio"
                                  name="timeframe"
                                  label={lang.receipt.text_custom}
                                  onChange={(e) => this.onInputChange('timeframe', e.target.id)}
                                  onKeyPress={this.handleEnterKeyPress}
                                />
                                <span>
                                  {customDate.start && customDate.end
                                    ? `${this.formatReceiptDate(customDate.start)} – ${this.formatReceiptDate(
                                      customDate.end
                                    )}`
                                    : '-'}
                                </span>
                              </div>
                            </div>
                            
                            {timeframe === 'custom' && (
                              <div className="custom-date-field">
                                <Form.Group>
                                  <Form.Label> {lang.receipt.text_time_frame} </Form.Label>
                                  <RangeDatePicker
                                    locale="de"
                                    customDate={this.state.customDate}
                                    setDate={(customDate) => this.setState({ customDate })}
                                  />
                                </Form.Group>
                              </div>
                            )}
                            </Form.Group>
                          </div>
                        </>
                        )}
                      <div style={{marginTop: '14px'}}>
                        <span className='modal-sub-headings'>Notizen</span>
                        <Row style={{marginTop: '6px', marginBottom: '5px'}}>
                          <Col xs={12}>
                            <Form.Group>
                              <Group position='right' style={{width: '100%'}}>
                                <Form.Label style={{paddingRight: '0px'}}> {`${notes ? notes.length : 0}/1000`}</Form.Label>
                              </Group>
                              <Form.Control
                                id="receipt-note"
                                name="receipt-note"
                                type="textarea"
                                placeholder=""
                                value={notes}
                                onChange={(e) => (e.target.value && e.target.value.length > 1000) ?
                                  showNotification({
                                    message: 'Sie haben die maximale Eingabegröße für das Notizfeld überschritten.',
                                    icon: <img src={RedCross} alt='red-cross'/>,
                                    id: 'notesLimitExceed'
                                  })
                                  : 
                                  this.onInputChange('notes', e.target.value)}
                                as="textarea"
                                style={{ height: 108, width: '100%' }}
                              />
                            </Form.Group>
                            {/* <Textarea
                              value={notes} 
                              onChange={(event) => this.setState({notes: event.currentTarget.value})} 
                            /> */}
                          </Col>
                        </Row>
                      </div>
                      
                    </div>

                    <div style={{marginTop: '5px'}} className="center-spacing add-receipt-modal-footer" id="receipt-add-edit-btns">
                      <div>
                        <ColorButton
                        onClick={() => {
                          this.resetForm();
                          toggle();
                        }}
                        className="gray-btn"
                        text={lang.general.text_cancel}
                        />
                      </div>
                      <div>
                        <ColorButton
                          buttonConfig={{ type: 'submit' }}
                          className="purple-btn"
                          text={edit ? lang.receipt.text_mobile_save_receipt : lang.receipt.text_mobile_add_receipt}
                          disabled={this.state.isFormValid}
                        />
                      </div>
                    </div>
                  </Stack>
                </Form>
              </Group>
            </Group>}
          </div>
        </CustomModal>
        {/* {this.state.AddCostTypeModal && ( */}
        {this.state.AddCostTypeModal &&
        <AddCostTypeModal
          record={null}
          className="add_cost_type_modal"
          edit={false}
          open={this.state.AddCostTypeModal}
          showCostData={this.state.costTypeOptions}
          toggle={this.toggleAddCostTypeModal}
          parent={this}
          callBackFunction={(id=null) => this.getCostTypes(id)}
        />}
        {imageOpen &&
        <ImageModal
          img={edit ? (record && record.file_name !== '' ? record.file_name : Receipt) : selectedFile ? selectedFile[0] : Receipt}
          className="expandImageModal"
          open={imageOpen}
          toggle={()=> this.setState({imageOpen: !imageOpen})}
          receiptDetailModalAdd={!edit}
        />}
        {openPaidModal &&
        <BuyPremiumModal
        heading={'Zusätzlichen Speicherplatz freischalten'}
        open={openPaidModal}
        text={<span style={{fontSize: '15px', color: 'rgb(19, 19, 92)', fontWeight: '400', textAlign: 'center', width: '350px'}}>Sie haben {usedSpaceInMB ? formatNumber(usedSpaceInMB, false) : '0,00'} MB von 100,00 MB verfügbarem Speicherplatz im Starter Paket genutzt. Schalten Sie hellohousing <span onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})} style={{color: 'rgb(85, 85, 255)', textDecoration: 'underline', cursor: 'pointer'}}>{currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' ? 'Premium' : 'Professional'}</span> frei, um weiteren Speicherplatz zu nutzen.</span>}
        onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})}
        onCancel={()=> this.setState({openPaidModal: false})}
        />}
        {openPaidModalForTaxCostType &&
        <BuyPremiumModal
        heading={'Auswahl der Steuerkostenart freischalten'}
        open={openPaidModalForTaxCostType}
        text={<span style={{fontSize: '15px', color: 'rgb(19, 19, 92)', fontWeight: '400', textAlign: 'center', width: '350px'}}>Aktivieren Sie hellohousing <span onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})} style={{color: 'rgb(85, 85, 255)', textDecoration: 'underline', cursor: 'pointer'}}>{currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' ? 'Premium' : 'Professional'}</span>, um Steuerkostenarten festzulegen und alle Daten für die Steuererklärung (Anlage V) abzurufen und zu exportieren.</span>}
        onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})}
        onCancel={()=> this.setState({openPaidModalForTaxCostType: false})}
        />}
        {openMultipleReceiptsUploadModal &&
        <MultipleReceiptsUploadModal
        open={openMultipleReceiptsUploadModal}
        selectedFiles={selectedFiles}
        propertySelected={this.props.propertySelected}
        toggle={(closeAll)=> this.setState({openMultipleReceiptsUploadModal: false}, ()=> closeAll && toggle())}
        showFirstRowLoader={()=> this.props.showFirstRowLoader()}
        setSelectedFiles={(selectedFiles)=> this.handleFileChange(selectedFiles, [])}
        />}
        {openAddMultipleDocumentsCofirmationModal &&
        <AddMultipleDocumentsCofirmationModal
        open={openAddMultipleDocumentsCofirmationModal}
        numberOfFiles={acceptedFiles.length}
        toggle={()=> this.setState({openAddMultipleDocumentsCofirmationModal: false, acceptedFiles: [], rejectedFiles: []})}
        next={()=> this.setState({openAddMultipleDocumentsCofirmationModal: false}, ()=> this.handleFileChange(acceptedFiles, rejectedFiles))}
        />}
        {/* )} */}
        <button style={{display: 'none'}} id='uploadReceiptDocs'/>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
    currentSubscription: state.generalReducer.currentSubscription,
    accountData: state.generalReducer.accountData
  };
};

const actions = {
  setAddReceiptModal, 
  setReceiptCurrentId, 
  setReceiptFiles,
  setRefreshReceiptDocumentsData
};

export default connect(mapStateToProps, actions)(ReceiptDetailModal);
